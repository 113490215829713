import { dateFormat, timeFormat } from './date'
import { label } from './labels'
import { FIELD_TYPES } from './other'

export const headingTest = [
  { label: 'Image', dbName: 'prodImg', type: FIELD_TYPES.image, additionalField: 'listImgs' },
  { label: 'First name', dbName: 'firstName', type: FIELD_TYPES.text },
  { label: 'Last name', dbName: 'lastName', type: FIELD_TYPES.text },
  { label: 'Age', dbName: 'age', type: FIELD_TYPES.integer },
  { label: 'Is true', dbName: 'isTrue', type: FIELD_TYPES.boolean },
  { label: 'Time format', dbName: 'time', type: FIELD_TYPES.date, format: timeFormat },
  { label: 'Date format', dbName: 'date', type: FIELD_TYPES.date, format: dateFormat },
  { label: '', dbName: 'actions', type: FIELD_TYPES.actions, actions: ['edit', 'duplicate', 'delete'] }
]
export const headingTest2 = [
  { label: 'Naziv firme', dbName: 'nazivFirme', type: FIELD_TYPES.text },
  { label: 'Drzava', dbName: 'drzava', type: FIELD_TYPES.text },
  { label: 'Date format', dbName: 'date', type: FIELD_TYPES.date, format: dateFormat },
  { label: '', dbName: 'actions', type: FIELD_TYPES.actions, actions: ['edit', 'duplicate', 'delete'], isPopup: true }
]
export const headingTest3 = [
  { label: 'First name', dbName: 'firstName', type: FIELD_TYPES.text },
  { label: 'Last name', dbName: 'lastName', type: FIELD_TYPES.text },
  { label: 'Date format', dbName: 'date', type: FIELD_TYPES.date, format: dateFormat },
  { label: '', dbName: 'actions', type: FIELD_TYPES.actions, actions: ['edit', 'duplicate', 'delete'], isPopup: false }
]

export const headingTipoviLica = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.entitySort, dbName: 'sifra', type: FIELD_TYPES.maped, map: 'entityType', mapLabel: 'label' }
]

export const headingGoodsGroup = [{ label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }]

export const headingSubBusinessActivity = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.businessActivity, dbName: 'delatnost', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingCategory = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }
  // { label: '', dbName: 'actions', type: FIELD_TYPES.actions, actions: ['delete'], isPopup: true }
]
export const headingBusinessActivity = [{ label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }]

export const headingBanka = [{ label: 'Naziv', dbName: 'naziv', type: FIELD_TYPES.text }]
export const headingReportType = [{ label: 'Naziv', dbName: 'naziv', type: FIELD_TYPES.text }]

export const headingProductsPort = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.subGroup, dbName: 'kategorija', nestedObj: 'naziv', type: FIELD_TYPES.text }
  // { label: label.currency, dbName: 'valutum', type: FIELD_TYPES.text, nestedObj: 'naziv' },
  // { label: label.measureUnit, dbName: 'jedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingProductBosnia = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.subGroup, dbName: 'kategorija', nestedObj: 'naziv', type: FIELD_TYPES.text }
  // { label: label.currency, dbName: 'valutum', type: FIELD_TYPES.text, nestedObj: 'naziv' },
  // { label: label.measureUnit, dbName: 'jedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingProductSeaPort = [{ label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }]

export const headingProductFutures = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.subGroup, dbName: 'kategorija', nestedObj: 'naziv', type: FIELD_TYPES.text }
  // { label: label.measureUnit, dbName: 'jedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingProductMilano = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }
  // { label: label.currency, dbName: 'valutum', type: FIELD_TYPES.text, nestedObj: 'naziv' },
  // { label: label.measureUnit, dbName: 'jedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingDelivery = [{ label: 'Naziv', dbName: 'naziv', type: FIELD_TYPES.text }]

export const headingPositionInCompany = [{ label: 'Naziv', dbName: 'naziv', type: FIELD_TYPES.text }]

export const headingUser = [
  { label: 'Ime', dbName: 'ime', type: FIELD_TYPES.text },
  // { label: 'Prezime', dbName: 'prezime', type: FIELD_TYPES.text },
  { label: 'Email', dbName: 'email', type: FIELD_TYPES.text }
]

export const headingMeasureUnit = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.markInput, dbName: 'oznaka', type: FIELD_TYPES.text }
]

export const headingMeasureUnitsRatio = [
  { label: label.fromMeasureUnit, dbName: 'izJedinicaMereId', type: FIELD_TYPES.maped, map: 'measureUnitsMaped', mapLabel: 'naziv' },
  { label: label.intoMeasureUnit, dbName: 'uJedinicaMereId', type: FIELD_TYPES.maped, map: 'measureUnitsMaped', mapLabel: 'naziv' },
  { label: label.ratio, dbName: 'odnos', type: FIELD_TYPES.decimal }
]

export const headingProducts = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.economyBranches, dbName: 'granePrivrede', type: FIELD_TYPES.text },
  { label: label.categoryInput, dbName: 'kategorija', nestedObj: 'naziv', type: FIELD_TYPES.text }
  // { label: label.measureUnits, dbName: 'jediniceMere', type: FIELD_TYPES.text },
  // { label: label.defaultMeasureUnit, dbName: 'jedinicaMereId', type: FIELD_TYPES.maped, map: 'measureUnitsMaped', mapLabel: 'naziv' }
]

export const headingCountry = [
  { label: label.countryInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.phonePrefixInput, dbName: 'pozivniBroj', type: FIELD_TYPES.text },
  { label: label.sortInput, dbName: 'sort', type: FIELD_TYPES.text }
]

export const headingEntityStatus = [{ label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }]

export const headingRegion = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingTownship = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.regionInput, dbName: 'region', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingCity = [
  { label: label.cityInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.zipCodeInput, dbName: 'postanskiBroj', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.regionInput, dbName: 'region', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.townshipInput, dbName: 'opstina', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.sortInput, dbName: 'sort', type: FIELD_TYPES.text }
]

export const headingService = [
  { label: label.serviceInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.kod, dbName: 'kod', type: FIELD_TYPES.text },
  { label: label.subGroup, dbName: 'kategorijaUsluga', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingServiceCategory = [
  { label: label.serviceCategoryInput, dbName: 'naziv', type: FIELD_TYPES.text },
  {
    label: label.serviceParentCategory,
    dbName: 'parentKategorijaId',
    type: FIELD_TYPES.maped,
    map: 'servicesCategories',
    mapLabel: 'naziv'
  }
]

export const headingStatus = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.durationInput, dbName: 'trajanje', type: FIELD_TYPES.text },
  { label: label.servicesIsValid, dbName: 'vazeUsluge', type: FIELD_TYPES.boolean },
  { label: label.nextStatus, dbName: 'sledeciStatusId', type: FIELD_TYPES.maped, map: 'statuses', mapLabel: 'naziv' }
]

export const headingTypeOfContact = [{ label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }]

export const headinProductDesc = [
  { label: label.productsDescInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingPackage = [
  { label: label.packageInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.durationTypeInput, dbName: 'tipTrajanja', type: FIELD_TYPES.maped, map: 'durationType', mapLabel: 'label' },
  { label: label.durationInput, dbName: 'trajanje', type: FIELD_TYPES.text }
]

export const headingAnnouncement = [
  // { label: 'ID', dbName: 'id', type: FIELD_TYPES.text },
  { label: label.date, dbName: 'createdAt', type: FIELD_TYPES.date, style: { width: '100px' } },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.amountInput, dbName1: 'kolicina', dbName3: 'unetaJedinicaMere', type: FIELD_TYPES.join },
  {
    label: label.priceInput,
    dbName1: 'cena',
    dbName2: 'valuta',
    dbName3: 'unetaCenaJedinicaMere',
    type: FIELD_TYPES.join,
    numFormat: 'money'
  },
  { label: label.cities, dbName: 'gradovi', type: FIELD_TYPES.text },

  { label: label.client, dbName: 'klijent', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingAnnouncementModal = [
  { label: label.date, dbName: 'createdAt', type: FIELD_TYPES.date },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.amountInput, dbName1: 'kolicina', dbName3: 'unetaJedinicaMere', type: FIELD_TYPES.join },
  {
    label: label.priceInput,
    dbName1: 'cena',
    dbName2: 'valuta',
    dbName3: 'unetaCenaJedinicaMere',
    type: FIELD_TYPES.join,
    numFormat: 'money'
  },
  { label: label.cities, dbName: 'gradovi', type: FIELD_TYPES.text }
]
export const headingBlog = [
  { label: label.blogTitle, dbName: 'naslov', type: FIELD_TYPES.text },
  { label: label.blogSubtitle, dbName: 'podnaslov', type: FIELD_TYPES.text },
  { label: label.dateOfImportInput, dbName: 'createdAt', type: FIELD_TYPES.date }
]

export const headingRoles = [{ label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }]
export const headingContactPerson = [
  { label: label.firstNameInput, dbName: 'ime', type: FIELD_TYPES.text },
  { label: label.lastNameInput, dbName: 'prezime', type: FIELD_TYPES.text },
  { label: label.emailInput, dbName: 'email', type: FIELD_TYPES.text },
  { label: label.mobileInput, dbName: 'mobilni', type: FIELD_TYPES.text },
  { label: label.functionInput, dbName: 'funkcija', type: FIELD_TYPES.text },
  { label: label.clients, dbName: 'klijent', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingClients = [
  { label: label.client, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.entitiesType, dbName: 'tipLica', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.PIBFarmInput, dbName: 'pib', type: FIELD_TYPES.text },
  { label: label.BPGFarmInputShort, dbName: 'bpg', type: FIELD_TYPES.text },
  { label: label.identificationNumberInput, dbName: 'maticniBroj', type: FIELD_TYPES.text },
  { label: label.mobileInput, dbName: 'kontaktTelefon', type: FIELD_TYPES.text },
  { label: label.emailInput, dbName: 'email', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.cityInput, dbName: 'grad', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.directorInput, dbName: 'direktor', type: FIELD_TYPES.text },
  { label: label.statusInput, dbName: 'statusLica', nestedObj: 'naziv', type: FIELD_TYPES.text }
]
export const registerClients = [
  { label: label.client, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.entitiesType, dbName: 'tipLica', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.directorInput, dbName: 'direktor', type: FIELD_TYPES.text },
  // { label: label.mobileInput, dbName: 'kontaktTelefon', type: FIELD_TYPES.text },
  // { label: label.emailInput, dbName: 'email', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.cityInput, dbName: 'grad', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.statusInput, dbName: 'status', type: FIELD_TYPES.status },
  { label: label.date, dbName: 'createdAt', type: FIELD_TYPES.date, format: timeFormat, style: { width: '180px' } }
]
export const headingClientsForReport = [
  { label: label.client, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.PIBFarmInput, dbName: 'pib', type: FIELD_TYPES.text },
  { label: label.BPGFarmInputShort, dbName: 'bpg', type: FIELD_TYPES.text },
  { label: label.identificationNumberInput, dbName: 'maticniBroj', type: FIELD_TYPES.text },
  { label: label.mobileInput, dbName: 'kontaktTelefon', type: FIELD_TYPES.text },
  { label: label.emailInput, dbName: 'email', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.cityInput, dbName: 'grad', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.directorInput, dbName: 'direktor', type: FIELD_TYPES.text }
]
export const headingClientsRecord = [
  { label: label.client, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.entitiesType, dbName: 'tipLicaId', type: FIELD_TYPES.maped, map: 'entityType', mapLabel: 'naziv' },
  { label: label.PIBFarmInput, dbName: 'pib', type: FIELD_TYPES.text },
  { label: label.BPGFarmInputShort, dbName: 'bpg', type: FIELD_TYPES.text },
  { label: label.identificationNumberInput, dbName: 'maticniBroj', type: FIELD_TYPES.text },
  { label: label.mobileInput, dbName: 'kontaktTelefon', type: FIELD_TYPES.text },
  { label: label.emailInput, dbName: 'email', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.cityInput, dbName: 'grad', nestedObj: 'naziv', type: FIELD_TYPES.text }
]
export const headingWorkFields = [{ label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text }]

export const headingSeaPort = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.cityInput, dbName: 'grad', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingStockExchange = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'drzava', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.currency, dbName: 'valutum', nestedObj: 'kod', type: FIELD_TYPES.text }
  // { label: label.cityInput, dbName: 'grad', nestedObj: 'naziv', type: FIELD_TYPES.text }
]

export const headingCurrency = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.kodInput, dbName: 'kod', type: FIELD_TYPES.text },
  { label: label.simbolInput, dbName: 'simbol', type: FIELD_TYPES.text }
]
export const headingCurrencyList = [
  // { label: label.countryInput, dbName: 'drzava', type: FIELD_TYPES.text },
  { label: label.currency, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.codeInput, dbName: 'sifra', type: FIELD_TYPES.text },
  { label: label.currencyBuy, dbName: 'kupovniKurs', type: FIELD_TYPES.text },
  { label: label.currencySell, dbName: 'prodajniKurs', type: FIELD_TYPES.text },
  { label: label.currencyMiddle, dbName: 'srednjiKurs', type: FIELD_TYPES.text },
  { label: label.date, dbName: 'datum', type: FIELD_TYPES.date }
]
export const headingWorldSeaPort = [
  // { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  // { label: label.seaPort, dbName: 'luka', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  // { label: label.measureUnitTitle, dbName: 'unetaJedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text },
  // { label: label.dateOfImportInput, dbName: 'datumUnosa', type: FIELD_TYPES.date },
  // { label: label.priceDatePeriodStart, dbName: 'periodVazenjaCenaStart', type: FIELD_TYPES.date },
  { label: label.dateOfImportInput, dbName: 'datumUnosa', type: FIELD_TYPES.date },
  { label: label.productSeaPortInput, dbName: 'lukaUsloviUtovaraId', type: FIELD_TYPES.maped, map: 'seaportConditions', mapLabel: 'naziv' },
  // { label: label.delivery, dbName: 'isporuka', type: FIELD_TYPES.maped, map: 'seaportDelivery', mapLabel: 'naziv' },
  // { label: label.priceInput, dbName: 'cena', type: FIELD_TYPES.text },
  // { label: label.measureUnit, dbName: 'jedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text },
  // { label: label.currency, dbName: 'valutum', nestedObj: 'kod', type: FIELD_TYPES.text },
  // { label: label.seaPortCurrency, dbName: 'valutaPlacanja', type: FIELD_TYPES.text }
  { label: 'Cena uneta', dbName1: 'cena', dbName2: 'valutum', dbName3: 'jedinicaMere', type: FIELD_TYPES.join, numFormat: 'money' },
  { label: 'Cena konvertovana', dbName1: 'cenaKonvertovana', dbName2: 'valutaPlacanja', type: FIELD_TYPES.joinTwo, numFormat: 'money' }
]

export const headingWorldStockExchange = [
  { label: label.stockExchange, dbName: 'berza', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.dateOfImportInput, dbName: 'datumUnosa', type: FIELD_TYPES.date },
  { label: label.delivery, dbName: 'isporuka', type: FIELD_TYPES.text },
  { label: 'Cena uneta', dbName1: 'cena', dbName2: 'valutum', dbName3: 'jedinicaMere', type: FIELD_TYPES.join, numFormat: 'money' },
  { label: 'Cena konvertovana', dbName1: 'cenaKonvertovana', dbName2: 'valutaPlacanja', type: FIELD_TYPES.joinTwo, numFormat: 'money' }
]

export const headingWorldStockExchangeMilano = [
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.dateOfImportInput, dbName: 'datumUnosa', type: FIELD_TYPES.date },
  // { label: label.priceMaxInput, dbName: 'cenaMax', type: FIELD_TYPES.text },
  // { label: label.priceMinInput, dbName: 'cenaMin', type: FIELD_TYPES.text },
  // { label: label.measureUnit, dbName: 'jedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text },
  // { label: label.currency, dbName: 'valutum', nestedObj: 'kod', type: FIELD_TYPES.text },
  {
    label: 'Cena MIN/MAX',
    dbName1: 'cenaMin',
    dbName2: 'cenaMax',
    dbName3: 'valutum',
    dbName4: 'jedinicaMere',
    type: FIELD_TYPES.joinFour
  },
  {
    label: 'Cena Konvertovana MIN/MAX',
    dbName1: 'cenaMinKonvertovana',
    dbName2: 'cenaMaxKonvertovana',
    dbName3: 'valutaPlacanja',
    type: FIELD_TYPES.joinThree
  }
]

export const headingPriceMovementBih = [
  { label: label.subGroup, dbName: 'kategorija', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: 'datum', dbName: 'datum', type: FIELD_TYPES.date },
  {
    label: 'Cena MIN/MAX',
    dbName1: 'cenaMin',
    dbName2: 'cenaMax',
    dbName3: 'valutum',
    dbName4: 'jedinicaMere',
    type: FIELD_TYPES.joinFour
  },
  {
    label: 'Cena Konvertovana MIN/MAX',
    dbName1: 'cenaMinKonvertovana',
    dbName2: 'cenaMaxKonvertovana',
    dbName3: 'valutaPlacanja',
    type: FIELD_TYPES.joinThree
  },
  { label: 'Period', dbName1: 'periodStart', dbName2: 'periodEnd', type: FIELD_TYPES.range }
]

export const headingCustomGoodsImportAndExport = [
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.customGoodsImportAndExport },
  { label: label.kod, dbName: 'kod', type: FIELD_TYPES.text },
  { label: label.countryInput, dbName: 'zemlja', type: FIELD_TYPES.text },
  { label: label.measureUnit, dbName: 'jedinicaMere', type: FIELD_TYPES.text },
  { label: label.dutyRate, dbName: 'stopaCarine', type: FIELD_TYPES.text },
  { label: label.netMass, dbName: 'netoMasaUKg', type: FIELD_TYPES.text },
  { label: label.worthInEuro, dbName: 'vrednostEur', type: FIELD_TYPES.text }
]
export const headingRealizations = [
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.seller, dbName: 'prodavac', type: FIELD_TYPES.text, nestedObj: 'naziv' },
  { label: label.buyer, dbName: 'kupac', type: FIELD_TYPES.text, nestedObj: 'naziv' },
  { label: label.amountInput, dbName1: 'kolicina', dbName3: 'unetaJedinicaMere', type: FIELD_TYPES.join },
  {
    label: label.priceInput,
    dbName1: 'cena',
    dbName2: 'valuta',
    dbName3: 'unetaCenaJedinicaMere',
    type: FIELD_TYPES.join,
    numFormat: 'money'
  },
  { label: label.paymentType, dbName: 'tipPlacanja', type: FIELD_TYPES.maped, map: 'paymentType', mapLabel: 'label' },
  { label: label.cityInput, dbName: 'grad', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.regionInput, dbName: 'region', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.dateRealizations, dbName: 'datum', type: FIELD_TYPES.date, format: dateFormat },
  { label: label.fca3, dbName: 'fcaKupac', type: FIELD_TYPES.boolean },
  { label: label.fca2, dbName: 'fcaDogovor', type: FIELD_TYPES.boolean }
]

export const headingIps = [
  { label: label.client, dbName: 'klijent', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.tbIpAdress, dbName: 'adresa', type: FIELD_TYPES.text },
  { label: label.deviceId, dbName: 'uredjajID', type: FIELD_TYPES.text },
  { label: label.ipAcesss, dbName: 'blokiran', type: FIELD_TYPES.boolean, isTrueLabel: 'Mobilnog', isFalseLabel: 'Desktopa' },
  { label: label.blocked, dbName: 'blokiran', type: FIELD_TYPES.boolean },
  { label: '', dbName: 'blokiran', type: FIELD_TYPES.disableEnable, style: { width: '150px' } }
]

export const headingActivitiesClient = [
  { label: 'ID', dbName: 'id', type: FIELD_TYPES.text },
  { label: label.client, dbName: 'klijent', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.action, dbName: 'akcija', type: FIELD_TYPES.actions },
  { label: label.page, dbName: 'podaci', type: FIELD_TYPES.path },
  { label: label.date, dbName: 'createdAt', type: FIELD_TYPES.date, format: timeFormat, style: { width: '180px' } }
]
export const headingActivitiesUser = [
  { label: 'ID', dbName: 'id', type: FIELD_TYPES.text },
  { label: label.korisnik, dbName: 'korisnik', nestedObj: 'ime', type: FIELD_TYPES.text },
  { label: label.action, dbName: 'akcija', type: FIELD_TYPES.actions },
  { label: label.page, dbName: 'podaci', type: FIELD_TYPES.path },
  { label: label.date, dbName: 'createdAt', type: FIELD_TYPES.date, format: timeFormat, style: { width: '180px' } }
]
