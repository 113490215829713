export const localStorageService = {
  destroy() {
    localStorage.clear()
  },

  set(type, data) {
    localStorage.setItem(type, JSON.stringify(data))
  },
  get(type) {
    const data = localStorage.getItem(type)
    if (!data) return null
    const res = JSON.parse(data)
    return res
  },

  isAuth() {
    const data = localStorage.getItem('sct') && localStorage.getItem('sct') !== 'undefined' ? JSON.parse(localStorage.getItem('sct')) : null
    const refres =
      localStorage.getItem('scrt') && localStorage.getItem('scrt') !== 'undefined' ? JSON.parse(localStorage.getItem('scrt')) : null
    if (!data || !refres) {
      localStorage.clear()
      return false
    } else return data
  }
}
