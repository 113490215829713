import React, { createRef, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import styles from './sidebar.module.scss'
import useWindowDimensions from 'hooks/onWindowResize'
import { label } from 'constants/labels'
import { FORM_CODE } from 'constants/other'
import { getRole } from 'redux/reducers/authSlice'
import { useSelector } from 'react-redux'
import { selectTriggeredUser } from 'redux/reducers/staticSlice'

const linksList = [
  { path: '/pocetni-ekran', label: label.dashboard, icon: <FontAwesomeIcon icon="fa-dashboard" />, sifra: FORM_CODE.dashboard },
  { path: '/registracije', label: label.register, icon: <FontAwesomeIcon icon="fa-registered" />, sifra: FORM_CODE.registration },
  { path: '/klijenti', label: label.clients, icon: <FontAwesomeIcon icon="building-user" />, sifra: FORM_CODE.client },
  {
    label: label.seaPortAndStockExchange,
    parent: true,
    id: 3,
    icon: <FontAwesomeIcon icon="fa-money-bill-trend-up" />,
    children: [
      { path: '/svetske-luke', label: label.worldSeaPort, sifra: FORM_CODE.worldSeaPort },
      { path: '/svetske-berze', label: label.wroldStockExchange, sifra: FORM_CODE.worldStockExchange },
      { path: '/svetska-berza-milano', label: label.wroldStockExchangeMilano, sifra: FORM_CODE.worldStockExchangeMilano },
      { path: '/kretanje-cena-bih', label: label.priceMovementBih, sifra: FORM_CODE.priceMovementBih }
    ]
  },
  {
    label: label.sifarnici,
    parent: true,
    id: 4,
    icon: <FontAwesomeIcon icon="fa-table-list" />,
    sifra: FORM_CODE.sifarnici,
    children: [
      { path: 'tipovi-lica', label: label.entitiesType, sifra: FORM_CODE.entitiesType },
      { path: 'valuta', label: label.currency, sifra: FORM_CODE.currency },
      { path: 'jedinica-mere', label: label.measureUnit, sifra: FORM_CODE.measureUnit },
      { path: 'odnos-jedinica-mera', label: label.measureUnitRatio, sifra: FORM_CODE.measureUnitRatio },
      { path: 'grana-privrede', label: label.economyBranch, sifra: FORM_CODE.economyBranch },
      // { path: 'kategorija1', label: 'Kategorija old', sifra: FORM_CODE.subGroup },
      { path: 'kategorija?tip=srbija', label: label.subGroup, sifra: FORM_CODE.subGroup },
      // { path: 'kategorija-luka', label: label.subGroupSeaport, sifra: FORM_CODE.subGroup },
      // { path: 'kategorija-bosna', label: label.subGroupBosnia, sifra: FORM_CODE.subGroup },
      // { path: 'kategorija-futures', label: label.subGroupFutures, sifra: FORM_CODE.subGroup },
      { path: 'proizvod?tip=srbija', label: label.products, sifra: FORM_CODE.goods },
      // { path: 'proizvod1', label: 'Proizvod old', sifra: FORM_CODE.goods },
      // { path: 'proizvod-bosna', label: label.productBosniaInput, sifra: FORM_CODE.productBosnia },
      // { path: 'proizvod-luka', label: label.productPort, sifra: FORM_CODE.productFOB },
      { path: 'luka-uslovi-utovara', label: label.productSeaPortInput, sifra: FORM_CODE.producstSeaPort },
      // { path: 'isporuka', label: label.delivery, sifra: FORM_CODE.delivery },
      { path: 'luka', label: label.seaPort, sifra: FORM_CODE.seaPort },
      { path: 'berza', label: label.stockExchange, sifra: FORM_CODE.stockExchange },
      // { path: 'proizvod-futures', label: label.productFuturesInput, sifra: FORM_CODE.producstFutures },
      // { path: 'proizvod-milano', label: label.productMilanoInput, sifra: FORM_CODE.producstFutures },
      // { path: 'opis-proizvoda', label: label.productsDescInput, sifra: FORM_CODE.goodsDescripton },
      { path: 'delatnost', label: label.businessActivity, sifra: FORM_CODE.businessActivity },
      { path: 'poddelatnost', label: label.subBusinessActiviti, sifra: FORM_CODE.subBusinessActiviti },
      { path: 'drzava', label: label.countryInput, sifra: FORM_CODE.country },
      { path: 'region', label: label.regionInput, sifra: FORM_CODE.region },
      { path: 'opstina', label: label.township, sifra: FORM_CODE.township },
      { path: 'grad', label: label.cityInput, sifra: FORM_CODE.city },
      { path: 'oblast', label: label.workField, sifra: FORM_CODE.workField },

      { path: 'banka', label: label.bank, sifra: FORM_CODE.bank },
      { path: 'status-lica', label: label.entityStatus, sifra: FORM_CODE.entityStatus },
      { path: 'vrsta-kontakta', label: label.typeOfContactInput, sifra: FORM_CODE.typeOfContact },
      // { path: 'kontakt-osoba', label: label.contactPersonInput },
      // { path: 'oglas', label: label.announcementInput, sifra: FORM_CODE.announcement },
      { path: 'blog', label: label.blogInput, sifra: FORM_CODE.blog },
      { path: 'pozicijafirma', label: label.positionInCompany, sifra: FORM_CODE.positionInCompany },
      { path: 'tip-izvestaja', label: label.typeOfReport, sifra: FORM_CODE.typeOfReport }
    ]
  },
  {
    label: label.customGoods,
    parent: true,
    id: 5,
    icon: <FontAwesomeIcon icon="fa-file-csv" />,
    children: [
      { path: '/carinske-robe-uvoz', label: label.import, sifra: FORM_CODE.customGoodsImport },
      { path: '/carinske-robe-izvoz', label: label.export, sifra: FORM_CODE.customGoodsExport }
    ]
  },
  {
    label: label.reports,
    parent: true,
    id: 6,
    icon: <FontAwesomeIcon icon="fa-file-signature" />,
    children: [
      { path: '/izvestaji/istorija-izvestaja', label: label.listOfReports, sifra: FORM_CODE.listOfReports },
      { path: '/izvestaji/carinske-robe-uvoz', label: label.customGoodsImportReport, sifra: FORM_CODE.customGoodsImportReport },
      { path: '/izvestaji/carinske-robe-izvoz', label: label.customGoodsExportReport, sifra: FORM_CODE.customGoodsExportReport },
      { path: '/izvestaji/svetske-luke', label: label.worldSeaPort, sifra: FORM_CODE.worldSeaPortReport },
      { path: '/izvestaji/svetske-berze', label: label.wroldStockExchange, sifra: FORM_CODE.wroldStockExchangeReport },
      { path: '/izvestaji/svetske-berze-milano', label: label.wroldStockExchangeMilano, sifra: FORM_CODE.wroldStockExchangeMilanoReport },
      // {
      //   path: '/izvestaji/svetske-berze-dvonedeljni-osvrt',
      //   label: label.twoWeeksWroldStockExchange,
      //   sifra: FORM_CODE.twoWeeksWroldStockExchange
      // },
      // {
      //   path: '/izvestaji/svetske-berze-dvonedeljni-osvrt',
      //   label: label.twoWeeksWroldStockExchange,
      //   sifra: FORM_CODE.twoWeeksWroldStockExchange
      // },
      { path: '/izvestaji/kretanje-cena-bih', label: label.priceMovementBih, sifra: FORM_CODE.priceMovementBihReport },
      // { path: '/izvestaji/kretanje-cena-srb', label: label.priceMovementSrb, sifra: FORM_CODE.priceMovementSrb },
      // // { path: '/izvestaji/kupci-poljoprivreda', label: label.buyersAgriculture },
      // { path: '/izvestaji/prodavci-poljoprivreda', label: label.sellersAgriculture },
      // { path: '/izvestaji/kupci-poljoprivreda', label: label.buyersAgriculture },
      // { path: '/izvestaji/kupci-prehrana', label: label.buyersNutrition },
      // { path: '/izvestaji/prodavci-prehrana', label: label.sellersNutrition },
      { path: '/izvestaji/oglasi', label: label.reportAnn, sifra: FORM_CODE.izvestajOglasi }
    ]
  },
  {
    label: label.preview,
    parent: true,
    id: 7,
    icon: <FontAwesomeIcon icon="fa-eye" />,
    children: [{ path: '/pregled/realizacije', label: label.realizations, sifra: FORM_CODE.realizations }]
  },
  {
    label: label.currencyList,
    id: 8,
    icon: <FontAwesomeIcon icon="fa-hand-holding-dollar" />,
    path: 'kursna-lista',
    sifra: FORM_CODE.currencyList
  },

  {
    label: label.activity,
    parent: true,
    id: 10,
    icon: <FontAwesomeIcon icon="fa-chalkboard-user" />,
    children: [
      { path: 'ips', label: label.ipAddress, sifra: FORM_CODE.ipAddress },
      { path: 'aktivnosti-korisnika', label: label.activitiesUsers, sifra: FORM_CODE.activitiesUsers },
      { path: 'aktivnosti-klijenta', label: label.activitiesClients, sifra: FORM_CODE.activitiesClients }
    ]
  },
  {
    label: label.packages,
    parent: true,
    id: 11,
    icon: <FontAwesomeIcon icon="fa-boxes-packing" />,
    children: [
      { path: 'usluge', label: label.services, sifra: FORM_CODE.services },
      { path: 'kategorija-usluge', label: label.servicesCategory, sifra: FORM_CODE.servicesCategory },
      { path: 'paket', label: label.packages, sifra: FORM_CODE.packages },
      { path: 'status', label: label.statusInput, sifra: FORM_CODE.status }
    ]
  },
  {
    label: label.administracija,
    parent: true,
    id: 9,
    icon: <FontAwesomeIcon icon="fa-house-laptop" />,
    sifra: FORM_CODE.administratiton,
    children: [
      { path: 'korisnici', label: label.korisnici, sifra: FORM_CODE.users },
      { path: 'organizacija', label: label.organization, sifra: FORM_CODE.organization },
      { path: 'uloge', label: label.roles, sifra: FORM_CODE.roles }
    ]
  }
]

const Sidebar = () => {
  const [active, setActive] = useState()
  const { isMobile } = useWindowDimensions()
  const [toggle, setToggle] = useState(isMobile)
  const [links, setLinks] = useState(linksList)
  const accessRights = useSelector(getRole)
  const trigger = useSelector(selectTriggeredUser)
  const refs = useMemo(() => {
    const refs = {}
    links.forEach((e, i) => {
      refs[i] = createRef(null)
    })
    setLinks(linksList)
    return refs
  }, [trigger])

  const toggleChildren = id => {
    if (active === id) setActive('')
    else setActive(id)
  }
  useEffect(() => setToggle(isMobile), [isMobile])

  return (
    <aside className={styles.wrapper}>
      {isMobile && (
        <div className={styles.toggle} onClick={() => setToggle(!toggle)}>
          <FontAwesomeIcon icon={`${toggle ? 'fa-bars' : 'fa-close'}`} />
        </div>
      )}
      <ul className={`${toggle ? styles.listHidden : styles.list}`}>
        {links.map((e, i) => (
          <React.Fragment key={`links_${i}`}>
            {!e.parent ? (
              <>
                {accessRights?.pravaPristupa?.[e.sifra]?.find(action => action === 'VIEW') && (
                  <li className={`${styles.noChildren} ${links.length === i + 1 ? styles.lastChilde : ''}`}>
                    <NavLink to={e.path} onClick={() => isMobile && setToggle(!toggle)}>
                      {e.icon && e.icon} {e.label}
                    </NavLink>
                  </li>
                )}
              </>
            ) : (
              <li
                className={`${styles.haveChildren} ${active === e.id ? styles.activeParent : ''} ${
                  links.length === i + 1 ? styles.lastChilde : ''
                }`}
                style={{ display: `${refs[i]?.current?.children?.length ? 'block' : 'none'}` }}
              >
                <div className={styles.parent} onClick={() => toggleChildren(e.id)}>
                  <span>
                    {e.icon && e.icon} {e.label}
                  </span>
                  <FontAwesomeIcon icon={`${active === e.id ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                </div>
                {e.children.length && (
                  <ul className={styles.children} ref={refs[i]}>
                    {e.children.map((child, i) => (
                      <React.Fragment key={i}>
                        {accessRights?.pravaPristupa?.[child.sifra]?.find(action => action === 'VIEW') && (
                          <li>
                            <NavLink to={child.path} onClick={() => isMobile && setToggle(!toggle)}>
                              {child.label}
                            </NavLink>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </aside>
  )
}

export default Sidebar
