import { createSlice } from '@reduxjs/toolkit'
import { localStorageService } from 'services/localStorage.service'

const initialToken =
  localStorage.getItem('sct') && localStorage.getItem('sct') !== 'undefined' ? JSON.parse(localStorage.getItem('sct')) : null
const initialRefreshToken =
  localStorage.getItem('sct') && localStorage.getItem('scrt') !== 'undefined' ? JSON.parse(localStorage.getItem('scrt')) : null

const initialId =
  localStorage.getItem('sci') && localStorage.getItem('sci') !== 'undefined' ? JSON.parse(localStorage.getItem('sci')) : null

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: initialToken, id: initialId, refreshToken: initialRefreshToken },
  reducers: {
    setCredentials: (state, action) => {
      const { token, id, refreshToken } = action.payload
      state.user = action.payload
      state.token = token
      state.refreshToken = refreshToken
      state.id = id
      localStorageService.set('sct', token)
      localStorageService.set('sci', id)
      localStorageService.set('scrt', refreshToken)
    },
    logOut: state => {
      localStorageService.destroy()
      state.user = null
      state.token = null
      state.id = null
      state.refreshToken = null
    },
    setUser: (state, action) => {
      state.user = action.payload
    }
  }
})

export const { setCredentials, logOut, setUser } = authSlice.actions

export default authSlice.reducer

export const selectCurrentId = state => state.auth.id
export const getRole = state => state.auth?.user?.uloga
export const selectCurrentUser = state => state.auth.user || null
