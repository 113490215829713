/** Queries for 'File import' */

import { tag } from 'api/rtkTags'

export const querySentData = (data, header, datumUnosa, datum, dbName) => {
  return {
    query: {
      name: 'data',
      mode: 'uploadFile',
      type: 'uploadFile',
      data: { values: data, header, datumUnosa, datum, dbName }
    },
    invalidatesTags: [tag.customGoodsExport, tag.customGoodsImport]
  }
}
