/** Page  'Carinske Robe Uvoz' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, OneColumn } from 'components/containers/containers'
import FormCustomGoodsImport from 'components/forms/formCustomGoodsImport'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { customGoodsImportAndExportFields } from 'constants/searchFields'
import { headingCustomGoodsImportAndExport } from 'constants/tbHeading'
import { queryGetPagedCustomGoodsImport } from 'query/queryCustomGoodsImport'
import { querySentData } from 'query/queryFile'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'

const CustomGoodsImport = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: customGoodsImport = [] } = useGetAllQuery(queryGetPagedCustomGoodsImport(pageNumber, pageSize, searchOptions))
  const [createData] = useUpdateCreateOneMutation()

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (customGoodsImport.rows && customGoodsImport.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, customGoodsImport.rows])

  const onSubmit = async data => {
    dispatch(setIsLoadingData(true))
    const res = await createData(querySentData(data?.importovaneRobe, data?.header, data?.datumUnosa, data.datum, data?.dbName))
    if (!res.error) dispatch(closeModal(1))
    dispatch(setIsLoadingData(false))
  }

  const showModal = () => {
    navigate(`/carinske-robe-uvoz`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.customGoodsImport}</h2>
              <FormCustomGoodsImport onSubmit={onSubmit} id={null} />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/carinske-robe-uvoz`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  return (
    <div>
      <Title title={label.customGoodsImport} total={customGoodsImport?.count}>
        <Button
          onClick={showModal}
          label={label.import}
          stylesClass="btnSecondary"
          iconLeft={<FontAwesomeIcon icon="fa-solid fa-file-csv" />}
          accessAction="ADD"
          accessCode={code}
        />
      </Title>
      <Search fields={customGoodsImportAndExportFields} onSearch={onSubmitSearch} />
      <OneColumn>
        <Table
          heading={headingCustomGoodsImportAndExport}
          data={customGoodsImport.rows}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={customGoodsImport?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormCustomGoodsImport existingData={data} header={true} id={id} onSubmit={onSubmit} />
          </FormWrapper>
        )}
      </OneColumn>
    </div>
  )
}
export default CustomGoodsImport
