import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useGetUserByIdQuery } from 'api/authApiSlice'
import { selectCurrentId, selectCurrentUser } from 'redux/reducers/authSlice'
import { useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { queryAddActivity } from 'query/queryActivity'
import { getVisitedPath } from './helpers'

const Public = () => {
  const location = useLocation()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const entityId = searchParams.get('id')
  const id = useSelector(selectCurrentId)
  const user = useSelector(selectCurrentUser)
  const [skip, setSkip] = useState(true)
  const [create] = useUpdateCreateOneMutation()

  useGetUserByIdQuery(id, { skip })
  useEffect(() => {
    if (id && !user) setSkip(false)
    return () => {
      setSkip(true)
    }
  }, [id, user])

  useEffect(() => {
    if (location && id) {
      const setActivity = async path => {
        await create(queryAddActivity(id, 'visited', null, { path: path, id: params?.id || entityId }))
      }
      if (location.pathname !== '/aktivnosti-korisnika') {
        const path = getVisitedPath(location)
        if (path) setActivity(path)
      }
    }
  }, [location])

  return (
    <div className="public-wrapper">
      <Outlet />
    </div>
  )
}
export default Public
