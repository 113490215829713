import React from 'react'
import PropTypes from 'prop-types'
import Button from '../button'
import styles from './modal.module.scss'
import { label } from 'constants/labels'

const ModalYesNo = ({ handleSubmit, question, data }) => {
  return (
    <div className={styles.modalYesNo}>
      <h4 className={styles.title}>{question}</h4>
      <div className={styles.btns}>
        <Button stylesClass="btnPrimary" label={label.yes} onClick={() => handleSubmit(true, data)} />
        <Button stylesClass="btnLight" label={label.no} onClick={() => handleSubmit(false, data)} />
      </div>
    </div>
  )
}

ModalYesNo.propTypes = {
  handleSubmit: PropTypes.func,
  question: PropTypes.string,
  data: PropTypes.object
}

export default ModalYesNo
