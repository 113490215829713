import { label } from 'constants/labels'
import React from 'react'
import styles from './record.module.scss'
import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetAllMapQuery, useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllActivityByClientId, queryGetAllProductsByClientId } from 'query/queryClientConectionTables'
import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import { queryGetAllSubBusinessActivity } from 'query/querySubBusinessActivity'
import { queryGetAllProducts } from 'query/queryProducts'

const BasicInfo = ({ client, city, entityType, onPlanerOpen }) => {
  const { data: products } = useGetAllMapQuery(queryGetAllProducts())
  const { data: clientProductsBuyData = [] } = useGetAllQuery(queryGetAllProductsByClientId(client.id, 'kupovina'))
  const { data: clientProductsSellData = [] } = useGetAllQuery(queryGetAllProductsByClientId(client.id, 'prodaja'))
  const { data: activity = [] } = useGetAllQuery(queryGetAllBusinessActivity())
  const { data: subActivity = [] } = useGetAllQuery(queryGetAllSubBusinessActivity())
  const { bussinesActivityPrimary, bussinesActivitySecondary } = useGetAllQuery(queryGetAllActivityByClientId(client.id), {
    selectFromResult: ({ data }) => {
      return {
        bussinesActivityPrimary: (data && data[0]) || null,
        bussinesActivitySecondary: (data && data[1]) || null
      }
    }
  })
  return (
    <div className={styles.basicInfo}>
      <div className={styles.leftBlock}>
        <h1>{client?.naziv}</h1>
        <h5>
          {label.typeEntityInput}: <b>{entityType?.naziv || '-'} </b>
        </h5>
        <h5>
          {label.adresaInput}:{' '}
          <b>
            {(city?.drzava?.naziv || 'N/A') +
              ', ' +
              (city?.region?.naziv || 'N/A') +
              ', ' +
              (city?.naziv || 'N/A') +
              ', ' +
              (client?.adresa || 'N/A')}
          </b>
        </h5>
        <h5>
          {label.companyPhoneInput}: <b>{client?.kontaktTelefon || 'N/A'}</b>
        </h5>
        <h5>
          {client?.tipLicaSifra === 'GZ' ? label.JMBGInput : label.PIBInput}: <b> {client?.pib || 'N/A'}</b>
        </h5>
        {client?.tipLicaSifra === 'GZ' && (
          <h5>
            {label.BPGInput}: <b>{client?.bpg || 'N/A'}</b>
          </h5>
        )}
        <h5>
          {label.maticniBroj}: <b>{client.maticniBroj}</b>
        </h5>
      </div>
      <div className={styles.middleBlock}>
        <div>
          <h5>
            {label.primaryActivity}:{' '}
            <b>
              {bussinesActivityPrimary ? activity?.find(e => e.id === bussinesActivityPrimary?.delatnostId || null)?.naziv : 'N/A'}
              {bussinesActivityPrimary
                ? ' - ' + subActivity?.find(e => e.id === bussinesActivityPrimary?.podDelatnostId || null)?.naziv
                : 'N/A'}
            </b>
          </h5>
          {bussinesActivitySecondary && (
            <h5>
              {label.secondaryActivity}:{' '}
              <b>
                {bussinesActivitySecondary ? activity?.find(e => e.id === bussinesActivitySecondary?.delatnostId || null)?.naziv : 'N/A'}

                {bussinesActivitySecondary
                  ? ' - ' + subActivity?.find(e => e.id === bussinesActivitySecondary?.podDelatnostId || null)?.naziv
                  : 'N/A'}
              </b>
            </h5>
          )}
        </div>
        <h5>
          {label.purchaseProductsInput}:{' '}
          <b>
            {clientProductsBuyData?.map((e, i) => (
              <span>
                {products?.entities?.[e.proizvodId]?.naziv}
                {clientProductsBuyData.length > i + 1 ? ',' : '.'}
              </span>
            ))}
          </b>
        </h5>
        <h5>
          {label.saleProductsInput}:{' '}
          <b>
            <b>
              {clientProductsSellData?.map((e, i) => (
                <span>
                  {products?.entities?.[e.proizvodId]?.naziv}
                  {clientProductsSellData.length > i + 1 ? ',' : '.'}
                </span>
              ))}
            </b>
          </b>
        </h5>
        {client.obradivaPovrsina && (
          <h5>
            {label.lendOwning}:<b> {client.obradivaPovrsina}</b>
          </h5>
        )}
        {client.opisPoslovanja && <p>{client.opisPoslovanja}</p>}
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.schedule}>
          <Button
            label={label.planer}
            stylesClass="btnWarning"
            iconLeft={<FontAwesomeIcon icon="fa-calendar-days" />}
            onClick={onPlanerOpen}
          />
        </div>
      </div>
    </div>
  )
}

export default BasicInfo
