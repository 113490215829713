import React, { useEffect, useRef, useState } from 'react'
import styles from './btnActions.module.scss'
import useClickOutside from 'hooks/outsideClick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BtnActions = ({ actions = [], data, onClick, isPopup = true }) => {
  const [toggle, setToggle] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(toggle)
  const dotsPosition = useRef()

  useEffect(() => {
    if (toggle) window.addEventListener('resize', setPositionOfTbPopup, true)
    return () => {
      window.removeEventListener('resize', setPositionOfTbPopup, true)
    }
  }, [toggle])

  useEffect(() => {
    setIsComponentVisible(toggle)
  }, [toggle])

  useEffect(() => {
    if (!isComponentVisible) setToggle(false)
  }, [isComponentVisible])

  const setPositionOfTbPopup = () => {
    const { top, left } = dotsPosition.current.getBoundingClientRect()
    setPosition({ top: `${top}px`, left: `${left - 135}px` })
  }

  const onActionClick = (e, type) => {
    e.stopPropagation()
    isPopup && setToggle(false)
    onClick(data, type)
  }

  const onToggle = e => {
    e.stopPropagation()
    setToggle(!toggle)
    setPositionOfTbPopup()
  }

  return (
    <div className={styles.wrapper}>
      {isPopup ? (
        <>
          <FontAwesomeIcon icon="fa-ellipsis" onClick={e => onToggle(e)} ref={dotsPosition} />
          <div className={`${toggle ? styles.visible : styles.hidden}`} style={{ top: position.top, left: position.left }} ref={ref}>
            {actions.includes('view') && (
              <div className={styles.action} onClick={e => onActionClick(e, 'view')}>
                <FontAwesomeIcon icon="fa-eye" />
                <span>View</span>
              </div>
            )}
            {actions.includes('edit') && (
              <div className={styles.action} onClick={e => onActionClick(e, 'edit')}>
                <FontAwesomeIcon icon="fa-pen-to-square" />
                <span>Edit</span>
              </div>
            )}
            {actions.includes('delete') && (
              <div className={styles.action} onClick={e => onActionClick(e, 'delete')}>
                <FontAwesomeIcon icon="fa-trash-can" />
                <span>Remove</span>
              </div>
            )}
            {actions.includes('duplicate') && (
              <div className={styles.action} onClick={e => onActionClick(e, 'duplicate')}>
                <FontAwesomeIcon icon="fa-clone" />
                <span>Duplicate</span>
              </div>
            )}
            {actions.includes('phone') && (
              <div className={styles.action} onClick={e => onActionClick(e, 'phone')}>
                <FontAwesomeIcon icon="fa-clone" />
                <span>Call</span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={styles.btnGroup}>
          {actions.includes('phone') && (
            <div className={styles.icon} onClick={e => onActionClick(e, 'phone')}>
              <FontAwesomeIcon icon="fa-square-phone-flip" />
            </div>
          )}
          {actions.includes('view') && (
            <div className={styles.icon} onClick={e => onActionClick(e, 'delete')}>
              <FontAwesomeIcon icon="fa-eye" />
            </div>
          )}
          {actions.includes('edit') && (
            <div className={styles.icon} onClick={e => onActionClick(e, 'edit')}>
              <FontAwesomeIcon icon="fa-pen-to-square" />
            </div>
          )}
          {actions.includes('delete') && (
            <div className={styles.icon} onClick={e => onActionClick(e, 'delete')}>
              <FontAwesomeIcon icon="fa-trash-can" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default BtnActions
