import React from 'react'
import styles from './tabs.module.scss'

const Tabs = ({ tabs = [], active, onClick, stylesClass = 'verticalTabs', globalClass = '' }) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass} `}>
      {tabs.map(e => (
        <div key={e.type} className={`${styles.tab} ${e.type === active ? styles.active : ''}`} onClick={() => onClick(e)}>
          {e.label}
        </div>
      ))}
    </div>
  )
}

export default Tabs
