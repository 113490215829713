import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import { label } from 'constants/labels'

const FormBtnSave = ({ disabled, handleSubmit, isLoading, id, code }) => {
  return (
    <div className="ta-r mt-2">
      <Button
        label={label.save}
        iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
        disabled={disabled}
        onClick={handleSubmit}
        loading={isLoading}
        stylesClass="btnWarning"
        accessAction={id ? 'UPDATE' : 'ADD'}
        accessCode={code}
      />
    </div>
  )
}

export default FormBtnSave
