import React, { useState } from 'react'
// import { useDispatch } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useLoginMutation } from 'api/authApiSlice'
// import { setCredentials } from 'redux/reducers/authSlice'
import Input from 'components/input'
import Button from '../../components/button/index'
import InputPassword from '../../components/inputPassword/index'
import { BoxContainer } from 'components/containers/containers'
import styles from './auth.module.scss'
import { label } from 'constants/labels'
import { initialLogin } from 'constants/initialValues'
import { useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { queryAddActivity } from 'query/queryActivity'
import { trimObjectData } from 'utils'

const validateForm = user => [user.email, user.lozinka].every(Boolean)

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'
  const [error, setError] = useState(false)
  const [user, setUser] = useState({ ...initialLogin })
  const [loading, setLoading] = useState(false)
  const [login] = useLoginMutation()
  const [create] = useUpdateCreateOneMutation()
  const handleChange = e => {
    setError(false)
    const { name, value } = e.target
    setUser(userData => ({ ...userData, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm(user)) {
      setLoading(true)
      const res = await login(trimObjectData(user))
      if (!res.error) {
        await create(queryAddActivity(res.data.id, 'logged', 'korisnik', res.data))
        navigate(from, { replace: true })
      }
      setLoading(false)
    } else {
      setError(true)
    }
  }
  return (
    <div className={styles.wrapper}>
      <BoxContainer globalClass="p-3">
        <div>
          <h2>Log in</h2>
          <Input
            className="form-control"
            name="email"
            value={user.email}
            label={label.emailInput}
            onChange={handleChange}
            placeholder={label.emailPlaceholder}
            errorTxt={error && !user.email && label.emailErr}
          />
          <InputPassword
            className="form-control"
            name="lozinka"
            value={user.lozinka}
            label={label.passwordInput}
            onChange={handleChange}
            placeholder={label.passwordPlaceholder}
            errorTxt={error && !user.lozinka && label.passwordErr}
          />
        </div>
        <div className={styles.btn}>
          <Button onClick={e => handleSubmit(e)} label={label.submit} globalClass="mt-2 w-100" loading={loading} disabled={loading} />
        </div>
        <div className="ta-c w-100">
          {/* If you dont have account please go to <NavLink to="/register">Register page!</NavLink> */}
          <br />
          <NavLink to="/forgotpassword">Zaboravljen sifra!</NavLink>
        </div>
      </BoxContainer>
    </div>
  )
}
export default Login
