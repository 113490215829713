/** Queries for 'Kategorija / Category' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialCategory } from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES } from 'constants/other'

export const queryGetPagedCategories = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'kategorija',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          where: { tipPodatka: DATA_TYPES.categorySerbia },
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.category
    }
  }
}

export const queryGetAllCategories = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'kategorija',
      data: {
        options: {
          ...options,
          where: { tipPodatka: DATA_TYPES.categorySerbia },
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.category
    }
  }
}

/**
 * @param {initialCategory} data
 */
export const queryAddCategory = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'kategorija',
      subType:DATA_TYPES.categorySerbia,
      data: { values: data }
    },
    invalidatesTags: [tag.category],
    successMsg: label.successMsgCreate + ' ' + label.msgCategory
  }
}

/**
* @param {initialCategory} data
@param {string} id
*/
export const queryUpdateCategory = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'kategorija',
      subType: DATA_TYPES.categorySerbia,
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.category, tag.imageAnnoucmentCategory],
    successMsg: label.successMsgEdit + ' ' + label.msgCategory
  }
}
/**
@param {string} id
*/
export const queryDeleteCategory = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'kategorija',
      subType:DATA_TYPES.categorySerbia,
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.category],
    successMsg: label.successMsgDelete + ' ' + label.msgCategory
  }
}
