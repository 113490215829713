import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import MultipleInputs from 'components/inputMultiple'
import Table from 'components/table'
import { initialConnectTbTimeOfContacts, multiFieldsContactTimeInitial } from 'constants/initialValues'
import { label } from 'constants/labels'
import { CLIENT_CONNECTION_TB, FIELD_TYPES } from 'constants/other'
import { cloneDeep } from 'lodash'
import { queryAddContactsTimeForClientId, queryGetAllContactsTimeByClientId } from 'query/queryClientConectionTables'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from 'redux/reducers/modalSlice'
import { getCurrentTime } from 'utils'

const TimeOfContactsModal = ({ id }) => {
  const dispatch = useDispatch()
  const [multiDataList, setMultiDataList] = useState([{ ...initialConnectTbTimeOfContacts, klijentId: id }])
  const [create] = useUpdateCreateOneMutation()
  const requiredMultiMap = useSelector(state => state.static.multipleRequiredFields)
  const [error, setError] = useState({ [CLIENT_CONNECTION_TB.timeOfContacts]: false })
  const [upcomingContacts, setUpcomingContacts] = useState([])
  const { data: timeOfContacts = [] } = useGetAllQuery(queryGetAllContactsTimeByClientId(id), {
    skip: !id
  })
  useEffect(() => {
    if (timeOfContacts.length) {
      const now = Date.now()
      const futureDates = timeOfContacts.filter(el => {
        return el.datumKontakta && new Date(el.datumKontakta).getTime() > now
      })
      setUpcomingContacts(futureDates)
    }
  }, [timeOfContacts])

  const onAddField = () => {
    const temp = cloneDeep(multiDataList)
    if (!checkIsMultiValid(temp)) setError(prev => ({ ...prev, [CLIENT_CONNECTION_TB.timeOfContacts]: true }))
    else {
      setError(prev => ({ ...prev, [CLIENT_CONNECTION_TB.timeOfContacts]: false }))
      temp.push({ ...initialConnectTbTimeOfContacts, klijentId: id })
      setMultiDataList(temp)
    }
  }

  const checkIsMultiValid = data => {
    const isValidArr = []
    if (requiredMultiMap[CLIENT_CONNECTION_TB.timeOfContacts]) {
      data.forEach(e => {
        Object.keys(requiredMultiMap[CLIENT_CONNECTION_TB.timeOfContacts]).forEach(key => {
          if (!e[key]) isValidArr.push(false)
        })
      })
    }
    return isValidArr.every(Boolean)
  }

  const onRemoveMultiselect = index => {
    const temp = cloneDeep(multiDataList)
    temp.splice(index, 1)
    setMultiDataList(temp)
  }

  const onMultipleChange = async (e, index, field) => {
    const { type, dbName } = field
    const temp = cloneDeep(multiDataList)
    if (type === 'input') {
      const { name, value } = e.target
      temp[index][name] = value
    } else if (type === 'select') {
      temp[index][dbName] = e.id
    } else if (type === 'datapicker') {
      temp[index][dbName] = e ? getCurrentTime(e) : null
    } else if (type === 'phone') {
      temp[index][dbName] = e
    }
    setMultiDataList(temp)
  }
  const handleSubmit = async () => {
    if (!checkIsMultiValid(multiDataList) || !multiDataList.length) {
      setError(prev => ({ ...prev, [CLIENT_CONNECTION_TB.timeOfContacts]: true }))
    } else {
      setError(prev => ({ ...prev, [CLIENT_CONNECTION_TB.timeOfContacts]: false }))
      const res = await create(queryAddContactsTimeForClientId(multiDataList))
      if (!res.error) dispatch(closeModal(1))
    }
  }
  return (
    <div>
      <h2 className="mb-2">{label.addNewTimeContact}</h2>
      {id && (
        <div className="mb-2">
          {!!upcomingContacts.length && (
            <Table
              data={upcomingContacts}
              heading={[
                { dbName: 'korisnik', label: label.salesSpecialist, type: FIELD_TYPES.text, nestedObj: 'korisnickoIme' },
                { dbName: 'datumKontakta', label: label.contactReasonNextInput, type: FIELD_TYPES.date },
                { dbName: 'vrstaKontaktum', label: label.contactReasonInput, type: FIELD_TYPES.text, nestedObj: 'naziv' }
              ]}
            />
          )}
        </div>
      )}
      <MultipleInputs
        name={CLIENT_CONNECTION_TB.timeOfContacts}
        fields={multiFieldsContactTimeInitial}
        data={multiDataList}
        onAdd={onAddField}
        onRemove={onRemoveMultiselect}
        onChange={onMultipleChange}
        label={label.multiFieldLabelTimeContact}
        // hideDataWithId={true}
        stylesClass="multiFieldsRow"
        errorGroup={error[CLIENT_CONNECTION_TB.timeOfContacts] && !multiDataList.length && 'Obavezao bar jedno vreme kontakta'}
        errorRow={error}
      />
      <div className="ta-r mt-2">
        <Button label={label.save} iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />} onClick={handleSubmit} stylesClass="btnWarning" />
      </div>
    </div>
  )
}

export default TimeOfContactsModal
