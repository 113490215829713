import { useGetAllQuery, useLazyGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { components } from 'react-select'
import dayjs from 'dayjs'
import Input from 'components/input'
import SelectInput from 'components/selectInput'
import { label } from 'constants/labels'
import { queryGetAllCity } from 'query/queryCity'
import { queryGetAllClients } from 'query/queryClient'
import { queryGetAllCurrency } from 'query/queryCurrency'
import { queryGetAllProducts } from 'query/queryProducts'
import React, { useState } from 'react'
import styles from './realziations.module.scss'
import DatePickerComp from 'components/dataPicker'
import { getCurrentTime } from 'utils'
import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox from 'components/checkbox'
import { queryAddRealizations, queryGetAllAnnouncement, queryGetAllRealizations } from 'query/queryAnnouncment'
import { PAYMENT_TYPE } from 'constants/staticOptions'
import { queryGetAllProductDesc } from 'query/queryProductDescription'
import { conditionalRealizations } from 'constants/conditionalFields'
import { pullConditionalFields } from 'components/forms/helpers/utils'
import { dateFormat } from 'constants/date'
import { closeModal } from 'redux/reducers/modalSlice'
import { useDispatch } from 'react-redux'
import ErrorMessage from 'components/errorMessage'
import { FIELD_TYPES } from 'constants/other'
import Table from 'components/table'
import { queryGetAllMeasureUnitProductsSearch } from 'query/queryMeasureUnitProduct'

const heading = [
  { label: 'Prodavac', dbName: 'prodavac', type: FIELD_TYPES.text, nestedObj: 'naziv' },
  { label: 'Kupac', dbName: 'kupac', type: FIELD_TYPES.text, nestedObj: 'naziv' },
  { label: label.amountInput, dbName1: 'kolicina', dbName3: 'unetaJedinicaMere', type: FIELD_TYPES.join },
  {
    label: label.priceInput,
    dbName1: 'cena',
    dbName2: 'valuta',
    dbName3: 'unetaCenaJedinicaMere',
    type: FIELD_TYPES.join,
    numFormat: 'money'
  }
]

const getInital = initial => {
  const tip = initial.tip
  return {
    objavaKupcaId: tip === 2 ? initial.id : null,
    objavaProdavcaId: tip === 1 ? initial.id : null,
    kupacId: tip === 2 ? initial.klijentId : null,
    prodavacId: tip === 1 ? initial.klijentId : null,
    proizvodId: initial.proizvodId,
    opisProizvodaId: initial.opisProizvodaId,
    regionId: null,
    gradId: null,
    datum: null,
    kolicina: +initial.kolicina,
    cena: +initial.cena,
    fcaKupac: null,
    fcaDogovor: null,
    napomenaProizvoda: initial.napomenaProizvoda,
    komentar: initial.komentar,
    tipPlacanja: initial.tipPlacanja,
    unetaJedinicaMereId: initial.unetaJedinicaMereId,
    unetaCenaJedinicaMereId: initial.unetaCenaJedinicaMereId,
    podrazumevanaJedinicaMereId: initial?.podrazumevanaJedinicaMereId,
    podrazumevanaCenaJedinicaMereId: initial?.podrazumevanaCenaJedinicaMereId,
    podrazumevanValutaId: initial?.valutaId,
    valutaId: initial.valutaId
  }
}
const CustomOptionSelect = props => {
  const { innerProps, data } = props
  return (
    <div {...innerProps} className="customOption">
      <span>
        Oglas:{data?.proizvod?.naziv}-{data?.opisProizvoda?.naziv}, {dayjs(data.createdAt).format(dateFormat)}
      </span>
    </div>
  )
}
const SingleValue = props => {
  const { data } = props
  return (
    <components.SingleValue {...props}>
      <div className="customSelect">
        Oglas:{data?.proizvod?.naziv}-{data?.opisProizvoda?.naziv}, {dayjs(data.createdAt).format(dateFormat)}
      </div>
    </components.SingleValue>
  )
}
const CustomOptionSelectCity = props => {
  const { innerProps, data } = props
  return (
    <div {...innerProps} className="customOption">
      <span>
        {data?.naziv},{data?.region?.naziv}
      </span>
    </div>
  )
}
const SingleValueCity = props => {
  const { data } = props
  return (
    <components.SingleValue {...props}>
      <div className="customSelect">
        {data?.naziv},{data?.region?.naziv}
      </div>
    </components.SingleValue>
  )
}
const Realizations = ({ announcement }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(() => {
    const initialState = getInital(announcement)
    return initialState
  })
  const [filterMap, setFilterMap] = useState({})
  const [error, setError] = useState(false)
  const [announcements, setAnnouncements] = useState([])
  const { data: products = [] } = useGetAllQuery(queryGetAllProducts())
  const { data: clients = [] } = useGetAllQuery(queryGetAllClients())
  const { data: currencies = [] } = useGetAllQuery(queryGetAllCurrency())
  const { data: cities = [] } = useGetAllQuery(queryGetAllCity())
  const { data: productsDesc = [] } = useGetAllQuery(queryGetAllProductDesc())
  const { data: measureUnits = [] } = useGetAllQuery(
    queryGetAllMeasureUnitProductsSearch({ where: { proizvodId: data.proizvodId }, skip: data.proizvodId })
  )
  const { data: realizations = [] } = useGetAllQuery(
    queryGetAllRealizations(
      {
        sqlType: {
          OR: [{ objavaKupcaId: announcement.id }, { objavaProdavcaId: announcement.id }]
        }
      },
      {
        skip: !announcement.id
      }
    )
  )
  const [getAnnouncments] = useLazyGetAllQuery()

  const [create] = useUpdateCreateOneMutation()

  const handleCheckboxChange = e => {
    const { name, checked } = e.target
    if (name === 'fcaKupac') {
      if (checked) setData(prev => ({ ...prev, fcaDogovor: false, fcaKupac: checked }))
    } else if (name === 'fcaDogovor') {
      if (checked) setData(prev => ({ ...prev, fcaDogovor: checked, fcaKupac: false }))
    }
    setData(prev => ({ ...prev, [name]: checked ?? null }))
  }

  const handleChange = e => {
    const { name, value, type } = e.target
    setData(prev => ({ ...prev, [name]: type === 'number' ? +value : value }))
  }

  const onSelectChange = async (e, name, conditional, key = 'id') => {
    if (conditional) {
      await getOptions(conditional, e)
    } else {
      setData(prev => ({ ...prev, [name]: e[key] ? e[key] : e?.id ?? null }))
    }
    if (name === 'gradId') {
      setData(prev => ({ ...prev, gradId: e.id, regionId: e.regionId }))
    }
    if (name === 'kupacId' || name === 'prodavacId') {
      const tip = name === 'kupacId' ? 2 : 1
      const res = await getAnnouncments(queryGetAllAnnouncement({ where: { klijentId: e.id, proizvodId: data.proizvodId, tip } }))
      if (!res.error) setAnnouncements(res.data)
    }
    if (name === 'opisProizvoda') {
      const product = products.find(e => e.id === e.proizvodId)
      setProducts(product)
    }
    if (name === 'proizvodId') {
      setProducts(e)
    }
  }

  const onDateChange = (date, name) => {
    setData(prev => ({ ...prev, [name]: date ? getCurrentTime(date) : null }))
  }
  const getOptions = async (mapName, selectValue) => {
    const { newFilterMap, newData } = await pullConditionalFields(mapName, conditionalRealizations, selectValue, data)
    setFilterMap(newFilterMap)
    setData(newData)
  }

  const isValid = data => {
    const validArr = [
      data.proizvodId,
      data.datum,
      data.kolicina,
      data.cena,
      data.unetaJedinicaMereId,
      data.unetaCenaJedinicaMereId,
      data.valutaId
    ]
    if (announcement.tip === 1) validArr.push(data.kupacId, data.objavaKupcaId)
    else validArr.push(data.prodavacId, data.objavaProdavcaId)
    return validArr.every(Boolean)
  }

  const onSubmit = async () => {
    const foundProduct = products.find(e => data.proizvodId === e.id)
    const reqData = {
      unetaJedinicaMereId: data.unetaJedinicaMereId,
      unetaCenaJedinicaMereId: data.unetaCenaJedinicaMereId,
      podrazumevanaJedinicaMereId: foundProduct?.jedinicaMereId,
      podrazumevanaCenaJedinicaMereId: foundProduct?.cenaJedinicaMereId,
      podrazumevanValutaId: foundProduct?.valutaId,
      valutaId: data.valutaId,
      objavaKupcaId: data.objavaKupcaId,
      objavaProdavcaId: data.objavaProdavcaId,
      kupacId: data.kupacId,
      prodavacId: data.prodavacId,
      proizvodId: data.proizvodId,
      opisProizvodaId: data.opisProizvodaId,
      regionId: data.regionId,
      gradId: data.gradId,
      datum: data.datum,
      kolicina: +data.kolicina,
      cena: +data.cena,
      fcaKupac: data.fcaKupac,
      fcaDogovor: data.fcaDogovor,
      napomenaProizvoda: data.napomenaProizvoda,
      komentar: data.komentar,
      tipPlacanja: data.tipPlacanja
    }

    if (isValid(data)) {
      const res = await create(queryAddRealizations(reqData))
      if (!res.error) dispatch(closeModal(2))
      setError(false)
    } else {
      setError(true)
    }
  }

  const setProducts = e => {
    setData(prev => ({
      ...prev,
      unetaJedinicaMereId: e.jedinicaMereId,
      valutaId: e.valutaId,
      unetaCenaJedinicaMereId: e.cenaJedinicaMereId
    }))
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div>
          <h3>
            {label.client}: {announcement?.klijent?.naziv}
          </h3>
          <h3>
            {label.products}: {announcement?.proizvod?.naziv}
          </h3>
        </div>
        <div>
          {announcement.tip === 1 ? (
            <span className={styles.supply}>{label.seller}</span>
          ) : (
            <span className={styles.demand}>{label.buyer}</span>
          )}
        </div>
      </div>
      {!!realizations.length && (
        <div>
          <h4>Prethodne realizacije</h4>
          <Table heading={heading} data={realizations} globalClass="mb-3" />
        </div>
      )}

      <h4>{label.addRealization}</h4>
      {announcement.tip === 1 ? (
        <div>
          <SelectInput
            options={clients}
            handleChangeSelect={e => onSelectChange(e, 'prodavacId')}
            selectedOption={clients.find(el => el.id === data.prodavacId || null)}
            customValue="id"
            customLabel="naziv"
            label={label.seller}
            stylesClass="selectLabelNext"
            disabled={true}
          />
          <SelectInput
            options={clients}
            handleChangeSelect={e => onSelectChange(e, 'kupacId')}
            selectedOption={clients.find(el => el.id === data.kupacId || null)}
            customValue="id"
            customLabel="naziv"
            label={label.buyer}
            stylesClass="selectLabelNext"
          />
          {error && !data.kupacId && <ErrorMessage message="Kupac je obavezno polje" globalClass="w-100 flex-row justify-end mb-2" />}
          {data.kupacId && (
            <>
              <SelectInput
                options={announcements}
                handleChangeSelect={e => onSelectChange(e, 'objavaKupcaId')}
                selectedOption={announcements.find(el => el.id === data.objavaKupcaId || null)}
                customValue="id"
                customLabel="naziv"
                label={label.advsBuyer}
                stylesClass="selectLabelNext"
                customComponents={{ Option: CustomOptionSelect, SingleValue: SingleValue }}
                // errorTxt={error && !data.objavaKupcaId && 'Objava je obavezno polje'}
              />
              {error && !data.objavaKupcaId && (
                <ErrorMessage message="Objava je obavezno polje" globalClass="w-100 flex-row justify-end mb-2" />
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          <SelectInput
            options={clients}
            handleChangeSelect={e => onSelectChange(e, 'kupacId')}
            selectedOption={clients.find(el => el.id === data.kupacId || null)}
            customValue="id"
            customLabel="naziv"
            label={label.buyer}
            stylesClass="selectLabelNext"
            disabled={true}
          />
          <SelectInput
            options={clients}
            handleChangeSelect={e => onSelectChange(e, 'prodavacId')}
            selectedOption={clients.find(el => el.id === data.prodavacId || null)}
            customValue="id"
            customLabel="naziv"
            label={label.seller}
            stylesClass="selectLabelNext"
            // errorTxt={error && !data.prodavacId && 'Prodavac je obavezno polje'}
          />
          {error && !data.prodavacId && <ErrorMessage message="Prodavac je obavezno polje" globalClass="w-100 flex-row justify-end mb-2" />}
          {data.kupacId && (
            <>
              <SelectInput
                options={announcements}
                handleChangeSelect={e => onSelectChange(e, 'objavaProdavcaId')}
                selectedOption={announcements.find(el => el.id === data.objavaProdavcaId || null)}
                customValue="id"
                customLabel="naziv"
                label={label.advsSeller}
                stylesClass="selectLabelNext"
                customComponents={{ Option: CustomOptionSelect, SingleValue: SingleValue }}
              />
              {error && !data.objavaProdavcaId && (
                <ErrorMessage message="Objava je obavezno polje" globalClass="w-100 flex-row justify-end mb-2" />
              )}
            </>
          )}
        </div>
      )}

      <SelectInput
        options={products || []}
        filter={filterMap?.products}
        handleChangeSelect={e => onSelectChange(e, 'proizvodId', 'proizvod')}
        selectedOption={products.find(el => el.id === data.proizvodId || null)}
        customValue="id"
        customLabel="naziv"
        label={label.products}
        stylesClass="selectLabelNext"
        disabled={true}
      />
      {error && !data.proizvodId && <ErrorMessage message="Proizvod je obavezno polje" globalClass="w-100 flex-row justify-end mb-2" />}
      <SelectInput
        options={productsDesc || []}
        filter={filterMap?.productsDesc}
        handleChangeSelect={e => onSelectChange(e, 'opisProizvodaId', 'opisProizvoda')}
        selectedOption={productsDesc.find(el => el.id === data.opisProizvodaId || null)}
        customValue="id"
        customLabel="naziv"
        label={label.productsDescInput}
        stylesClass="selectLabelNext"
        disabled={true}
      />
      <Input
        name="napomenaProizvoda"
        label={label.advsTerm}
        placeholder={label.placeholder}
        value={data.napomenaProizvoda}
        onChange={handleChange}
        stylesClass="inputLabelNext"
      />
      <div className={styles.group}>
        <div className={styles.row}>
          <Input
            name="kolicina"
            label={label.amountInput}
            placeholder={label.placeholder}
            value={data.kolicina}
            onChange={handleChange}
            errorTxt={error && !data.kolicina && 'Obavezno polje'}
            type="number"
          />
          <SelectInput
            options={measureUnits}
            handleChangeSelect={e => onSelectChange(e, 'unetaJedinicaMereId', false, 'jedinicaMereId')}
            selectedOption={measureUnits.find(el => el.jedinicaMereId === data.unetaJedinicaMereId || null)}
            customValue="jedinicaMereId"
            customLabel="jmNaziv"
            label={label.measureUnitsInput}
            errorTxt={error && !data.unetaJedinicaMereId && 'Obavezno polje'}
          />
        </div>

        <div className={`${styles.row} ${styles.price}`}>
          <Input
            name="cena"
            label={label.priceInput}
            placeholder="00.00"
            value={data.cena}
            onChange={handleChange}
            errorTxt={error && !data.cena && 'Obavezno polje'}
            type="number"
          />
          <SelectInput
            options={currencies}
            handleChangeSelect={e => onSelectChange(e, 'valutaId')}
            selectedOption={currencies.find(el => el.id === data.valutaId || null)}
            customValue="id"
            customLabel="kod"
            label={label.currency}
            errorTxt={error && !data.valutaId && 'Obavezno polje'}
          />
          <div className={styles.between}>/</div>
          <SelectInput
            options={measureUnits}
            handleChangeSelect={e => onSelectChange(e, 'unetaCenaJedinicaMereId', false, 'jedinicaMereId')}
            selectedOption={measureUnits.find(el => el.jedinicaMereId === data.unetaCenaJedinicaMereId || null)}
            customValue="jedinicaMereId"
            customLabel="jmNaziv"
            label={label.measureUnitsInput}
            errorTxt={error && !data.unetaCenaJedinicaMereId && 'Obavezno polje'}
          />
        </div>
      </div>

      <DatePickerComp
        startDate={data.datum ? new Date(data.datum) : null}
        onChange={date => onDateChange(date, 'datum')}
        label={label.dateRealizations}
        stylesClass="defaultPickerNext"
      />
      {error && !data.datum && <ErrorMessage message="Datum je obavezno polje" globalClass="w-100 flex-row justify-end mb-2" />}
      <Input
        name="komentar"
        label={label.advsComent}
        placeholder={label.placeholder}
        value={data.komentar}
        onChange={handleChange}
        stylesClass="inputLabelNext"
        rows={2}
      />
      <SelectInput
        options={PAYMENT_TYPE}
        handleChangeSelect={e => onSelectChange(e, 'tipPlacanja')}
        selectedOption={PAYMENT_TYPE.find(el => el.id === data.tipPlacanja || null)}
        customValue="id"
        customLabel="label"
        label={label.paymentType}
        stylesClass="selectLabelNext"
      />
      <SelectInput
        options={cities || []}
        handleChangeSelect={e => onSelectChange(e, 'gradId')}
        selectedOption={cities.find(el => el.id === data.gradId || null)}
        customValue="id"
        customLabel="naziv"
        label={label.francoCity}
        stylesClass="selectLabelNext"
        customComponents={{ Option: CustomOptionSelectCity, SingleValue: SingleValueCity }}
      />

      <div className={styles.rowCheck}>
        <span className={styles.label} />
        <Checkbox
          id="fcaDealRealizations"
          name="fcaDogovor"
          label={label.fca2}
          checked={data.fcaDogovor}
          onChange={handleCheckboxChange}
          globalClass="mt-1"
        />
      </div>
      <div className={styles.rowCheck}>
        <span className={styles.label} />
        <Checkbox
          id="fcaBuyerRealizations"
          name="fcaKupac"
          label={label.fca3}
          checked={data.fcaKupac}
          onChange={handleCheckboxChange}
          globalClass="mt-1"
        />
      </div>
      <div className={styles.btn}>
        <Button label={label.save} iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />} onClick={onSubmit} stylesClass="btnWarning" />
      </div>
    </div>
  )
}

export default Realizations
