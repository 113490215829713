import React from 'react'
import styles from './loader.module.scss'

export const Loader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}></div>
    </div>
  )
}

export const LoaderText = ({ text }) => {
  return (
    <div className={styles.loader}>
      <h4>{text}</h4>
    </div>
  )
}
export const LoaderWithText = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      <h4>{text}</h4>
      <div className={styles.loader}></div>
    </div>
  )
}
