import { createSlice } from '@reduxjs/toolkit'
const staticSlice = createSlice({
  name: 'static',
  initialState: {
    images: [],
    isLoading: false,
    isDisabled: false,
    selectedCountry: {},
    multipleRequiredFields: {},
    multipleValidArr: [],
    pageLoader: false,
    triggerUser: false
  },
  reducers: {
    setUploadImages: (state, action) => {
      state.images = action.payload
    },
    setIsLoadingData: (state, action) => {
      state.isLoading = action.payload
    },
    setIsDisabled: (state, action) => {
      state.isDisabled = action.payload
    },
    getMultipleRequiredFields: (state, action) => {
      state.multipleRequiredFields = { ...state.multipleRequiredFields, ...action.payload }
    },
    arrMutiFieldsValid: (state, action) => {
      state.multipleValidArr = [...state.multipleValidArr, action.payload]
    },
    setPageLoader: (state, action) => {
      state.pageLoader = action.payload
    },
    triggerUserApi: (state, action) => {
      state.triggerUser = action.payload
    }
  }
})

export const {
  setUploadImages,
  setIsLoadingData,
  setIsDisabled,
  getMultipleRequiredFields,
  arrMutiFieldsValid,
  setPageLoader,
  triggerUserApi
} = staticSlice.actions

export const selectTriggeredUser = state => state.static.triggerUser

export default staticSlice.reducer
