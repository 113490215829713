import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './dataPicker.module.scss'
import ErrorMessage from '../errorMessage/index'
import { calendarMonthFormat } from 'constants/date'

const YearPickerComp = ({
  onChange,
  stylesClass = 'defaultPickerYear',
  globalClass = '',
  startDate,
  name,
  label,
  showTime = false,
  format = calendarMonthFormat,
  placeholder = 'YYYY',
  errorTxt,
  disabled = false,
  endDate,
  haveIcon = true,
  isPreview
}) => {
  const datepickerRef = useRef(null)
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current
    datepickerElement.setFocus(true)
  }
  return (
    <div className={`${styles[stylesClass]} ${globalClass} ${disabled && styles.disabled}   ${isPreview && styles.preview} g-month-picker`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`${styles.picker}`}>
        <DatePicker
          selected={startDate}
          onChange={date => onChange(date, name)}
          showTimeSelect={showTime}
          dateFormat={format}
          showYearPicker
          placeholderText={placeholder}
          disabled={disabled}
          startDate={startDate}
          endDate={endDate}
          ref={datepickerRef}
        />
        {haveIcon && (
          <span className={styles.icon} onClick={() => handleClickDatepickerIcon()}>
            <FontAwesomeIcon icon="fa-calendar-days" />
          </span>
        )}
      </div>
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

export default YearPickerComp
