/** Queries for 'Kategorija  Bosna / Category BIH' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialCategoryBosnia } from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES } from 'constants/other'

export const queryGetPagedCategoriesBosnia = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'kategorija',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          where: { tipPodatka: DATA_TYPES.categoryBih },
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.categoryBosnia
    }
  }
}

export const queryGetAllCategoriesBosnia = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'kategorija',
      data: {
        options: {
          where: { tipPodatka: DATA_TYPES.categoryBih },
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.categoryBosnia
    }
  }
}

/**
 * @param {initialCategoryBosnia} data
 */
export const queryAddCategoryBosnia = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'kategorija',
      subType: DATA_TYPES.categoryBih,
      data: { values: data }
    },
    invalidatesTags: [tag.categoryBosnia],
    successMsg: label.successMsgCreate + ' ' + label.msgCategoryBosnia
  }
}

/**
* @param {initialCategoryBosnia} data
@param {string} id
*/
export const queryUpdateCategoryBosnia = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'kategorija',
      subType: DATA_TYPES.categoryBih,
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.categoryBosnia, tag.imageAnnoucmentCategory],
    successMsg: label.successMsgEdit + ' ' + label.msgCategoryBosnia
  }
}
/**
@param {string} id
*/
export const queryDeleteCategoryBosnia = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'kategorija',
      subType: DATA_TYPES.categoryBih,
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.categoryBosnia],
    successMsg: label.successMsgDelete + ' ' + label.msgCategoryBosnia
  }
}
