import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, OneColumn } from 'components/containers/containers'
import FormAddMultiplePriceMovementBih from 'components/forms/formAddMultipleFiedls/formAddMultiplePriceMovementBih'
//  import HistoryTable from 'components/historyTable'
import MessageNoData from 'components/messageNoData'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { priceMovementBihFields } from 'constants/searchFields'
import { headingPriceMovementBih } from 'constants/tbHeading'
import {
  queryAddPriceMovementBihMultiple,
  queryDeletePriceMovementBih,
  queryGetLastInsertPriceMovementBih,
  queryGetPagedPriceMovementBih,
  queryUpdatePriceMovementBih
} from 'query/queryPriceMovementBih'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'

const PriceMovementBih = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: priceMovementBih = [] } = useGetAllQuery(queryGetPagedPriceMovementBih(pageNumber, pageSize, searchOptions))
  const { data: lastInsertPriceMovementBih = [] } = useGetAllQuery(queryGetLastInsertPriceMovementBih())
  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [preview, setPreview] = useState('table')

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (priceMovementBih.rows && priceMovementBih.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, priceMovementBih.rows])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(queryUpdatePriceMovementBih(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddPriceMovementBihMultiple(data))
      if (!res.error) {
        dispatch(closeModal(1))
        setPreview('table')
      }
    }
    dispatch(setIsLoadingData(false))
  }

  // const onRowClick = data => {
  //   dispatch(setIsDisabled(true))
  //   navigate(`/kretanje-cena-bih?id=${data.id}`)
  //   setPreview('form')
  //   setData(data)
  // }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeletePriceMovementBih(data.id))
      if (!res.error) dispatch(closeModal(1))
    } else {
      dispatch(closeModal(1))
    }
  }

  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.masgDeletePriceMovementBih} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/kretanje-cena-bih`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const goBack = () => {
    if (!data.id) setPreview('table')
    else {
      navigate('/kretanje-cena-bih')
      setPreview('table')
    }
  }

  // const openHistory = () => {
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: (
  //           <div>
  //             <HistoryTable
  //               collection="kretanjeCenaBih"
  //               link="/kretanje-cena-bih?id="
  //               connectionTb={[
  //                 { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
  //                 { rtkQuery: queryGetAllProducts(), dbName: 'proizvodId' },
  //                 { rtkQuery: queryGetAllClients(), dbName: 'klijentId' },
  //                 { rtkQuery: queryGetAllCurrency(), dbName: 'valutaId' },
  //                 { rtkQuery: queryGetAllMeasureUnits(), dbName: 'jedinicaMereId' }
  //               ]}
  //             />
  //           </div>
  //         ),
  //         order: 1,
  //         size: 'xlg'
  //       },
  //       active: 1
  //     })
  //   )
  // }

  switch (preview) {
    case 'table':
      return (
        <div>
          <Title title={label.priceMovementBih} total={priceMovementBih?.count}>
            <Button
              onClick={() => setPreview('addLast')}
              label={label.lastInsert}
              iconLeft={<FontAwesomeIcon icon="fa-cloud-arrow-up" />}
              accessAction="ADD"
              accessCode={code}
            />
            <Button
              onClick={() => setPreview('add')}
              label={label.add}
              iconLeft={<FontAwesomeIcon icon="fa-plus" />}
              accessAction="ADD"
              accessCode={code}
            />
          </Title>
          <Search fields={priceMovementBihFields} onSearch={onSubmitSearch} />
          {/* {!!priceMovementBih?.rows?.length && (
              <h3 className="history-link" onClick={openHistory}>
                {label.historyChanges}
              </h3>
            )} */}
          <OneColumn classWidth="width5050">
            <Table
              heading={headingPriceMovementBih}
              data={priceMovementBih.rows}
              // onRowClick={onRowClick}
              onAction={onActionClick}
              havePagination={true}
              onPaginationChange={onPaginationChange}
              pageNumber={pageNumber}
              pageSize={pageSize}
              total={priceMovementBih?.count}
            />
          </OneColumn>
        </div>
      )
    case 'addLast':
      return (
        <div>
          <Title title={label.priceMovementBih} goBackClick={goBack} globalClass="mb-2" />
          <FormWrapper>
            <FormAddMultiplePriceMovementBih
              existingData={lastInsertPriceMovementBih}
              range={
                lastInsertPriceMovementBih?.length > 0
                  ? { start: lastInsertPriceMovementBih[0]?.periodStart, end: lastInsertPriceMovementBih[0]?.periodEnd }
                  : {}
              }
              id={id}
              onSubmit={onSubmit}
            />
          </FormWrapper>
        </div>
      )
    case 'add':
      return (
        <div>
          <Title title={label.priceMovementBih} goBackClick={goBack} globalClass="mb-2" />
          <FormWrapper>
            <FormAddMultiplePriceMovementBih existingData={data} id={id} onSubmit={onSubmit} />
          </FormWrapper>
        </div>
      )
    default:
      return <MessageNoData message={label.messageNoData} />
  }
}

export default PriceMovementBih
