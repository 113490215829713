/** Page  'Istorija - Izvestaj' */
import { useGetAllQuery } from 'api/dataApiSlice'
import { OneColumn } from 'components/containers/containers'
import Search from 'components/search'
import Table from 'components/table'
import { PAGE_SIZE } from 'constants/other'
import { headingHistoryReport } from 'constants/reportHeading'
import { historyReportFields } from 'constants/searchFields'
import { queryGetPagedHistoryReport } from 'query/queryHistoryReport'
import { queryGetAllReportTypes } from 'query/queryReportType'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const emailDataExample = {
  subject: 'Izvestaj',
  title: 'Izvestaj',
  text: 'Ovaj email sadrzi link sa dostupnim pdf-om'
  // previewName: 'Izvestaj MAJ'
}

const HistoryReports = () => {
  //  dispatch = useDispatch()
  const navigate = useNavigate()
  // const [searchParams] = useSearchParams()
  // const id = searchParams.get('id')
  // const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: historyReport = [] } = useGetAllQuery(queryGetPagedHistoryReport(pageNumber, pageSize, searchOptions))
  const { data: typeOfReports = [] } = useGetAllQuery(queryGetAllReportTypes())
  // const [deleteOne] = useDeleteOneMutation()
  // const [sendSinglePdfLink] = useSendSinglePdfLinkMutation()

  // const dataReportExportImport = {
  //   ...emailDataExample,
  //   tbInfo: {
  //     name: 'Infotim Logistika',
  //     description: 'Izvestaj'
  //   },
  //   heading: {}
  // }

  // useEffect(() => {
  //   if (id) {
  //     dispatch(setIsDisabled(true))
  //     const found = (historyReport.rows && historyReport.rows.find(e => e.id === +id)) || {}
  //     setData(found)
  //   } else {
  //     setData({})
  //   }
  // }, [id, historyReport.rows])

  // useEffect(() => {
  //   return () => dispatch(setIsDisabled(false))
  // }, [])

  // const onSubmit = async (data, id) => {
  //   dispatch(setIsLoadingData(true))
  //   if (id) {
  //     const res = await sendSinglePdfLink({ ...dataReportExportImport, ...data })
  //     if (!res.error) dispatch(setIsDisabled(true))
  //   }
  //   dispatch(setIsLoadingData(false))
  // }

  // const onRowClick = data => {
  //   dispatch(setIsDisabled(true))
  //   navigate(`/izvestaji/istorija-izvestaja?id=${data.id}`)
  //   setData(data)
  // }

  // const onActionClick = (data, type) => {
  //   if (type === 'delete') openDeleteModal(data)
  // }

  // const handleDelete = async (answer, data) => {
  //   if (answer) {
  //     const res = await deleteOne(queryDeleteHistoryReport(data.id))
  //     if (!res.error) dispatch(closeModal(1))
  //   } else {
  //     dispatch(closeModal(1))
  //   }
  // }
  // const openDeleteModal = data => {
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.history} />,
  //         order: 1,
  //         size: 'sm'
  //       },
  //       active: 1
  //     })
  //   )
  // }
  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/izvestaji/istorija-izvestaja`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  return (
    <div>
      <Search fields={historyReportFields} onSearch={onSubmitSearch} />
      <OneColumn globalClass="mt-2">
        <Table
          heading={headingHistoryReport}
          data={historyReport.rows}
          // onRowClick={onRowClick}
          // onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={historyReport?.count}
          map={{
            historyReport: typeOfReports.reduce((e, x) => {
              return { ...e, [x.kod]: x }
            }, {})
          }}
        />
        {/* {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormHistoryReport existingData={data} header={true} id={id} onSubmit={onSubmit} onDelete={openDeleteModal} />
          </FormWrapper>
        )} */}
      </OneColumn>
    </div>
  )
}
export default HistoryReports
