/** Queries for 'Aktivnost korisnika' */

import { tag } from 'api/rtkTags'

export const queryGetPagedActivities = (pageNumber, pageSize, options = {}, type) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'aktivnostKorisnika',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: type === 'korisnik' ? tag.activitiesUsers : tag.activitiesClients
    }
  }
}

export const queryAddActivity = (id, action, entity, data) => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'aktivnostKorisnika',
      data: {
        values: {
          tip: 'korisnik',
          korisnikId: id,
          akcija: action,
          entitet: entity,
          podaci: data
        }
      }
    },
    invalidatesTags: [tag.activitiesUsers, tag.activitiesClients]
  }
}
