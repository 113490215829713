/** Form for  add/edit  'Kategorija  Futures / Category futures' */
import Input from 'components/input'
import { Loader } from 'components/loader'
import { initialCategoryFutures } from 'constants/initialValues'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import { errCategory as errorMsg } from './helpers/errorMsg'
import { validateCategory as validateForm } from './helpers/validate'
import FormBtnSave from './formHeader/formButton'

const FormCategoryFutures = ({ existingData = {}, id, onSubmit, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialCategoryFutures })

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      naziv: data.naziv,
      aktivan: data.aktivan,
      tipPodatka: data.tipPodatka
    }
    if (data.id) reqData.id = data.id

    if (validateForm(reqData)) {
      onSubmit(reqData)
    } else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader />
        </div>
      )}
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.categoryTitle + ': 2'}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.nazivPlaceholder}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />

        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormCategoryFutures
