/** Queries for 'Kretanje cena bih view' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
export const queryGetPagedPriceMovementBihSearch = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'kretanjeCenaBih_search',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.priceMovementBihSerach
    }
  }
}

export const queryGetAllPriceMovementBihSearch = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'kretanjeCenaBih_search',
      data: {
        options: {
          ...options,
          distinct: true
        }
      }
    },
    providesTags: {
      type: tag.priceMovementBihSerach
    }
  }
}
