import { useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { OneColumn } from 'components/containers/containers'
import FormClients from 'components/forms/formClient'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { registerSearchFields } from 'constants/searchFields'
import { STATUS_CLIENT_ID } from 'constants/staticOptions'
import { registerClients } from 'constants/tbHeading'
import { queryGetPagedClientSearch, queryUpdateClient } from 'query/queryClient'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import RegistrationsDetails from './details'

const Registrations = () => {
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [searchOptions, setSearchOptions] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const [cases, setCases] = useState('')
  const [data, setData] = useState({})
  const [updateData] = useUpdateCreateOneMutation()

  const { data: clients = [] } = useGetAllQuery(
    queryGetPagedClientSearch(pageNumber, pageSize, {
      ...searchOptions,
      where: { statusLicaId: null }
    })
  )
  const onRowClick = data => {
    if (data) {
      setData(data)
      if (data.status === STATUS_CLIENT_ID.novi || data.status === STATUS_CLIENT_ID.odbijen) {
        setCases('details')
      } else if (data.status === STATUS_CLIENT_ID.dodat) {
        setCases('addStatus')
      }
    }
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const goBack = () => {
    setCases('')
  }

  const onSubmit = async data => {
    const res = await updateData(queryUpdateClient(data, data.id))
    if (!res.error) dispatch(closeModal(1))
    setCases('')
  }

  const openOkModal = (data, add) => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <ModalYesNo
              data={data}
              handleSubmit={answer => handleAddOrReject(answer, add)}
              question={add ? label.msgAcceptRegistration : label.msgRejectRegistration}
            />
          ),
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const handleAddOrReject = async (answer, condition) => {
    if (answer && condition) {
      const res = await updateData(queryUpdateClient({ ...data, status: STATUS_CLIENT_ID.dodat }, data.id))
      if (!res.error) dispatch(closeModal(1))
      setCases('')
    } else if (answer && !condition) {
      const res = await updateData(queryUpdateClient({ ...data, status: STATUS_CLIENT_ID.odbijen }, data.id))
      if (!res.error) dispatch(closeModal(1))
      setCases('')
    } else {
      dispatch(closeModal(1))
    }
  }

  const renderFields = () => {
    switch (cases) {
      case 'details':
        return <RegistrationsDetails existingData={data} goBack={goBack} openOkModal={openOkModal} />
      case 'addStatus':
        return <FormClients existingData={data} id={data.id} goBack={goBack} onSubmit={onSubmit} />
      case '':
        return (
          <div>
            <Title title={label.registerPreview} total={clients?.count} />
            <Search fields={registerSearchFields} onSearch={onSubmitSearch} />
            <OneColumn>
              <Table
                heading={registerClients}
                data={clients?.rows}
                onRowClick={onRowClick}
                // onAction={onActionClick}
                havePagination={true}
                onPaginationChange={onPaginationChange}
                pageNumber={pageNumber}
                pageSize={pageSize}
                total={clients?.count}
              />
            </OneColumn>
          </div>
        )
      default:
        break
    }
  }

  return <div>{renderFields()}</div>
}

export default Registrations
