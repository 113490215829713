import React, { useState } from 'react'
import TextArea from '../textarea'
import { label } from '../../constants/labels'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/button'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import styles from './listMessages.module.scss'
import dayjs from 'dayjs'
import { useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { queryCreateRemark, queryUpdateRemark } from 'query/queryClientConectionTables'
import { selectCurrentId } from 'redux/reducers/authSlice'

export const ModalRemarkMessage = ({ existingData = {}, onSubmit }) => {
  const [data, setData] = useState(existingData)

  return (
    <div>
      <h3 className="mb-4">{label.noteInputEdit}</h3>
      <TextArea
        rows={7}
        name="message"
        label={label.noteInput}
        placeholder={label.placeholder}
        value={data.poruka}
        onChange={e => setData(prev => ({ ...prev, poruka: e.target.value }))}
      />
      <div className="ta-r">
        <Button label={label.save} onClick={() => onSubmit(data)} stylesClass="btnWarning" />
      </div>
    </div>
  )
}

const ListMessages = ({ listMessages, clientId }) => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const [updateOne] = useUpdateCreateOneMutation()
  const userid = useSelector(selectCurrentId)
  const handleChange = e => {
    setMessage(e.target.value)
    // onChange(e.target.value)
  }
  const onSubmit = async data => {
    if (data) {
      const res = await updateOne(queryUpdateRemark(data, data.id))
      if (!res.error) dispatch(closeModal(1))
    } else {
      const res = await updateOne(queryCreateRemark({ poruka: message, klijentId: clientId, korisnikId: userid }))
      if (!res.error) dispatch(closeModal(1))
    }
    setMessage('')
  }

  const openModalEdit = data => {
    dispatch(
      openModal({
        arrItem: { content: <ModalRemarkMessage existingData={data} onSubmit={onSubmit} />, order: 1, size: 'lg' },
        active: 1
      })
    )
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.write}>
        <TextArea
          rows={4}
          name="message"
          label={label.noteInputAdd}
          placeholder={label.placeholder}
          value={message}
          onChange={handleChange}
        />
        <div className="ta-r">
          <Button label={label.save} onClick={() => onSubmit(null)} stylesClass="btnWarning" />
        </div>
      </div>
      <div className={styles.box}>
        {!!listMessages.length &&
          listMessages.map(e => (
            <div key={e.id} className={styles.item}>
              <div className={styles.heading}>
                <span>{e.korisnik?.korisnickoIme}</span>
                <small>{dayjs(e.createdAt).format('MMMM D, YYYY h:mm')}</small>
              </div>
              <div className={styles.message}>{e.poruka} </div>
              <b onClick={() => openModalEdit(e)}>Promeni</b>
            </div>
          ))}
      </div>
      {/* {onChange && (
        <TextArea
          rows={4}
          name="message"
          label={label.noteInputAdd}
          placeholder={label.placeholder}
          
          value={message}
          onChange={handleChange}
        />
      )} */}
    </div>
  )
}

export default ListMessages
