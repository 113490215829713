import React from 'react'
import styles from './messageNoData.module.scss'
const MessageNoData = ({ message, globalClass = '', stylesClass = '' }) => {
  return (
    <div className={`${styles.wrapper} ${stylesClass ? styles[stylesClass] : ''} ${globalClass}`}>
      <h3>{message}</h3>
    </div>
  )
}

export default MessageNoData
