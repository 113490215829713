import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetOneQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper } from 'components/containers/containers'
import FormBlog from 'components/forms/formBlog'
import ModalYesNo from 'components/modal/modalYesNo'
import Title from 'components/title'
import { label } from 'constants/labels'
import { queryAddBlog, queryDeleteBlog, queryGetBlogBiId, queryUpdateBlog } from 'query/queryBlog'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsLoadingData } from 'redux/reducers/staticSlice'

const AddEditBlog = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [deleteOne] = useDeleteOneMutation()
  const [createData] = useUpdateCreateOneMutation()
  const { data = {}, refetch } = useGetOneQuery(queryGetBlogBiId(id), { skip: !id })

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await createData(queryUpdateBlog(data, id))
      if (!res.error) {
        dispatch(setIsLoadingData(false))
        navigate('/blog')
      }
    } else {
      const res = await createData(queryAddBlog(data))
      if (!res.error) {
        dispatch(setIsLoadingData(false))
        navigate('/blog')
      }
    }
    dispatch(setIsLoadingData(false))
    refetch()
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteBlog(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/blog`)
    } else {
      dispatch(closeModal(1))
    }
  }

  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteBlog} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={`${id ? data.naslov : label.addBlog}`} goBackClick={() => navigate('/blog')} globalClass="mb-2">
        {id && (
          <Button
            label={label.delete}
            stylesClass="btnLight"
            iconLeft={<FontAwesomeIcon icon="fa-trash-can" />}
            onClick={() => openDeleteModal(data)}
          />
        )}
      </Title>
      <FormWrapper>
        <FormBlog existingData={data} id={id} onSubmit={onSubmit} />
      </FormWrapper>
    </div>
  )
}

export default AddEditBlog
