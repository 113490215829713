/** Page  'Oglas' */

import { useGetAllQuery } from 'api/dataApiSlice'
import Search from 'components/search'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { announcementFilter } from 'constants/searchFields'
import { queryGetPagedAnnouncementSearch } from 'query/queryAnnouncment'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/reducers/modalSlice'
import ModalAnnouncement from './modal'
import { getRangeEnd, getRangeStart } from 'utils'
import { cloneDeep } from 'lodash'
import AnnouncementTables from './tables'
import { headingAnnouncement } from 'constants/tbHeading'
const defaultSearchFields = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'createdAt',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom,
    value: { start: getRangeStart(new Date()), end: getRangeEnd(new Date()) },
    dateValue: { start: getRangeStart(new Date()), end: getRangeEnd(new Date()) }
  }
]
const Announcement = () => {
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: announcementsSupply = [] } = useGetAllQuery(
    queryGetPagedAnnouncementSearch(pageNumber, pageSize, { ...searchOptions, where: { tip: 1 } }, 'supplay')
  )
  const { data: announcementsDemand = [] } = useGetAllQuery(
    queryGetPagedAnnouncementSearch(pageNumber, pageSize, { ...searchOptions, where: { tip: 2 } }, 'demand')
  )

  const onRowClick = data => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <ModalAnnouncement existingData={data} clientId={data.klijentId} />
            </div>
          ),
          order: 1,
          size: 'w-100'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }
  // const openHistory = () => {
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: <History onOpenModal={openHistoryOne} isModal={true} />,
  //         order: 1,
  //         size: 'xlg'
  //       },
  //       active: 1
  //     })
  //   )
  // }
  // const openHistoryOne = id => {
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: <History id={id} />,
  //         order: 2,
  //         size: 'xlg'
  //       },
  //       active: 2
  //     })
  //   )
  // }

  return (
    <div>
      <Search fields={cloneDeep(announcementFilter)} onSearch={onSubmitSearch} defaultFields={defaultSearchFields} />
      <AnnouncementTables
        heading={headingAnnouncement}
        announcementsSupply={announcementsSupply}
        announcementsDemand={announcementsDemand}
        onRowClick={onRowClick}
        havePagination={true}
        onPaginationChange={onPaginationChange}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    </div>
  )
}
export default Announcement
