const { useGetAllQuery } = require('api/dataApiSlice')
const { default: ListMessages } = require('components/listMessages')
const { queryGetAllRemarkByClientId } = require('query/queryClientConectionTables')
const { useParams } = require('react-router-dom')

const PreviewRemark = () => {
  const { id } = useParams()
  const { data: remarkData = [] } = useGetAllQuery(queryGetAllRemarkByClientId(id))

  return (
    <div className="mb-3">
      <ListMessages listMessages={remarkData} clientId={id} />{' '}
    </div>
  )
}
export default PreviewRemark
