/** Page  'Svetske Luke-Izvestaj' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetAllQuery, useLazyGetAllQuery } from 'api/dataApiSlice'
import { useGeneratePdfMutation } from 'api/fileApiSlice'
import Button from 'components/button'
import { FormWrapper, OneColumn } from 'components/containers/containers'
import FormCreateReport from 'components/forms/formCreateReport'
import { LoaderWithText } from 'components/loader'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { dateFormat } from 'constants/date'
import { label } from 'constants/labels'
import { headingForReportsWorldSeaPort, headingReportWorldSeaPort } from 'constants/reportHeading'
import { worldSeaPortReportFields } from 'constants/searchFields'
import dayjs from 'dayjs'
import { queryGetAllWorldSeaPortSearch } from 'query/queryWorldSeaPortSearch'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { openModal } from 'redux/reducers/modalSlice'
import { isObjectEmpty } from 'utils'
import styles from './worldSeaPortReport.module.scss'
import { queryGetAllReportTypes } from 'query/queryReportType'
import { cloneDeep } from 'lodash'

export const emailDataExample = {
  subject: 'Izvestaj svetske luke',
  title: 'Izvestaj svetske luke',
  text: 'Ovaj email sadrzi link sa dostupnim pdf-om'
  // previewName: 'Izvestaj MAJ'
}

const WorldSeaPortReport = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [, setSearchOptions] = useState({})
  const [getPreview, { isLoading }] = useGeneratePdfMutation()
  const [runSearch] = useLazyGetAllQuery()
  const [data, setData] = useState([])
  const [pdfUrl, setPdfUrl] = useState([])
  const [period, setPeriod] = useState('')
  const { data: typeOfReports = [] } = useGetAllQuery(queryGetAllReportTypes())
  const dataReportExportImport = {
    email: emailDataExample,
    tbInfo: {
      name: 'Infotim Logistika',
      description: `Izvestaj svetkse luke ${period}`
    },
    heading: {}
  }

  headingForReportsWorldSeaPort?.forEach(item => {
    dataReportExportImport.heading[item.label] = { label: item.label, style: item.style }
  })

  const getPreviewPdf = async dataForm => {
    const res = await getPreview({
      values: {
        ...dataReportExportImport,
        tbInfo: {
          ...dataReportExportImport.tbInfo,
          description: dataForm.description
        },
        arr: [...data]
      },
      template: 'tableReport',
      period,
      dataForm
    })
    if (res && res.data && res.data.pdf && res.data.pdf.data) {
      const uint8Array = new Uint8Array(res.data.pdf.data)
      const blob = new Blob([uint8Array], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      setPdfUrl(pdfUrl)
      setData([])
    }
  }

  const onSubmitSearch = async data => {
    if (!isObjectEmpty(data)) {
      setData([])
    }
    setPdfUrl([])
    const res = await runSearch(queryGetAllWorldSeaPortSearch(data))
    if (!res.error) {
      setData(res.data)
      const dateFound = data.search?.find(e => e.dbField === 'datumUnosa')
      if (dateFound?.value) {
        const { start, end } = cloneDeep(dateFound.value)
        const formattedStart = start ? dayjs(start).format(dateFormat) : ''
        const formattedEnd = end ? dayjs(end).format(dateFormat) : ''
        setPeriod(`${formattedStart} - ${formattedEnd}`)
      }
    }
    setSearchOptions(data)
  }

  const createReport = () => {
    navigate(`/izvestaji/svetske-luke`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.createReport}</h2>
              <FormCreateReport
                onSubmit={getPreviewPdf}
                typeOfReports={typeOfReports.filter(e => e.kod === 'SVL')}
                period={period}
                selectedReport={typeOfReports.find(e => e.kod === 'SVL')}
              />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <LoaderWithText text={label.pleaseWait} />
        </div>
      ) : (
        <>
          <Title title={label.worldSeaPort} total={data.length}>
            {!!data?.length && (
              <Button
                onClick={() => createReport()}
                label={label.createReport}
                iconLeft={<FontAwesomeIcon icon="fa-save" />}
                accessAction="ADD"
                accessCode={code}
                stylesClass="btnWarning"
              />
            )}
          </Title>
          <Search fields={worldSeaPortReportFields} onSearch={onSubmitSearch} />
          <OneColumn>
            <Table heading={headingReportWorldSeaPort} data={data} total={data?.length} />
          </OneColumn>
          {pdfUrl && pdfUrl.length > 0 && (
            <div>
              <div className={styles.buttonPosition}>
                <div>
                  <Button onClick={() => setPdfUrl([])} globalClass={'mb-2'} iconLeft={<FontAwesomeIcon icon="fa-trash-can" />} />
                </div>
              </div>
              <embed src={pdfUrl} width="100%" height="800px" alt="pdf" type="application/pdf"></embed>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default WorldSeaPortReport
