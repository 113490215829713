/** Page  'Gard ' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormCity from 'components/forms/formCity'
import HistoryTable from 'components/historyTable'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { cityFields } from 'constants/searchFields'
import { headingCity } from 'constants/tbHeading'
import { queryAddCity, queryDeleteCity, queryGetPagedCity, queryUpdateCity } from 'query/queryCity'
import { queryGetAllCountry } from 'query/queryCountry'
import { queryGetAllRegion } from 'query/queryRegion'
import { queryGetAllTownship } from 'query/queryTownship'
import { queryGetAllUsers } from 'query/queryUser'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'

const City = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: cities = [] } = useGetAllQuery(queryGetPagedCity(pageNumber, pageSize, searchOptions))

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (cities.rows && cities.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, cities?.rows])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(queryUpdateCity(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddCity(data))
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/grad?id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/grad`)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteCity(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/grad`)
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteCity} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  const showModal = () => {
    navigate(`/grad`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.addCity}</h2>
              <FormCity existingData={data} onSubmit={onSubmit} id={null} />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="grad"
                link="/grad?id="
                connectionTb={[
                  { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
                  { rtkQuery: queryGetAllCountry(), dbName: 'drzavaId' },
                  { rtkQuery: queryGetAllRegion(), dbName: 'regionId' },
                  { rtkQuery: queryGetAllTownship(), dbName: 'opstinaId' }
                ]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.cityInput} total={cities?.count}>
        <Button onClick={showModal} label={label.add} iconLeft={<FontAwesomeIcon icon="fa-plus" />} accessAction="ADD" accessCode={code} />
      </Title>
      <Search fields={cityFields} onSearch={onSubmitSearch} />
      {!!cities?.rows?.length && (
        <h3 className="history-link" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns>
        <Table
          heading={headingCity}
          data={cities?.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={cities?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormCity existingData={data} header={true} id={id} onSubmit={onSubmit} onDelete={openDeleteModal} code={code} />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default City
