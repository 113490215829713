import BtnActions from 'components/btnActions'
import MessageNoData from 'components/messageNoData'
import PreviewImages from 'components/previewImages'
import { dateFormat } from 'constants/date'
import { label } from 'constants/labels'
import { FIELD_TYPES } from 'constants/other'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import styles from './reportCustomGoodsImport.module.scss'

const getFieldValue = (data, head, onAction) => {
  let value = data[head.dbName]
  if (head.nestedObj) value = data[head.dbName][head.nestedObj]

  let newValue
  switch (head.type) {
    case FIELD_TYPES.text:
      newValue = value || '-'
      break
    case FIELD_TYPES.date:
      newValue = dayjs(value).format(head.format || dateFormat)
      break
    case FIELD_TYPES.boolean:
      newValue = value ? 'TRUE' : 'FALSE'
      break
    case FIELD_TYPES.image:
      newValue = <PreviewImages imgs={value} />
      break
    case FIELD_TYPES.actions:
      newValue = <BtnActions actions={head.actions} onClick={onAction} data={data} isPopup={head.isPopup} />
      break
    default:
      newValue = value
      break
  }
  return newValue
}

const ReportCustomGoodsImportAndExport = ({
  stylesClass = 'bordered',
  globalClass = '',
  tableWidth = '100%',
  heading = [],
  name,
  data = [],
  colSpan = 0,
  tableRef,
  setPdfData
}) => {
  const [groupedObject, setGrouped] = useState({})
  const [kodovi, setKodovi] = useState([])

  useEffect(() => {
    countVrednostEur()
  }, [data])

  const setDataForPdf = data => {
    setPdfData && setPdfData(data)
  }

  const countVrednostEur = () => {
    const carinskeRobeMapKod = {}
    const kodoviLoc = []
    data?.forEach(one => {
      if (!kodoviLoc.includes(one.kod)) kodoviLoc.push(one.kod)
      if (carinskeRobeMapKod && carinskeRobeMapKod[one.kod] && carinskeRobeMapKod[one.kod].niz) {
        carinskeRobeMapKod[one.kod].niz = [...carinskeRobeMapKod[one.kod].niz, one]
        carinskeRobeMapKod[one.kod].totalEur += Number(one.vrednostEur.replace(/,/g, ''))
        carinskeRobeMapKod[one.kod].totalKG += Number(one.netoMasaUKg.replace(/,/g, ''))
      } else {
        carinskeRobeMapKod[one.kod] = {}
        carinskeRobeMapKod[one.kod].niz = [one]
        carinskeRobeMapKod[one.kod].totalEur = Number(one.vrednostEur.replace(/,/g, ''))
        carinskeRobeMapKod[one.kod].totalKG = Number(one.netoMasaUKg.replace(/,/g, ''))
      }
    })
    setKodovi(kodoviLoc)
    setGrouped(carinskeRobeMapKod)
    setDataForPdf({ groupedObject: carinskeRobeMapKod, arr: kodoviLoc })
  }

  const getHeaders = () => {
    return (
      <>
        <tr>
          {heading.map(el => (
            <th key={`${name}_${el.dbName}`} className={`th_${el.dbName}`}>
              <div>
                <span>{el.label}</span>
              </div>
            </th>
          ))}
        </tr>
      </>
    )
  }

  const formatNumber = number => {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  const getBody = () => {
    return (
      <>
        {kodovi && kodovi.length ? (
          kodovi.map(kod => (
            <>
              {groupedObject[kod] &&
                groupedObject[kod].niz &&
                groupedObject[kod].niz.length &&
                groupedObject[kod].niz.map((obj, i) => (
                  <>
                    <tr key={`tb_${i}`}>
                      {heading.map(head => {
                        const value = getFieldValue(obj, head)
                        return (
                          <>
                            <td
                              key={`${name}_${head.dbName}`}
                              className={`
                        th_${head.dbName}
                      `}
                            >
                              {typeof value === 'string'
                                ? value.split('/n').map((item, index) => (
                                    <div
                                      key={`${name}_${head.dbName}_${i}_${index}`}
                                      style={{ paddingLeft: item.startsWith('-') ? '20px' : '0' }}
                                    >
                                      {item.replace(/^(?!- )- /gm, '')}
                                    </div>
                                  ))
                                : value}
                            </td>
                          </>
                        )
                      })}
                    </tr>
                  </>
                ))}

              <tr>
                <td colSpan="2" className={styles.removeOnlyRight}></td>
                <td colSpan="2" className={styles.removeOnlyLeft}></td>
                <td className={styles.greenResault}>{formatNumber(groupedObject[kod].totalKG)}</td>
                <td className={styles.greenResault}>{formatNumber(groupedObject[kod].totalEur)}</td>
              </tr>
            </>
          ))
        ) : (
          <tr>
            <td colSpan={colSpan || heading.length}>
              <MessageNoData message={label.messageNoData} />
            </td>
          </tr>
        )}
      </>
    )
  }

  return (
    <div className={`${styles[stylesClass]} ${globalClass} `}>
      <div ref={tableRef} id="printTable" className={styles.tbWrapper}>
        <table style={{ width: tableWidth }}>
          <thead>{getHeaders()}</thead>
          <tbody>{getBody()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default ReportCustomGoodsImportAndExport
