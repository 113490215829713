import { getClientHeader } from './clientHeader'
import { getCommonHeader } from './commonHeader'
import { getCommonText } from './comonText'
import { getSigniture } from './signiture'

const getTable = (data = {}) => {
  const children = []
  data?.products?.forEach((product, i) =>
    children.push({
      type: 'tr',
      children: [
        {
          type: 'td',
          class: 'alignCenter',
          children: [{ text: (i + 1).toString() || '-' }]
        },
        {
          type: 'td',
          children: [{ text: product?.name?.toString() || '-' }]
        },
        {
          type: 'td',
          class: 'alignCenter',
          children: [{ text: product?.unit?.toString() || '-' }]
        },
        {
          type: 'td',
          class: 'alignCenter',
          children: [{ text: product?.quantity?.toString() || '-' }]
        },
        {
          type: 'td',
          class: 'alignCenter',
          children: [{ text: product?.pricePerUnit?.toString() || '-' }]
        },
        {
          type: 'td',
          class: 'alignCenter',
          children: [{ text: product?.tax?.toString() || '-' }]
        },
        {
          type: 'td',
          class: 'alignCenter',
          children: [{ text: product?.pdvRate?.toString() || '-' }]
        },
        {
          type: 'td',
          class: 'alignCenter',
          children: [{ text: product?.pdv?.toString() || '-' }]
        },
        {
          type: 'td',
          class: 'alignRight',
          children: [{ text: product?.total?.toString() || '-' }]
        }
      ]
    })
  )
  return children
}

export const getTemplateBill = (data = {}, type) => {
  return [
    ...getCommonHeader(data),
    ...getClientHeader(data, type),
    {
      type: 'table',
      class: 'defaultTable',
      children: [
        {
          type: 'tr',
          children: [
            {
              type: 'th',
              class: 'alignCenter',
              children: [{ text: 'Red br.', bold: true }]
            },
            {
              type: 'th',
              children: [{ text: 'Vrsta dobra', bold: true }]
            },
            {
              type: 'th',
              class: 'alignCenter',
              children: [{ text: 'Jedinica mere', bold: true }]
            },
            {
              type: 'th',
              class: 'alignCenter',
              children: [{ text: 'Kol.', bold: true }]
            },
            {
              type: 'th',
              class: 'alignCenter',
              children: [{ text: 'Cena po jedinici', bold: true }]
            },
            {
              type: 'th',
              children: [{ text: 'Poreska osnovica', bold: true }]
            },
            {
              type: 'th',
              class: 'alignCenter',
              children: [{ text: 'Stopa PDV', bold: true }]
            },
            {
              type: 'th',
              class: 'alignCenter',
              children: [{ text: 'PDV', bold: true }]
            },
            {
              type: 'th',
              class: 'alignRight',
              children: [{ text: 'Ukupna nadoknada', bold: true }]
            }
          ]
        },
        ...getTable(data),
        {
          type: 'tr',
          children: [
            {
              type: 'td',
              children: [{ text: '' }]
            },
            {
              type: 'td',
              children: [{ text: 'UKUPNO', bold: true }]
            },
            {
              type: 'td',
              children: [{ text: '' }]
            },
            {
              type: 'td',
              children: [{ text: '' }]
            },
            {
              type: 'td',
              children: [{ text: '' }]
            },
            {
              type: 'td',
              children: [{ text: '' }]
            },
            {
              type: 'td',
              children: [{ text: '' }]
            },
            {
              type: 'td',
              children: [{ text: '' }]
            },
            {
              type: 'td',
              class: 'alignRight',
              children: [{ text: data?.productTotalPrice?.toString() || '-', bold: true }]
            }
          ]
        }
      ]
    },
    {
      type: 'paragraph',
      class: 'spaceAround',
      children: [{ text: '' }]
    },
    ...getCommonText(data, type),
    ...getSigniture()
  ]
}
