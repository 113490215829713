import { useGetAllQuery } from 'api/dataApiSlice'
import { PAYMENT_TYPE } from 'constants/staticOptions'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { realizationsSerachFields } from 'constants/searchFields'
import { headingRealizations } from 'constants/tbHeading'
import { queryGetAllPagedRealizations } from 'query/queryAnnouncment'
import React, { useState } from 'react'

const Realizations = () => {
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: realizations = {} } = useGetAllQuery(queryGetAllPagedRealizations(pageNumber, pageSize, searchOptions))

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }
  return (
    <div>
      <Title title={label.realization} total={realizations?.count} />
      <Search fields={realizationsSerachFields} onSearch={onSubmitSearch} />
      <Table
        heading={headingRealizations}
        data={realizations?.rows || []}
        globalClass="my-3"
        map={{
          paymentType: PAYMENT_TYPE.reduce((e, x) => {
            return { ...e, [x.id]: x }
          }, {})
        }}
        havePagination={true}
        onPaginationChange={onPaginationChange}
        pageNumber={pageNumber}
        pageSize={pageSize}
        total={realizations?.count}
      />
    </div>
  )
}

export default Realizations
