/** Queries for 'Svetska berza' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialWorldStockExchange } from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES } from 'constants/other'

export const queryGetPagedWorldStockExchange = (pageNumber, pageSize, options = {}, isMilano) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'svetskaBerza',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: isMilano ? tag.worldStockExchangeMilano : tag.worldStockExchange
    }
  }
}

export const queryGetAllWorldStockExchange = (options, isMilano) => {
  if (options.where) options.where = { ...options.where, isMilano }
  else options.where = { isMilano }
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'svetskaBerza',
      data: { options }
    },
    providesTags: {
      type: isMilano ? tag.worldStockExchangeMilano : tag.worldStockExchange
    }
  }
}
/**
 * @param {initialWorldStockExchange} data
 */
export const queryAddWorldStockExchange = (data, isMilano) => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'svetskaBerza',
      data: { values: data }
    },
    invalidatesTags: isMilano ? [tag.worldStockExchangeMilano] : [tag.worldStockExchange],
    successMsg: label.successMsgCreate + ' ' + label.msgWordStock
  }
}

/**
* @param {initialWorldStockExchange} data
@param {string} id
*/
export const queryUpdateWorldStockExchange = (data, id, isMilano) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'svetskaBerza',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: isMilano ? [tag.worldStockExchangeMilano] : [tag.worldStockExchange],
    successMsg: label.successMsgEdit + ' ' + label.msgWordStock
  }
}
/**
@param {string} id
*/
export const queryDeleteWorldStockExchange = (id, isMilano) => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'svetskaBerza',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: isMilano
      ? [tag.worldStockExchangeMilano, tag.worldStockExchangeMilanoLast]
      : [tag.worldStockExchange, tag.worldStockExchangeLast],
    successMsg: label.successMsgDelete + ' ' + label.msgWordStock
  }
}

export const queryAddWorldStockExchangeMultiple = (data, isMilano) => {
  return {
    query: {
      name: 'data',
      mode: 'newMulti',
      type: 'svetskaBerza',
      subType: isMilano ? DATA_TYPES.productsMilano : DATA_TYPES.productsFutures,
      data: { values: data }
    },
    invalidatesTags: isMilano
      ? [tag.worldStockExchangeMilano, tag.worldStockExchangeMilanoLast]
      : [tag.worldStockExchange, tag.worldStockExchangeLast],
    successMsg: label.successMsgCreate + ' ' + label.msgWordPort
  }
}

export const queryGetLastInsertWorldStockExchange = (options, isMilano) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'svetskaBerza',
      data: { options, subMode: 'lastInsert' }
    },
    providesTags: {
      type: isMilano ? tag.worldStockExchangeMilanoLast : tag.worldStockExchangeLast
    }
  }
}
