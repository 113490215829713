import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from '../api'
import authReducer from './reducers/authSlice'
import modalReducer from './reducers/modalSlice'
import dataReducer from './reducers/dataSlice'
import staticReducer from './reducers/staticSlice'
import toastService from 'services/toastService'
import socketReducer from './reducers/socketSlice'

const env = process.env.NODE_ENV

const rtkQueryErrorLogger = api => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!', api, action.payload)
    const base = action?.payload?.data
    const msgErr = base?.error?.message || base?.message || 'Something went wrong'
    toastService.show('error', msgErr)
  }
  return next(action)
}

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    modal: modalReducer,
    data: dataReducer,
    static: staticReducer,
    socket: socketReducer
  },
  middleware: getDefaultMiddleware =>
    env === 'development'
      ? [...getDefaultMiddleware({ serializableCheck: false }), logger, api.middleware, rtkQueryErrorLogger]
      : [...getDefaultMiddleware({ serializableCheck: false }), api.middleware, rtkQueryErrorLogger],
  devTools: false
})

setupListeners(store.dispatch)
