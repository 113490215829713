/** Page  'Svetske luke' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, OneColumn } from 'components/containers/containers'
import FormAddMultipleWorldSeaPort from 'components/forms/formAddMultipleFiedls/formAddMultipleWorldSeaPort'
// import HistoryTable from 'components/historyTable'
import MessageNoData from 'components/messageNoData'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { worldSeaPortFields } from 'constants/searchFields'
import { headingWorldSeaPort } from 'constants/tbHeading'
import { queryGetAllPortDelivery } from 'query/queryPortDelivery'
import {
  queryAddWorldSeaPortMultiple,
  queryDeleteWorldSeaPort,
  queryGetLastInsertWorldSeaPort,
  queryGetPagedWorldSeaPort,
  queryUpdateWorldSeaPort
} from 'query/queryWorldSeaPort'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'

const WorldSeaPort = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: worldSeaPort = [] } = useGetAllQuery(queryGetPagedWorldSeaPort(pageNumber, pageSize, searchOptions))
  const { data: lastInsertWorldSeaPort = [] } = useGetAllQuery(queryGetLastInsertWorldSeaPort())
  const { data: seaportConditions = [] } = useGetAllQuery(queryGetAllPortDelivery())
  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [preview, setPreview] = useState('table')
  const seaportConditionsForMaping = seaportConditions

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (worldSeaPort.rows && worldSeaPort.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, worldSeaPort.rows])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(queryUpdateWorldSeaPort(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddWorldSeaPortMultiple(data))
      if (!res.error) {
        dispatch(closeModal(1))
        setPreview('table')
      }
    }
    dispatch(setIsLoadingData(false))
  }

  // const onRowClick = data => {
  //   dispatch(setIsDisabled(true))
  //   navigate(`/svetske-luke?id=${data.id}`)
  //   setPreview('form')
  //   setData(data)
  // }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      await deleteOne(queryDeleteWorldSeaPort(data.id))
      dispatch(closeModal(1))
      navigate('/svetske-luke')
      setPreview('table')
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteWorldSeaPort} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/svetske-luke`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const goBack = () => {
    if (!data.id) setPreview('table')
    else {
      navigate('/svetske-luke')
      setPreview('table')
    }
  }

  // const openHistory = () => {
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: (
  //           <div>
  //             <HistoryTable
  //               collection="svetskaLuka"
  //               link="/svetske-luke?id="
  //               connectionTb={[
  //                 { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
  //                 { rtkQuery: queryGetAllProducts(), dbName: 'proizvodId' },
  //                 { rtkQuery: queryGetAllSeaPort(), dbName: 'lukaId' },
  //                 { rtkQuery: queryGetAllCurrency(), dbName: 'valutaId' },
  //                 { rtkQuery: queryGetAllMeasureUnits(), dbName: 'jedinicaMereId' }
  //               ]}
  //             />
  //           </div>
  //         ),
  //         order: 1,
  //         size: 'xlg'
  //       },
  //       active: 1
  //     })
  //   )
  // }

  switch (preview) {
    case 'table':
      return (
        <div>
          <Title title={label.worldSeaPort} total={worldSeaPort?.count}>
            <Button
              onClick={() => setPreview('addLast')}
              label={label.lastInsert}
              iconLeft={<FontAwesomeIcon icon="fa-cloud-arrow-up" />}
              accessAction="ADD"
              accessCode={code}
            />
            <Button
              onClick={() => setPreview('add')}
              label={label.add}
              iconLeft={<FontAwesomeIcon icon="fa-plus" />}
              accessAction="ADD"
              accessCode={code}
            />
          </Title>
          <Search fields={worldSeaPortFields} onSearch={onSubmitSearch} />
          {/* {!!worldSeaPort?.rows?.length && (
              <h3 className="history-link" onClick={openHistory}>
                {label.historyChanges}
              </h3>
            )} */}
          <OneColumn classWidth="width5050">
            <Table
              heading={headingWorldSeaPort}
              data={worldSeaPort.rows}
              // onRowClick={onRowClick}
              onAction={onActionClick}
              havePagination={true}
              onPaginationChange={onPaginationChange}
              pageNumber={pageNumber}
              pageSize={pageSize}
              total={worldSeaPort?.count}
              map={{
                seaportConditions: seaportConditionsForMaping.reduce((e, x) => {
                  return { ...e, [x.id]: x }
                }, {})
              }}
            />
          </OneColumn>
        </div>
      )
    case 'addLast':
      return (
        <div>
          <Title title={label.worldSeaPort} goBackClick={goBack} globalClass="mb-2" />
          <FormWrapper>
            <FormAddMultipleWorldSeaPort existingData={lastInsertWorldSeaPort} id={id} onSubmit={onSubmit} />
          </FormWrapper>
        </div>
      )
    case 'add':
      return (
        <div>
          <Title title={label.worldSeaPort} goBackClick={goBack} globalClass="mb-2" />
          <FormWrapper>
            <FormAddMultipleWorldSeaPort id={id} onSubmit={onSubmit} />
          </FormWrapper>
        </div>
      )
    default:
      return <MessageNoData message={label.messageNoData} />
  }
}
export default WorldSeaPort
