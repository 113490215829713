import { BoxContainer } from 'components/containers/containers'
import styles from './auth.module.scss'
import FormSmsCode from '../../components/forms/formSmsCode'
import { useForgotPasswordCheckSmsCodeMutation } from 'api/authApiSlice'
import { useNavigate } from 'react-router-dom'

const EnterSmsCode = () => {
  const [forgotPasswordCheckSmsCode] = useForgotPasswordCheckSmsCodeMutation()
  const navigate = useNavigate()

  const onSubmit = async data => {
    const tempData = await forgotPasswordCheckSmsCode(data)
    navigate('/reset/' + tempData.data.forgotCode + '/' + tempData.data.email + '/sms')
  }

  return (
    <div className={styles.wrapper}>
      <BoxContainer globalClass="p-3">
        <div>
          <h2>Unesite code koji vam je stigao na sms</h2>
        </div>
        <FormSmsCode onSubmit={onSubmit} />
      </BoxContainer>
    </div>
  )
}

export default EnterSmsCode
