import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'
import { closeModal } from 'redux/reducers/modalSlice'
import styles from './modal.module.scss'
import useClickOutside from 'hooks/outsideClick'
import { PropTypes } from 'prop-types'

const ModalWrapper = ({ show, content, size = 'md', iconClose = true, stylesClass = 'customModal' }) => {
  const dispatch = useDispatch()
  const modalParam = useSelector(state => state.modal)
  const { ref, isComponentVisible } = useClickOutside(show)
  const [modalIsOpen] = useState(show)

  useEffect(() => {
    if (!isComponentVisible) close()
  }, [ref])

  const close = () => dispatch(closeModal(modalParam.active))

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={close}
      contentLabel="Custom modal"
      className={`${styles[stylesClass]} ${styles[size]}`}
      overlayClassName={`${styles.customOverlay}`}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.modalBody} ref={ref}>
        {iconClose && (
          <span className={styles.close} onClick={close}>
            <FontAwesomeIcon icon="fa-close" />
          </span>
        )}
        {content}
      </div>
    </Modal>
  )
}

ModalWrapper.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.element,
  size: PropTypes.string,
  iconClose: PropTypes.bool,
  stylesClass: PropTypes.string
}
export default ModalWrapper
