import CustomPagination from 'components/pagination'
import { dateFormat } from 'constants/date'
import { FIELD_TYPES } from 'constants/other'
import dayjs from 'dayjs'
import styles from './activity.module.scss'

import { label } from 'constants/labels'
import MessageNoData from 'components/messageNoData'
import { ACTIVITY_ACTIONS, PAGE_PATH } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const getFieldValue = (data, head, type) => {
  let value = data[head.dbName]
  if (head.nestedObj) value = data[head.dbName]?.[head.nestedObj]
  let newValue
  switch (head.type) {
    case FIELD_TYPES.actions: {
      newValue = ACTIVITY_ACTIONS[data?.[head.dbName]] || '-'
      break
    }
    case FIELD_TYPES.path: {
      const path = PAGE_PATH[type][value?.path] || value?.path
      const pathWithId = value.id ? PAGE_PATH[type]?.ids?.find(el => value?.path?.includes(el?.key)) : null
      const pathWithMoreQueries = value.id ? value?.path?.includes('&&') : null
      let href = `${pathWithId?.key}${pathWithId?.id === 'search' ? `?id=${value?.id}` : value?.id}`
      if (pathWithMoreQueries) {
        href = `${pathWithId?.key}${pathWithId?.id === 'search' ? `&&id=${value?.id}` : value?.id}`
      }
      newValue =
        value.id && path ? (
          <div className={styles.pathWithId}>
            <div>
              {pathWithId?.label}
              <a href={href} className={styles.newValue} target="_blank" rel="noreferrer">
                <b> ID:{value?.id}</b> <FontAwesomeIcon icon="fa-up-right-from-square" />
              </a>
            </div>
            {value.tabName && <b>TAB:{value.tabName}</b>}
          </div>
        ) : (
          path || '-'
        )
      break
    }
    case FIELD_TYPES.text:
      newValue = value || '-'
      break
    case FIELD_TYPES.date:
      newValue = dayjs(value).format(head.format || dateFormat)
      break

    default:
      newValue = value
      break
  }
  return newValue
}

const ActivityTable = ({ data, heading, type, pageSize, pageNumber, onPaginationChange }) => {
  const getHeaders = () => {
    return (
      <>
        <tr>
          {heading.map(el => (
            <th key={`${type}_${el.dbName}`} className={`${styles[el.dbName] ? styles[el.dbName] : ''}`}>
              {el.label}
            </th>
          ))}
        </tr>
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      <table>
        <thead>{getHeaders()}</thead>
        <tbody>
          {data?.rows?.length ? (
            data.rows.map((e, i) => (
              <tr key={`tb_${i}`} className={`${styles[e.dbName] ? styles[e.dbName] : ''}`}>
                {heading.map(head => (
                  <td>{getFieldValue(e, head, type)}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <MessageNoData message={label.messageNoData} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!!data?.rows?.length && <CustomPagination pageSize={pageSize} page={pageNumber} onChange={onPaginationChange} total={data.count} />}
    </div>
  )
}

export default ActivityTable
