import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllCelebrations } from 'query/queryClientConectionTables'
import styles from '../record.module.scss'
import Table from 'components/table'
import { CELEBRATION_TYPE } from 'constants/staticOptions'
import { FIELD_TYPES } from 'constants/other'
import { monthDay } from 'constants/date'
import { useParams } from 'react-router-dom'

const PreviewCelebration = () => {
  const { id } = useParams()
  const { data: celebrations = [] } = useGetAllQuery(queryGetAllCelebrations(id))
  const onAction = (value, action) => console.log('action', action, value)
  return (
    <div className={styles.contactsTable}>
      {celebrations.length ? (
        <div>
          <Table
            map={{
              tipProslave: CELEBRATION_TYPE.reduce((e, x) => {
                return { ...e, [x.id]: x }
              }, {})
            }}
            heading={[
              { dbName: 'ime', label: 'Ime/Slava', type: FIELD_TYPES.text },
              { dbName: 'tipProslave', label: 'Tip ', type: FIELD_TYPES.maped, map: 'tipProslave', mapLabel: 'label' },
              { dbName: 'datumProslave', label: 'Datum ', format: monthDay, type: FIELD_TYPES.date }
            ]}
            name="listCelebrations"
            data={celebrations}
            onAction={onAction}
            haveActionInline={true}
          />
        </div>
      ) : (
        <div>Nama podataka</div>
      )}
    </div>
  )
}
export default PreviewCelebration
