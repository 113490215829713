import { useSelector } from 'react-redux'
import { getAccessRights } from 'utils'

const AccessRights = ({ code, children, action = 'VIEW' }) => {
  const user = useSelector(state => state?.auth?.user)
  console.log('USER', user)
  if (getAccessRights(user, code, action)) return <div>Nemate prava da pristupite ovoj stranici</div>
  return children
}

export default AccessRights
