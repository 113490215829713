/** Page  'IP' */

import { useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { OneColumn } from 'components/containers/containers'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { ipsFields } from 'constants/searchFields'
import { headingIps } from 'constants/tbHeading'
import { queryDisableIp, queryGetPagedIps } from 'query/queryIps'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled } from 'redux/reducers/staticSlice'

const Ips = () => {
  const dispatch = useDispatch()
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: ips = {} } = useGetAllQuery(queryGetPagedIps(pageNumber, pageSize, searchOptions))
  const [updateData] = useUpdateCreateOneMutation()

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onActionClick = (data, type) => {
    const temp = { id: data.id, isDisabled: type === 'disable' }
    openConfirmModal(temp)
  }
  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }

  const handleUpdate = async (answer, data) => {
    if (answer) {
      const res = await updateData(queryDisableIp(data.isDisabled, data.id))
      if (!res.error) dispatch(closeModal(1))
    } else {
      dispatch(closeModal(1))
    }
  }
  const openConfirmModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <ModalYesNo
              handleSubmit={handleUpdate}
              data={data}
              question={data.isDisabled ? label.msgDisableDisableIps : label.msgDisableEnableIps}
            />
          ),
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  return (
    <div>
      <Title title={label.ipAddress} total={ips?.count} />
      <Search fields={ipsFields} onSearch={onSubmitSearch} />
      <OneColumn>
        <Table
          heading={headingIps}
          data={ips?.rows}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={ips?.count}
        />
      </OneColumn>
    </div>
  )
}
export default Ips
