/** Queries for 'Uloge/Roles' */
import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialRole } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetAllRoles = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'uloga',
      data: {
        options: {
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.roles
    }
  }
}

export const queryGetRoleById = id => {
  return {
    query: {
      name: 'data',
      mode: 'get',
      type: 'uloga',
      data: {
        options: {
          where: { id },
          order: [['naziv', 'ASC']]
        }
      }
    }
    // providesTags: {
    //   type: tag.roles
    // }
  }
}

export const queryGetAllForms = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'forma',
      data: {
        options: {
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.forms
    }
  }
}

/**
 * @param {initialRole} data
 */
export const queryAddRole = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'uloga',
      data: { values: data }
    },
    invalidatesTags: [tag.roles, tag.user],
    successMsg: label.successMsgCreate + ' ' + label.msgUloga
  }
}

/**
* @param {initialRole} data
@param {string} id
*/
export const queryUpdateRole = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'uloga',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.roles, tag.user],
    successMsg: label.successMsgEdit + ' ' + label.msgUloga
  }
}
