/** Form for  add  'Carinske robe izvoz' */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import DatePickerComp from 'components/dataPicker'
import MonthPickerComp from 'components/dataPicker/monthPicker'
import XLSXImport from 'components/XLSXImport'
import { initialCustomGoodsImportAndExport } from 'constants/initialValues'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { getCurrentTime, isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import { errCustomGoodsImportAndExport as errorMsg } from './helpers/errorMsg'
import { validateCustomGoodsImportAndExport as valdateForm } from './helpers/validate'

const FormCustomGoodsExport = ({ existingData = {}, id, onSubmit, onDelete, header = false }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialCustomGoodsImportAndExport })

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  useEffect(() => {
    setData(prev => ({ ...prev, dbName: 'carinskeRobeIzvoz', datumUnosa: new Date() }))
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      datumUnosa: data.datumUnosa,
      datum: data.datum,
      importovaneRobe: data.importovaneRobe,
      dbName: data.dbName,
      header: data.header,
      aktivan: data.aktivan
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleFile = fileData => {
    const { importovaneRobe = [], header = [] } = fileData
    setData(formData => ({ ...formData, importovaneRobe, header }))
  }

  const onDateChange = (date, name) => {
    setData(formData => ({ ...formData, [name]: date ? getCurrentTime(date) : null }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.customGoodsImport + ': '}
            disabled={disabled}
          />
        </div>
      )}
      <div>
        <DatePickerComp
          startDate={data.datumUnosa ? new Date(data.datumUnosa) : null}
          onChange={date => onDateChange(date, 'datumUnosa')}
          label={label.dateOfImportInput}
          errorTxt={error && !data.datumUnosa && errorMsg('datumUnosa', data)}
          disabled
          // showTime={true}
        />
        <MonthPickerComp
          startDate={data.datum ? new Date(data.datum) : null}
          onChange={date => onDateChange(date, 'datum')}
          label={label.month}
          errorTxt={error && !data.datum && errorMsg('datum', data)}

          // showTime={true}
        />
        <XLSXImport customGoodsImport handleFileUpload={handleFile} />
        <div className="ta-r mt-2">
          <Button
            label={label.importFile}
            iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
            disabled={!data?.importovaneRobe?.length > 0 && data?.datumUnosa}
            onClick={handleSubmit}
            loading={isLoading}
            stylesClass="btnWarning"
          />
        </div>
      </div>
    </div>
  )
}

export default FormCustomGoodsExport
