import { tag } from 'api/rtkTags'
import { label } from 'constants/labels'

export const queryGetAllValutesByProductId = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'proizvodiValute',
      data: {
        options
      }
    },
    providesTags: {
      type: tag.productCurency
    }
  }
}

export const queryDeleteProductCurrency = (id, tip) => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'proizvodiValute',
      subType: tip,
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.productCurency],
    successMsg: label.successMsgDelete + ' ' + label.msgProductsCurrency
  }
}
