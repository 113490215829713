/** Form for  add/edit/remove  'Tip izvestaja/ReportsType' */

import Input from 'components/input'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import FormBtnSave from './formHeader/formButton'

const FormReportType = ({ existingData = {}, id, onSubmit, header = false, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ kod: '', naziv: '' })

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = { naziv: data.naziv, kod: data.kod }
    if (data.id) reqData.id = data.id
    if (data.naziv && data.kod) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            // onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.typeOfReport}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && 'Naziv je obavezan'}
          disabled={disabled}
          isPreview={disabled}
        />
        <Input
          name="naziv"
          label={label.kodInput}
          placeholder={label.kodInput}
          value={data.kod}
          onChange={handleChange}
          errorTxt={error && !data.kod && 'Kod je obavezan'}
          disabled={true}
          isPreview={disabled}
        />
        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormReportType
