/** Queries for 'Proizvod Luka' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialProductsPort } from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES } from 'constants/other'

export const queryGetPagedProductsPort = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'proizvod',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          where: { tipPodatka: DATA_TYPES.productsPort },
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.productsPort
    }
  }
}

export const queryGetProduct = id => {
  return {
    query: {
      name: 'data',
      mode: 'get',
      type: 'proizvod',
      data: {
        options: {
          where: { id }
        }
      }
    },
    providesTags: {
      type: tag.productsPort
    }
  }
}

export const queryGetAllProductsPort = (options = {}) => {
  const query = { tipPodatka: DATA_TYPES.productsPort }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query

  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'proizvod',
      data: {
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.productsPort
    }
  }
}
/**
 * @param {initialProductsPort} data
 */
export const queryAddProductsPort = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'proizvod',
      subType: DATA_TYPES.productsPort,
      data: { values: data }
    },
    invalidatesTags: [tag.productsPort, tag.productCurency, tag.measureUnitProduct],
    successMsg: label.successMsgCreate + ' ' + label.msgProductsPort
  }
}

/**
* @param {initialProductsPort} data
@param {string} id
*/
export const queryUpdateProductsPort = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'proizvod',
      subType: DATA_TYPES.productsPort,
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.productsPort, tag.productCurency, tag.measureUnitProduct],
    successMsg: label.successMsgEdit + ' ' + label.msgProductsPort
  }
}
/**
@param {string} id
*/
export const queryDeleteProductsPort = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'proizvod',
      subType: DATA_TYPES.productsPort,
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.productsPort],
    successMsg: label.successMsgDelete + ' ' + label.msgProductsPort
  }
}

export const queryGetAllValutesByProductIdSearchPort = (options = {}) => {
  const query = { tipPodatka: DATA_TYPES.productsPort }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'proizvodiValute_search',
      data: {
        options
      }
    },
    providesTags: {
      type: tag.productCurency
    }
  }
}

export const queryGetAllMeasureUnitProductsSearchPort = options => {
  const query = { tipPodatka: DATA_TYPES.productsPort }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'jedinicaMereProizvod_search',
      data: { options }
    },
    providesTags: {
      type: tag.measureUnitProduct
    }
  }
}
