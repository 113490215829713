/** Page  'Klijenti / Clients' */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetAllQuery } from 'api/dataApiSlice'
import Button from 'components/button'
import { OneColumn } from 'components/containers/containers'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { headingClients } from 'constants/tbHeading'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { queryGetAllClients, queryGetPagedClientSearch } from 'query/queryClient'
import { PAGE_SIZE } from 'constants/other'
import HistoryTable from 'components/historyTable'
import { queryGetAllUsers } from 'query/queryUser'
import { queryGetAllProducts } from 'query/queryProducts'
import { openModal } from 'redux/reducers/modalSlice'
import { CELEBRATION_TYPE, STATUS_CLIENT } from 'constants/staticOptions'
import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import { queryGetAllSubBusinessActivity } from 'query/querySubBusinessActivity'
import ClientSearch from './search'

const Clients = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [searchOptions, setSearchOptions] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const { data: clients = [], isFetching } = useGetAllQuery(
    queryGetPagedClientSearch(pageNumber, pageSize, {
      ...searchOptions,
      sqlType: {
        NOT: { key: 'statusLicaId', value: null },
        ...searchOptions?.sqlType
      }
    })
  )

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/klijenti/karton/${data.id}`)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="klijent"
                link="/klijenti/karton/"
                connectionTb={[
                  { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
                  { rtkQuery: queryGetAllProducts(), dbName: 'proizvodId' },
                  { rtkQuery: queryGetAllClients(), dbName: 'klijentId' },
                  { rtkQuery: queryGetAllBusinessActivity(), dbName: 'delatnostId' },
                  { rtkQuery: queryGetAllSubBusinessActivity(), dbName: 'podDelatnostId' },
                  { staticData: CELEBRATION_TYPE, dbName: 'tipProslave' },
                  { staticData: STATUS_CLIENT, dbName: 'status' }
                ]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }

  return (
    <div>
      <Title title={label.clients} total={clients?.count}>
        <Button
          onClick={() => navigate('dodaj')}
          label={label.add}
          iconLeft={<FontAwesomeIcon icon="fa-plus" />}
          accessAction="ADD"
          accessCode={code}
        />
      </Title>
      <div className="mt-1">
        <ClientSearch havePagination={true} setSearchFields={setSearchOptions} isLoading={isFetching} />
      </div>
      {!!clients?.rows?.length && (
        <h3 className="history-link-border" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <OneColumn>
        <Table
          heading={headingClients}
          data={clients?.rows}
          onRowClick={onRowClick}
          // onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={clients?.count}
        />
      </OneColumn>
    </div>
  )
}
export default Clients
