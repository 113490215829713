/** Form for  add/edit/remove  'Paket' */

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { validatePackage as valdateForm } from './helpers/validate'
import { errPackage as errorMsg } from './helpers/errorMsg'
import { initialPackage } from 'constants/initialValues'
import { isObjectEmpty } from 'utils'
import { label } from 'constants/labels'
import { FormGroup, TwoColumns } from 'components/containers/containers'
import TextArea from 'components/textarea'
import SelectInput from 'components/selectInput'
import { statusPackage, PACKAGE_DURATION } from '../../constants/staticOptions'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllService } from 'query/queryService'
import ErrorMessage from 'components/errorMessage'
import { queryGetAllServiceCategories } from 'query/queryServiceCategory'
import NestedServices from 'components/nestedServices'
import { cloneDeep } from 'lodash'

function convertToNestedObject(arr = []) {
  const result = {}

  function addToResult(item) {
    const { id, parentKategorijaId } = item
    if (!parentKategorijaId) {
      result[id] = {}
      result[id].usluge = []
      addChildItems(id, result[id])
    }
  }

  function addChildItems(parentId, parentObject) {
    arr.forEach(item => {
      if (item.parentKategorijaId === parentId) {
        parentObject[item.id] = {}
        parentObject[item.id].usluge = []
        addChildItems(item.id, parentObject[item.id])
      }
    })
  }

  arr.forEach(addToResult)
  return result
}

const findPath = (ob, key) => {
  const path = []
  const keyExists = obj => {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return false
    } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return true
    } else if (Array.isArray(obj)) {
      const parentKey = path.length ? path.pop() : ''

      for (let i = 0; i < obj.length; i++) {
        path.push(`${parentKey}[${i}]`)
        const result = keyExists(obj[i], key)
        if (result) {
          return result
        }
        path.pop()
      }
    } else {
      for (const k in obj) {
        path.push(k)
        const result = keyExists(obj[k], key)
        if (result) {
          return result
        }
        path.pop()
      }
    }
    return false
  }

  keyExists(ob)

  return path.join('.')
}

const FormPackage = ({ existingData = {}, id, onSubmit }) => {
  const isLoading = useSelector(state => state.static.isLoading)
  //  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialPackage })
  const { data: services = [] } = useGetAllQuery(queryGetAllService())
  const { data: servicesCategory = [] } = useGetAllQuery(queryGetAllServiceCategories())
  const [checkedItems, setCheckedItems] = useState({})
  const [checkedAccordion, setCheckedAccordion] = useState({})
  const newObject = convertToNestedObject(servicesCategory)

  services?.forEach(newItem => {
    const path = findPath(newObject, newItem.kategorijaUslugaId)
    const newPath = path === '' ? newItem.kategorijaUslugaId + '.usluge' : path + '.' + newItem.kategorijaUslugaId + '.usluge'
    const keys = newPath.split('.')
    let currentValue = newObject
    for (const key of keys) {
      currentValue = currentValue[key] ?? []
    }

    if (Array.isArray(currentValue)) {
      currentValue.push(newItem)
    }
  })

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) {
      setData({ ...existingData })

      const newCheckedItems = {}
      existingData?.usluge?.forEach(serviceId => {
        newCheckedItems[serviceId] = true
      })
      setCheckedItems(newCheckedItems)

      const newCheckedKategorije = {}
      existingData?.kategorije?.forEach(serviceId => {
        newCheckedKategorije[serviceId] = true
      })
      setCheckedAccordion(newCheckedKategorije)
    }
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()

    const reqData = {
      aktivan: data.aktivan,
      tipTrajanja: data.tipTrajanja,
      trajanje: data.trajanje,
      naziv: data.naziv,
      opis: data.opis,
      kratakOpis: data.kratakOpis,
      status: data.status,
      usluge: data.usluge,
      kategorije: data.kategorije
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onSelectChange = async (e, name) => {
    setData(prev => ({ ...prev, [name]: e?.id || null }))
  }

  const handleCheckboxChange = event => {
    const serviceId = parseInt(event.target.id)
    const isChecked = event.target.checked
    const pathToCheckbox = findParentObjects(newObject, serviceId)
    const newPathToCheckbox = pathToCheckbox.filter(item => item !== 'usluge')
    const tempAccordion = cloneDeep(checkedAccordion)
    for (const idAccordion of newPathToCheckbox) {
      if (tempAccordion[idAccordion]) {
        delete tempAccordion[idAccordion]
      }
    }

    const updatedUsluge = [...data.usluge]

    if (isChecked) {
      updatedUsluge.push(serviceId)
    } else {
      const index = updatedUsluge.indexOf(serviceId)
      if (index !== -1) {
        updatedUsluge.splice(index, 1)
      }
    }
    setData(prevState => ({
      ...prevState,
      usluge: updatedUsluge
    }))

    setCheckedItems(prevState => ({
      ...prevState,
      [serviceId]: isChecked
    }))

    setCheckedAccordion(tempAccordion)
  }

  function findObjectByKey(obj, key) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return obj[key]
    }
    for (const prop in obj) {
      if (typeof obj[prop] === 'object' && obj[prop] !== null) {
        const result = findObjectByKey(obj[prop], key)
        if (result) {
          return result
        }
      }
    }
    return null
  }

  const onChangeHandleSelect = (event, checkBoxId) => {
    const isChecked = event.target.checked
    const ids = []
    const idsAccordion = []
    idsAccordion.push(checkBoxId)
    const testObject = findObjectByKey(newObject, checkBoxId)

    function recursiveSearch(obj) {
      for (const prop in obj) {
        if (prop === 'usluge' && Array.isArray(obj[prop])) {
          for (const usluga of obj[prop]) {
            ids.push(usluga.id)
          }
        } else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
          idsAccordion.push(prop)
          recursiveSearch(obj[prop])
        }
      }
    }

    if (testObject) {
      recursiveSearch(testObject)
    }

    const tempServices = cloneDeep(checkedItems)
    for (const id of ids) {
      if (tempServices[id] && !event.target.checked) {
        delete tempServices[id]
      } else if (event.target.checked) {
        tempServices[id] = true
      }
    }
    const tempAccordion = cloneDeep(checkedAccordion)
    for (const idAccordion of idsAccordion) {
      if (tempAccordion[idAccordion] && !event.target.checked) {
        delete tempAccordion[idAccordion]
      } else if (event.target.checked) {
        tempAccordion[idAccordion] = true
      }
    }

    const updatedUsluge = data?.usluge ? [...data.usluge] : []
    const updatedKategorije = data?.kategorije ? [...data.kategorije] : []

    function addItemToData(item, data) {
      if (!data.includes(item)) {
        data.push(item)
      }
    }

    function removeItemFromData(item, data) {
      const index = data.indexOf(item)
      if (index !== -1) {
        data.splice(index, 1)
      }
    }

    function checkItemFromData(source, target) {
      for (const item of source) {
        if (isChecked) {
          addItemToData(item, target)
        } else {
          removeItemFromData(item, target)
        }
      }
    }

    checkItemFromData(ids, updatedUsluge)
    checkItemFromData(idsAccordion, updatedKategorije)
    setData(prevState => ({
      ...prevState,
      usluge: updatedUsluge,
      kategorije: updatedKategorije
    }))
    setCheckedAccordion(tempAccordion)
    setCheckedItems(tempServices)
  }

  function findParentObjects(data, clickedCheckboxId, path = []) {
    for (const key in data) {
      const currentPath = [...path, key]
      if (key === 'usluge' && Array.isArray(data[key])) {
        for (const usluga of data[key]) {
          if (usluga.id === clickedCheckboxId) {
            return currentPath
          }
        }
      } else if (typeof data[key] === 'object') {
        const result = findParentObjects(data[key], clickedCheckboxId, currentPath)
        if (result) {
          return result
        }
      }
    }
    return null
  }

  return (
    <div>
      <TwoColumns>
        <div>
          <FormGroup label={label.groupFormTitleBasic}>
            <Input
              name="naziv"
              label={label.nameInput}
              placeholder={label.placeholder}
              globalClass="my-2"
              value={data.naziv}
              onChange={handleChange}
              errorTxt={error && !data.naziv && errorMsg('naziv', data)}
              // disabled={disabled}
              // isPreview={disabled}
            />
            <SelectInput
              options={PACKAGE_DURATION || []}
              handleChangeSelect={e => onSelectChange(e, 'tipTrajanja')}
              selectedOption={PACKAGE_DURATION.find(el => el.id === data.tipTrajanja || null)}
              customValue="value"
              customLabel="label"
              label={label.durationTypeInput}
              globalClass="my-3"
              errorTxt={error && !data.tipTrajanja && errorMsg('tipTrajanja', data)}
              // disabled={disabled}
              // isPreview={disabled}
            />
            <Input
              name="trajanje"
              label={label.durationInput}
              placeholder={label.placeholder}
              globalClass="mb-2"
              value={data.trajanje}
              onChange={handleChange}
              errorTxt={error && !data.trajanje && errorMsg('trajanje', data)}
              type="number"
              // disabled={disabled}
              // isPreview={disabled}
            />
            <SelectInput
              options={statusPackage || []}
              handleChangeSelect={e => onSelectChange(e, 'status')}
              selectedOption={statusPackage.find(el => el.id === data.status || null)}
              customValue="value"
              customLabel="label"
              label={label.statusInput}
              globalClass="my-3"
              errorTxt={error && !data.status && errorMsg('status', data)}
              // disabled={disabled}
              // isPreview={disabled}
            />
          </FormGroup>
        </div>
        <div>
          <FormGroup label={label.descInputPackage}>
            <TextArea
              rows={4}
              name="opis"
              label={label.descriptionDitailInput}
              placeholder={label.placeholder}
              globalClass="mb-2"
              value={data.opis}
              onChange={handleChange}
              errorTxt={error && !data.opis && errorMsg('opis', data)}
              // disabled={disabled}
              // isPreview={disabled}
            />
            <TextArea
              rows={2}
              name="kratakOpis"
              label={label.shortDescriptionInput}
              placeholder={label.placeholder}
              globalClass="mb-2"
              value={data.kratakOpis}
              onChange={handleChange}
              errorTxt={error && !data.kratakOpis && errorMsg('kratakOpis', data)}
              // isPreview={disabled}
              // disabled={disabled}
            />

            <Checkbox
              name="aktivan"
              label={label.activeInput}
              checked={data.aktivan}
              onChange={e => setData(formData => ({ ...formData, aktivan: e.target.checked }))}
              globalClass="mt-3 mb-2"
              // disabled={disabled}
            />
          </FormGroup>
        </div>
      </TwoColumns>
      <div style={{ position: 'relative' }} className="pt-3">
        <h3 className="mb-1 c-warning t-upper">{label.services}</h3>
        <NestedServices
          dataNested={newObject}
          servicesCategory={servicesCategory}
          handleCheckboxChange={handleCheckboxChange}
          checkedItems={checkedItems}
          checkedAccordion={checkedAccordion}
          // disabled={disabled}
          onChangeHandleSelect={onChangeHandleSelect}
        />
        {data.usluge.length === 0 && error && <ErrorMessage message={label.serviceInputErr} />}
      </div>
      <div className="ta-r mt-2">
        <Button
          label={label.save}
          iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
          // disabled={disabled}
          onClick={handleSubmit}
          loading={isLoading}
          stylesClass="btnWarning"
        />
      </div>
    </div>
  )
}

export default FormPackage
