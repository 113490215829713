import React from 'react'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { label } from 'constants/labels'
import SelectInput from 'components/selectInput'
import DatePickerComp from 'components/dataPicker'
import MonthPickerComp from 'components/dataPicker/monthPicker'
import YearPickerComp from 'components/dataPicker/yearPicker'
import styles from './search.module.scss'

const Fields = ({ data, onChange }) => {
  return data.map((field, i) => {
    return (
      <React.Fragment key={`${field.dbField}_${i}`}>
        {field.type === 'text' && (
          <div className={styles.field}>
            <Input
              id={`search_${field.dbField}}`}
              value={field.value}
              name={field.dbField}
              type="text"
              label={field.label}
              onChange={e => onChange(field.dbField, e.target.value)}
              globalClass="mb-1 pr-1"
            />
          </div>
        )}

        {field.type === 'checkbox' && (
          <div className={styles.field}>
            <Checkbox
              id={`search_${field.dbField}}`}
              name={field.dbField}
              label={field.label}
              onChange={e => onChange(field.dbField, e.target.checked)}
              checked={field.value}
              globalClass="mb-1 pr-1"
            />
          </div>
        )}
        {field.type === 'select' && (
          <div className={styles.field}>
            <SelectInput
              keyName={`select_${field.dbField}_${i}`}
              options={field.options}
              handleChangeSelect={e => onChange(field.dbField, e?.[field?.keyValue], null, e)}
              selectedOption={field.options && field?.options?.find(el => el?.[field.keyValue] === field?.value || null)}
              customValue={field.keyValue}
              customLabel={field.keyLabel}
              label={field.label}
              globalClass="mb-1 pr-1"
            />
          </div>
        )}
        {field.type === 'multiselect' && (
          <div className={styles.field}>
            <SelectInput
              keyName={`multiselect_${field.dbField}_${i}`}
              options={field.options}
              handleChangeSelect={e => onChange(field.dbField, e)}
              selectedOption={field?.options?.filter(el => (field.value ? field.value.includes(el?.[field.keyValue]) : null))}
              customValue={field.keyValue}
              customLabel={field.keyLabel}
              label={field.label}
              multiselect={true}
              isClearable={false}
              globalClass="mb-1 pr-1"
            />
          </div>
        )}
        {field.type === 'number' && (
          <div className={styles.field}>
            <Input
              value={field.value}
              name={field.dbField}
              type="number"
              label={field.label}
              onChange={e => onChange(field.dbField, e.target.value)}
              globalClass="mb-1 pr-1"
            />
          </div>
        )}
        {field.type === 'date' && (
          <React.Fragment>
            {field.subType === 'date' && (
              <div className={styles.field}>
                <DatePickerComp
                  startDate={field?.dateValue ? new Date(field.dateValue) : null}
                  onChange={date => onChange(field.dbField, date || null)}
                  label={field.label}
                  globalClass="mb-1"
                />
              </div>
            )}
            {field.subType === 'month' && (
              <div className={styles.field}>
                <MonthPickerComp
                  startDate={field?.dateValue ? new Date(field.dateValue) : null}
                  onChange={date => onChange(field.dbField, date || null)}
                  label={field.label}
                  globalClass="mb-1"
                />
              </div>
            )}
            {field.subType === 'year' && (
              <div className={styles.field}>
                <YearPickerComp
                  name="rod"
                  startDate={field?.dateValue ? new Date(field.dateValue) : null}
                  onChange={date => onChange(field.dbField, date || null)}
                  label={label.categoryInputRod}
                />
              </div>
            )}
          </React.Fragment>
        )}
        {field.type === 'range' && (
          <div className={styles.range}>
            {field.subType === 'datapicker' && (
              <>
                <DatePickerComp
                  startDate={field.dateValue?.start ? new Date(field.dateValue.start) : null}
                  onChange={date => onChange(field.dbField, date || null, 'start')}
                  label={field.labelFrom}
                  globalClass="mb-1 mr-1"
                />
                <DatePickerComp
                  startDate={field.dateValue?.end ? new Date(field.dateValue.end) : null}
                  onChange={date => onChange(field.dbField, date || null, 'end')}
                  label={field.labelTo}
                  globalClass="mb-1 mr-1"
                />
              </>
            )}
            {field.subType === 'inputRange' && (
              <>
                <Input
                  value={field.dateValue?.start}
                  name={field.dbField}
                  type={field.inputType}
                  label={field.labelFrom}
                  onChange={e => onChange(field.dbField, e.target.value, 'start')}
                  globalClass="mb-1 pr-1"
                />
                <Input
                  value={field.dateValue?.end}
                  name={field.dbField}
                  type={field.inputType}
                  label={field.labelTo}
                  onChange={e => onChange(field.dbField, e.target.value, 'end')}
                  globalClass="mb-1 pr-1"
                />
              </>
            )}
          </div>
        )}
      </React.Fragment>
    )
  })
}
export default Fields
