import { useGetAllQuery, useGetOneQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { FormWrapper } from 'components/containers/containers'
import FormClients from 'components/forms/formClient'
import { queryGetAllCity } from 'query/queryCity'
import { queryGetAllClients, queryGetByIdClient, queryUpdateClient } from 'query/queryClient'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { setIsLoadingData } from 'redux/reducers/staticSlice'
import styles from './record.module.scss'
import { queryGetAllEntityTypes } from 'query/queryEntityType'
import { label } from 'constants/labels'
import { Loader } from 'components/loader'
import Table from 'components/table'
import { headingClientsRecord } from 'constants/tbHeading'
import FormHeading from './formHeading'
import Tabs from 'components/tabs'
import PreviewOfClient from './preview'
import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import { openModal } from 'redux/reducers/modalSlice'
import ClientSearch from '../search'
import TimeOfContactsModal from './timeOfContacts'
import BasicInfo from './basicInfo'
import MessageNoData from 'components/messageNoData'
import { queryGetAllEntityStatus } from 'query/queryEntityStatus'
import { queryGetAllActivityByClientId } from 'query/queryClientConectionTables'
import { queryGetAllUsers } from 'query/queryUser'
import { queryGetAllProducts } from 'query/queryProducts'
import { queryGetAllCurrency } from 'query/queryCurrency'
import { queryGetAllSubBusinessActivity } from 'query/querySubBusinessActivity'
import { CELEBRATION_TYPE, STATUS_CLIENT } from 'constants/staticOptions'
import HistoryTable from 'components/historyTable'
import { queryAddActivity } from 'query/queryActivity'
import { selectCurrentId } from 'redux/reducers/authSlice'

export const tabs = [
  {
    label: label.tabsLabelView,
    type: 'pregled'
  },
  {
    label: label.tabsLabelBasic,
    type: 'osnovni'
  },
  {
    label: label.tabsLabelActivity,
    type: 'aktivnosti'
  },
  {
    label: label.tabsLabelServices,
    type: 'usluge'
  },
  {
    label: label.tabsLabelBill,
    type: 'racuni'
  },
  {
    label: label.tabsLabelAvansBill,
    type: 'avansi'
  },
  {
    label: label.tabsLabelContract,
    type: 'ugovori'
  },
  {
    label: label.tabsLabelHistory,
    type: 'istorija'
  }
]

const CompanyRecord = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useParams()
  const userId = useSelector(selectCurrentId)
  const navigate = useNavigate()
  const [updateData] = useUpdateCreateOneMutation()
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [clients, setClients] = useState([])
  const [create] = useUpdateCreateOneMutation()
  const { data: client = {}, isLoading } = useGetOneQuery(queryGetByIdClient(id))
  const { data: clientActivityData = [] } = useGetAllQuery(queryGetAllActivityByClientId(id))
  const { poljoprivreda } = useGetAllQuery(queryGetAllBusinessActivity(), {
    selectFromResult: ({ data }) => {
      return {
        poljoprivreda: data?.find(e => e.naziv?.toLocaleLowerCase().includes('poljoprivreda')) || null
      }
    }
  })
  const { city } = useGetAllQuery(queryGetAllCity(), {
    selectFromResult: ({ data }) => {
      return {
        city: data?.find(e => e.id === client?.gradId) || {}
      }
    }
  })

  const { status } = useGetAllQuery(queryGetAllEntityStatus(), {
    selectFromResult: ({ data }) => {
      return {
        status: data?.find(el => el.naziv?.toLocaleLowerCase() === 'aktivan') || null
      }
    }
  })

  const { entityType, entityTypes } = useGetAllQuery(queryGetAllEntityTypes(), {
    selectFromResult: ({ data }) => {
      return {
        entityType: data?.find(e => e.id === client?.tipLicaId) || {},
        entityTypes: data || []
      }
    }
  })

  const onSubmit = async data => {
    dispatch(setIsLoadingData(true))
    await updateData(queryUpdateClient(data, id))
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = async data => {
    navigate(`/klijenti/karton/${data.id}`)
  }

  const onPlanerOpen = async () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <TimeOfContactsModal id={id} />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  const onTabChange = async tab => {
    setActiveTab(tab)
    await create(queryAddActivity(userId, 'visited', null, { tabName: tab.label, page: 'klijent', path: location.pathname, id: id }))
  }
  const renderTabContent = type => {
    switch (type) {
      case 'pregled':
        return <PreviewOfClient clientId={id} />
      case 'osnovni':
        return (
          <FormWrapper>
            <FormClients header={true} onSubmit={onSubmit} existingData={client} id={id} />
          </FormWrapper>
        )
      case 'aktivnosti':
        return <h5>U izradi - Aktivnosti </h5>
      case 'usluge':
        return <h5>U izradi usluge</h5>

      case 'racuni':
        return <h5>U izradi - Racuni</h5>
      case 'avansi':
        return <h5>U izradi - Avansi</h5>

      case 'ugovori':
        return <h5>U izradi - Ugovori</h5>
      case 'istorija':
        return (
          <HistoryTable
            id={id}
            collection="klijent"
            connectionTb={[
              { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
              { rtkQuery: queryGetAllProducts(), dbName: 'proizvodId' },
              { rtkQuery: queryGetAllClients(), dbName: 'klijentId' },
              { rtkQuery: queryGetAllCurrency(), dbName: 'valutaId' },
              { rtkQuery: queryGetAllCurrency(), dbName: 'jedinicaMereId' },
              { rtkQuery: queryGetAllBusinessActivity(), dbName: 'delatnostId' },
              { rtkQuery: queryGetAllSubBusinessActivity(), dbName: 'podDelatnostId' },
              { staticData: CELEBRATION_TYPE, dbName: 'tipProslave' },
              { staticData: STATUS_CLIENT, dbName: 'status' }
            ]}
            showTitle={false}
          />
        )

      default:
        return <h5>U izradi</h5>
    }
  }
  //   clientActivityData.find(el => el.naziv?.toLocaleLowerCase().includes('poljoprivreda'))
  if (isLoading) return <Loader />
  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.searchContainer}>
          {status?.id === client.statusId && (
            <>
              <MessageNoData message="Molimo vas unesite bar jedan rodjendan za klijenta!" stylesClass="warning" globalClass="mb-2" />
              {clientActivityData.find(el => el.delatnostId === poljoprivreda?.id) && !client.obradivaPovrsina && (
                <MessageNoData
                  message="Molimo vas unesite za klijenta koji se bavi poljoprivredom obradivu povrsinu!"
                  stylesClass="warning"
                  globalClass="mb-2"
                />
              )}
              {/* {!client.opisPoslovanja && (
                <MessageNoData message="Molimo vas unesite za klijenta opis poslovanja!" stylesClass="warning" globalClass="mb-2" />
              )} */}
            </>
          )}

          <ClientSearch setClients={setClients} />
          {!!clients.length && (
            <div className={styles.searchTable}>
              <Table
                heading={headingClientsRecord}
                data={clients}
                onRowClick={onRowClick}
                map={{
                  entityType: entityTypes.reduce((e, x) => {
                    return { ...e, [x.id]: x }
                  }, {})
                }}
              />
            </div>
          )}
        </div>
        <BasicInfo client={client} city={city} entityType={entityType} onPlanerOpen={onPlanerOpen} />
        <FormHeading client={client} />
      </div>

      <div className={styles.body}>
        <div className={styles.first}>
          <Tabs tabs={tabs} active={activeTab.type} onClick={tab => onTabChange(tab)} />
        </div>
        <div className={styles.second}>{renderTabContent(activeTab.type)}</div>
      </div>
    </div>
  )
}

export default CompanyRecord
