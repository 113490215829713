/** Form for  add 'Svetska berza Milano' */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import MultipleInputs from 'components/inputMultiple'
import { initialWorldStockExchangeMilano, multiFieldsWorldStockExchangeMilanoInitial } from 'constants/initialValues'
import { label } from 'constants/labels'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentTime, updateMapCurrencyAndMeasure } from 'utils'
import { validateWorldStockExchangeMilano } from '../helpers/validate'
import { queryGetAllStockExchange } from 'query/queryStockExchange'
import { useGetAllQuery } from 'api/dataApiSlice'
import toastService from 'services/toastService'

const FormAddMultipleWorldStockExchangeMilano = ({ existingData = [], onSubmit }) => {
  const isLoading = useSelector(state => state.static.isLoading)
  const [error, setError] = useState(false)
  const [milanoStock, setMilanoStockFound] = useState(false)
  const [data, setData] = useState({
    svetskaBerzaMilano: [{ ...initialWorldStockExchangeMilano, datumUnosa: getCurrentTime(new Date()) }]
  })
  const [dinamicMultiData, setDinamicMultiData] = useState(multiFieldsWorldStockExchangeMilanoInitial)
  const [multiError, setMultiError] = useState({})
  const requiredMultiMap = useSelector(state => state.static.multipleRequiredFields)
  const { data: stockExchange = [] } = useGetAllQuery(queryGetAllStockExchange())

  useEffect(() => {
    if (milanoStock && existingData.length) {
      const updatedData = existingData.map(item => ({
        ...item,
        berzaId: milanoStock.id,
        datumUnosa: getCurrentTime(new Date())
      }))
      setData({ svetskaBerzaMilano: cloneDeep(updatedData) })
      pullMapedMultiFields(cloneDeep(updatedData))
    }
  }, [existingData, milanoStock])

  useEffect(() => {
    const milanoStockFound = stockExchange.find(item => item.naziv.toLowerCase().includes('milano'))
    if (milanoStockFound) {
      setMilanoStockFound(milanoStockFound)
      const updatedData = [initialWorldStockExchangeMilano].map(item => ({
        ...item,
        berzaId: milanoStockFound.id,
        valutaId: milanoStockFound.valutaId,
        datumUnosa: getCurrentTime(new Date())
      }))
      setData({ svetskaBerzaMilano: cloneDeep(updatedData) })
    }
  }, [stockExchange])

  const pullMapedMultiFields = async data => {
    let mapedOptionsProducts = {}
    const mapedOptionsCurrency = {}
    const mapedOptionsMeasure = {}
    data.forEach(async (el, i) => {
      mapedOptionsCurrency[i] = el.currencyList
      mapedOptionsMeasure[i] = el.unitMeasureList
      mapedOptionsProducts = el.productsList
    })
    const cloneFields = cloneDeep(multiFieldsWorldStockExchangeMilanoInitial)
    const indx = cloneFields.findIndex(e => e.dbName === 'proizvodId')
    cloneFields[indx].options = mapedOptionsProducts
    const indx1 = cloneFields.findIndex(e => e.dbName === 'valutaId')
    cloneFields[indx1].mapOptions = mapedOptionsCurrency
    const indx2 = cloneFields.findIndex(e => e.dbName === 'jedinicaMereId')
    cloneFields[indx2].mapOptions = mapedOptionsMeasure
    setDinamicMultiData(cloneFields)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const reqDataArray = data.svetskaBerzaMilano.map(item => ({
      datumUnosa: item.datumUnosa,
      cenaMax: item.cenaMax,
      cenaMin: item.cenaMin,
      aktivan: item.aktivan,
      berzaId: item.berzaId,
      proizvodId: item.proizvodId,
      isMilano: true,
      jedinicaMereId: item.jedinicaMereId,
      valutaId: milanoStock.valutaId || item.valutaId,
      kategorijaId: item.kategorijaId,
      podrazumevanaJedinicaMereId: item.podrazumevanaJedinicaMereId,
      podrazumevanValutaId: item.podrazumevanValutaId,
      podrazumevanaCenaJedinicaMereId: item.podrazumevanaCenaJedinicaMereId,
      id: null
    }))

    const isValid = reqDataArray.every(reqData => validateWorldStockExchangeMilano(reqData))
    if (isValid) {
      onSubmit(reqDataArray)
    } else {
      setError(true)
    }
  }

  const checkIsMultiValid = (name, data) => {
    const isValidArr = []
    if (requiredMultiMap[name]) {
      data.forEach(e => {
        Object.keys(requiredMultiMap[name]).forEach(key => {
          if (!e[key]) isValidArr.push(false)
        })
      })
    }
    if (!isValidArr.every(Boolean)) {
      setMultiError(prev => ({ ...prev, [name]: true }))
      return
    } else {
      setMultiError(prev => ({ ...prev, [name]: false }))
    }
    return isValidArr.every(Boolean)
  }

  const onAddField = name => {
    const temp = cloneDeep(data.svetskaBerzaMilano)
    if (!checkIsMultiValid(name, temp)) return
    temp.push({
      ...initialWorldStockExchangeMilano,
      berzaId: milanoStock.id,
      valutaId: milanoStock.valutaId,
      datumUnosa: getCurrentTime(new Date())
    })
    setData(prev => ({ ...prev, [name]: temp }))
  }

  const onMultipleChange = async (e, index, field, name, fields) => {
    const { type, dbName, selectValue } = field
    setDinamicMultiData(fields)
    const temp = cloneDeep(data.svetskaBerzaMilano)
    if (type === 'input') {
      const { name, value } = e.target
      temp[index][name] = value
    } else if (type === 'select') {
      // if (dbName === 'berzaId') temp[index].valutaId = e.valutaId
      if (dbName === 'proizvodId') {
        temp[index].jedinicaMereId = e.jedinicaMereId
        temp[index].valutaId = data[name][index]?.valutaId
        temp[index].podrazumevanaJedinicaMereId = e.jedinicaMereId
        temp[index].podrazumevanValutaId = e.valutaId
        temp[index].podrazumevanaCenaJedinicaMereId = e.cenaJedinicaMereId
        temp[index].kategorijaId = e.kategorijaId
      }
      if (selectValue) temp[index][dbName] = e[selectValue]
      else temp[index][dbName] = e.id
    } else if (type === 'multiselect') {
      if (Array.isArray(e)) {
        const selectedItems = e.map(item => item.id)
        temp[index][dbName] = selectedItems
      }
    } else if (type === 'datapicker') {
      temp[index][dbName] = e ? getCurrentTime(e) : null
    }

    setData(prev => ({ ...prev, [name]: temp }))
  }

  const onKeyPress = e => {
    if (e && e.target && e.target.name === 'jedinicaMereId' && e.key === 'Enter') {
      onAddField('svetskaBerzaMilano')
    }
  }
  const onRemoveMultiselect = (index, item, name) => {
    const temp = cloneDeep(data[name])
    if (temp.length === 1) return toastService.show('warn', 'Morate imati barem jedan unos')
    temp.splice(index, 1)
    setData(prev => ({ ...prev, [name]: temp }))
    const updateMap = updateMapCurrencyAndMeasure(index, dinamicMultiData)
    setDinamicMultiData(updateMap)
  }

  return (
    <div>
      <div>
        <MultipleInputs
          stylesClass="multiThreeFields"
          name="svetskaBerzaMilano"
          fields={dinamicMultiData}
          data={data.svetskaBerzaMilano}
          onAdd={onAddField}
          onRemove={onRemoveMultiselect}
          onChange={onMultipleChange}
          label={label.multiFieldLabelImport}
          labelForm={label.wroldStockExchangeMilano}
          errorGroup={error && 'Postoje obavezna polja'}
          errorRow={multiError}
          onKeyPress={onKeyPress}
        />
      </div>

      <div className="ta-r mt-2">
        <Button
          label={label.save}
          iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
          onClick={handleSubmit}
          loading={isLoading}
          stylesClass="btnWarning"
        />
      </div>
    </div>
  )
}

export default FormAddMultipleWorldStockExchangeMilano
