export const dateFormat = 'DD.MM.YYYY'
export const timeFormat = 'DD.MM.YYYY HH:mm'
export const yearFormat = 'YYYY'
export const monthFormat = 'MM'
export const monthDay = 'DD MMM'
export const calendarPreviewFormat = 'dd.MM.yyyy'
export const calendarNoYearFormat = 'dd MMM'
export const calendarPreviewTimeFormat = 'h:mm aa'
export const calendarMonthPreviewFormat = 'yyyy-MM'
export const calendarMonthFormat = 'yyyy'
export const dateDBFormat = 'YYYY-MM-DD'
