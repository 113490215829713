import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'components/image'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/reducers/modalSlice'
import styles from './previewImageAnnoucment.module.scss'

const SingleImg = ({ src, i, imgs }) => {
  const [active, setActive] = useState(src)
  const [index, setIndex] = useState(i)
  const length = imgs.length

  const changeImages = type => {
    if (type === 'prev') {
      setActive(imgs[index - 1]?.link)
      setIndex(index - 1)
    } else {
      setActive(imgs[index + 1]?.link)
      setIndex(index + 1)
    }
  }

  return (
    <div className={styles.singleImg}>
      {length > 1 && index !== 0 && (
        <div className={styles.prev} onClick={() => changeImages('prev')}>
          <FontAwesomeIcon icon="fa-chevron-left" />
        </div>
      )}
      <Image src={active} />
      {length > 1 && index + 1 !== length && (
        <div className={styles.next} onClick={() => changeImages('next')}>
          <FontAwesomeIcon icon="fa-chevron-right" />
        </div>
      )}
    </div>
  )
}

export const ImagesModal = ({ src, imgs = [], modalPosition }) => {
  const dispatch = useDispatch()
  const showImage = (src, index) => {
    dispatch(
      openModal({
        arrItem: { content: <SingleImg src={src} i={index} imgs={imgs} />, order: modalPosition || 1, size: 'vh-100' },
        active: modalPosition || 1
      })
    )
  }
  return (
    <div className={styles.imagesModal}>
      {imgs.length > 1 ? (
        <div className={styles.grid}>
          {imgs.map((e, i) => (
            <div key={`imgList_${i}`} className={styles.imgBox}>
              <Image src={e.link} onClick={() => showImage(e.link, i)} />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.oneImage}>
          <Image src={src} />
        </div>
      )}
    </div>
  )
}
