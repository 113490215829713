/** Form for  add/edit/remove  'Luka uslovi isporuke' */
import Input from 'components/input'
import { initialPortDelivery } from 'constants/initialValues'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import { errPortDelivery as errorMsg } from './helpers/errorMsg'
import { validatePortDelivery as valdateForm } from './helpers/validate'
import SelectInput from 'components/selectInput'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllCurrency } from 'query/queryCurrency'
import { queryGetAllMeasureUnits } from 'query/queryMeasureUnit'
import FormBtnSave from './formHeader/formButton'

const FormPortDelivery = ({ existingData = {}, id, onSubmit, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialPortDelivery })
  const { data: currency = [] } = useGetAllQuery(queryGetAllCurrency())
  const { data: measureUnits = [] } = useGetAllQuery(queryGetAllMeasureUnits())

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      aktivan: data.aktivan,
      naziv: data.naziv,
      valutaId: data.valutaId,
      jedinicaMereId: data.jedinicaMereId
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.productSeaPortInput}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.placeholder}
          globalClass=""
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={currency}
          handleChangeSelect={e => setData(prev => ({ ...prev, valutaId: e.id }))}
          selectedOption={currency.find(el => el.id === data.valutaId)}
          customValue="id"
          customLabel="kod"
          label={label.currency}
          errorTxt={error && !data.valutaId && errorMsg('valuta')}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={measureUnits}
          handleChangeSelect={e => setData(prev => ({ ...prev, jedinicaMereId: e.id }))}
          selectedOption={measureUnits.find(el => el.id === data.jedinicaMereId)}
          customValue="id"
          customLabel="naziv"
          label={label.measureUnit}
          errorTxt={error && !data.jedinicaMereId && errorMsg('jedinicaMere')}
          disabled={disabled}
          isPreview={disabled}
        />
        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormPortDelivery
