import { useGetAllQuery, useLazyGetAllMapQuery } from 'api/dataApiSlice'
import CustomPagination from 'components/pagination'
import { dateFormat } from 'constants/date'
import { FIELD_TYPES, PAGE_SIZE } from 'constants/other'
import dayjs from 'dayjs'
import { queryGetPagedHistoryByType } from 'query/queryHistory'
import React, { useEffect, useState } from 'react'
import styles from './historyTable.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { collectionLabelsHistory, dbLabelsHistory, defaultHeadingTb } from './constants'
import { label } from 'constants/labels'
import MessageNoData from 'components/messageNoData'

const getValueByType = (field, value) => {
  let newValue = value
  switch (field?.type) {
    case 'date':
      newValue = dayjs(new Date(value)).format(field.format || dateFormat)
      break
    case 'boolean': {
      newValue = value === true || value === 1 ? 'Da' : 'Ne'
      break
    }
    default:
      newValue = value
      break
  }
  return newValue
}

const LinkValue = ({ link, id, value, isModal, onOpenModal, dependentCollection, action, isHistoryOne }) => {
  if (isModal && !isHistoryOne && action !== 'deleted')
    return (
      <span className={styles.newValue}>
        <b onClick={() => onOpenModal(id)}>{value}</b> <FontAwesomeIcon icon="fa-up-right-from-square" />
      </span>
    )
  if (!dependentCollection && action === 'deleted') return <b className={styles.newValue}> {value}.</b>
  return (
    <a href={`${link}${id}`} className={styles.newValue} target="_blank" rel="noreferrer">
      {' '}
      <b> {value}</b> <FontAwesomeIcon icon="fa-up-right-from-square" />
    </a>
  )
}

export const getFieldValue = (data, head, mapConnectingTb, link, isModal, onOpenModal, isHistoryOne) => {
  const { kolekcija, zavisnaKolekcija, akcija } = data

  const value = data[head.dbName]
  const oldValue = data?.prethodnaPromena || null
  const currentValue = data?.podaci || null

  const linkValue = currentValue?.[collectionLabelsHistory[kolekcija]?.dbName]

  let foreignKeyLinkValue = null
  let connectionValue = null

  const fKey = collectionLabelsHistory?.[kolekcija]?.foreignKey
  const fLabel = collectionLabelsHistory?.[kolekcija]?.foreignDbName
  const keyMap = collectionLabelsHistory?.[kolekcija]?.map

  if (zavisnaKolekcija) {
    if (mapConnectingTb?.[keyMap]) {
      connectionValue = mapConnectingTb[keyMap]?.[currentValue[keyMap]]?.[fLabel]
    } else {
      connectionValue = currentValue[collectionLabelsHistory[kolekcija]?.dbName]
    }

    if (mapConnectingTb?.[fKey] && currentValue?.[fKey]) {
      foreignKeyLinkValue = mapConnectingTb[fKey]?.[currentValue[fKey]]?.[fLabel]
    }
  }

  let newValue
  switch (head.type) {
    case 'korisnikId':
      newValue = mapConnectingTb[head.dbName] && mapConnectingTb[head.dbName][value] ? mapConnectingTb[head.dbName][value]?.ime : '-'
      break
    case 'podatakId':
      newValue = zavisnaKolekcija ? data.straniKljucId : data.podatakId
      break
    case FIELD_TYPES.text:
      newValue = value || '-'
      break
    case FIELD_TYPES.date:
      newValue = dayjs(value).format(head.format || dateFormat)
      break
    case FIELD_TYPES.history: {
      let temp = '-'
      if (value && akcija === 'update' && !Array.isArray(value)) {
        temp = Object.keys(value).map((key, i) => {
          let foreignValue = null
          let foreignValueOld = null
          const labels = dbLabelsHistory?.[kolekcija]
          // if value is foreignKey
          if (labels?.[key] && labels?.[key]?.map && value?.[key] !== 'object') {
            const foreignKey = labels[key].map
            const foreignDbName = labels[key].dbName
            const foreignData = mapConnectingTb?.[foreignKey]
            if (foreignData?.[value[key]]) foreignValue = foreignData[value[key]]?.[foreignDbName]
            if (foreignData?.[oldValue[key]]) foreignValueOld = foreignData[oldValue[key]]?.[foreignDbName]
          }

          const newValue = getValueByType(labels?.[key], foreignValue || value[key])
          const prevValue = getValueByType(labels?.[key], foreignValueOld || oldValue[key])

          if (newValue === prevValue) return null
          return (
            <div key={`${key}_${i}`} className={styles.changes}>
              {labels?.[key] && (
                <>
                  <FontAwesomeIcon icon="fa-pen-to-square" />
                  <span>
                    {labels[key]?.label} je promenjen{labels[key]?.suffix || ''},<span> nova vrednost je </span>
                    <b className={styles.newValue}>{!newValue ? 'PRAZNO POLJE' : newValue}</b>
                  </span>
                  <>
                    <small> a prethodna vrednost </small>
                    {prevValue ? (
                      <>
                        je bila <b className={styles.oldValue}>{prevValue}</b>
                      </>
                    ) : (
                      <b>nije postojala</b>
                    )}
                  </>
                  .
                  {(link || isModal) && (
                    <LinkValue
                      link={link}
                      id={zavisnaKolekcija ? data.straniKljucId : data.podatakId}
                      value={zavisnaKolekcija ? foreignKeyLinkValue : linkValue}
                      isModal={isModal}
                      onOpenModal={onOpenModal}
                      dependentCollection={zavisnaKolekcija}
                      action={akcija}
                      isHistoryOne={isHistoryOne}
                    />
                  )}
                </>
              )}
            </div>
          )
        })
      } else if (akcija === 'new' && currentValue) {
        temp = (
          <div className={styles.created}>
            <FontAwesomeIcon icon="fa-square-check" /> Kreiran{collectionLabelsHistory[kolekcija]?.suffix || ''}{' '}
            {collectionLabelsHistory[kolekcija]?.message || ' -'}{' '}
            {link || isModal ? (
              <b>
                {zavisnaKolekcija ? (
                  <b>
                    {connectionValue} -{' '}
                    <LinkValue
                      link={link}
                      id={data.straniKljucId}
                      value={foreignKeyLinkValue}
                      isModal={isModal}
                      onOpenModal={onOpenModal}
                      dependentCollection={zavisnaKolekcija}
                      action={akcija}
                      isHistoryOne={isHistoryOne}
                    />
                  </b>
                ) : (
                  <LinkValue
                    link={link}
                    id={data.podatakId}
                    value={linkValue}
                    isModal={isModal}
                    onOpenModal={onOpenModal}
                    dependentCollection={zavisnaKolekcija}
                    action={akcija}
                    isHistoryOne={isHistoryOne}
                  />
                )}
              </b>
            ) : (
              zavisnaKolekcija && <b>{connectionValue}</b>
            )}
          </div>
        )
      } else if (akcija === 'deleted' && currentValue) {
        temp = (
          <div className={styles.deleted}>
            <FontAwesomeIcon icon="fa-trash-can" />
            Izbrisan{collectionLabelsHistory[kolekcija]?.suffix || ''} {collectionLabelsHistory[kolekcija].message}{' '}
            {link || isModal ? (
              <b>
                {zavisnaKolekcija ? (
                  <b>
                    {connectionValue} -{' '}
                    <LinkValue
                      link={link}
                      id={data.straniKljucId}
                      value={foreignKeyLinkValue}
                      isModal={isModal}
                      onOpenModal={onOpenModal}
                      dependentCollection={zavisnaKolekcija}
                      action={akcija}
                      isHistoryOne={isHistoryOne}
                    />
                  </b>
                ) : (
                  <LinkValue
                    link={link}
                    id={data.podatakId}
                    value={linkValue}
                    isModal={isModal}
                    onOpenModal={onOpenModal}
                    dependentCollection={zavisnaKolekcija}
                    action={akcija}
                    isHistoryOne={isHistoryOne}
                  />
                )}
              </b>
            ) : (
              zavisnaKolekcija && <b>{connectionValue}</b>
            )}
          </div>
        )
      }

      newValue = temp || '-'
      break
    }

    default:
      newValue = value
      break
  }
  return newValue
}
const getMap = (data = [], customId) => {
  const map = {}

  data.forEach(e => {
    map[e[customId] || e.id] = e
  })
  return map
}
const HistoryTable = ({ collection, connectionTb = [], link, id, type, showTitle = true, isModal = false, onOpenModal }) => {
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [mapedData, setMapedData] = useState({})
  const { data: historyData = {} } = useGetAllQuery(queryGetPagedHistoryByType(pageNumber, pageSize, collection, id, type), {
    refetchOnMountOrArgChange: true
  })
  const [getAllMap] = useLazyGetAllMapQuery()

  useEffect(() => {
    const getOptions = async () => {
      const map = {}
      for (const el of connectionTb) {
        if (el.rtkQuery) {
          const res = await getAllMap(el.rtkQuery).unwrap()
          map[el.dbName] = res.entities
        }
        if (el.staticData) map[el.dbName] = getMap(el.staticData, el.customId)
      }
      setMapedData(map)
    }
    getOptions()
  }, [connectionTb])

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }
  const getHeaders = () => {
    return (
      <>
        <tr>
          {defaultHeadingTb.map(el => (
            <th key={`${collection}_${el.dbName}`} className={`${styles[el.dbName] ? styles[el.dbName] : ''}`}>
              {el.label}
            </th>
          ))}
        </tr>
      </>
    )
  }

  return (
    <div className={styles.wrapper}>
      {showTitle && <h2>{label.historyChange}</h2>}
      <table>
        <thead>{getHeaders()}</thead>
        <tbody>
          {historyData?.rows?.length ? (
            historyData?.rows.map((e, i) => (
              <tr key={`tb_${i}`} className={`${styles[e.dbName] ? styles[e.dbName] : ''}`}>
                {defaultHeadingTb.map(head => (
                  <td>{getFieldValue(e, head, mapedData, link, isModal, onOpenModal, id)}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <MessageNoData message={label.messageNoData} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!!historyData?.rows?.length && (
        <CustomPagination pageSize={pageSize} page={pageNumber} onChange={onPaginationChange} total={historyData.total} />
      )}
    </div>
  )
}

export default HistoryTable
