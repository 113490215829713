/** Queries for 'Carinske robe uvoz' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialCustomGoodsImportAndExport } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedCustomGoodsImport = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'carinskeRobeUvoz',
      data: {
        pageNumber,
        pageSize,
        options: { ...options, distinct: true, order: [['kod', 'ASC']] }
      }
    },
    providesTags: {
      type: tag.customGoodsImport
    }
  }
}

export const queryGetAllCustomGoodsImport = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'carinskeRobeUvoz',
      data: {
        options: { ...options, distinct: true, order: [['kod', 'ASC']] }
      }
    },
    providesTags: {
      type: tag.customGoodsImport
    }
  }
}
/**
 * @param {initialCustomGoodsImportAndExport} data
 */
export const queryAddCustomGoodsImport = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'carinskeRobeUvoz',
      data: { values: data }
    },
    invalidatesTags: [tag.customGoodsImport],
    successMsg: label.successMsgImport + ' ' + label.customGoodsImport
  }
}
