import Tabs from 'components/tabs'
import React, { useState } from 'react'
import styles from './record.module.scss'
import { TwoColumns } from 'components/containers/containers'
import PreviewRemark from './remark'
import PreviewContacts from './contacts'
import Announcement from './announcment'
import PreviewCelebration from './celebrations'
import { label } from 'constants/labels'
import { openModal } from 'redux/reducers/modalSlice'
import { useDispatch, useSelector } from 'react-redux'
import ModalAnnouncement from './announcment/modal'
import { setUploadImages } from 'redux/reducers/staticSlice'
import { useLocation, useParams } from 'react-router-dom'
import { useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { queryAddActivity } from 'query/queryActivity'
import { selectCurrentId } from 'redux/reducers/authSlice'

export const tabs = [
  {
    label: label.tabsLabelView,
    type: 'pregled'
  },
  {
    label: label.tabsLabelCelebration,
    type: 'proslave'
  },
  {
    label: label.tabsLabelAdds,
    type: 'oglasi'
  },
  {
    label: label.tabsLabelAddAdd,
    type: 'dodajOglas',
    clickEvent: true
  }
]

const previewTypes = type => {
  switch (type) {
    case 'pregled':
      return (
        <TwoColumns>
          <PreviewContacts />
          <PreviewRemark />
        </TwoColumns>
      )

    case 'proslave':
      return <PreviewCelebration />

    case 'oglasi':
      return <Announcement />
    default:
      break
  }
}
const PreviewOfClient = ({ clientId }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useParams()
  const userId = useSelector(selectCurrentId)
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [create] = useUpdateCreateOneMutation()
  const openModalAnnouncment = () => {
    dispatch(setUploadImages([]))
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div className={styles.announcmentModal}>
              <ModalAnnouncement clientId={clientId} />
            </div>
          ),
          order: 1,
          size: 'w-100'
        },
        active: 1
      })
    )
  }

  const onTabChange = async tab => {
    await create(queryAddActivity(userId, 'visited', null, { tabName: tab.label, page: 'klijent', path: location.pathname, id: id }))
    if (tab?.clickEvent) {
      if (tab.type === 'dodajOglas') openModalAnnouncment()
    } else {
      setActiveTab(tab)
    }
  }

  return (
    <div className={styles.subTabs}>
      <Tabs tabs={tabs} active={activeTab.type} onClick={tab => onTabChange(tab)} stylesClass="horizontalTabs" />
      <div className={styles.subTabsBody}>{previewTypes(activeTab.type)}</div>
    </div>
  )
}

export default PreviewOfClient
