export const label = {
  sifarnici: 'Šifarnici',
  dashboard: 'Početni ekran',
  reports: 'Izveštaji',
  preview: 'Pregled',
  administracija: 'Administracija',
  activity: 'Aktivnosti',
  organization: 'Organizacija',
  placeholder: 'Unos...',
  clients: 'Klijenti',
  register: 'Registracije',
  client: 'Klijent',
  entitiesType: 'Tipovi lica',
  institutions: 'Institucije',
  seaPortAndStockExchange: 'Luke i berze',
  others: 'Ostali',
  economyBranch: 'Privredne grane',
  subGroup: 'Kategorija',
  subGroupSeaport: 'Kategorija luka',
  subGroupBosnia: 'Kategorija Bosna',
  subGroupFutures: 'Kategorija futures',
  products: 'Proizvod',
  article: 'Artikal',
  accessRights: 'Prava pristupa',
  role: 'Role',
  korisnici: 'Zaposleni',
  korisnik: 'Zaposleni',
  packages: 'Paketi',
  banners: 'Baneri',
  services: 'Usluge',
  servicesCategory: 'Kategorija usluge',
  workpeople: 'Zaposleni',
  businessActivity: 'Delatnost',
  subBusinessActiviti: 'Pod Delatnost',
  bank: 'Banka',
  measureUnit: 'Jedinica mere',
  measureUnitRatio: 'Odnos jedinica mera',
  fromMeasureUnit: 'Iz jedinice mere',
  intoMeasureUnit: 'U jedinicu mere',
  measureUnits: 'Jedinice mere',
  entityStatus: 'Status lica',
  township: 'Opština',
  roles: 'Uloge',
  ipAddress: 'IP adrese klijenata',
  seaPort: 'Luka',
  worldSeaPort: 'Svetske luke',
  stockExchange: 'Berza',
  wroldStockExchange: 'Svetske berze',
  wroldStockExchangeMilano: 'Svetska berza Milano',
  twoWeeksWroldStockExchange: 'Dvonedeljni osvrt na Svetske berze',
  currency: 'Valuta',
  seaPortCurrency: 'Valuta placanja',
  stockExchangeCurrency: 'Valuta placanja',
  stockExchangeBosniaCurrency: 'Valuta placanja',
  stockExchangeMilanoCurrency: 'Valuta placanja',
  currencyPayment: 'Valuta placanja',
  defaultCurrency: 'Podrazumevana valuta',
  currencyList: 'Kursna lista',
  priceMovementBih: 'Kretanje cena BiH',
  priceMovementSrb: 'Kretanje cena SRB',
  customGoods: 'Carinske robe',
  customGoodsImport: 'Carinska roba uvoz',
  customGoodsExport: 'Carinska roba izvoz',
  customGoodsImportReport: 'Carinska roba uvoz',
  customGoodsExportReport: 'Carinska roba izvoz',
  pdf: 'Pdf',
  buyers: 'Kupci',
  sellers: 'Prodavci',
  buyersAgriculture: 'Poljoprivreda-kupci',
  sellersAgriculture: 'Poljoprivreda-prodavci',
  buyersNutrition: 'Prehrana-kupci',
  sellersNutrition: 'Prehrana-prodavci',
  timeOfContacts: 'Unos datuma kontakta',
  salesSpecialist: 'Komercijalista',
  workField: 'Oblast',
  history: 'Istorija',
  typeOfReport: 'Tip izveštaja',
  link: 'Link',
  defaultMeasureUnit: 'Podrazumevana JM za kolicinu',
  relationshipMeasureUnit: 'Odnos',
  ratio: 'Odnos',
  registerPreview: 'Pregled svih registracija',
  date: 'Datum',
  dateFrom: 'Datum od',
  dateTo: 'Datum do',
  amountFrom: 'Kolicina od',
  amountTo: 'Kolicina do',
  type: 'Tip',

  /* Forme, input polja i greske */

  createReport: 'Napravi izveštaj',
  title: 'Naslov',
  listOfReports: 'Lista izveštaja',
  messageInput: 'Poruka',
  functionInput: 'Funkcija',
  isAddContactInput: 'Kontakt u oglasima',
  directorInput: 'Ime zastupnika',
  directorTelInput: 'Telefon zastupnika',

  periodInput: 'Period je obavezno polje',
  reaquiredFiled: 'Obavezno polje',
  dutyRate: 'Stopa carine',

  registrerDetails: 'Detalji registracije',

  month: 'Mesec',

  delivery: 'Isporuka',

  lastInsert: 'Poslednji unos',

  sentReport: 'Posalji izveštaj',
  listOfClients: 'Lista klijenata',

  netMass: 'Neto masa u KG',
  worthInEuro: 'Vrednost u Euru',

  markInput: 'Oznaka',
  markErr: 'Oznaka je obavezno polje',

  stockExchangeInput: 'Berza',
  stockExchangeErr: 'Berza je obavezno polje',
  stockExchangeSelectErr: 'Morate dodati berze',

  currencyErr: 'Valuta je obavezno polje.',
  currencySelectErr: 'Morate dodati valute',

  seaPortErr: 'Luka je obavezno polje.',
  seaPortSelectErr: 'Morate dodati luke',

  bankaErr: 'Banka je obavezno polje',
  contactErr: 'Bar jedan kontakt je obavezan',

  clientErr: 'Klijent je obavezno polje',
  clientSelectErr: 'Morate dodati klijente',

  bidDemand: 'Tražnja',
  bidSupply: 'Ponuda',
  bidDemandInput: 'Vrsta objave',
  bidDemandErr: 'Vrsta objave je obavezno polje.',

  BPGFarmInput: 'Broj poljoprivrednog gazdinstva',
  BPGFarmInputShort: 'BPG',
  PIBFarmInput: 'PIB/JMBG',
  PIBInput: 'PIB',
  JMBGInput: 'JMBG',
  PIBErr: 'PIB je obavezno polje .',
  PIBLengthErr: 'PIB mora imati 9 karaktera.',

  codeInput: 'Šifra',
  codeErr: 'Šifra je obavezno polje.',
  vrstaErr: 'Vrsta je obavezno polje',

  identificationNumberInput: 'Matični Broj',
  identificationNumberErr: 'Matični Broj je obavezno polje.',

  BPGInput: 'BPG',
  obveznikPDVa: 'Obveznik PDV-a?',

  companyNameInput: 'Ime firme',
  companyNameErr: 'Ime firme je obavezno polje.',
  companyFarmNameInput: 'Ime i prezime',
  companyFarmNameErr: 'Ime i prezime je obavezno polje.',

  typeEntityInput: 'Tip lica',
  typeEntityInput2: 'Odaberi tip lica',
  typeEntityErr: 'Tip lica je obavezno polje.',
  typeLegalEntitiesCodeErr: 'Unesite tip lica pod šifrom: ',

  adresaInput: 'Adresa',
  adresaErr: 'Adresa je obavezno polje.',

  ownedLend: 'Obradiva površina u hektarima',
  bussinesDesc: 'Opis poslovanja',
  bussinesDescErr: 'Opis poslovanja je obavezano polje',

  contactPersonInput: 'Kontakt osoba',
  contactPersonsInput: 'Kontakt osobe',
  contactPersonErr: 'Kontakt osoba je obavezno polje.',
  contactPersonSelectErr: 'Morate dodati kontakt osobu.',

  cities: 'Gradovi',
  cityInput: 'Grad',
  cityErr: 'Grad je obavezno polje.',
  citySelectErr: 'Morate dodati grad.',
  cityMilanoSelectErr: 'Morate dodati grad pod imenom Milano',

  regionInput: 'Region',
  regionErr: 'Region je obavezno polje.',
  regionSelectErr: 'Morate dodati region.',

  countryInput: 'Država',
  countryErr: 'Država je obavezno polje.',
  countrySelectErr: 'Morate dodati državu.',

  townshipInput: 'Opština',
  townshipErr: 'Opština je obavezno polje.',
  townshipSelectErr: 'Morate dodati opština.',

  bankAccountInput: 'Tekući račun',
  bankAccountForeignInput: 'Devizni račun',

  businessActivitiSelectErr: 'Morate dodati delatnost.',
  businessActivitiErr: 'Obavezna je delatnost.',

  businessActivityOneInput: 'Delatnost 1.',
  businessActivityOneErr: 'Obavezna je jedna delatnost.',

  businessActivityTwoInput: 'Delatnost 2.',
  businessActivityTwoErr: 'Delatnost 3.',

  subBusinessActivitiInput: 'Poddelatnost',
  subBusinessActivitiErr: 'Obavezna je poddelatnost.',
  subBusinessActivitiSelectErr: 'Morate dodati poddelatnosti.',

  areaInput: 'Oblast',
  areaErr: 'Oblast je obavezno polje.',

  entitySort: 'Vrsta',
  announcementInput: 'Oglas',

  addBlog: 'Dodaj aktuelnost',
  blogInput: 'Aktuelnost',
  blogTitle: 'Naslov',
  blogSubtitle: 'Podnaslov',
  blogText: 'Tekst',
  blogTitleErr: 'Naslov je obavezno polje.',
  blogSubtitleErr: 'Podnaslov je obavezno polje.',
  blogTextErr: 'Tekst je obavezno polje.',

  descriptionInput: 'Opis',
  descriptionDitailInput: 'Detaljan opis',
  descriptionErr: 'Opis je obavezno polje.',
  shortDescriptionInput: 'Kratak opis',
  shortDescriptionErr: 'Kratak opis je obavezno polje',

  priceInput: 'Cena',
  priceErr: 'Cena je obavezno polje.',

  priceMinInputReali: 'MIN cena ralizacije',
  priceMinInput: 'MIN cena',
  priceMinErr: 'MAX cena je obavezno polje.',

  priceMaxInput: 'MAX cena',
  priceMaxInputReali: 'Max cena ralizacije',
  priceMaxErr: 'MIN cena je obavezno polje.',

  priceDatePeriodInput: 'Period važenja cena',
  priceDatePeriodErr: 'Period važenja cena je obavezno polje',

  priceDatePeriodStart: 'Period važenja cena pocetak',
  priceDatePeriodEnd: 'Period važenja cena kraj',

  amountEnteredInput: 'Uneta količina',
  amountEnteredErr: 'Uneta količina je obavezno polje.',

  amountInput: 'Količina',
  amountInputReali: 'Količina realizovana',
  amountErr: 'Količina je obavezno polje.',

  statusInput: 'Status',
  statusErr: 'Status je obavezno polje.',

  typeOfContactInput: 'Vrsta kontakta',

  kod: 'Kod',
  kodInput: 'Kod valute',
  kodErr: 'Kod valute je obavezno polje.',
  simbolInput: 'Simbol',
  simbolErr: 'Simbol je obavezno polje.',
  serviceInput: 'Usluga',
  serviceInputErr: 'Morate izabrati uslugu',
  serviceCategoryInput: 'Kategorija Usluga',
  serviceCategoryInputErr: 'Morate izabrati kategoriju usluge',
  serviceParentCategoryInput: 'Da li je kategorija glavna ili pripada drugoj kategoriji',
  serviceParentCategory: 'Pripada kategoriji',
  serviceParentCategoryInputErr: 'Morate izabrati parent kategoriju usluge',

  phoneInput: 'Kontakt telefon',

  companyPhoneInput: 'Kontakt telefon firme',
  companyPhoneErr: 'Telefon i pozivni broj su obavezni.',
  companyPhoneErrLenth: 'Telefon mora imati bar 10 brojeva',

  supplierPhoneInput: 'Telefon zastupnika',
  supplierNameInput: 'Ime zastupnika',
  supplierNameErr: 'Telefon je obavezno polje.',

  zipCodeInput: 'Poštanski broj',
  zipCodeErr: 'Poštanski broj je obavezno polje.',

  webInput: 'Web stranica',

  term: 'Pojam iz napomene',
  tel: 'Telefon',
  person: 'Kontakt osoba',

  emailInput: 'Email',
  emailReq: 'Email je obavezan.',
  emailErr: 'Email nije validan.',
  emailPlaceholder: 'Email...',

  dateOfContactErr: 'Datum kontakta je obavezan.',
  dateOfEnteringCompany: 'Datum unosa firme',
  dateOfEntering: 'Datum unosa',

  dateContactInput: 'Datum kontakta',
  dateInput: 'Datum',
  dateErr: 'Datum je obavezno polje',

  inputFullName: 'Ime i prezime/Slava',
  inputTypeSelabration: 'Tip',

  dateOfImportInput: 'Datum unosa',
  dateOfTerm: 'Oglas važi do',
  dateRealizations: 'Datum realizacije',
  purchaseProductsInput: 'Proizvod koji najčešće kupuje',
  saleProductsInput: 'Proizvod koju najčešće prodaje',

  contactReasonInput: 'Razlog kontakta',
  contactReasonNextInput: 'Sledeći datum kontakta',

  passwordInput: 'Lozinka',
  passwordPlacehodler: 'Password...',
  passwordConfirm: 'Potvrdi lozinku:',
  passwordErr: 'Lozinka je obavezno polje!',
  passwordNewInput: 'Nova lozinka:',
  passwordNewErr: 'Nova lozinka je obavezno polje!',
  passwordConfirmErr: 'Morate potvrditi novu lozinku!',
  passwordConfirmEqualErr: 'Lozinke nisu identicne!',

  forgotPasswordInput: 'Nova lozinka',
  forgotPasswordTitle: 'Unesite novu lozinku za korisnika',
  forgotPassword: 'Promeni šifru',
  forgotPasswordPhoneErrLenth: 'Mobilni mora imati bar 10 brojeva',
  forgotSmsCode: 'Sms kod',
  forgotSmsCodeErr: 'Kod je obavezan',
  forgotSmsCodeErrLength: 'Morate uneti sve cifre koda',

  usernameInput: 'Korisnicko ime',

  noteInput: 'Napomena',
  noteInputAdd: 'Dodaj napomenu',
  noteInputEdit: 'Izmeni napomenu',

  ulogaInput: 'Uloga',
  usernameErr: 'Korisnicko ime je obavezno polje.',
  ulogaErr: 'Uloga je obavezno polje.',

  ponoviLozinkuInput: 'Ponovi lozinku',
  ponoviLozinkuErr: 'Ponovljena lozinka je obavezno polje.',
  ponoviLozinkuRazlicitErr: 'Lozinka i ponovljena lozinka nisu iste.',

  firstNameInput: 'Ime',
  firstNameErr: 'Ime je obavezno polje.',

  lastNameInput: 'Prezime',
  lastNameErr: 'Prezime je obavezno polje.',

  sortInput: 'Sort',
  sortInputErr: 'Sort je obavezno polje',

  phonePrefixInput: 'Pozivni broj',
  phonePrefixInputErr: 'Pozivni broj je obavezno polje',

  clientActivityErr: 'Minimum jedna delatnost i podelatnost obavezna',
  clientContactsErr: 'Minimum jedna kontakt obavezan',
  clientContactsTimeErr: 'Obavezan sledeci datum kontakta',
  clientProductBuyErr: 'Obavezan je bar jedan proizvod koju kupuje',
  clientProductSellErr: 'Obavezan je bar jedan proizvod koju prodaje',
  bossErr: 'Ime direktora je obavezno',
  clientUserErr: 'Komercijalista je obavezan',

  mobileInput: 'Mobilni',
  mobileErr: 'Broj telefona je obavezno polje',
  mobileValidErr: 'Broj telefona nije validan',

  packageInput: 'Paket',
  durationTypeInput: 'Tip trajanja',
  durationTypeErr: 'Tip trajanja je obavezno polje.',
  durationInput: 'Trajanje',
  durationInputErr: 'Trajanje je obavezno polje.',

  titleAdd: 'Naslov oglasa',
  nameInput: 'Naziv',
  namePlaceholder: 'Unesi naziv...',
  nameInputErr: 'Naziv je obavezno polje',
  nameClientLegalInputErr: 'Ime firme je obavezno polje',

  measureUnitRatioErr: 'Odnos je obavezno polje...',
  fromMeasureUnitRatioErr: 'Iz jedinice mere  je obavezno polje',
  intoMeasureUnitRatioErr: 'U jedinicu mere je obavezno polje',

  activeInput: 'Aktivan',
  activeInputErr: 'Aktivan je obavezno polje.',

  productErr: 'Proizvod je obavezno polje',
  productSelectErr: 'Morate dodati proizvod.',

  bosnaValutaPlacanja: 'Bosna valuta plaćanja',
  bosnaValutaPlacanjaErr: 'Bosna valuta plaćanja je obavezno polje',

  productPort: 'Proizvod-luka',
  productBosniaInput: 'Proizvod-Bosna',
  productBosniaErr: 'Proizvod Bosna je obavezno polje',
  productBosniaSelectErr: 'Morate dodati Bosna proizvod.',

  productSeaPortInput: 'Luka-uslovi utovara',
  productSeaPortErr: 'Proizvod Luka je obavezno polje',
  productSeaPorSelectErr: 'Morate dodati Luka proizvod.',
  productSeaPortCurrencyErr: 'Luka valuta placanja je obavezno polje',

  productFuturesInput: 'Proizvod-Futures',
  futuresValutaPlacanja: 'Futures valuta plaćanja',
  futuresValutaPlacanjaErr: 'Futures valuta plaćanja je obavezno polje',

  productMilanoInput: 'Proizvod-Milano',
  milanoValutaPlacanja: 'Valuta plaćanja',
  milanoValutaPlacanjaErr: 'Valuta plaćanja je obavezno polje',

  lukaValutaPlacanja: 'Luka valuta plaćanja',
  lukaValutaPlacanjaErr: 'Luka valuta plaćanja je obavezno polje',

  economyBranches: 'Privredne grane',
  economyBranchInput: 'Privredna grana',
  economyBranchErr: 'Privredna grana je obavezna',
  economyBranchSelectErr: 'Morate dodati privrednu granu',

  categoryInput: 'Kategorija',
  categoryErr: 'Kategorija je obavezna',
  categorySelectErr: 'Morate dodati kategoriju',

  categoryInputRod: 'Rod',

  productsDescInput: 'Opis proizvoda',
  productDescSelectErr: 'Morate dodati proizvod',
  productDesErr: 'Opis proizvoda je obavezno polje',

  descInputPackage: 'Opis paketa',

  measureUnitsInput: 'Jedinica mere',
  measureUnitsValute: 'Podrazumevana JM za cenu',
  measureUnitsSelectErr: 'Morate dodati jedinicu mere',
  measureUnitsInputErr: 'Jedinica mere je obavezna',
  measureCatInputErr: 'Kategorija je obavezna',
  dateDurationInputErr: 'Datum važenja je obavezno polje',
  amountNum: 'Količina objava',
  businessPhone: 'Poslovni broj',
  businessPhoneErr: 'Poslovni broj je obavezan.',
  businessPhoneValidErr: 'Poslovni broj nije validan.',

  birthYear: 'Godina rodjenja',
  birthYearErr: 'Godina rodjenja je obavezna.',

  positionInCompany: 'Pozicija u firmi',
  positionInCompanyErr: 'Pozicija u firmi je obavezna',

  workingInCompanyStartDate: 'Datum pocetka rada u firmi',
  workingInCompanyStartDateErr: 'Datum pocetka rada u firmi je obavezan',

  maticniBroj: 'Maticni broj',
  maticniBrojErr: 'Maticni broj je obavezan',

  nextStatId: 'Sledeci status Id',
  servicesIsValid: 'Vaze usluge',
  nextStatus: 'Sledeci status',

  insertClientInSystem: 'Unesite korisnika u sistem',
  rejectRegApplication: 'Odbaci registraciju',

  msgAcceptRegistration: 'Da li zelite da prihvatite registraciju?',
  msgRejectRegistration: 'Da li zelite da odbijete registraciju?',

  /* Forme, heading */
  dodajValutu: 'Dodaj valutu',
  dodajBanku: 'Dodaj banku:',
  dodajDelatnost: 'Dodaj delatnost',
  subBusinessActivity: 'Poddelatnost',
  dodajTipLica: 'Dodaj tip lica:',
  dodajUsera: 'Dodaj korisnika:',
  addEconomyBranch: 'Dodaj privrednu granu:',
  addCategory: 'Dodaj kategoriju:',
  addCategorySeaport: 'Dodaj kategoriju Luka:',
  addCategoryBosnia: 'Dodaj kategoriju Bosna:',
  addCategoryFutures: 'Dodaj kategoriju Futures:',
  addMeasureUnit: 'Dodaj jedinicu mere',
  addBank: 'Dodaj banku:',
  addBusinessActivity: 'Dodaj delatnost',
  addSubBusinessActivity: 'Dodaj poddelatnost',
  addEntitesType: 'Dodaj tip lica',
  addUsera: 'Dodaj korisnika',
  addEntityStatus: 'Dodaj status lica',
  addCountry: 'Dodaj državu',
  addRegion: 'Dodaj region',
  addTownship: 'Dodaj opštinu',
  addCity: 'Dodaj grad',
  addService: 'Dodaj uslugu',
  addStatus: 'Dodaj status',
  addServiceCategory: 'Dodaj kategoriju uslugu',
  addProductDesc: 'Dodaj opis proizvoda',
  addPackage: 'Dodaj paket',
  addTypeOfContact: 'Dodaj vrstu kontakta',
  addAnnouncement: 'Dodaj objavu',
  addProduct: 'Dodaj proizvod',
  addContactPerson: 'Dodaj kontakt osobu',
  addWorkField: 'Dodaj oblast',
  addSeaPort: 'Dodaj luku',
  addStockExchange: 'Dodaj berzu',
  addCurrency: 'Dodaj valutu',
  addWorldSeaPort: 'Dodaj svetsku luku',
  addWroldStockExchange: 'Dodaj svetsku berzu',
  addWroldStockExchangeMilano: 'Dodaj svetsku berzu Milano',
  addPriceMovementBih: 'Dodaj kretanje cena za BiH',
  addClient: 'Dodaj novog klijenta',
  recordClient: 'Karton klijenta',
  addPositionInCompany: 'Dodaj poziciju u firmi:',
  addPortProduct: 'Dodaj proizvod-luka:',
  addBosniaProduct: 'Dodaj Bosna proizvod:',
  addSeaPortProduct: 'Dodaj luku-uslovi utovara:',
  addFuturesProduct: 'Dodaj Futures proizvod:',
  addMilanoProduct: 'Dodaj Milano proizvod:',
  addDelivery: 'Dodaj isporuku:',
  /* buttons */

  save: 'Sačuvaj',
  importFile: 'Uvezi',
  submit: 'Submit',
  add: 'Dodaj',
  edit: 'Edit',
  delete: 'Obrisi',
  test: 'Test',
  light: 'Light',
  import: 'Uvoz',
  export: 'Izvoz',
  yes: 'Da',
  no: 'Ne',
  login: 'Login',
  logout: 'Logout',
  openModal: 'Open modal',
  search: 'Pretraži',
  resetPhone: 'Resetuj lozinku pomocu telefona',
  resetMail: 'Resetuj lozinku pomocu emaila',
  send: 'Pošalji',

  /* Confirm delete messages */
  msgDeleteEntityType: 'Da li ste sigurni da želite da izbrišete tip lica?',
  msgDeleteBank: 'Da li ste sigurni da želite da izbrišete banku?',
  msgDeleteBussinesActivity: 'Da li ste sigurni da želite da izbrišete delatnost?',
  msgDeleteSubBussinesActivity: 'Da li ste sigurni da želite da izbrišete poddelatnost?',
  msgDeleteUser: 'Da li ste sigurni da želite da izbrišete usera?',
  msgDeleteCategory: 'Da li ste sigurni da želite da izbrišete kategoriju?',
  msgDeleteEconomyBranch: 'Da li ste sigurni da želite da izbrišete privrednu granu?',
  msgDeleteMeasureUnit: 'Da li ste sigurni da želite da izbrišete jedinicu mere?',
  msgDeleteProduct: 'Da li ste sigurni da želite da izbrišete proizvod?',
  msgDeleteImage: 'Da li ste sigurni da želite da izbrišete sliku?',
  msgDeleteEntityStatus: 'Da li ste sigurni da želite da izbrišete status lica?',
  msgDeleteTownship: 'Da li ste sigurni da želite da izbrišete opštinu?',
  msgDeleteCountry: 'Da li ste sigurni da želite da izbrišete državu?',
  msgDeleteRegion: 'Da li ste sigurni da želite da izbrišete region?',
  msgDeleteCity: 'Da li ste sigurni da želite da izbrišete grad?',
  msgDeleteService: 'Da li ste sigurni da želite da izbrišete uslugu?',
  msgDeleteTypeOfContact: 'Da li ste sigurni da želite da izbrišete vrstu kontakta?',
  msgDeleteProductDesc: 'Da li ste sigurni da želite da izbrišete opis proizvoda?',
  msgDeletePackage: 'Da li ste sigurni da želite da izbrišete paket?',
  msgDeleteContactPerson: 'Da li ste sigurni da želite da izbrišete kontakt osobu?',
  msgDeleteAnnouncement: 'Da li ste sigurni da želite da izbrišete objavu?',
  msgDeleteClient: 'Da li ste sigurni da želite da izbrišete ovog klijenta?',
  msgDeleteBlog: 'Da li ste sigurni da želite da izbrišete aktuelnost?',
  msgDeleteLegalEntitiesSerbia: 'Da li ste sigurni da želite da izbrišete pravno lice Srbija?',
  msgDeleteLegalEntitiesForeignCountries: 'Da li ste sigurni da želite da izbrišete pravno lice Inostranstvo?',
  msgDeleteFarmSerbia: 'Da li ste sigurni da želite da izbrišete gazdinstvo Srbija?',
  msgDeleteFarmForeignCountries: 'Da li ste sigurni da želite da izbrišete gazdinstvo Inostranstvo?',
  msgDeleteWorkField: 'Da li ste sigurni da želite da izbrišete oblast?',
  msgDeleteSeaPort: 'Da li ste sigurni da želite da izbrišete luku?',
  msgDeleteStockExchange: 'Da li ste sigurni da želite da izbrišete berzu?',
  msgDeleteCurrency: 'Da li ste sigurni da želite da izbrišete valutu?',
  msgDeleteWorldSeaPort: 'Da li ste sigurni da želite da izbrišete svetsku luku?',
  msgDeleteWorldStockExchange: 'Da li ste sigurni da želite da izbrišete svetsku berzu?',
  msgDeleteWorldStockExchangeMilano: 'Da li ste sigurni da želite da izbrišete svetsku berzu Milano?',
  masgDeletePriceMovementBih: 'Da li ste sigurni da želite da izbrišete kretanje cena za BiH?',
  masgDeleteAClient: 'Da li ste sigurni da želite da izbrišete ovu grupu ?',
  msgDeletePositionInCompany: 'Da li ste sigurni da želite da izbrišete poziciju u firmi?',
  msgDeleteProductsPort: 'Da li ste sigurni da želite da izbrišete proizvod luku?',
  msgDeleteBosniaProduct: 'Da li ste sigurni da želite da izbrišete Bosna proizvod?',
  msgDeleteSeaPortProduct: 'Da li ste sigurni da želite da izbrišete Luka proizvod?',
  msgDeleteMilanoProduct: 'Da li ste sigurni da želite da izbrišete Milano proizvod?',
  msgDeleteDelivery: 'Da li ste sigurni da želite da izbrišete isporuku?',
  msgDeleteDependentTb: 'Da li ste sigurni da želite da izbrišete berzu i njene valute i JM?',
  msgDisableDisableIps: 'Da li ste sigurni da želite da blokirate ovaj IP?',
  msgDisableEnableIps: 'Da li ste sigurni da želite da odblokirate ovaj IP?',

  successMsgImport: 'Uspešno ste uneli',
  successMsgCreate: 'Uspešno ste kreirali',
  successMsgEdit: 'Uspešno ste izmenili',
  successMsgDelete: 'Uspešno ste izbrisali',
  successMsgGeneral: 'Uspešno izvršena akcija',
  successForgotPasswordMail: 'Mail sa linkom za resetovanje lozinke je poslat na uneti email.',
  successChangeForgotPasswordMail: 'Uspesno ste zamenili lozinku.',
  successForgotPasswordSms: 'Kod za resetovanje lozinke je poslat na uneti broj telefona.',
  successForgotPasswordSmsCheck: 'Uneti kod je validan',
  msgAnnouncement: 'objavu',
  msgRealizations: 'realizaciju',
  msgBlog: 'aktuelnost',
  msgBank: 'banku',
  msgIp: 'IP adresu',
  msgActivity: 'delatnost',
  msgSubActivity: 'poddelatnost',
  msgCity: 'grad',
  msgContactPerson: 'kontakt osobu',
  msgCountry: 'državu',
  msgCurrency: 'valutu',
  msgEntityType: 'tip lica',
  msgEntityStatus: 'status lica',
  msgProducts: 'proizvod',
  msgProductsMesure: 'JM proizvoda',
  msgProductsCurrency: 'valutu proizvoda',
  msgProductsPort: 'Proizvod luka',
  msgBosniaProducts: 'Bosna proizvod',
  msgPortDelivery: 'Luka-uslovi utovara',
  msgFuturesProducts: 'Futures proizvod',
  msgFuturesMilano: 'Milano proizvod',
  msgProductDesc: 'opis proizvoda',
  msgEconomyBranch: 'privrednu granu',
  msgCategory: 'kategoriju',
  msgCategoryPort: 'kategoriju luke',
  msgCategoryBosnia: 'kategoriju Bosne',
  msgCategoryFutures: 'kategoriju futures',
  msgServiceCategory: 'kategoriju usluge',
  msgMeasureUnit: 'jedinicu mere',
  msgPackage: 'paket',
  msgRegion: 'region',
  msgUloga: 'ulogu',
  msgPort: 'luku',
  msgService: 'uslugu',
  msgStatus: 'status',
  msgStockExc: 'berzu',
  msgTownship: 'opštinu',
  msgContact: 'vrstu kontaka',
  msgUser: 'korisnika',
  msgAdminPass: 'šifru',
  msgWorkField: 'oblast',
  msgWordPort: 'svetsku luku',
  msgWordStock: 'svetsku berzu',
  msgOrganization: 'organizaciju',
  msgLegalEntity: 'pravno lice',
  msgPriceMovementBih: 'kretanje cena BiH',
  msgSave: 'Klikni na sačuvaj da bi promene bile zapamćene',
  msgClient: 'klijenta',
  msgPositionInCompany: 'poziciju u firmi',
  msgDelivery: 'isporuku',
  msgReportType: 'tip izveštaja',

  /* title */
  economyBranchTitle: 'Privredna grana',
  categoryTitle: 'Kategorija Srbija',
  categorySeaportTitle: 'Kategorija Luka',
  categoryBosniaTitle: 'Kategorija Bosna',
  categoryFuturesTitle: 'Kategorija Futures',
  measureUnitTitle: 'podrzumevana JM',
  measureUnitRatioTitle: 'Odnos jedinice mera',
  measureTitle: 'pJedinica mere',
  roleView: 'Pregled',
  roleEdit: 'Izmeni',
  roleAdd: 'Dodaj',
  roleDelete: 'Izbriši',
  roleCheckAll: 'Označi sve opcije',
  roleAddNew: 'Dodaj novu ulogu',
  roleErrAccess: 'Bar jedna opcija mora biti označena',

  messageNoData: 'Nema podataka',

  orgName: 'Ime organizacije',
  orgAddress: 'Adresa organizacije',
  orgCity: 'Grad',
  orgMob: 'Mobilni telefon',
  orgTel: 'Fiksni telefon',
  orgWeb: 'Website',
  orgEmail: 'E-mail',
  orgPib: 'PIB',
  orgCode: 'Šifra delatnosti',
  orgFirmNum: 'Matični broj',
  orgBankNum: 'Žiro račun',
  orgBankName: 'Naziv banke',
  orgSuccesUpdate: 'Uspešno ste promenili podatke organizacije',
  orgAddBankNum: 'Dodaj novi žiro račun',

  multiFieldLabelActivity: 'Dodaj novu delatnost i podelatnost',
  multiFieldLabelContact: 'Dodaj novu kontakt osobu',
  multiFieldLabelFormActivity: 'Delatnosti i podelatnosti',
  multiFieldLabelFormContact: 'Kontakt osobe',
  multiFieldLabelFormBank: 'Lista računa',
  multiFieldLabelTimeContact: 'Dodaj novi datum kontakta',
  multiFieldLabelFormTimeContact: 'Datumi kontakta',
  multiFieldLabelFormCelebration: 'Slave i rođendani',
  multiFieldLabelCelebration: 'Dodaj novu slavu ili rođendan',
  multiFieldLabelImport: 'Dodaj unos',

  groupFormTitleBasic: 'Osnovni podaci',
  groupFormTitleAdd: 'Dodatni podaci',
  groupFormContact: 'Kontakt podaci',
  groupFormBank: 'Podaci o banci',
  groupLabelProducts: 'proizvod koji prati',
  groupLabelSellBuy: 'Proizvod koji se prodaje ili kupuje',
  groupFormType: 'Tip lica',

  tabsLabelView: 'Pregled',
  tabsLabelBasic: 'Osnovni podaci',
  tabsLabelAdds: 'Pretraga svih oglasa',
  tabsLabelAddAdd: 'Dodaj oglas',
  tabsLabelBill: 'Računi',
  tabsLabelAvansBill: 'Fakture i avansi',
  tabsLabelContract: 'Ugovori',
  tabsLabelActivity: 'Aktivnosti korisnika u aplikaciji',
  tabsLabelCelebration: 'Rođendani i slave',
  tabsLabelServices: 'Usluge i dodavanje usluga',
  tabsLabelHistory: 'Istorija',

  errRequiredAll: 'Popunite sva obavezna polja',

  errMeasureUnitRatioDoubled: 'Za ove dve jedinice mere je vec definisan odnos',

  historyChanges: 'Pogledajte istoriju izmena',
  changes: 'Izmena',
  historyChange: 'Istorija promena',

  recordDebt: 'Dugovanje',
  recordDuration: 'Period trajanja',
  recordActivity: 'Primarna delatnost',
  recordLastContact: 'Poslednji datum kontakta',
  addNewTimeContact: 'Dodaj sledece vreme kontakta',
  primaryActivity: 'Primarna delatnost',
  secondaryActivity: 'Sekundarna delatnost',
  lendOwning: 'Obradive povrsine (Hektari)',
  planer: 'Planer',

  saveAsNewAdd: 'Sačuvaj novi oglas',
  refreshAdd: 'Ažuriraj oglas',

  paymentType: 'Način plaćanja',
  paymentTypeErr: 'Način plaćanja obavezno polje',
  francoCity: 'Franko utovareno grad',
  francoRegion: 'Franko utovareno region',
  francoRegionErr: 'Franko utovareno ili FCA kupac ili FCA dogovor je obavezno polje ',
  addVisible: 'Oglas vidljiv',
  priceVisible: 'Cena vidljiva',
  priceMoving: 'Izveštaj kretanje cena',
  goodsReportLabel: 'Robni izveštaj',
  reportAnn: 'Izveštaj Srbija',
  realization: 'Realizovano',
  pdv: 'PDV',
  fca1: 'FCA utovareno',
  fca2: 'FCA dogovor',
  fca3: 'FCA kupac',
  addRealization: 'Dodaj novu realizaciju',
  seller: 'Prodavac',
  buyer: 'Kupac',
  advsSeller: 'Objava prodavca',
  advsBuyer: 'Objava kupca',
  advsTerm: 'Napomena proizvoda',
  advsComent: 'Komentar',
  realizations: 'Realizacija',

  currencyBuy: 'Kupovni kurs',
  currencySell: 'Prodajni kurs',
  currencyMiddle: 'Srednji kurs',
  tbIpAdress: 'IP adrese',
  ipAcesss: 'Pristup sa',
  blocked: 'Blokiran',
  contactNumber: 'Kontakt brojevi',
  activitiesUsers: 'Aktivnosti zaposlenog',
  activitiesClients: 'Aktivnosti klijenta',
  deviceId: 'Uređaj ID',
  action: 'Akcije',
  userView: 'Pregledao',
  page: 'Stranica',
  productInput: 'Proizvod',
  pleaseWait: 'Molimo vas sačekajte dok se izveštaj ne kreira...'
}
