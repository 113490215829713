/** Queries for 'City' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialPositionInCompany } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedPositionInCompany = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'pozicijaFirma',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.positionInCompany
    }
  }
}

export const queryGetAllPositionInCompany = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'pozicijaFirma',
      data: { options }
    },
    providesTags: {
      type: tag.positionInCompany
    }
  }
}

/**
 * @param {initialPositionInCompany} data
 */
export const queryAddPositionInCompany = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'pozicijaFirma',
      data: { values: data }
    },
    invalidatesTags: [tag.positionInCompany],
    successMsg: label.successMsgCreate + ' ' + label.msgPositionInCompany
  }
}

/**
* @param {initialPositionInCompany} data
@param {string} id
*/
export const queryUpdatePositionInCompany = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'pozicijaFirma',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.positionInCompany],
    successMsg: label.successMsgEdit + ' ' + label.msgPositionInCompany
  }
}
/**
@param {string} id
*/
export const queryDeletePositionInCompany = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'pozicijaFirma',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.positionInCompany],
    successMsg: label.successMsgDelete + ' ' + label.msgPositionInCompany
  }
}
