/** Form for  add/edit/remove  'Drzava' */

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { validateCountry as valdateForm } from './helpers/validate'
import { errCountry as errorMsg } from './helpers/errorMsg'
import FormHeader from './formHeader'
import { initialCountry } from 'constants/initialValues'
import { isObjectEmpty } from 'utils'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { label } from 'constants/labels'
import FormBtnSave from './formHeader/formButton'

const FormCountry = ({ existingData = {}, id, onSubmit, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialCountry })

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      naziv: data.naziv,
      sort: data.sort,
      aktivan: data.aktivan,
      pozivniBroj: data.pozivniBroj
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    let { name, value } = e.target
    if (name === 'pozivniBroj') value = value.startsWith('+') ? value : '+' + value
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.countryInput}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.placeholder}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <Input
          name="pozivniBroj"
          label={label.phonePrefixInput}
          placeholder={'+381'}
          value={data.pozivniBroj}
          onChange={handleChange}
          disabled={disabled}
          isPreview={disabled}
          size={9}
          errorTxt={error && !data.pozivniBroj && errorMsg('pozivniBroj', data)}
        />
        <Input
          name="sort"
          label={label.sortInput}
          placeholder={label.placeholder}
          value={data.sort}
          onChange={handleChange}
          disabled={disabled}
          type="number"
          isPreview={disabled}
        />
        <Checkbox
          name="aktivan"
          label={label.activeInput}
          checked={data.aktivan}
          onChange={e => setData(formData => ({ ...formData, aktivan: e.target.checked }))}
          globalClass="mt-3 "
          disabled={disabled}
        />
        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormCountry
