// search icons here https://fontawesome.com/search?m=free&o=r

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faGavel,
  faDashboard,
  faMoneyBillWheat,
  faCircleUser,
  faBars,
  faClose,
  faChevronDown,
  faSignIn,
  faSignOut,
  faUser,
  faSliders,
  faPlus,
  faFloppyDisk,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faEllipsis,
  faEye,
  faPenToSquare,
  faTrashCan,
  faClone,
  faSpinner,
  faCircleXmark,
  faCloudArrowUp,
  faCalendarDays,
  faEyeSlash,
  faFileCsv,
  faTableList,
  faHouseLaptop,
  faMoneyBillTrendUp,
  faFileSignature,
  faBuildingUser,
  faEnvelope,
  faMobile,
  faMobileScreen,
  faCheck,
  faSquareCheck,
  faUpRightFromSquare,
  faMagnifyingGlass,
  faSquarePhoneFlip,
  faListCheck,
  faChalkboardUser,
  faCoins,
  faRegistered,
  faCircleDollarToSlot,
  faDollarSign,
  faHandHoldingDollar,
  faBoxesPacking
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faGavel,
  faDashboard,
  faMoneyBillWheat,
  faCircleUser,
  faBars,
  faClose,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faSignIn,
  faSignOut,
  faUser,
  faSliders,
  faPlus,
  faFloppyDisk,
  faEllipsis,
  faEye,
  faPenToSquare,
  faTrashCan,
  faClone,
  faSpinner,
  faCircleXmark,
  faCloudArrowUp,
  faCalendarDays,
  faEyeSlash,
  faFileCsv,
  faTableList,
  faCircleUser,
  faHouseLaptop,
  faMoneyBillTrendUp,
  faFileSignature,
  faBuildingUser,
  faEnvelope,
  faMobile,
  faMobileScreen,
  faCheck,
  faSquareCheck,
  faMagnifyingGlass,
  faUpRightFromSquare,
  faSquarePhoneFlip,
  faListCheck,
  faChalkboardUser,
  faCoins,
  faRegistered,
  faCircleDollarToSlot,
  faDollarSign,
  faHandHoldingDollar,
  faBoxesPacking
)
