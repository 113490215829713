/** Queries for 'Banka' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialBank } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedBanks = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'banka',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.bank
    }
  }
}

export const queryGetAllBanks = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'banka',
      data: {
        options: {
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.bank
    }
  }
}
/**
 * @param {initialBank} data
 */
export const queryAddBank = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'banka',
      data: { values: data }
    },
    invalidatesTags: [tag.bank],
    successMsg: label.successMsgCreate + ' ' + label.msgBank
  }
}

/**
* @param {initialBank} data
@param {string} id
*/
export const queryUpdateBank = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'banka',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.bank],
    successMsg: label.successMsgEdit + ' ' + label.msgBank
  }
}
/**
@param {string} id
*/
export const queryDeleteBank = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'banka',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.bank],
    successMsg: label.successMsgDelete + ' ' + label.msgBank
  }
}
