/** Form for  add/edit/remove  'Jedinica mere' */

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/input'
import { validateMeasureUnitsRatio as validateForm } from './helpers/validate'
import { errMeasureUnitsRatio as errorMsg } from './helpers/errorMsg'
import FormHeader from './formHeader'
import { initialMeasureUnitsRatio } from 'constants/initialValues'
import { isObjectEmpty } from 'utils'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { label } from 'constants/labels'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllMeasureUnits } from 'query/queryMeasureUnit'
import SelectInput from 'components/selectInput'
import toastService from 'services/toastService'
import FormBtnSave from './formHeader/formButton'

const FormMeasureUnitsRatio = ({ existingData = {}, id, onSubmit, onDelete, header = false, measureUnitRatioData, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialMeasureUnitsRatio })
  const { data: measureUnitsOptions = [] } = useGetAllQuery(queryGetAllMeasureUnits())

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    const exists = measureUnitRatioData.find(
      item => item.izJedinicaMereId === data.izJedinicaMereId && item.uJedinicaMereId === data.uJedinicaMereId
    )
    if (exists && exists.id !== data.id) {
      toastService.show('error', label.errMeasureUnitRatioDoubled)
      return
    }
    e.preventDefault()
    const reqData = {
      izJedinicaMereId: data.izJedinicaMereId,
      uJedinicaMereId: data.uJedinicaMereId,
      odnos: (+data.odnos).toFixed(6)
    }
    if (data.id) reqData.id = data.id

    if (validateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const onSelectChange = async (e, name) => {
    setData(prev => ({ ...prev, [name]: e?.id || null }))
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.measureUnitTitle + ': '}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <SelectInput
          options={measureUnitsOptions}
          // filter={filterMap?.country}
          handleChangeSelect={e => onSelectChange(e, 'izJedinicaMereId', 'uJedinicaMereId')}
          selectedOption={measureUnitsOptions.find(el => el.id === data.izJedinicaMereId || null)}
          customValue="id"
          customLabel="naziv"
          label={label.fromMeasureUnit}
          errorTxt={error && !data.izJedinicaMereId && errorMsg('izJedinicaMereId')}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={measureUnitsOptions}
          // filter={filterMap?.country}
          handleChangeSelect={e => onSelectChange(e, 'uJedinicaMereId', 'uJedinicaMereId')}
          selectedOption={measureUnitsOptions.find(el => el.id === data.uJedinicaMereId || null)}
          customValue="id"
          customLabel="naziv"
          label={label.intoMeasureUnit}
          errorTxt={error && !data.uJedinicaMereId && errorMsg('uJedinicaMereId')}
          disabled={disabled}
          isPreview={disabled}
        />
        <Input
          name="odnos"
          label={label.measureUnitRatio}
          placeholder={label.placeholder}
          value={Number(data.odnos)}
          onChange={handleChange}
          errorTxt={error && !data.odnos && errorMsg('odnos')}
          disabled={disabled}
          isPreview={disabled}
          type="number"
        />

        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormMeasureUnitsRatio
