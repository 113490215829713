/** Page  'Korisnici' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useChangePasswordByAdminMutation, useRegisterByAdminMutation, useUpdateOneMutation } from 'api/authApiSlice'
import { useDeleteOneMutation, useGetAllQuery } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormUser from 'components/forms/formUser'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { userFields } from 'constants/searchFields'
import { headingUser } from 'constants/tbHeading'
import { queryGetAllRoles } from 'query/queryRole'
import { queryGetAllPositionInCompany } from 'query/queryPositionInCompany'
import { queryDeleteUser, queryGetAllUsers, queryGetPagedUsers } from 'query/queryUser'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'
import HistoryTable from 'components/historyTable'

const User = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [searchOptions, setSearchOptions] = useState({})
  const [createData] = useRegisterByAdminMutation()
  const [updateData] = useUpdateOneMutation()
  const [changePassword] = useChangePasswordByAdminMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const { data: users = [] } = useGetAllQuery(queryGetPagedUsers(pageNumber, pageSize, searchOptions))
  const { data: roles = [] } = useGetAllQuery(queryGetAllRoles())
  const { data: positionInCompany = [] } = useGetAllQuery(queryGetAllPositionInCompany())

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (users.rows && users.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, users?.rows])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(data)
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(data)
      if (!res.error) {
        dispatch(setIsDisabled(true))
        dispatch(closeModal(1))
      }
    }
    dispatch(setIsLoadingData(false))
  }

  const onPassChange = async data => {
    const res = await changePassword(data)
    if (!res.error) dispatch(closeModal(1))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/korisnici?id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/korisnici`)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteUser(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/korisnici`)
    } else {
      dispatch(closeModal(1))
    }
  }

  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteUser} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  const showModal = () => {
    navigate(`/korisnici`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.dodajUsera}</h2>
              <FormUser roles={roles} positionInCompany={positionInCompany} onSubmit={onSubmit} id={null} />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="korisnik"
                link="/korisnici?id="
                connectionTb={[
                  { rtkQuery: queryGetAllPositionInCompany(), dbName: 'pozicijaFirmaId' },
                  { rtkQuery: queryGetAllRoles(), dbName: 'ulogaId' },
                  { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' }
                ]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.korisnik} total={users?.count}>
        <Button onClick={showModal} label={label.add} iconLeft={<FontAwesomeIcon icon="fa-plus" />} accessAction="ADD" accessCode={code} />
      </Title>
      <Search fields={userFields} onSearch={onSubmitSearch} />
      {!!users?.rows?.length && (
        <h3 className="history-link" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns>
        <Table
          heading={headingUser}
          data={users?.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={users?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormUser
              roles={roles}
              positionInCompany={positionInCompany}
              existingData={data}
              header={true}
              id={id}
              onSubmit={onSubmit}
              onPasswordChange={onPassChange}
              onDelete={openDeleteModal}
              code={code}
            />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default User
