/** Form for  add/edit  'Kategorija Srbija / Category' */

import { useDeleteOneMutation, useGetAllQuery } from 'api/dataApiSlice'
import Input from 'components/input'
import { Loader } from 'components/loader'
import ModalYesNo from 'components/modal/modalYesNo'
import { ImagesModal } from 'components/previewImageAnnoucment'
import UploadMedia from 'components/uploadMedia'
import { initialCategory } from 'constants/initialValues'
import { label } from 'constants/labels'
import { queryDeleteImageAnnoucmentCategory, queryGetAllImageAnnoucmentCategoryByCategoryId } from 'query/queryImageAnnoucmentCategory'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setUploadImages } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import { errCategory as errorMsg } from './helpers/errorMsg'
import { prepareForUpload } from './helpers/utils'
import { validateCategory as validateForm } from './helpers/validate'
import FormBtnSave from './formHeader/formButton'

const FormCategory = ({ existingData = {}, id, onSubmit, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const [deleteOne] = useDeleteOneMutation()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialCategory })
  const { data: existingImageAnnocumentCategory = [] } = useGetAllQuery(queryGetAllImageAnnoucmentCategoryByCategoryId(existingData.id), {
    skip: !existingData.id
  })
  const images = useSelector(state => state.static.images)

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  useEffect(() => {
    if (existingImageAnnocumentCategory && existingData.id) {
      dispatch(setUploadImages(existingImageAnnocumentCategory))
    }
  }, [existingData.id, existingImageAnnocumentCategory])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      naziv: data.naziv,
      aktivan: data.aktivan,
      tipPodatka: data.tipPodatka
    }
    if (data.id) reqData.id = data.id

    if (validateForm(reqData)) {
      const fileIds = await prepareForUpload(images)
      onSubmit(reqData, fileIds)
    } else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const onImageChange = images => {
    dispatch(setUploadImages(images))
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteImageAnnoucmentCategory(data.id))
      if (!res.error) dispatch(closeModal(1))
    } else {
      dispatch(closeModal(1))
    }
  }
  const onImagePreview = () => {
    if (images[0].link) {
      dispatch(
        openModal({
          arrItem: {
            content: <ImagesModal imgs={images} modalPosition={3} />,
            order: 1,
            size: 'lg'
          },
          active: 1
        })
      )
    }
  }

  const removeUploadedImage = index => {
    if (!(images[index] instanceof File)) {
      dispatch(
        openModal({
          arrItem: {
            content: <ModalYesNo handleSubmit={handleDelete} data={images[index]} question={label.msgDeleteImage} />,
            order: 1,
            size: 'sm'
          },
          active: 1
        })
      )
    } else {
      const newImages = images.filter((e, i) => i !== index)
      dispatch(setUploadImages(newImages))
    }
  }

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader />
        </div>
      )}
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.categoryTitle + ': '}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.nazivPlaceholder}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <UploadMedia
          disabled={images?.length > 0 || disabled}
          onImageChange={onImageChange}
          images={images}
          removeUploadedImage={index => removeUploadedImage(index)}
          onImagePreview={onImagePreview}
          label="Dodajte sliku za kategoriju"
        />

        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormCategory
