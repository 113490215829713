import React from 'react'
import { PropTypes } from 'prop-types'
import styles from './input.module.scss'
import ErrorMessage from '../errorMessage'

const Input = ({
  onChange,
  stylesClass = 'defaultInput',
  isPreview = false,
  globalClass = '',
  name,
  label,
  value = '',
  placeholder,
  disabled = false,
  id,
  errorTxt,
  size,
  type = 'text',
  onKeyPress
}) => {
  const previewValue = type === 'number' ? Number(value) : value || '-'
  const isNumber = type === 'number' ? Number(value) || '' : value
  // const isNull = type === 'number' ? 0 : ''
  return (
    <div className={`${styles[stylesClass]} ${globalClass} ${isPreview && styles.preview} g-input input-${name}`}>
      {label && <label htmlFor={id ?? name}>{label}</label>}
      <input
        size={size}
        maxLength={size}
        className={styles.input}
        id={id ?? name}
        type={type}
        name={name}
        value={isPreview ? previewValue : isNumber}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        onKeyPress={onKeyPress}
      />
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

Input.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default Input
