/** Page  'Kursna lista' */
import { useGetAllQuery } from 'api/dataApiSlice'
import { OneColumn } from 'components/containers/containers'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { currencyListFields } from 'constants/searchFields'
import { headingCurrencyList } from 'constants/tbHeading'
import { queryGetPagedCurrencyList } from 'query/queryCurrency'
import { useState } from 'react'

const CurrencyList = () => {
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: currency = [] } = useGetAllQuery(queryGetPagedCurrencyList(pageNumber, pageSize, searchOptions))

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  return (
    <div>
      <Title title={label.currencyList} total={currency?.count} />
      <Search fields={currencyListFields} onSearch={onSubmitSearch} />
      <OneColumn>
        <Table
          heading={headingCurrencyList}
          data={currency.rows}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={currency?.count}
        />
      </OneColumn>
    </div>
  )
}
export default CurrencyList
