import { useParams } from 'react-router-dom'
import styles from '../record.module.scss'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllContactsByClientId } from 'query/queryClientConectionTables'
import Table from 'components/table'
import { FIELD_TYPES } from 'constants/other'

const PreviewContacts = () => {
  const { id } = useParams()
  const { data: contacts = [] } = useGetAllQuery(queryGetAllContactsByClientId({ where: { klijentId: id } }))
  const onAction = (value, action) => console.log('action', action, value)
  return (
    <div className={styles.contactsTable}>
      {contacts.length ? (
        <div>
          <Table
            heading={[
              { dbName: 'ime', label: 'Ime ', type: FIELD_TYPES.text },
              { dbName: 'mobilni', label: 'Telefon ', type: FIELD_TYPES.text },
              { dbName: 'email', label: 'Email ', type: FIELD_TYPES.text },
              { dbName: 'funkcija', label: 'Funkcija', type: FIELD_TYPES.text },
              { label: '', dbName: 'actions', type: FIELD_TYPES.actions, actions: ['phone'], isPopup: false }
            ]}
            name="listContacts"
            data={contacts}
            onAction={onAction}
            haveActionInline={true}
          />
        </div>
      ) : (
        <div>Nama podataka</div>
      )}
    </div>
  )
}
export default PreviewContacts
