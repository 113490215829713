import React, { useCallback, useEffect, useState } from 'react'
import styles from './search.module.scss'
import useDebounce from 'hooks/useDebounce'
import Input from 'components/input'
import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Search from 'components/search'
import { clientsSearchFields } from 'constants/searchFields'
import { queryAllClientSearch } from 'query/queryClient'
import { useLazyGetAllQuery } from 'api/dataApiSlice'
import { isObjectEmpty } from 'utils'
import { Loader } from 'components/loader'

const ClientSearch = ({ setClients, havePagination = false, setSearchFields, isLoading = false }) => {
  const [searchBasic, setSearchBasic] = useState('')
  const [showAdancedSearch, setShowAdancedSearch] = useState(false)
  const [loading, setLoading] = useState(isLoading)
  const debouncedValue = useDebounce(searchBasic, 300)
  const [getClients] = useLazyGetAllQuery()

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  const search = useCallback(async () => {
    const multi = {
      sqlType: {
        MULTI_SEARCH: {
          fields: [
            'naziv',
            'pib',
            'maticniBroj',
            'kontaktTelefon',
            'bpg',
            'email',
            'imeKontakta',
            'emailKontakta',
            'telefonKontakta',
            'napomena'
          ],
          value: searchBasic
        }
      }
    }
    if (!havePagination) {
      if (!searchBasic.length) return setClients([])
      setLoading(true)
      const res = await getClients(queryAllClientSearch(multi)).unwrap()
      setClients(res)
      setLoading(false)
    } else {
      setSearchFields(multi)
    }
  }, [debouncedValue])

  useEffect(() => {
    search()
  }, [debouncedValue, search])

  const onSubmitAdvancedSearch = async data => {
    if (!havePagination) {
      if (isObjectEmpty(data)) {
        setClients([])
        return
      }
      const res = await getClients(queryAllClientSearch(data)).unwrap()
      setClients(res)
    } else {
      setSearchFields(data)
    }
  }

  return (
    <div className={styles.search}>
      {!showAdancedSearch && (
        <div className={styles.searchBasic}>
          <Input
            value={searchBasic}
            placeholder="Pretrazuj po nazivu,PIB-u,MB-u,BPG-u,imenu,email-u i telefonu kontakt osobe, pojmu iz napomene..."
            onChange={e => setSearchBasic(e.target.value)}
            globalClass="mb-1"
          />
          <div> {loading && <Loader />} </div>
        </div>
      )}
      <div className={styles.searchAdanced}>
        {!showAdancedSearch && (
          <Button
            label="Napredna pretraga "
            iconLeft={<FontAwesomeIcon icon="fa-magnifying-glass" />}
            onClick={() => setShowAdancedSearch(true)}
            stylesClass="btnGray"
          />
        )}
        <div style={{ display: showAdancedSearch ? 'block' : 'none' }}>
          <Search fields={clientsSearchFields} onSearch={onSubmitAdvancedSearch} onClose={() => setShowAdancedSearch(false)} />
        </div>
      </div>
    </div>
  )
}

export default ClientSearch
