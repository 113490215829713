import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import differenceWith from 'lodash/differenceWith'

export const getDeleteUpdateConectionTbData = (
  data,
  clientActivity,
  clientActivityData,
  clientContacts,
  clientContactData,
  clientTimeOfContacts,
  clientTimeContactData,
  clientProductsBuyData,
  productsBuy,
  clientProductsSellData,
  productsSell,
  clientCelebrations,
  clientCelebrationsData
  // remark
) => {
  const diff1 = differenceWith(clientActivity, clientActivityData, isEqual)
  const diff2 = differenceWith(clientContacts, clientContactData, isEqual)
  const diff3 = differenceWith(clientTimeOfContacts, clientTimeContactData, isEqual)
  const diff4 = differenceWith(clientCelebrations, clientCelebrationsData, isEqual)
  const deleteBuy = clientProductsBuyData.filter(x => !productsBuy.some(y => y.proizvodId === x.proizvodId))
  const createBuy = productsBuy.filter(x => !clientProductsBuyData.some(y => y.proizvodId === x.proizvodId))
  const deleteSell = clientProductsSellData.filter(x => !productsSell.some(y => y.proizvodId === x.proizvodId))
  const createSell = productsSell.filter(x => !clientProductsSellData.some(y => y.proizvodId === x.proizvodId))
  return {
    ...data,
    connectingTables: {
      clientActivity: diff1,
      clientContacts: diff2,
      clientTimeOfContacts: diff3,
      clientCelebrations: diff4,
      productsBuy: createBuy,
      productsBuyDelete: deleteBuy,
      productsSell: createSell,
      productsSellDelete: deleteSell
      // remark
    }
  }
}

export const getDeleteUpdateConectionTbDataForProduct = (
  id,
  measureUnit,
  measureUnitData,
  descriptionProduct,
  descriptionProductData,
  economyBranch,
  economyBranchData
) => {
  const diff1 = differenceWith(measureUnit, measureUnitData, isEqual)
  const diff2 = differenceWith(descriptionProduct, descriptionProductData, isEqual)

  const deleteBranch = economyBranchData.filter(x => !economyBranch.some(y => y.granaPrivredeId === x.granaPrivredeId))
  const createBranch = economyBranch.filter(x => !economyBranchData.some(y => y.granaPrivredeId === x.granaPrivredeId))

  if (!id) {
    return {
      jediniceMere: measureUnit,
      opisProizvoda: diff2,
      granaPrivrede: createBranch
    }
  }
  return {
    jediniceMere: diff1,
    opisProizvoda: diff2,
    granaPrivrede: createBranch,
    granaPrivredeDelete: deleteBranch
  }
}
// export const getDeleteUpdateConectionTbDataForProduct = (
//   data,
//   measureUnit,
//   measureUnitData,
//   descriptionProduct,
//   descriptionProductData,
//   economyBranch,
//   economyBranchData
// ) => {
//   const diff1 = differenceWith(measureUnit, measureUnitData, isEqual)
//   const diff2 = differenceWith(descriptionProduct, descriptionProductData, isEqual)
//   const diff3 =
//     economyBranch?.length > economyBranchData?.length
//       ? differenceWith(economyBranch, economyBranchData, isEqual)
//       : differenceWith(economyBranchData, economyBranch, isEqual)

//   let deleteFlag = false
//   if (economyBranch?.length > economyBranchData?.length) {
//     deleteFlag = true
//   }

//   return {
//     ...data,
//     connectingTables: {
//       jediniceMere: diff1,
//       opisProizvoda: diff2,
//       granaPrivrede: !deleteFlag ? diff3 : [],
//       granaPrivredeDelete: deleteFlag ? diff3 : []
//     }
//   }
// }

// export const getDeleteUpdateConectionTbDataForProductFutures = (id, existingExchenge, newExchenge) => {
//   if (!id) {
//     return {
//       productsFeturesStockExchange: newExchenge
//     }
//   }
//   const deleteExchenge = existingExchenge.filter(x => !newExchenge.some(y => y.berzaId === x.berzaId))
//   const createExchenge = newExchenge.filter(x => !existingExchenge.some(y => y.berzaId === x.berzaId))
//   return {
//     productsFeturesStockExchange: createExchenge,
//     productsFeturesStockExchangeDelete: deleteExchenge
//   }
// }

export const getDeleteUpdateConectionTbDataJmCurrency = (id, connectingTables) => {
  const { existingCurrency, newCurrency, measureUnitProduct, newMeasureUnitProduct } = connectingTables
  if (!id) {
    return {
      productsCurrency: newCurrency,
      jediniceMere: newMeasureUnitProduct
    }
  }
  return {
    productsCurrency: differenceWith(newCurrency, existingCurrency, isEqual),
    jediniceMere: differenceWith(newMeasureUnitProduct, measureUnitProduct, isEqual)
  }
}
export const getDiffForImageObject = (existingFileIds, images) => {
  const diff1 = differenceWith(images, existingFileIds, isEqual)
  return diff1
}

export const pullConditionalFields = async (mapName, filterMap, selectValue, data) => {
  const { filters, additionalStateFields, emptyFields, stateField } = cloneDeep(filterMap[mapName])
  const newData = cloneDeep(data)
  if (filters) {
    Object.keys(filters).forEach(key => {
      filters[key].filterValue = selectValue?.id || null
    })
  }
  newData[stateField] = selectValue?.id || null

  if (additionalStateFields) {
    additionalStateFields.forEach(e => {
      newData[e] = selectValue?.[e] || null
    })
  }

  if (emptyFields) {
    emptyFields.forEach(e => {
      newData[e] = null
    })
  }

  return { newData, newFilterMap: filters }
}

export const prepareForUpload = async files => {
  return Promise.all(
    files.map(async file => {
      const orientation = await getOrientation(file)
      const base64ToRotate = await getImageBase64(file)
      const base64 = await getCorrectImageOrientationFromBase64(base64ToRotate, orientation)

      const dataToUpload = {
        name: file.name,
        data: base64,
        type: 'base64'
      }

      return dataToUpload
    })
  )
}

const getOrientation = async file => {
  return new Promise(resolve => {
    try {
      const reader = new FileReader()
      reader.onload = function (e) {
        const view = new DataView(e.target.result)
        if (view.getUint16(0, false) !== 0xffd8) {
          return resolve(-2)
        }
        const length = view.byteLength
        let offset = 2
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) return resolve(-1)
          const marker = view.getUint16(offset, false)
          offset += 2
          if (marker === 0xffe1) {
            if (view.getUint32((offset += 2), false) !== 0x45786966) {
              return resolve(-1)
            }

            const little = view.getUint16((offset += 6), false) === 0x4949
            offset += view.getUint32(offset + 4, little)
            const tags = view.getUint16(offset, little)
            offset += 2
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) === 0x0112) {
                return resolve(view.getUint16(offset + i * 12 + 8, little))
              }
            }
          } else if ((marker & 0xff00) !== 0xff00) {
            break
          } else {
            offset += view.getUint16(offset, false)
          }
        }
        return resolve(-1)
      }
      reader.readAsArrayBuffer(file)
      return resolve(0)
    } catch (error) {
      console.log(error)
      return resolve(0)
    }
  })
}

export const getImageBase64 = async imageFile => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(imageFile)

    reader.addEventListener(
      'load',
      () => {
        // reader.result is the image file in base64
        return resolve(reader.result)
      },
      false
    )
  })
}

const getCorrectImageOrientationFromBase64 = async (srcBase64, srcOrientation) => {
  return new Promise(resolve => {
    const img = new Image()

    img.onload = function () {
      const width = img.width
      const height = img.height
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const pixelRatio = window.devicePixelRatio
      const scale = 0.6 - pixelRatio / 10

      // set proper canvas dimensions before transform & export
      if (srcOrientation > 4 && srcOrientation < 9) {
        canvas.width = height * scale
        canvas.height = width * scale
      } else {
        canvas.width = width * scale
        canvas.height = height * scale
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0)
          break
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height)
          break
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height)
          break
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0)
          break
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0)
          break
        case 7:
          ctx.transform(0, -1, -1, 0, height, width)
          break
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width)
          break
        default:
          break
      }

      // draw image
      ctx.drawImage(img, 0, 0, width * scale, height * scale)
      // export base64

      return resolve(canvas.toDataURL())
    }
    img.src = srcBase64
  })
}

// export const getConditionalCountries1 = async (mapName, selectValue, data, optionsMap, pullData, optionMapOnLoad) => {
//   let newOptionsMap = cloneDeep(optionsMap)
//   if (optionMapOnLoad) newOptionsMap = { ...newOptionsMap, ...optionMapOnLoad }
//   const newData = cloneDeep(data)
//   if (pullData) {
//     const { methods, additionalStateFields, stateField, emptyFields } = pullData[mapName]
//     if (methods) {
//       for (const mapfields of methods) {
//         const { method, queryField, mapField } = mapfields
//         if (method) {
//           const res = await store.dispatch(api.endpoints.getAll.initiate(method({ where: { [queryField]: selectValue.id } }))).unwrap()
//           newOptionsMap[mapField] = res
//           // newData[stateField] = selectValue.id
//         }
//       }
//     }
//     newData[stateField] = selectValue.id

//     if (additionalStateFields) {
//       additionalStateFields.forEach(e => {
//         newData[e] = selectValue[e]
//       })
//     }
//     if (emptyFields) {
//       emptyFields.forEach(e => {
//         newData[e] = null
//       })
//     }
//   }
//   return { newData, newOptionsMap }
// }
