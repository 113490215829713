import React from 'react'
import styles from './tableTest.module.scss'

const data = [
  {
    Kategorija1: [
      {
        naziv: 'Ovo je objava broj 1 20 tona',
        kompanija: 'AGRAA DOO PELAGICEVO ',
        brTelefona: '38165789654',
        datumUnosa: '27:12:2023'
      },
      {
        naziv: 'Ovo je objava broj 2 30 tona',
        kompanija: 'ADJUK AGRAA DOO Novi Becej ',
        brTelefona: '38165789654',
        datumUnosa: '27:12:2023'
      },
      {
        naziv: 'Ovo je objava broj 3 10 tona',
        kompanija: 'AGROTEAM DOO NS ',
        brTelefona: '38165789654',
        datumUnosa: '27:12:2023'
      }
    ],
    Kategorija2: [
      {
        naziv: 'Ovo je objava broj 4 20 tona',
        kompanija: 'AGRAA DOO PELAGICEVO ',
        brTelefona: '38165789654',
        datumUnosa: '27:12:2023'
      },
      {
        naziv: 'Ovo je objava broj 5 30 tona',
        kompanija: 'ADJUK AGRAA DOO Novi Becej ',
        brTelefona: '38165789654',
        datumUnosa: '27:12:2023'
      },
      {
        naziv: 'Ovo je objava broj 6 10 tona',
        kompanija: 'AGROTEAM DOO NS ',
        brTelefona: '38165789654',
        datumUnosa: '27:12:2023'
      }
    ]
  }
]

const TableTest = () => {
  return (
    <table className={styles.blueTable}>
      <tbody>
        {data.map((category, catIndex) =>
          Object.entries(category).map(([categoryName, categoryRows], rowIndex) => (
            <React.Fragment key={catIndex + '_' + rowIndex}>
              <tr>
                <td className={styles.borderTop} colSpan="3">
                  {categoryName}
                </td>
              </tr>
              <tr>
                <td>Oglas</td>
                <td>Kompanija</td>
                <td>Broj Telefona</td>
                <td>Datum</td>
              </tr>
              {categoryRows.map((row, cellIndex) => (
                <tr className={styles.borderBottom} key={catIndex + '_' + rowIndex + '_' + cellIndex}>
                  <td>{row.naziv}</td>
                  <td>{row.kompanija}</td>
                  <td>{row.brTelefona}</td>
                  <td>{row.datumUnosa}</td>
                </tr>
              ))}
            </React.Fragment>
          ))
        )}
      </tbody>
    </table>
  )
}

export default TableTest
