export const tag = {
  roles: 'ROLES',
  entityTypes: 'ENTITY_TYPES',
  economyBranch: 'ECONOMY_BRANCH',
  category: 'CATEGORY',
  categoryPorts: 'CATEGORY_PORT',
  categoryBosnia: 'CATEGORY_BOSNIA',
  categoryFutures: 'CATEGORY_FUTURES',
  serviceCategory: 'SERVICE_CATEGORY',
  subBusinessActivity: 'SUB_BUSINESS_ACTIVITY',
  businessActivity: 'BUSINESS_ACTIVITY',
  bank: 'BANK',
  user: 'USER',
  users: 'USERS',
  measureUnit: 'MEASURE_UNIT',
  measureUnitRatio: 'MEASURE_UNIT_RATIO',
  products: 'PRODUCTS',
  productsPort: 'PRODUCTS_PORT',
  productsBosnia: 'PRODUCTS_BOSNIA',
  portDelivery: 'PORT_DELIVERY',
  productsFutures: 'PRODUCTS_FUTURES',
  productsMilano: 'PRODUCTS_MILANO',
  country: 'COUNTRY',
  entityStatus: 'ENTITY_STATUS',
  status: 'STATUS',
  township: 'TOWNSHIP',
  region: 'REGION',
  city: 'CITY',
  service: 'SERVICE',
  typeOfContact: 'TYPE_OF_CONTACT',
  productDescription: 'PRODUCT_DESCRIPTION',
  package: 'PACKAGE',
  announcement: 'ANNOUNCMENT',
  announcementSupplay: 'ANNOUNCMENT_SUPPLAY',
  announcementDemand: 'ANNOUNCMENT_DEMAND',
  oneAnnouncement: 'ONA_ANNOUNCMENT',
  blog: 'BLOG',
  forms: 'FORMS',
  contactPerson: 'CONTACT_PERSON',
  workField: 'WORK_FIELD',
  organization: 'ORGANIZATION',
  seaPort: 'SEA_PORT',
  stockExchange: 'STOCK_EXCHANGE',
  currency: 'CURRENCY',
  currencyList: 'CURRENCY_LIST',
  worldSeaPort: 'WORLD_SEA_PORT',
  worldSeaPortSerach: 'WORLD_SEA_PORT_SEARCH',
  worldStockExchange: 'WORLD_STOCK_EXCHANGE',
  worldStockExchangeSearch: 'WORLD_STOCK_EXCHANGE_SEARCH',
  worldStockExchangeLast: 'WORLD_STOCK_EXCHANGE_LAST',
  worldStockExchangeMilano: 'WORLD_STOCK_EXCHANGE_MILANO',
  worldStockExchangeMilanoLast: 'WORLD_STOCK_EXCHANGE_MILANO_LAST',
  worldStockProductFuturesSearch: 'WORLD_STOCK_PRODUCT_FUTURES_SEARCH',
  priceMovementBih: 'PRICE_MOVEMENT_BIH',
  priceMovementBihSerach: 'PRICE_MOVEMENT_BIH_SEARCH',
  customGoodsImport: 'CARINSKE_ROBE_UVOZ',
  customGoodsExport: 'CARINSKE_ROBE_IZVOZ',
  clientActivity: 'CLIENT_ACTIVITY',
  clientContacts: 'CLIENT_CONTACTS',
  clientTimeContacts: 'CLIENT_TIME_CONTACTS',
  clients: 'CLIENTS',
  registerClients: 'CLIENTS_REGISTER',
  client: 'CLIENT',
  clientsCelebrations: 'CLIENTS_CELEBRATIONS',
  clientsRemark: 'CLIENTS_REMARK',
  history: 'HISTORY',
  historyReports: 'HISTORY_REPORTS',
  measureUnitProduct: 'MEASURE_UNIT_PRODUCT',
  clientsProducts: 'CLIENT_PRODUCTS',
  positionInCompany: 'POSITION_IN_COMPANY',
  delivery: 'DELIVERY',
  economyBranchCatProduct: 'ECONOMY_BRANCH_CAT_PRODUCT',
  stockExchangeCatProductFutures: 'STOCK_EXCHANGE_CAT_PRODUCT_FUTURES',
  imageAnnoucmentCategory: 'IMAGE_ANNOUCMENT_CATEGORY',
  announcementFca: 'FCA',
  realizations: 'REALIZATIONS',
  ips: 'IPS',
  activitiesUsers: 'ACTIVITIES_USERS',
  activitiesClients: 'ACTIVITIES_CLIENTS',
  productCurency: 'PRODUCT_CURENCY',
  reportTypes: 'REPORT_TYPES'
}

export const tagArray = [
  tag.roles,
  tag.entityTypes,
  tag.subBusinessActivity,
  tag.businessActivity,
  tag.bank,
  tag.user,
  tag.users,
  tag.economyBranch,
  tag.category,
  tag.categoryPorts,
  tag.categoryBosnia,
  tag.categoryFutures,
  tag.serviceCategory,
  tag.measureUnit,
  tag.products,
  tag.entityStatus,
  tag.status,
  tag.country,
  tag.region,
  tag.township,
  tag.service,
  tag.typeOfContact,
  tag.productDescription,
  tag.package,
  tag.announcement,
  tag.announcementSupplay,
  tag.announcementDemand,
  tag.forms,
  tag.contactPerson,
  tag.workField,
  tag.organization,
  tag.seaPort,
  tag.stockExchange,
  tag.currency,
  tag.worldSeaPort,
  tag.worldStockExchange,
  tag.priceMovementBih,
  tag.customGoodsImport,
  tag.customGoodsExport,
  tag.clientActivity,
  tag.clientContacts,
  tag.clientTimeContacts,
  tag.clientsCelebrations,
  tag.clientsRemark,
  tag.clients,
  tag.client,
  tag.blog,
  tag.history,
  tag.reports,
  tag.measureUnitProduct,
  tag.clientsProducts,
  tag.positionInCompany,
  tag.productsPort,
  tag.productsBosnia,
  tag.delivery,
  tag.portDelivery,
  tag.productsFutures,
  tag.economyBranchCatProduct,
  tag.stockExchangeCatProductFutures,
  tag.imageAnnoucmentCategory,
  tag.announcementFca,
  tag.realizations,
  tag.oneAnnouncement,
  tag.currencyList,
  tag.ips,
  tag.activitiesUsers,
  tag.activitiesClients,
  tag.measureUnitRatio,
  tag.productCurency,
  tag.worldStockExchangeMilano,
  tag.worldStockExchangeMilanoLast,
  tag.worldStockExchangeLast,
  tag.reportTypes,
  tag.worldSeaPortSerach,
  tag.priceMovementBihSerach,
  tag.worldStockExchangeSearch,
  tag.worldStockProductFuturesSearch
]
