import React from 'react'
import styles from './record.module.scss'
import { label } from 'constants/labels'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllUsers } from 'query/queryUser'
import { queryGetAllEntityStatus } from 'query/queryEntityStatus'

const FormHeading = ({ client }) => {
  const { user } = useGetAllQuery(queryGetAllUsers(), {
    selectFromResult: ({ data }) => {
      return {
        user: data?.find(e => e.id === client?.korisnikId) || {}
      }
    }
  })
  const { status } = useGetAllQuery(queryGetAllEntityStatus(), {
    selectFromResult: ({ data }) => {
      return {
        status: data?.find(e => e.id === client?.statusLicaId) || {}
      }
    }
  })
  return (
    <div className={styles.formHeader}>
      <>
        <div className={styles.basic}>
          <div className={`${styles.item} ${styles.warrning}`}>
            <h3>{label.recordDebt}</h3>
            <span>33.000 DIN</span>
          </div>
          <div className={styles.item}>
            <h3>{label.recordDuration}</h3>
            <div className={styles.duration}>
              <span>15 Jun 2023 - 15 Jun 2024</span>
            </div>
          </div>
          <div className={styles.item}>
            <h3>{label.packageInput}</h3>
            <span>Mobilna aplikacija Info tim logistika</span>
          </div>
          <div className={styles.item}>
            <h3>{label.statusInput}</h3>
            <span>{status?.naziv}</span>
          </div>
          <div className={styles.item}>
            <h3>{label.recordLastContact}</h3>
            <span>15 Jun 2023</span>
          </div>
          <div className={styles.item}>
            <h3>{label.salesSpecialist}</h3>
            <span>
              {user?.ime} {user?.prezime}
            </span>
          </div>
        </div>
      </>
    </div>
  )
}

export default FormHeading
