import React from 'react'
import styles from './title.module.scss'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Title = ({ title, desc, total, children, stylesClass = 'defaultTitle', globalClass = '', goBackClick, goBackUrl = '' }) => {
  return (
    <div className={`${styles.wrapper} ${styles[stylesClass]} ${globalClass} g-title`}>
      <div className={styles.title}>
        <h1>
          <span>
            {goBackUrl && (
              <b>
                <NavLink to={goBackUrl}>
                  <FontAwesomeIcon icon="fa-chevron-left" />
                </NavLink>
              </b>
            )}
            {goBackClick && (
              <b onClick={goBackClick}>
                <FontAwesomeIcon icon="fa-chevron-left" />
              </b>
            )}

            {title}
          </span>
          {(total || total === 0) && <small className={styles.total}>Ukupno: {total}</small>}
        </h1>
        {desc && <div className="g-title-desc">{desc}</div>}
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default Title
