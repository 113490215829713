const env = process.env.REACT_APP_ENVIROMENT
let url, wsUrl

if (env === 'production') {
  url = 'https://prod...'
  wsUrl = ''
} else if (env === 'dev') {
  url = 'https://infoteam.hyperether.com'
  wsUrl = 'wss://infoteam.hyperether.com'
} else if (env === 'development') {
  url = 'http://localhost:3000/'
  wsUrl = ''
} else {
  url = 'http://localhost:3000/'
  wsUrl = 'ws://localhost:3000'
}
export { url, wsUrl }
