/** Form for  add/edit/remove  'Proizvod-features' */

import { useGetAllQuery } from 'api/dataApiSlice'
import Input from 'components/input'
import MultipleInputs from 'components/inputMultiple'
import SelectInput from 'components/selectInput'
import {
  initialCurrencyForProducts,
  initialMeasureForProducts,
  initialProductFutures,
  multiFieldsCurrencyProductInitial,
  multiFieldsMeasureUnitIntial
} from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES, PRODUCTS_CONNECTION_TB } from 'constants/other'
import { cloneDeep } from 'lodash'
import { queryGetAllCategoriesFutures } from 'query/queryCategoryFutures'
import { queryGetAllValutesByProductId } from 'query/queryCurrencyProducts'
import { queryGetAllMeasureUnitProductByProductId } from 'query/queryMeasureUnitProduct'
import { queryGetAllMeasureUnitsRatio } from 'query/queryMeasureUnitRatio'
import { queryGetAllStockExchangeNotMilano } from 'query/queryStockExchange'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import toastService from 'services/toastService'
import { getJMRationsMap, isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import { errProductFutures as errorMsg } from './helpers/errorMsg'
import { getDeleteUpdateConectionTbDataJmCurrency } from './helpers/utils'
import { validateProductFutures as validateForm } from './helpers/validate'
import FormBtnSave from './formHeader/formButton'
import Checkbox from 'components/checkbox'
import styles from './form.module.scss'

// const _findJedinicaMereId = jediniceMere => {
//   const defaultJedinicaMere = jediniceMere.find(el => el.podrazumevana === true || el.podrazumevana === 1)
//   return defaultJedinicaMere ? defaultJedinicaMere.jedinicaMereId : null
// }

// const _findValutaId = valute => {
//   const defaultValuta = valute.find(el => el.podrazumevana === true || el.podrazumevana === 1)
//   return defaultValuta ? defaultValuta.valutaId : null
// }

// const _findJedinicaMereValutaId = jediniceMere => {
//   const defaultJedinicaMere = jediniceMere.find(el => el?.podrazumevanaValutaJedinica === true || el?.podrazumevanaValutaJedinica === 1)
//   return defaultJedinicaMere ? defaultJedinicaMere.jedinicaMereId : null
// }
const initialMultiFields = {
  [PRODUCTS_CONNECTION_TB.measureUnit]: [{ ...initialMeasureForProducts }],
  [PRODUCTS_CONNECTION_TB.productsCurrency]: [{ ...initialCurrencyForProducts }]
}
const FormProductFutures = ({ existingData = {}, id, onSubmit, onDelete, header = false, onMultiDelete, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialProductFutures })
  const [odnosMera, setOdnosMera] = useState({})
  const [multiDataMap, setMultiDataMap] = useState({})
  const [multiError, setMultiError] = useState({})
  const requiredMultiMap = useSelector(state => state.static.multipleRequiredFields)
  const { data: categoriesFutures = [] } = useGetAllQuery(queryGetAllCategoriesFutures())
  const { data: measureUnitRatio = [] } = useGetAllQuery(queryGetAllMeasureUnitsRatio())

  const { data: stockExchangeData = [] } = useGetAllQuery(queryGetAllStockExchangeNotMilano())

  const { data: existingCurrency = [] } = useGetAllQuery(queryGetAllValutesByProductId({ where: { proizvodId: existingData.id } }), {
    skip: !existingData.id
  })

  const { data: measureUnitProduct = [] } = useGetAllQuery(
    queryGetAllMeasureUnitProductByProductId(existingData.id, { order: [['createdAt', 'ASC']] }),
    {
      skip: !existingData.id
    }
  )

  let ratioMap = getJMRationsMap(measureUnitRatio)

  useEffect(() => {
    if (measureUnitRatio.length && id) {
      ratioMap = getJMRationsMap(measureUnitRatio)
    }
  }, [measureUnitRatio])

  useEffect(() => {
    if (measureUnitProduct.length && existingCurrency.length && measureUnitRatio.length && existingData.id) {
      const converted = cloneDeep(measureUnitProduct).map(e => {
        if (e.odnos) e.odnos = Number(e.odnos)
        return e
      })
      const resultMap = converted.reduce((result, item) => {
        const { berzaId, ...rest } = item
        if (!result[berzaId]) result[berzaId] = { measureUnit: [rest] }
        else result[berzaId].measureUnit.push(rest)
        if (item.podrazumevana) setOdnosMera(prev => ({ ...prev, [berzaId]: ratioMap[item.jedinicaMereId] }))
        return result
      }, {})

      const resultMap2 = existingCurrency.reduce((result, item) => {
        const { berzaId, ...rest } = item
        if (!result[berzaId]) result[berzaId] = { ...resultMap[berzaId], productsCurrency: [rest] }
        else result[berzaId].productsCurrency.push(rest)
        return result
      }, {})

      setMultiDataMap(resultMap2)
    }
  }, [measureUnitProduct, existingCurrency, measureUnitRatio])

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) {
      setData(prev => ({ ...prev, ...existingData }))
    }
  }, [id, existingData])

  const getSubmitData = multiData => {
    const measureUnitsList = []
    const currenciesList = []
    for (const [, value] of Object.entries(multiData)) {
      if (value.measureUnit) measureUnitsList.push(...value.measureUnit)
      if (value.productsCurrency) currenciesList.push(...value.productsCurrency)
    }
    const measureUnitNew = measureUnitsList
      .map(e => {
        if (e.odnos) e.odnos = (+e.odnos).toFixed(6)
        return e
      })
      .filter(e => e.jedinicaMereId)
    const reqData = {
      aktivan: data.aktivan,
      naziv: data.naziv,
      kategorijaId: data.kategorijaId,
      tipPodatka: data.tipPodatka || DATA_TYPES.productsFutures,
      connectingTables: {
        ...getDeleteUpdateConectionTbDataJmCurrency(id, {
          existingCurrency,
          newCurrency: currenciesList,
          measureUnitProduct,
          newMeasureUnitProduct: measureUnitNew
        })
      }
    }

    if (data.id) reqData.id = data.id
    return reqData
  }
  const handleSubmit = async () => {
    const reqData = getSubmitData(multiDataMap)
    const isMultiValid = []
    Object.keys(multiDataMap).forEach(berzaKey => {
      Object.keys(multiDataMap[berzaKey]).forEach(key => {
        isMultiValid.push(checkIsMultiValid(key, multiDataMap[berzaKey][key], berzaKey))
      })
    })
    if (validateForm(reqData, { existingCurrency, measureUnitProduct }) && isMultiValid.every(Boolean)) {
      onSubmit(reqData, id)
    } else {
      setError(true)
      toastService.show('warn', 'Morate dodati berzu i podrazumevanu JM za cenu i kolicinu i  podrazumevanu valutu')
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const checkIsMultiValid = (name, data, berza) => {
    const isValidArr = []
    if (requiredMultiMap[name]) {
      data?.forEach(e => {
        Object.keys(requiredMultiMap[name]).forEach(key => {
          if (!e[key]) isValidArr.push(false)
        })
      })
    }
    if (!isValidArr.every(Boolean)) {
      setMultiError(prev => ({ ...prev, [berza]: { ...prev[berza], [name]: true } }))
      return
    } else setMultiError(prev => ({ ...prev, [berza]: { ...prev[berza], [name]: false } }))

    return isValidArr.every(Boolean)
  }

  const changeOdnosMera = (data, index, berzaId) => {
    const temp = cloneDeep(data)
    const odnosMeraTemp = ratioMap[data[index].jedinicaMereId]
    if (temp.length) {
      temp.map((item, i) => {
        if (!item.valutaId) {
          if (i === index) {
            item.odnos = 1
          } else {
            item.odnos = odnosMeraTemp ? Number(odnosMeraTemp[item.jedinicaMereId]) : null
          }
        }
        return item
      })
    }

    setOdnosMera(prev => ({ ...prev, [berzaId]: odnosMeraTemp }))
    return temp
  }

  const setOnlyOneCheckboxToTrue = (temp, currentIndex, dbName, berzaId) => {
    const idJediniceMere = temp[currentIndex].jedinicaMereId
    const odnosMeraTemp = ratioMap[idJediniceMere]
    if (dbName === 'podrazumevana' && odnosMeraTemp) setOdnosMera(prev => ({ ...prev, [berzaId]: odnosMeraTemp }))

    if (temp.length) {
      temp.forEach((item, i) => {
        temp[i][dbName] = i === currentIndex
        if (dbName === 'podrazumevana' && !item.valutaId) {
          if (i === currentIndex) {
            temp[i].odnos = 1
          } else {
            temp[i].odnos = odnosMeraTemp ? Number(odnosMeraTemp[temp[i].jedinicaMereId]) : null
          }
        }
      })
    }
  }

  const onMultipleChange = async (e, index, field, name, berzaId) => {
    const { type, dbName } = field
    let temp = cloneDeep(multiDataMap[berzaId][name])
    if (name === 'measureUnit') {
      const isObjectWithIdTestPresent = multiDataMap[berzaId][name].some(item => item.jedinicaMereId === e.id)
      if (isObjectWithIdTestPresent) return toastService.show('error', 'Ovu jedinicu mere ste vec dodali!')
    }
    if (name === 'productsCurrency') {
      const isObjectWithIdPresent = multiDataMap[berzaId][name].some(item => item.valutaId === e.id)
      if (isObjectWithIdPresent) return toastService.show('error', 'Ovu valutu ste vec dodali!')
    }
    if (type === 'input') {
      const { name, value } = e.target
      temp[index][name] = value.toUpperCase()
    } else if (type === 'select') {
      temp[index][dbName] = e.id
      if (temp.length === 1 && dbName === 'jedinicaMereId') {
        temp[index].odnos = 1
        temp[index].podrazumevana = true
        temp[index].podrazumevanaValutaJedinica = true
        setOdnosMera(prev => ({ ...prev, [berzaId]: ratioMap[temp[index].jedinicaMereId] }))
      } else {
        if (temp[index].podrazumevana && dbName !== 'valutaId') temp = changeOdnosMera(temp, index, berzaId)
        else if (dbName !== 'valutaId') temp[index].odnos = odnosMera[berzaId] ? odnosMera[berzaId][e.id] : null
      }
      if (temp.length === 1 && dbName === 'valutaId') {
        temp[index].podrazumevana = true
      }
    } else if (type === 'checkbox') {
      const { checked } = e.target
      if (checked) setOnlyOneCheckboxToTrue(temp, index, dbName, berzaId)
      else {
        if (temp[index][dbName] && !checked) temp[index][dbName] = true
        else temp[index][dbName] = false
      }
    }
    temp[index].berzaId = berzaId
    setMultiDataMap(prev => ({ ...prev, [berzaId]: { ...prev[berzaId], [name]: temp } }))
  }

  const onAddField = (name, berzaId) => {
    const temp = cloneDeep(multiDataMap[berzaId][name])
    if (temp && !checkIsMultiValid(name, temp, berzaId)) return
    temp.push(name === 'productsCurrency' ? initialCurrencyForProducts : initialMeasureForProducts)
    setMultiDataMap(prev => ({ ...prev, [berzaId]: { ...prev[berzaId], [name]: temp } }))
  }

  const onRemove = (index, item, name, berzaId) => {
    const temp = cloneDeep(multiDataMap[berzaId][name])
    if (temp[index].podrazumevana)
      return toastService.show('warn', `Ne mozete izbrisati podrazumevanu ${name === 'productsCurrency' ? 'valutu' : 'JM za kolicinu'}`)
    if (temp[index].podrazumevanaValutaJedinica) return toastService.show('warn', `Ne mozete izbrisati podrazumevanu JM za cenu`)
    if (temp.length === 1)
      return toastService.show('warn', `Morate imati bar jednu ${name === 'productsCurrency' ? 'valutu' : 'jedinicu mere'}`)

    temp.splice(index, 1)
    const newMulti = { ...multiDataMap, [berzaId]: { ...multiDataMap[berzaId], [name]: temp } }
    const reqData = getSubmitData(newMulti)
    if (item.id) onMultiDelete({ item, data: reqData, name })
    else {
      temp.splice(index, 1)
      setMultiDataMap(prev => ({ ...prev, [berzaId]: { ...prev[berzaId], [name]: temp } }))
    }
  }
  const onCheckboxChange = (e, value) => {
    const cloneMultiFields = cloneDeep(multiDataMap)
    if (e.target.checked) setMultiDataMap(prev => ({ ...prev, [value.id]: initialMultiFields }))
    else {
      if (
        cloneMultiFields[value.id][PRODUCTS_CONNECTION_TB.measureUnit].some(item => item.id) ||
        cloneMultiFields[value.id][PRODUCTS_CONNECTION_TB.productsCurrency].some(item => item.id)
      ) {
        const deleteJm = cloneMultiFields[value.id][PRODUCTS_CONNECTION_TB.measureUnit].map(e => e.id)
        const deleteCurrency = cloneMultiFields[value.id][PRODUCTS_CONNECTION_TB.productsCurrency].map(e => e.id)
        onMultiDelete({ data: { deleteJm, deleteCurrency } })
      } else {
        delete cloneMultiFields[value.id]
        setMultiDataMap(cloneMultiFields)
      }
    }
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.productFuturesInput}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.placeholder}
          globalClass=""
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={categoriesFutures}
          handleChangeSelect={e => setData(prev => ({ ...prev, kategorijaId: e.id }))}
          selectedOption={categoriesFutures.find(el => el.id === data.kategorijaId)}
          customValue="id"
          customLabel="naziv"
          label={label.subGroupFutures}
          errorTxt={error && !data.kategorijaId && errorMsg('kategorija')}
          disabled={disabled}
          isPreview={disabled}
        />
        <h4 className={styles.stylesGroupMutiTitle}>Izaberi valute i JM za berzu</h4>
        {stockExchangeData.length &&
          stockExchangeData.map((berza, i) => (
            <div key={`berza_${berza.id}_${i}`} className={styles.groupFeturesMultifield}>
              <Checkbox
                name={`berza_${berza.id}_${i}`}
                label={berza.naziv}
                checked={multiDataMap[berza.id]}
                onChange={el => onCheckboxChange(el, berza)}
                globalClass="my-2"
                disabled={disabled}
                isPreview={disabled}
              />

              {multiDataMap[berza.id] && (
                <MultipleInputs
                  name={PRODUCTS_CONNECTION_TB.productsCurrency}
                  fields={multiFieldsCurrencyProductInitial}
                  data={multiDataMap?.[berza.id][PRODUCTS_CONNECTION_TB.productsCurrency] || []}
                  onAdd={name => onAddField(name, berza.id)}
                  onRemove={(index, item, name) => onRemove(index, item, name, berza.id)}
                  onChange={(e, index, field, name) => onMultipleChange(e, index, field, name, berza.id)}
                  label={label.dodajValutu}
                  stylesClass="multiTwoFields"
                  globalClass="mt-3"
                  errorRow={multiError[berza.id]}
                  disabled={disabled}
                  isPreview={disabled}
                  keyIndex={i}
                  errorGroup={
                    multiDataMap?.[berza.id]?.[PRODUCTS_CONNECTION_TB.productsCurrency]?.length === 0 && error && errorMsg('valuta', data)
                  }
                />
              )}
              {multiDataMap[berza.id] && (
                <MultipleInputs
                  name={PRODUCTS_CONNECTION_TB.measureUnit}
                  fields={multiFieldsMeasureUnitIntial}
                  data={multiDataMap?.[berza.id][PRODUCTS_CONNECTION_TB.measureUnit] || []}
                  onAdd={name => onAddField(name, berza.id)}
                  onRemove={(index, item, name) => onRemove(index, item, name, berza.id)}
                  onChange={(e, index, field, name) => onMultipleChange(e, index, field, name, berza.id)}
                  label={label.addMeasureUnit}
                  stylesClass="multiFourEl"
                  globalClass="mt-3"
                  errorRow={multiError[berza.id]}
                  disabled={disabled}
                  isPreview={disabled}
                  keyIndex={i}
                  errorGroup={
                    multiDataMap?.[berza.id]?.[PRODUCTS_CONNECTION_TB.measureUnit]?.length === 0 && error && errorMsg('jedinicaMere', data)
                  }
                />
              )}
            </div>
          ))}

        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormProductFutures
