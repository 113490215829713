/** Form for 'Kreiranje izvestaja' */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import Input from 'components/input'
import SelectInput from 'components/selectInput'
import { initialCreateReport } from 'constants/initialValues'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { errCreateReport as errorMsg } from './helpers/errorMsg'
import { validateCreateReport } from './helpers/validate'
import { closeModal } from 'redux/reducers/modalSlice'
import DatePickerComp from 'components/dataPicker'
import { isEmpty } from 'lodash'

const FormCreateReport = ({ onSubmit, typeOfReports = [], selectedReport = {}, period }) => {
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialCreateReport })

  useEffect(() => {
    if (!isEmpty(selectedReport)) {
      setData(prev => ({
        ...prev,
        naziv: period ? selectedReport.naziv + ' za ' + period : selectedReport.naziv,
        description: period ? selectedReport.naziv + ' za ' + period : selectedReport.naziv,
        kod: selectedReport.kod,
        datumUnosa: new Date()
      }))
    }
  }, [selectedReport])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      description: data.description,
      naziv: data.naziv,
      datumUnosa: data.datumUnosa,
      kod: data.kod
    }
    if (validateCreateReport(reqData)) {
      onSubmit(reqData)
      dispatch(closeModal(1))
    } else setError(true)
  }

  const onSelectChange = (e, name) => {
    setData(prev => ({ ...prev, [name]: e?.naziv || null, kod: e.kod }))
  }

  return (
    <div>
      <div>
        <Input
          name="description"
          label={label.title}
          placeholder={label.placeholder}
          value={data.description}
          onChange={e => {
            setData(formData => ({ ...formData, description: e.target.value, naziv: e.target.value }))
          }}
          errorTxt={error && !data.description && errorMsg('description', data)}
        />
        <SelectInput
          options={typeOfReports}
          handleChangeSelect={e => onSelectChange(e, 'naziv')}
          selectedOption={selectedReport}
          customValue="naziv"
          customLabel="naziv"
          label={label.typeOfReport}
          errorTxt={error && !data.naziv && errorMsg('tipIzvestaja')}
          disabled
        />
        <DatePickerComp
          startDate={data.datumUnosa ? new Date(data.datumUnosa) : null}
          label={label.dateOfImportInput}
          errorTxt={error && !data.datumUnosa && errorMsg('datumUnosa', data)}
          disabled={true}
        />

        <div className="ta-r mt-2">
          <Button
            label={label.createReport}
            iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
            onClick={handleSubmit}
            stylesClass="btnWarning"
          />
        </div>
      </div>
    </div>
  )
}

export default FormCreateReport
