/** Queries for 'SlikaObjavaKategorija' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { label } from 'constants/labels'

export const queryGetPagedImageAnnoucmentCategory = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'slikaObjavaKategorija',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.imageAnnoucmentCategory
    }
  }
}

export const queryGetAllImageAnnoucmentCategory = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'slikaObjavaKategorija',
      data: {}
    },
    providesTags: {
      type: tag.imageAnnoucmentCategory
    }
  }
}

export const queryAddImageAnnoucmentCategory = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'slikaObjavaKategorija',
      data: { values: data }
    },
    invalidatesTags: [tag.imageAnnoucmentCategory],
    successMsg: label.successMsgCreate
  }
}

/**
@param {string} id
*/
export const queryUpdateImageAnnoucmentCategory = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'slikaObjavaKategorija',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.imageAnnoucmentCategory],
    successMsg: label.successMsgEdit
  }
}
/**
@param {string} id
*/
export const queryDeleteImageAnnoucmentCategory = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'slikaObjavaKategorija',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.imageAnnoucmentCategory],
    successMsg: label.successMsgDelete
  }
}

export const queryGetAllImageAnnoucmentCategoryByAnnoucmentId = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'slikaObjavaKategorija',
      data: {
        options: {
          where: { objavaId: id }
        }
      }
    },
    providesTags: {
      type: tag.imageAnnoucmentCategory
    }
  }
}

export const queryGetAllImageAnnoucmentCategoryByCategoryId = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'slikaObjavaKategorija',
      data: {
        options: {
          where: { kategorijaId: id }
        }
      }
    },
    providesTags: {
      type: [tag.imageAnnoucmentCategory]
    }
  }
}
