import { label } from 'constants/labels'
import toastService from 'services/toastService'
import { api } from '.'
import { tag } from './rtkTags'
// import { tag } from './rtkTags'

export const fileApiSlice = api.injectEndpoints({
  endpoints: builder => ({
    generatePdf: builder.mutation({
      query: data => ({
        url: `/api/file/pdf`,
        method: 'POST',
        body: {
          mode: 'generatePdf',
          data: { ...data }
        }
      }),
      invalidatesTags: [tag.historyReports]
    }),
    uploadAndSendPdf: builder.mutation({
      query: data => ({
        url: `/api/file/pdf`,
        method: 'POST',
        body: {
          mode: 'uploadAndSendPdf',
          data
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', arg?.successMsg || label.successMsgGeneral)
        } catch (err) {
          console.log(err)
        }
      }
    }),
    sendSinglePdfLink: builder.mutation({
      query: data => ({
        url: `/api/file/pdf`,
        method: 'POST',
        body: {
          mode: 'sendSinglePdfLink',
          data
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', arg?.successMsg || label.successMsgGeneral)
        } catch (err) {
          console.log(err)
        }
      }
    }),
    uploadMedia: builder.mutation({
      query: data => ({
        url: `/api/file/s3`,
        method: 'POST',
        body: {
          mode: 'upload',
          data
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', arg?.successMsg || label.successMsgGeneral)
        } catch (err) {
          console.log(err)
        }
      }
    })
  })
})

export const { useGeneratePdfMutation, useUploadAndSendPdfMutation, useSendSinglePdfLinkMutation, useUploadMediaMutation } = fileApiSlice
