import React from 'react'
import styles from './button.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { getAccessRights } from 'utils'
import { selectCurrentUser } from 'redux/reducers/authSlice'

const IconContent = ({ label, iconLeft, iconRight }) => {
  return (
    <>
      {iconLeft && <div className={styles.iconLeft}>{iconLeft}</div>}
      {label && <span>{label}</span>}
      {iconRight && <div className={styles.iconRight}>{iconRight}</div>}
    </>
  )
}

const Button = ({
  label,
  stylesClass = 'btnPrimary',
  globalClass = '',
  iconRight,
  iconLeft,
  disabled,
  onClick,
  loading,
  accessAction,
  accessCode
}) => {
  const user = useSelector(selectCurrentUser)
  if (accessAction && accessCode && getAccessRights(user, accessCode, accessAction)) return null

  return (
    <>
      {!loading ? (
        <button className={`${styles[stylesClass]} ${globalClass} g-btn`} onClick={onClick} disabled={disabled}>
          <IconContent label={label} iconRight={iconRight} iconLeft={iconLeft} />
        </button>
      ) : (
        <button className={`${styles[stylesClass]} ${styles.spinner} ${globalClass}`} disabled={true}>
          <div>
            <IconContent label={label} iconRight={iconRight} iconLeft={iconLeft} />
          </div>
          <FontAwesomeIcon icon="fa-spinner fa-spin" spin />
        </button>
      )}
    </>
  )
}
export default Button
