import React from 'react'
import styles from './pie.module.scss'
import variables from './../../../styles/variables.scss'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie as PieChart } from 'react-chartjs-2'

const Pie = (
  { info = [42.5, 67.5] },
  labels = ['Dataset 1', 'Dataset 2'],
  titleText = 'Pie grafikon',
  legendPosition = 'top',
  globalClass = ''
) => {
  ChartJS.register(ArcElement, Tooltip, Legend)
  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Votes',
        data: info,
        backgroundColor: [variables.green, variables.red],
        borderColor: variables.white,
        hoverBorderColor: variables.white,
        borderAlign: 'inner',
        hoverOffset: 7,
        borderWidth: 0,
        offset: 5
      }
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: legendPosition
      },
      title: {
        display: true,
        text: titleText
      }
    }
  }

  return (
    <div className={styles.container}>
      <PieChart data={data} options={options} className={`${styles.pie} ${globalClass}`} />
    </div>
  )
}

export default Pie
