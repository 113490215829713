import toastService from 'services/toastService'
import { api } from '.'
import { providesListArr, providesListObj } from './rtkUtils'
import { label } from 'constants/labels'
import { createEntityAdapter } from '@reduxjs/toolkit'
const dataAdapter = createEntityAdapter({ selectId: data => data.id })

const initialState = dataAdapter.getInitialState()
export const dataApiSlice = api.injectEndpoints({
  endpoints: builder => ({
    getAll: builder.query({
      query: newData => {
        const {
          query: { mode, name, type, data, subType }
        } = newData
        return {
          url: `/api/data/${name}/${type}`,
          subType,
          method: 'POST',
          body: {
            mode,
            data
          }
        }
      },
      providesTags: (result, error, arg) => {
        const provideTags = arg.providesTags ? providesListArr(arg.providesTags.type, arg.providesTags.tagTypeId) : []
        return provideTags
      }
    }),
    getAllMap: builder.query({
      query: newData => {
        const {
          query: { mode, name, type, data, subType }
        } = newData
        return {
          url: `/api/data/${name}/${type}`,
          method: 'POST',
          body: {
            mode,
            data,
            subType
          }
        }
      },
      transformResponse: res => {
        return dataAdapter.setAll(initialState, res)
      },
      providesTags: (result, error, arg) => {
        const provideTags = arg.providesTags ? providesListArr(arg.providesTags.type, arg.providesTags.tagTypeId) : []
        return provideTags
      }
    }),
    getOne: builder.query({
      query: newData => {
        const {
          query: { mode, name, type, data }
        } = newData
        return {
          url: `/api/data/${name}/${type}`,
          method: 'POST',
          body: {
            mode,
            data
          }
        }
      },
      providesTags: (result, error, arg) => {
        const provideTags = arg.providesTags ? providesListObj(result, arg.providesTags.type) : []
        return provideTags
      }
    }),

    updateCreateOne: builder.mutation({
      query: newData => {
        const {
          query: { mode, name, type, data, subType }
        } = newData
        return {
          url: `/api/data/${name}/${type}`,
          method: 'POST',
          body: {
            subType,
            mode,
            data
          }
        }
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err && arg?.successMsg) toastService.show('success', arg?.successMsg || label.successMsgGeneral)
        } catch (err) {
          console.log(err)
        }
      },
      invalidatesTags: (res, error, arg) => {
        return arg?.invalidatesTags || []
      }
    }),

    deleteOne: builder.mutation({
      query: newData => {
        const {
          query: { mode, name, type, data, subType }
        } = newData
        return {
          url: `/api/data/${name}/${type}`,
          method: 'POST',
          body: {
            subType,
            mode,
            data
          }
        }
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', arg?.successMsg || label.successMsgGeneral)
        } catch (err) {
          console.log(err)
        }
      },
      invalidatesTags: (res, error, arg) => {
        return arg?.invalidatesTags || []
      }
    })
    // getPage: builder.query({
    //   query: newData => {
    //     const { mode, name, type, data } = newData
    //     return {
    //       url: `/api/data/${name}/${type}`,
    //       method: 'POST',
    //       body: {
    //         mode,
    //         data
    //       }
    //     }
    //   },
    //   providesTags: (result, error, arg) => {
    //     const tags = arg.providesTags
    //       ? providesListPage(result?.rows, arg.providesTags.type, arg.providesTags.tagTypeId)
    //       : []
    //     return tags
    //   }
    // })
  })
})

export const {
  useGetAllQuery,
  useGetAllMapQuery,
  useLazyGetAllQuery,
  useLazyGetAllMapQuery,
  useGetOneQuery,
  useLazyGetOneQuery,
  useUpdateCreateOneMutation,
  useDeleteOneMutation
} = dataApiSlice
