import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom'
import { localStorageService } from 'services/localStorage.service'
import { useGetUserByIdQuery } from 'api/authApiSlice'
import { selectCurrentId, selectCurrentUser } from 'redux/reducers/authSlice'
import Sidebar from 'components/sidebar'
import { useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { queryAddActivity } from 'query/queryActivity'
import { getVisitedPath } from './helpers'
import { selectTriggeredUser, triggerUserApi } from 'redux/reducers/staticSlice'

const Auth = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const entityId = searchParams.get('id')
  const id = useSelector(selectCurrentId)
  const user = useSelector(selectCurrentUser)
  const trigger = useSelector(selectTriggeredUser)
  const [skip, setSkip] = useState(true)
  const [create] = useUpdateCreateOneMutation()

  useGetUserByIdQuery(id, { skip })
  useEffect(() => {
    if ((id && !user) || trigger) setSkip(false)
    if (trigger && skip) dispatch(triggerUserApi(false))
    return () => {
      setSkip(true)
    }
  }, [id, user, trigger])

  useEffect(() => {
    if (location && id) {
      const setActivity = async path => {
        await create(queryAddActivity(id, 'visited', null, { path: path, id: params?.id || entityId }))
      }

      if (location.pathname !== '/aktivnosti-korisnika') {
        const path = getVisitedPath(location)
        if (path) setActivity(path)
      }
    }
  }, [location])

  return localStorageService.isAuth() ? (
    <div className="auth-wrapper">
      <Sidebar />
      <div className="routes-content">
        <section id="dash-section">
          <Outlet />
        </section>
      </div>
    </div>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  )
}
export default Auth
