/** Page  'Report type / Tip izvestaja' */
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormReportType from 'components/forms/formReportType'
import HistoryTable from 'components/historyTable'
import ModalYesNo from 'components/modal/modalYesNo'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { headingReportType } from 'constants/tbHeading'
import { queryAddReportType, queryDeleteReportType, queryGetPagedReportTypes, queryUpdateReportType } from 'query/queryReportType'
import { queryGetAllUsers } from 'query/queryUser'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'

const ReportType = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const { data: reportTypes = [] } = useGetAllQuery(queryGetPagedReportTypes(pageNumber, pageSize))
  const [createData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (reportTypes.rows && reportTypes.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, reportTypes?.rows])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await createData(queryUpdateReportType(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddReportType(data))
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/tip-izvestaja?id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/tip-izvestaja`)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteReportType(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/tip-izvestaja`)
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteBank} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  // const showModal = () => {
  //   navigate(`/tip-izvestaja`)
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: (
  //           <FormWrapper>
  //             <h2>{label.typeOfReport}</h2>
  //             <FormReportType onSubmit={onSubmit} id={null} />
  //           </FormWrapper>
  //         ),
  //         order: 1,
  //         size: 'md'
  //       },
  //       active: 1
  //     })
  //   )
  // }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="tipIzvestaja"
                link="/tip-izvestaja?id="
                connectionTb={[{ rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' }]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.typeOfReport} total={reportTypes?.count}>
        {/* <Button onClick={showModal} label={label.add} iconLeft={<FontAwesomeIcon icon="fa-plus" />} /> */}
      </Title>
      {!!reportTypes?.rows?.length && (
        <h3 className="history-link" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns>
        <Table
          heading={headingReportType}
          data={reportTypes?.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={reportTypes?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormReportType
              existingData={data}
              header={true}
              id={id}
              onSubmit={onSubmit}
              code={code}
              // onDelete={openDeleteModal}
            />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default ReportType
