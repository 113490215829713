/** Form for  add/edit/remove  'Berza' */

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { validateStockExchange as valdateForm } from './helpers/validate'
import { errStockExchange as errorMsg } from './helpers/errorMsg'
import FormHeader from './formHeader'
import { initialStockExchange } from 'constants/initialValues'
import { isObjectEmpty } from 'utils'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { label } from 'constants/labels'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllCountry } from 'query/queryCountry'
import SelectInput from 'components/selectInput'
import { pullConditionalFields } from './helpers/utils'
import { conditionalMapSeaCountryCity } from 'constants/conditionalFields'
import { queryGetAllCurrency } from 'query/queryCurrency'

const FormStockExchange = ({ existingData = {}, id, onSubmit, onDelete, header = false }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialStockExchange })
  const { data: currency = [] } = useGetAllQuery(queryGetAllCurrency())
  const { data: countries = [] } = useGetAllQuery(queryGetAllCountry())
  const [filterMap, setFilterMap] = useState({})

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      naziv: data.naziv,
      aktivan: data.aktivan,
      drzavaId: data.drzavaId,
      valutaId: data.valutaId
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const onSelectChange = async (e, name, conditional) => {
    if (conditional) {
      await getOptions(conditional, e)
    } else {
      setData(prev => ({ ...prev, [name]: e?.id || null }))
    }
  }
  const getOptions = async (mapName, selectValue) => {
    const { newFilterMap, newData } = await pullConditionalFields(mapName, conditionalMapSeaCountryCity, selectValue, data)
    setFilterMap(newFilterMap)
    setData(newData)
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.stockExchange}
            disabled={disabled}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.placeholder}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={countries}
          filter={filterMap?.country}
          handleChangeSelect={e => onSelectChange(e, 'drzavaId', 'drzava')}
          selectedOption={countries.find(el => el.id === data.drzavaId || null)}
          customValue="id"
          customLabel="naziv"
          label={label.countryInput}
          errorTxt={error && !data.drzavaId && errorMsg('drzavaId', { countries: countries })}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={currency}
          handleChangeSelect={e => setData(formData => ({ ...formData, valutaId: e.id }))}
          selectedOption={currency.find(el => el.id === data.valutaId)}
          customValue="id"
          customLabel="kod"
          label={label.currency}
          errorTxt={error && !data.valutaId && errorMsg('valuta')}
          disabled={disabled}
          isPreview={disabled}
        />
        <Checkbox
          name="aktivan"
          label={label.activeInput}
          checked={data.aktivan}
          onChange={e => setData(formData => ({ ...formData, aktivan: e.target.checked }))}
          globalClass="mt-3 "
          disabled={disabled}
          isPreview={disabled}
        />
        {!disabled && (
          <div className="ta-r mt-2">
            <Button
              label={label.save}
              iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
              disabled={disabled}
              onClick={handleSubmit}
              loading={isLoading}
              stylesClass="btnWarning"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FormStockExchange
