import React, { useState } from 'react'
import './pagination.scss'
import 'rc-pagination/assets/index.css'
import Pagination from 'rc-pagination'
import SelectInput from 'components/selectInput'

const PAGE_OPTIONS = ['10', '20', '50', '100'].map(value => ({ value: Number(value), label: value }))

const CustomPagination = ({ data = [], page, pageSize, defaultPageSize = 10, onChange, total }) => {
  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  const NextIcon = () => {
    return <span className="navigation-span">Next</span>
  }

  const PreviousIcon = () => {
    return <span className="navigation-span">Previous</span>
  }

  const onPageSizeChange = option => {
    const pageSize = option.value
    setCurrentPageSize(pageSize)
    onChange(currentPage, pageSize)
  }

  const onPaginationChange = page => {
    setCurrentPage(page)
    onChange(page, currentPageSize)
  }

  return !data || total === 0 || (total < currentPageSize && defaultPageSize === currentPageSize) ? null : (
    <div className="pagination-wrapper">
      <Pagination
        className="pagination-component"
        total={total}
        defaultPageSize={defaultPageSize}
        pageSize={currentPageSize}
        nextIcon={NextIcon}
        prevIcon={PreviousIcon}
        defaultCurrent={1}
        current={currentPage}
        onChange={onPaginationChange}
        showLessItems
      />
      <SelectInput
        selectedOption={PAGE_OPTIONS.find(one => one.value === currentPageSize)}
        options={PAGE_OPTIONS}
        handleChangeSelect={e => onPageSizeChange(e)}
        globalClass="mb-0"
      />
    </div>
  )
}

export default CustomPagination
