/** Page  'Berza Milano' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, OneColumn } from 'components/containers/containers'
import FormAddMultipleWorldStockExchangeMilano from 'components/forms/formAddMultipleFiedls/formAddMultipleWorldStockExchangeMilano'
import MessageNoData from 'components/messageNoData'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { worldStockExchangeMilanoFields } from 'constants/searchFields'
import { headingWorldStockExchangeMilano } from 'constants/tbHeading'
import {
  queryAddWorldStockExchangeMultiple,
  queryDeleteWorldStockExchange,
  queryGetLastInsertWorldStockExchange,
  queryGetPagedWorldStockExchange,
  queryUpdateWorldStockExchange
} from 'query/queryWorldStockExchange'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'

const WorldStockExchangeMilano = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const [lastData, setLastData] = useState([])
  const { data: worldStockExchangeMilano = [] } = useGetAllQuery(
    queryGetPagedWorldStockExchange(
      pageNumber,
      pageSize,
      {
        ...searchOptions,
        where: { isMilano: true }
      },
      true
    )
  )
  const { data: lastInsertWorldStockExchange = [] } = useGetAllQuery(
    queryGetLastInsertWorldStockExchange({ where: { isMilano: true } }, true)
  )

  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [preview, setPreview] = useState('table')

  useEffect(() => {
    if (lastInsertWorldStockExchange) setLastData(lastInsertWorldStockExchange)
  }, [lastInsertWorldStockExchange])

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (worldStockExchangeMilano.rows && worldStockExchangeMilano.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData([])
    }
  }, [id, worldStockExchangeMilano.rows])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(queryUpdateWorldStockExchange(data, id, true))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddWorldStockExchangeMultiple(data, true))
      if (!res.error) {
        dispatch(closeModal(1))
        setPreview('table')
      }
    }
    dispatch(setIsLoadingData(false))
  }

  // const onRowClick = data => {
  //   dispatch(setIsDisabled(true))
  //   navigate(`/svetska-berza-milano?id=${data.id}`)
  //   setPreview('form')
  //   setData(data)
  // }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteWorldStockExchange(data.id, false))
      if (!res.error) dispatch(closeModal(1))
      goBack()
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteWorldStockExchangeMilano} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/svetska-berza-milano`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const goBack = () => {
    if (!data.id) setPreview('table')
    else {
      navigate('/svetska-berza-milano')
      setPreview('table')
    }
  }

  switch (preview) {
    case 'table':
      return (
        <div>
          <Title title={label.wroldStockExchangeMilano} total={worldStockExchangeMilano?.count}>
            <Button
              onClick={() => setPreview('addLast')}
              label={label.lastInsert}
              iconLeft={<FontAwesomeIcon icon="fa-cloud-arrow-up" />}
              accessAction="ADD"
              accessCode={code}
            />
            <Button
              onClick={() => setPreview('add')}
              label={label.add}
              iconLeft={<FontAwesomeIcon icon="fa-plus" />}
              accessAction="ADD"
              accessCode={code}
            />
          </Title>
          <Search fields={worldStockExchangeMilanoFields} onSearch={onSubmitSearch} />
          {/* {!!worldStockExchangeMilano?.rows?.length && (
              <h3 className="history-link" onClick={openHistory}>
                {label.historyChanges}
              </h3>
            )} */}
          <OneColumn classWidth="width5050">
            <Table
              heading={headingWorldStockExchangeMilano}
              data={worldStockExchangeMilano.rows}
              // onRowClick={onRowClick}
              onAction={onActionClick}
              havePagination={true}
              onPaginationChange={onPaginationChange}
              pageNumber={pageNumber}
              pageSize={pageSize}
              total={worldStockExchangeMilano?.count}
            />
          </OneColumn>
        </div>
      )
    case 'addLast':
      return (
        <div>
          <Title title={label.wroldStockExchangeMilano} goBackClick={goBack} globalClass="mb-2" />
          <FormWrapper>
            <FormAddMultipleWorldStockExchangeMilano existingData={lastData} id={id} onSubmit={onSubmit} onDelete={openDeleteModal} />
          </FormWrapper>
        </div>
      )
    case 'add':
      return (
        <div>
          <Title title={label.wroldStockExchangeMilano} goBackClick={goBack} globalClass="mb-2" />
          <FormWrapper>
            <FormAddMultipleWorldStockExchangeMilano existingData={data} id={id} onSubmit={onSubmit} />
          </FormWrapper>
        </div>
      )
    default:
      return <MessageNoData message={label.messageNoData} />
  }
}
export default WorldStockExchangeMilano
