import { useState } from 'react'
import { label } from 'constants/labels'
import InputPassword from 'components/inputPassword/index'
import Button from 'components/button'
import { validateChangeForgotPasswordMail as validateForm } from './helpers/validate'
import { useParams } from 'react-router-dom'
import styles from '../../pages/auth/auth.module.scss'
import { errChangeForgotPassword as errorMsg } from './helpers/errorMsg'

const FormForgotPassword = ({ onSubmit }) => {
  const { forgotToken, email, type } = useParams()
  const [error, setError] = useState(false)
  const [user, setUser] = useState({
    token: forgotToken,
    email: email,
    type: type,
    lozinka: '',
    lozinkaPotvrda: ''
  })

  const handleChange = e => {
    setError(false)
    const { name, value } = e.target
    setUser(userData => ({ ...userData, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm(user)) onSubmit(user)
    else setError(true)
  }

  return (
    <>
      <div>
        <h2>Resetuj lozinku</h2>
        <InputPassword
          className="form-control"
          name="lozinka"
          value={user.lozinka}
          label={label.passwordInput}
          onChange={handleChange}
          placeholder={label.placeholder}
          errorTxt={error && !user.lozinka && errorMsg('lozinka')}
        />
        <InputPassword
          className="form-control"
          name="lozinkaPotvrda"
          value={user.lozinkaPotvrda}
          label={label.passwordConfirm}
          onChange={handleChange}
          placeholder={label.placeholder}
          errorTxt={error && errorMsg('lozinkaPotvrda', user)}
        />
      </div>
      <div className={styles.btn}>
        <Button onClick={e => handleSubmit(e)} label={label.submit} globalClass="mt-2 w-100" />
      </div>
    </>
  )
}

export default FormForgotPassword
