/** Queries for 'User' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialUser } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedUsers = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'korisnik',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          order: [['ime', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.users
    }
  }
}

export const queryGetAllUsers = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'korisnik',
      data: {
        options: {
          ...options,
          order: [['ime', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.users
    }
  }
}

export const queryGetAllUsersMap = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'korisnik',
      data: {}
    },
    providesTags: {
      type: tag.users
    }
  }
}
/**
@param {string} id
*/
export const queryDeleteUser = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'korisnik',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.users],
    successMsg: label.successMsgDelete + ' ' + label.msgUser
  }
}
