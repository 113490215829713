/** Queries for 'JedinicaMereProizvod' */

import { tag } from 'api/rtkTags'
import { label } from 'constants/labels'
import { DATA_TYPES } from 'constants/other'

export const queryGetAllMeasureUnitProducts = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'jedinicaMereProizvod',
      data: { options }
    },
    providesTags: {
      type: tag.measureUnitProduct
    }
  }
}
export const queryGetAllMeasureUnitProductsSearch = options => {
  const query = { tipPodatka: DATA_TYPES.productsSerbia }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'jedinicaMereProizvod_search',
      data: { options }
    },
    providesTags: {
      type: tag.measureUnitProduct
    }
  }
}
/**
@param {string | Array} id
*/
export const queryDeleteMeasureUnitProduct = (id, tip) => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'jedinicaMereProizvod',
      subType: tip,
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.measureUnitProduct],
    successMsg: label.successMsgDelete + ' ' + label.msgProductsMesure
  }
}

export const queryGetAllMeasureUnitProductByProductId = (id, options) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'jedinicaMereProizvod',
      data: {
        options: { ...options, where: { proizvodId: id } }
      }
    },
    providesTags: {
      type: tag.measureUnitProduct
    }
  }
}
