import React, { useCallback, useMemo, useRef, useState } from 'react'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { withHistory } from 'slate-history'
import { Slate, Editable, withReact } from 'slate-react'
import { Element, Leaf } from './elements'
import { withHtml } from './helpers'
import { createEditor } from 'slate'
import styles from './slate.module.scss'

const downloadPdfDocument = rootElementId => {
  const input = document.getElementById(rootElementId)
  html2canvas(input).then(canvas => {
    const imgData = canvas.toDataURL('image/png')
    // eslint-disable-next-line
    const pdf = new jsPDF()
    pdf.addImage(imgData, 'JPEG', 0, 0)
    pdf.save('download.pdf')
  })
}
const SlateEditor = ({ template, classes = {} }) => {
  const [initial] = useState(template)
  const reportTemplateRef = useRef(null)
  const renderElement = useCallback(props => <Element {...props} classes={classes} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHtml(withReact(withHistory(createEditor()))), [])

  function printDiv(divName) {
    const printContents = document.getElementById(divName).innerHTML
    const originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
  }
  return (
    <div className={styles.slateWrapper}>
      <div className="">
        <button onClick={() => printDiv('printContainer')}>Print this page</button>
        <button onClick={() => downloadPdfDocument('printContainer')}>Convert to PDF</button>
      </div>
      <div className={styles.border}>
        <div id="printContainer" ref={reportTemplateRef} className={styles.print}>
          <Slate editor={editor} value={initial}>
            <Editable renderElement={renderElement} renderLeaf={renderLeaf} placeholder="Paste in some HTML..." />
          </Slate>
        </div>
      </div>
    </div>
  )
}

export default SlateEditor
