import Button from 'components/button'
import Checkbox from 'components/checkbox'
import { TwoColumns } from 'components/containers/containers'
import DatePickerComp from 'components/dataPicker'
import Input from 'components/input'
import InputPassword from 'components/inputPassword'
import RadioInput from 'components/radioInput'
import SelectInput from 'components/selectInput'
import SwitchBtn from 'components/switchBtn'
import Table from 'components/table'
import TextArea from 'components/textarea'
import Title from 'components/title'
import { calendarPreviewTimeFormat, dateFormat, timeFormat } from 'constants/date'
import { headingTest, headingTest2, headingTest3 } from 'constants/tbHeading'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import { openModal } from 'redux/reducers/modalSlice'
import { setUploadImages } from 'redux/reducers/staticSlice'
import UploadMedia from 'components/uploadMedia'
import styles from './basic.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Line from 'components/charts/line'
import Pie from 'components/charts/pie'
import { label } from 'constants/labels'
import XLSXImport from 'components/XLSXImport'
import { FIELD_TYPES } from 'constants/other'
import TableTest from 'components/tableTest'

// import { SectionContainer } from '../../components/containers/containers'

const options = [
  { name: 'option1', id: 1, color: '#FF6B00' },
  { name: 'option2', id: 2, color: 'red' },
  { name: 'option3', id: 3, color: 'yellow' },
  { name: 'option4', id: 4, color: 'purple' }
]

const dataTb = [
  {
    firstName: 'Jelena',
    lastName: 'Ivezic',
    age: 36,
    time: new Date(),
    date: new Date(),
    isTrue: false,
    prodImg: [
      'https://www.infotim.rs/site/img/design/android.jpg1',
      'https://www.infotim.rs/oglasi/281/1585891300_2408781.jpg',
      'https://www.infotim.rs/oglasi/269/thumbs/1559739136_1923382.jpg',
      'https://www.infotim.rs/oglasi/269/1559739108_1241016.jpg'
    ]
  },
  {
    firstName: 'Jelena2',
    lastName: 'Ivezic2',
    age: 320,
    time: new Date(),
    date: new Date(),
    isTrue: true,
    prodImg: ['https://www.infotim.rs/oglasi/281/1585891300_2408781.jpg', 'https://www.infotim.rs/oglasi/269/1559739108_1241016.jpg']
  },
  {
    firstName: 'Jelena3',
    lastName: 'Ivezic3',
    age: 8,
    time: new Date(),
    date: new Date(),
    isTrue: false,
    prodImg: ['https://www.infotim.rs/oglasi/269/thumbs/1559739136_1923382.jpg']
  },
  {
    firstName: 'Jelena4',
    lastName: 'Ivezic4',
    age: 8,
    time: new Date(),
    date: new Date(),
    isTrue: false
  },
  {
    firstName: 'Jelena4',
    lastName: 'Ivezic4',
    age: 8,
    time: new Date(),
    date: new Date(),
    isTrue: false,
    prodImg: ['https://www.infotim.rs/oglasi/281/1585891300_2408781.jpg']
  }
]

const customTbFields = {
  name: 'test',
  nameInDb: 'testDB',
  description: 'testDescription',
  fields: [
    { label: 'First name', nameInDb: 'First Name', type: FIELD_TYPES.text },
    { label: 'Last name', nameInDb: 'Last Name', type: FIELD_TYPES.text },
    { label: 'Gender', nameInDb: 'Gender', type: FIELD_TYPES.text },
    { label: 'Country', nameInDb: 'Country', type: FIELD_TYPES.text },
    { label: 'Age', nameInDb: 'Age', type: FIELD_TYPES.integer },
    { label: 'Date format', nameInDb: 'Date', type: FIELD_TYPES.date }
  ],
  headings: [
    { label: 'First name', dbName: 'First Name', type: FIELD_TYPES.text, haveErr: true },
    { label: 'Last name', dbName: 'Last Name', type: FIELD_TYPES.text, haveErr: true },
    { label: 'Gender', dbName: 'Gender', type: FIELD_TYPES.text, haveErr: true },
    { label: 'Country', dbName: 'Country', type: FIELD_TYPES.text, haveErr: true },
    { label: 'Age', dbName: 'Age', type: FIELD_TYPES.integer, haveErr: true },
    { label: 'Date format', dbName: 'Date', type: FIELD_TYPES.date, format: dateFormat, haveErr: true },
    { label: '', dbName: 'actions', type: FIELD_TYPES.actions, actions: ['edit', 'duplicate', 'delete'], isPopup: false }
  ]
}

const CustomOptionSelect = props => {
  const { innerProps, data } = props
  return (
    <div {...innerProps} className={styles.customSelect}>
      <div className={styles.customCircle} style={{ background: data.color || 'grey' }} />
      <span>{data.name}</span>
    </div>
  )
}
const SingleValue = props => {
  const { data } = props
  return (
    <components.SingleValue {...props}>
      <div className={styles.customSelect}>
        <div className={styles.customCircle} style={{ background: data.color || 'grey' }} />
        <span>{data.name}</span>
      </div>
    </components.SingleValue>
  )
}

const BasicComponents = () => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [testCheck, setTestCheck] = useState(true)
  const [testRadio, setTestRadio] = useState('option1')
  const [testSwitch, setTestSwitch] = useState(true)
  const [selected, setSelected] = useState(options[1])
  const [time, setTime] = useState()
  const [date, setDate] = useState()
  const images = useSelector(state => state.static.images)

  const showModal = () => {
    dispatch(openModal({ arrItem: { content: <div>Test modal</div>, order: 1, size: 'sm' }, active: 1 }))
  }

  const onSwitchChange = checked => {
    setTestSwitch(checked)
  }

  const onSelectChange = e => {
    setSelected(e)
  }

  const onDateChange = date => {
    const formatted = date ? dayjs(date).format(dateFormat) : ''
    setDate(formatted)
  }

  const onDateTimeChange = date => {
    const formatted = date ? dayjs(date).format(timeFormat) : ''
    setTime(formatted)
  }

  const onImageChange = images => {
    dispatch(setUploadImages(images))
  }
  const onTableAction = (type, data) => {
    console.log('onTableAction', type, data)
  }
  const onRowClick = data => {
    console.log('onRowClick', data)
  }

  return (
    <div className="bg-secondary br-xs">
      <Title title={'Pravno lice Srbija'} desc={'Lorem ipsum,Lorem ipsumLorem ipsum'}>
        <Button onClick={showModal} label={label.add} stylesClass="btnSecondary" iconLeft={<FontAwesomeIcon icon="fa-plus" />} />
        <Button onClick={showModal} label={label.test} />
        <Button onClick={showModal} label={label.light} stylesClass="btnLight" />
      </Title>
      <TwoColumns>
        <div>
          <div>
            <h2>Icons</h2>
            <p>Import icons in file components/icons </p>
            <h4>
              Search icons
              <a className="c-bright" href="https://fontawesome.com/search?m=free&o=r" target="_blank" rel="noreferrer">
                HERE
              </a>
            </h4>
            <h3>
              <FontAwesomeIcon icon="fa-dashboard" /> <small>"FontAwesomeIcon icon="fa-dashboard"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-gavel" /> <small>"FontAwesomeIcon icon="fa-gavel"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-dashboard" /> <small>"FontAwesomeIcon icon="fa-dashboard"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-money-bill-wheat" /> <small>"FontAwesomeIcon icon="fa-money-bill-wheat"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-circle-user" />
              <small>"FontAwesomeIcon icon="fa-circle-user"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-bars" /> <small>"FontAwesomeIcon icon="fa-bars"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-close" /> <small>"FontAwesomeIcon icon="fa-close"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-sign-in" /> <small>"FontAwesomeIcon icon="fa-sign-in"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-sign-out" /> <small>"FontAwesomeIcon icon="fa-sign-out"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-user" /> <small>"FontAwesomeIcon icon="fa-user"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-sliders" /> <small>"FontAwesomeIcon icon="fa-sliders"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-plus" />
              <small>"FontAwesomeIcon icon="fa-plus"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-floppy-disk" />
              <small>"FontAwesomeIcon icon="fa-floppy-disk"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-chevron-up" />
              <small>"FontAwesomeIcon icon="fa-chevron-up"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-chevron-down" />
              <small>"FontAwesomeIcon icon="fa-chevron-down"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-chevron-left" />
              <small>"FontAwesomeIcon icon="fa-chevron-left"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-chevron-right" />
              <small>"FontAwesomeIcon icon="fa-chevron-right"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-ellipsis" />
              <small>"FontAwesomeIcon icon="fa-ellipsis"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-eye" />
              <small>"FontAwesomeIcon icon="fa-eye"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-pen-to-square" />
              <small>"FontAwesomeIcon icon="fa-pen-to-square"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-trash-can" />
              <small>"FontAwesomeIcon icon="fa-trash-can"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-clone" />
              <small>"FontAwesomeIcon icon="fa-clone"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-circle-xmark" />
              <small> "FontAwesomeIcon icon="fa-circle-xmark"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-cloud-arrow-up" />
              <small> "FontAwesomeIcon icon="fa-cloud-arrow-up"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-calendar-days" />
              <small> "FontAwesomeIcon icon="fa-calendar-days"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-eye-slash" />
              <small> "FontAwesomeIcon icon="fa-eye-slash"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-eye" />
              <small> "FontAwesomeIcon icon="fa-eye-slash"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-file-csv" />
              <small> "FontAwesomeIcon icon="fa-file-csv"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-envelope" />
              <small> "FontAwesomeIcon icon="fa-envelope"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-mobile" />
              <small> "FontAwesomeIcon icon="fa-mobile"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-mobile-screen" />
              <small> "FontAwesomeIcon icon="fa-mobile-screen"</small>
            </h3>
          </div>
          <Button onClick={showModal} label={label.openModal} stylesClass="btnSecondary" />
          <br />
          <Input name="testInput" label="test input" errorTxt="Error message" placeholder={label.placeholder} />
          <Input name="testInput" label="test input" placeholder={label.placeholder} globalClass="mb-1" />
          <br />
          <Input name="testInput" label="test input" stylesClass="inputLabelNext" />
          <br />
          <Button label="test button" globalClass=" w-100" />
          <br />
          <Button label="test button" stylesClass="btnSecondary" iconRight={<i className="icon-right-arrow"></i>} />
          <br />
          <TextArea label="test Textarea" rows={10} name="testtextarea" globalClass="mt-3" />
          <br />
          <InputPassword label={label.passwordInput} value={password} onChange={e => setPassword(e.target.value)} name="password" />
          <br />
          <Checkbox name="testCheckbox" label="Test checkbox" checked={testCheck} onChange={e => setTestCheck(e.target.checked)} />
          <br />
          <div style={{ background: 'white', padding: '20px' }}>
            <RadioInput name="testRadio" label="Test checkbox" checked={testRadio === 'option1'} onChange={() => setTestRadio('option1')} />
            <br />
            <RadioInput name="testRadio" label="Test checkbox" checked={testRadio === 'option2'} onChange={() => setTestRadio('option2')} />
            <br />
            <SwitchBtn onChange={onSwitchChange} checked={testSwitch} />
            <br />
            <SwitchBtn onChange={onSwitchChange} checked={testSwitch} label="test switch" globalClass="pl-3" />
          </div>
          <br />
          <SelectInput
            options={options}
            handleChangeSelect={onSelectChange}
            selectedOption={options.find(e => e.id === selected.id) || null}
            customLabel="name"
            label="Test select"
          />
          <br />
          <SelectInput
            options={options}
            handleChangeSelect={onSelectChange}
            selectedOption={options.find(e => e.id === selected.id) || null}
            customLabel="name"
            label="Test select"
            additionalClass="selectLabelNext"
            customComponents={{ Option: CustomOptionSelect, SingleValue: SingleValue }}
          />
          <DatePickerComp
            startDate={date ? new Date(date) : null}
            onChange={onDateChange}
            label={label.dateInput}
            errorTxt={'Date is required!!'}

            // showTime={true}
          />
          <DatePickerComp
            startDate={time ? new Date(time) : null}
            onChange={onDateTimeChange}
            label={label.dateInput}
            errorTxt={'Date is required!!'}
            showTimeSelectOnly={true}
            showTime={true}
            stylesClass={'timePicker'}
            format={calendarPreviewTimeFormat}
            placeholder={calendarPreviewTimeFormat}
          />
        </div>
        <div className={styles.table}>
          <UploadMedia onImageChange={onImageChange} images={images} label="Dodajte slike" />
          <Table
            heading={headingTest3}
            data={dataTb}
            colSpan={3}
            name="users"
            globalClass="mb-5"
            onAction={onTableAction}
            onClick={onRowClick}
          />

          <Table
            heading={headingTest2}
            data={dataTb}
            colSpan={3}
            name="users"
            globalClass="mb-5"
            onAction={onTableAction}
            onRowClick={onRowClick}
          />
          <Line globalClass="mb-5" />
          <Pie globalClass="mb-5" />
        </div>
      </TwoColumns>
      <Table heading={headingTest} data={dataTb} colSpan={3} name="users2I" onAction={onTableAction} onRowClick={onRowClick} />
      <Table
        heading={headingTest2}
        data={dataTb}
        colSpan={3}
        name="users"
        globalClass="mb-5"
        tableWidth="initial"
        columnWidth="auto"
        onAction={onTableAction}
        onRowClick={onRowClick}
      />

      <Table
        heading={headingTest}
        data={dataTb}
        colSpan={3}
        tableWidth="initial"
        columnWidth="auto"
        name="users1"
        stylesClass="bordered"
        globalClass="mb-5"
        onAction={onTableAction}
        onRowClick={onRowClick}
      />
      <XLSXImport dataSource={customTbFields} />

      <TableTest />
    </div>
  )
}

export default BasicComponents
