import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { BoxContainer } from 'components/containers/containers'
import { useSelector } from 'react-redux'
import { selectCurrentId, selectCurrentUser } from 'redux/reducers/authSlice'
import { monthDay } from 'constants/date'
import styles from './dashboard.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { queryGetAllContactsTimeByUserId } from 'query/queryClientConectionTables'
import { useLazyGetAllQuery } from 'api/dataApiSlice'
import Table from 'components/table'
import { label } from 'constants/labels'
import { FIELD_TYPES } from 'constants/other'
import { getCurrentTime } from 'utils'

const Dashboard = () => {
  const [upcomingContacts, setUpcomingContacts] = useState([])
  const user = useSelector(selectCurrentUser)
  const id = useSelector(selectCurrentId)
  const [getUpcomingContacts] = useLazyGetAllQuery()

  useEffect(() => {
    const getTimeOfContacts = async () => {
      const res = await getUpcomingContacts(
        queryGetAllContactsTimeByUserId({
          where: { korisnikId: id },
          sqlType: {
            GTE: { key: 'datumKontakta', value: getCurrentTime(new Date()) }
          }
        })
      )
      if (!res.error) setUpcomingContacts(res.data)
    }
    if (id) getTimeOfContacts()
  }, [id])

  return (
    <BoxContainer>
      <div className={styles.wrapper}>
        <div className={styles.heading}>
          <h1>
            <FontAwesomeIcon icon="fa-list-check" />
            <span>{user?.ime}</span>
          </h1>
          <b>{dayjs(new Date()).format(monthDay)}</b>
        </div>
        <div>
          <Table
            data={upcomingContacts}
            globalClass="mt-3"
            heading={[
              { dbName: 'datumKontakta', label: label.contactReasonNextInput, type: FIELD_TYPES.date },
              { dbName: 'klijent', label: label.client, type: FIELD_TYPES.text, nestedObj: 'naziv' },
              { dbName: 'vrstaKontaktum', label: label.contactReasonInput, type: FIELD_TYPES.text, nestedObj: 'naziv' }
            ]}
          />
        </div>
      </div>
    </BoxContainer>
  )
}

export default Dashboard
