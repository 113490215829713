import React, { useEffect, useState } from 'react'
import styles from './disappearMsg.module.scss'

const DisappearMsg = ({ text, stylesClass = 'defaultSmall', reset }) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
      reset()
    }, 2000)

    return () => clearTimeout(timeId)
  }, [])

  // If show is false the component will return null and stop here
  if (!show) return null

  // If show is true this will be returned
  return (
    <div className={`${styles.wrapper} ${styles[stylesClass]} `}>
      <h6>{text}</h6>
    </div>
  )
}
export default DisappearMsg
