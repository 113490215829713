/** Form for  add/edit/remove  'Blog' */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import Input from 'components/input'
import SelectInput from 'components/selectInput'
import { initialBlog } from 'constants/initialValues'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isObjectEmpty } from 'utils'
import { errBlog as errorMsg } from './helpers/errorMsg'
import { validateBlog as valdateForm } from './helpers/validate'
import { FormGroup, OneColumn } from 'components/containers/containers'
import Wysiwyg from 'components/reactquill'
import { BLOGS_STATUS } from '../../constants/staticOptions'

const FormBlog = ({ existingData = {}, id, onSubmit }) => {
  const isLoading = useSelector(state => state.static.isLoading)
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialBlog })
  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      aktivan: data.aktivan,
      naslov: data.naslov,
      podnaslov: data.podnaslov,
      tekst: data.tekst,
      blogStatus: data.blogStatus
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    if (e && e.target) {
      const { name, value } = e.target
      setData(formData => ({ ...formData, [name]: value }))
    } else {
      setData(formData => ({ ...formData, tekst: e }))
    }
  }

  const onSelectChange = async (e, name) => {
    setData(prev => ({ ...prev, [name]: e?.id || null }))
  }

  return (
    <div>
      <div>
        <OneColumn columnClass="width50">
          <FormGroup label={label.groupFormTitleBasic}>
            <Input
              name="naslov"
              label={label.blogTitle}
              placeholder={label.placeholder}
              value={data.naslov}
              onChange={handleChange}
              errorTxt={error && !data.naslov && errorMsg('naslov', data)}
              // disabled={disabled}
              // isPreview={disabled}
            />
            <Input
              name="podnaslov"
              label={label.blogSubtitle}
              placeholder={label.placeholder}
              value={data.podnaslov}
              onChange={handleChange}
              errorTxt={error && !data.podnaslov && errorMsg('podnaslov', data)}
              // disabled={disabled}
              // isPreview={disabled}
            />
            <SelectInput
              options={BLOGS_STATUS || []}
              handleChangeSelect={e => onSelectChange(e, 'blogStatus')}
              selectedOption={BLOGS_STATUS.find(el => el.id === data.blogStatus || null)}
              customValue="value"
              customLabel="label"
              label={label.statusInput}
              errorTxt={error && !data.blogStatus && errorMsg('blogStatus', data)}
              // disabled={disabled}
              // isPreview={disabled}
            />
          </FormGroup>
        </OneColumn>
        <h3 className="mt-3 c-warning t-upper">{label.blogText}</h3>
        <Wysiwyg
          name="tekst"
          // label={label.blogText}
          placeholder={label.placeholder}
          value={data.tekst}
          onChange={handleChange}
          errorTxt={error && !data.tekst && errorMsg('tekst', data)}
          // disabled={disabled}
          // isPreview={disabled}
          globalClass="mt-1"
        />
        <div className="ta-r mt-2">
          <Button
            label={label.save}
            iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
            // disabled={disabled}
            onClick={handleSubmit}
            loading={isLoading}
            stylesClass="btnWarning"
          />
        </div>
      </div>
    </div>
  )
}

export default FormBlog
