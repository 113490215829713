import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from './phoneInput.module.scss'
import ErrorMessage from 'components/errorMessage'

const PhoneInputComp = ({
  onChange,
  value,
  label,
  placeholder = '381 640 000 000',
  disabled = false,
  isPreview = false,
  errorTxt,
  globalClass = '',
  stylesClass = 'defaultPhone'
  // preferred = ['rs', 'ba', 'mn', 'hr', 'hy', 'mk', 'sl', 'bg', 'it', 'fr', 'ch', 'de', 'it']
}) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass} ${isPreview && styles.preview} g-phone-input`}>
      <label>{label}</label>
      <PhoneInput
        // regions={'europe'}
        defaultMask={'.. ... ....'}
        alwaysDefaultMask={true}
        // autoFormat={false}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        inputClass="custom-phone-input"
        containerClass="custom-phone-container"
        // preferredCountries={preferred}
        disableDropdown={true}
      />
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}
export default PhoneInputComp
