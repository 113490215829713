import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import { label as text } from 'constants/labels'
import styles from './formHeader.module.scss'
const FormHeader = ({
  onEdit,
  onSave,
  onDelete,
  title = '',
  // label = '',
  disabled,
  component,
  stylesClass = 'defaultFormHeader',
  globalClass = '',
  systematic = '',
  code
}) => {
  return (
    <div className={`${styles.wrapper} ${styles[stylesClass]} ${globalClass} g-formHeader`}>
      {!systematic && (
        <div className={styles.btnsGroup}>
          {disabled ? (
            <Button
              label={text.edit}
              stylesClass="btnSecondary"
              iconLeft={<FontAwesomeIcon icon="fa-pen-to-square" />}
              onClick={onEdit}
              accessAction="UPDATE"
              accessCode={code}
            />
          ) : (
            <Button
              label={text.save}
              iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
              stylesClass="btnWarning"
              onClick={onSave}
              accessAction="UPDATE"
              accessCode={code}
            />
          )}
          {onDelete && (
            <Button
              label={text.delete}
              stylesClass="btnLight"
              iconLeft={<FontAwesomeIcon icon="fa-trash-can" />}
              onClick={onDelete}
              accessAction="DELETE"
              accessCode={code}
            />
          )}
        </div>
      )}
      <div>
        <h2>
          <span>
            {/* {label} */}
            <small>{title}</small>
          </span>
          {component && <div className={styles.component}>{component}</div>}
        </h2>
      </div>
    </div>
  )
}

export default FormHeader
