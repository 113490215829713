/** Form for  change password by admin for 'User' */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import { label } from 'constants/labels'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { errMsgFormUser } from './helpers/errorMsg'
import InputPassword from 'components/inputPassword'

const FormAdminChangePassword = ({ id, onSubmit }) => {
  const isLoading = useSelector(state => state.static.isLoading)
  const [error, setError] = useState(false)
  const [data, setData] = useState({ lozinka: '', id })

  const handleSubmit = async e => {
    e.preventDefault()
    if (data.lozinka) {
      onSubmit(data, id)
    } else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(prev => ({ ...prev, [name]: value }))
  }

  return (
    <div>
      <h3 className="">{label.forgotPasswordTitle}</h3>
      <InputPassword
        name="lozinka"
        label={label.forgotPasswordInput}
        placeholder={label.placeholder}
        value={data.lozinka}
        onChange={handleChange}
        errorTxt={error && !data.lozinka && errMsgFormUser('lozinka', data)}
        type="password"
        disabled={isLoading}
      />
      <div className="ta-r mt-2">
        <Button
          label={label.save}
          iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
          disabled={isLoading}
          onClick={handleSubmit}
          loading={isLoading}
          stylesClass="btnWarning"
        />
      </div>
    </div>
  )
}

export default FormAdminChangePassword
