// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced
// export const providesList = (results, tagType, tagTypeId = null, isArray) => {
//   if (tagTypeId) {
//     return results
//       ? [{ type: tagType, id: tagTypeId }, ...results.map(({ _id }) => ({ type: tagType, id: _id }))]
//       : [{ type: tagType, id: tagTypeId }]
//   } else {
//     return [tagType, ...results.map(({ _id }) => ({ type: tagType, id: _id }))]
//     return [{ type: tagType }]
//   }

//   //  return results ? [{ type: tagType, id: results._id }] : [{ type: tagType }]

// }

export const providesListPage = (results, tagType, tagTypeId = null) => {
  if (tagTypeId) {
    return results
      ? [{ type: tagType, id: tagTypeId }, ...results.map(({ _id }) => ({ type: tagType, id: _id }))]
      : [{ type: tagType, id: tagTypeId }]
  } else {
    return [tagType, ...results.map(({ _id }) => ({ type: tagType, id: _id }))]
  }
}

export const providesListArr = (tagType, tagTypeId = null) => {
  if (tagTypeId) {
    return [{ type: tagType, id: tagTypeId }]
  } else {
    return [tagType]
  }
}

export const providesListObj = (result, tagType) => {
  return [{ type: tagType, id: result._id }]
}
