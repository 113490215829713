// /** Form for  add/edit/remove  'Pravna lica' */

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery } from 'api/dataApiSlice'
import Button from 'components/button'
import SelectInput from 'components/selectInput'
import {
  initialConnectCelebration,
  initialConnectTbActivity,
  initialConnectTbContacts,
  initialConnectTbTimeOfContacts,
  initialClient,
  multiFieldsContactsInitial
} from 'constants/initialValues'
import { label } from 'constants/labels'
import { queryGetAllEntityTypes } from 'query/queryEntityType'
import { queryGetAllProducts } from 'query/queryProducts'
import { queryGetAllBanks } from 'query/queryBank'
import { queryGetAllCity } from 'query/queryCity'
import { queryGetAllCountry } from 'query/queryCountry'
import { queryGetAllRegion } from 'query/queryRegion'
import { queryGetAllTownship } from 'query/queryTownship'
import { queryGetAllUsers } from 'query/queryUser'
// import { setIsDisabled } from 'redux/reducers/staticSlice'
import cloneDeep from 'lodash/cloneDeep'
import { getCurrentTime, isObjectEmpty } from 'utils'
import { validateClientsLegal, validateClientsFarm } from '../helpers/validate'
import { queryGetAllWorkField } from 'query/queryWorkField'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import ModalYesNo from 'components/modal/modalYesNo'
import {
  queryDeleteActivityById,
  queryDeleteCelebrationsById,
  queryDeleteContactsById,
  queryGetAllActivityByClientId,
  queryGetAllCelebrations,
  queryGetAllContactsByClientId,
  queryGetAllContactsTimeByClientId,
  queryGetAllProductsByClientId
} from 'query/queryClientConectionTables'

import { selectCurrentId } from 'redux/reducers/authSlice'
import { CLIENT_CONNECTION_TB, GOODS_TYPE } from 'constants/other'
import { pullConditionalFields, getDeleteUpdateConectionTbData } from '../helpers/utils'
import FormClientsLegal from './formClientsLegal'
import FormClientsFarm from './formClientsFarm'
import toastService from 'services/toastService'
import styles from './forms.module.scss'
import { conditionalMapClient } from 'constants/conditionalFields'
import { FormRow } from 'components/containers/containers'
import { queryGetAllEntityStatus } from 'query/queryEntityStatus'

const multiDataInitial = {
  [CLIENT_CONNECTION_TB.activity]: { ...initialConnectTbActivity },
  [CLIENT_CONNECTION_TB.contact]: { ...initialConnectTbContacts },
  [CLIENT_CONNECTION_TB.timeOfContacts]: { ...initialConnectTbTimeOfContacts },
  [CLIENT_CONNECTION_TB.clientCelebration]: { ...initialConnectCelebration }
}

const FormClients = ({ existingData = {}, id, onSubmit, goBack }) => {
  const dispatch = useDispatch()
  const userid = useSelector(selectCurrentId)
  const isLoading = useSelector(state => state.static.isLoading)
  const [error, setError] = useState(true)
  const [data, setData] = useState({ ...initialClient, korisnikId: !id ? userid : null })
  const [productsSell, setProductsSell] = useState([])
  const [productsBuy, setProductsBuy] = useState([])
  const [filterMap, setFilterMap] = useState({})
  const [isDomestic, setIsDomestic] = useState(true)
  const [multiError, setMultiError] = useState({})
  const [multiDataList, setMultiDataList] = useState({
    [CLIENT_CONNECTION_TB.activity]: [],
    [CLIENT_CONNECTION_TB.contact]: [],
    [CLIENT_CONNECTION_TB.timeOfContacts]: [],
    [CLIENT_CONNECTION_TB.clientCelebration]: []
  })
  const requiredMultiMap = useSelector(state => state.static.multipleRequiredFields)
  const [newMultiConatctInitial, setNewMultiConatctInitial] = useState(multiFieldsContactsInitial)
  const { data: entityTypes = [] } = useGetAllQuery(queryGetAllEntityTypes())
  const { data: countries = [] } = useGetAllQuery(queryGetAllCountry())
  const { data: cities = [] } = useGetAllQuery(queryGetAllCity())
  const { data: region = [] } = useGetAllQuery(queryGetAllRegion())
  const { data: township = [] } = useGetAllQuery(queryGetAllTownship())
  const { data: products = [] } = useGetAllQuery(queryGetAllProducts())
  const { data: salesSpecialist = [] } = useGetAllQuery(queryGetAllUsers())
  const { data: bank = [] } = useGetAllQuery(queryGetAllBanks())
  const { data: workFieldData = [] } = useGetAllQuery(queryGetAllWorkField())
  const { data: entityStatus = [] } = useGetAllQuery(queryGetAllEntityStatus())
  const { data: clientActivityData = [] } = useGetAllQuery(queryGetAllActivityByClientId(existingData.id), { skip: !existingData.id })
  const { data: clientContactData = [] } = useGetAllQuery(queryGetAllContactsByClientId({ where: { klijentId: existingData.id } }), {
    skip: !existingData.id
  })
  const { data: clientTimeContactData = [] } = useGetAllQuery(queryGetAllContactsTimeByClientId(existingData.id), {
    skip: !existingData.id
  })
  // const { data: remarkData = [] } = useGetAllQuery(queryGetAllRemarkByClientId(existingData.id), { skip: !existingData.id })

  const { data: clientCelebrationsData = [] } = useGetAllQuery(queryGetAllCelebrations(existingData.id), {
    skip: !existingData.id
  })

  const { data: clientProductsBuyData = [] } = useGetAllQuery(queryGetAllProductsByClientId(existingData.id, 'kupovina'), {
    skip: !existingData.id
  })
  const { data: clientProductsSellData = [] } = useGetAllQuery(queryGetAllProductsByClientId(existingData.id, 'prodaja'), {
    skip: !existingData.id
  })
  const [deleteOne] = useDeleteOneMutation()

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) {
      setData({ ...existingData })
      const foundDrzava = countries?.find(el => existingData.drzavaId === el.id && el.naziv?.toLocaleLowerCase().includes('srbija'))
      if (foundDrzava) setIsDomestic(true)
      else setIsDomestic(false)
    }
  }, [id, existingData])

  useEffect(() => {
    if (clientActivityData && existingData.id) setMultiDataList(prev => ({ ...prev, [CLIENT_CONNECTION_TB.activity]: clientActivityData }))
  }, [clientActivityData])

  useEffect(() => {
    if (clientTimeContactData && existingData.id)
      setMultiDataList(prev => ({ ...prev, [CLIENT_CONNECTION_TB.timeOfContacts]: clientTimeContactData }))
  }, [clientTimeContactData])

  useEffect(() => {
    if (clientContactData && existingData.id) setMultiDataList(prev => ({ ...prev, [CLIENT_CONNECTION_TB.contact]: clientContactData }))
  }, [clientContactData])

  useEffect(() => {
    if (clientCelebrationsData && existingData.id) {
      setMultiDataList(prev => ({ ...prev, [CLIENT_CONNECTION_TB.clientCelebration]: clientCelebrationsData }))
    }
  }, [clientCelebrationsData])

  useEffect(() => {
    if (clientProductsBuyData && existingData.id) setProductsBuy(clientProductsBuyData)
  }, [clientProductsBuyData])

  useEffect(() => {
    if (clientProductsSellData && existingData.id) setProductsSell(clientProductsSellData)
  }, [clientProductsSellData])

  const handleSubmit = async e => {
    e.preventDefault()
    let temp = {}
    const clientActivityremoveFilter = multiDataList[CLIENT_CONNECTION_TB.activity].map(e => {
      if ('filter' in e) delete e.filter
      return e
    })
    if (id) {
      temp = getDeleteUpdateConectionTbData(
        data,
        clientActivityremoveFilter,
        clientActivityData,
        multiDataList[CLIENT_CONNECTION_TB.contact],
        clientContactData,
        multiDataList[CLIENT_CONNECTION_TB.timeOfContacts],
        clientTimeContactData,
        clientProductsBuyData,
        productsBuy,
        clientProductsSellData,
        productsSell,
        multiDataList[CLIENT_CONNECTION_TB.clientCelebration],
        clientCelebrationsData
        // remark
      )
    } else {
      temp = {
        // ...rest,
        ...data,
        connectingTables: {
          clientActivity: clientActivityremoveFilter,
          clientContacts: multiDataList[CLIENT_CONNECTION_TB.contact],
          clientTimeOfContacts: multiDataList[CLIENT_CONNECTION_TB.timeOfContacts],
          productsBuy,
          productsSell,
          clientCelebrations: multiDataList[CLIENT_CONNECTION_TB.clientCelebration]
          // remark
        }
      }
    }
    //  setData(temp)
    const connectionTbExisting = {
      clientActivityData,
      clientContactData,
      clientTimeContactData,
      clientProductsBuyData,
      clientProductsSellData
    }
    const isMultiValid = []
    Object.keys(multiDataList).forEach(key => {
      isMultiValid.push(checkIsMultiValid(key, multiDataList[key]))
    })

    const isValid =
      data.tipLicaSifra === 'GZ'
        ? validateClientsFarm(temp, connectionTbExisting, isDomestic)
        : validateClientsLegal(temp, connectionTbExisting, isDomestic)
    if (isValid && isMultiValid.every(Boolean)) {
      onSubmit(temp, id)
      // setData(prev => ({ ...prev, connectingTables: [] }))
    } else {
      toastService.show('error', label.errRequiredAll)
      setError(true)
    }
  }
  const handleChange = e => {
    let { name, value, type } = e.target
    if (type === 'number' && !value) value = null
    setData(prev => ({ ...prev, [name]: value }))
  }

  const handleCheckboxChange = e => {
    const { name, checked } = e.target
    setData(formData => ({ ...formData, [name]: checked ?? null }))
  }

  const handlePhoneChange = (type, number) => {
    const phoneNum = number.startsWith('+') ? number : '+' + number
    setData(formData => ({ ...formData, [type]: phoneNum || null }))
  }

  const getOptions = async (mapName, selectValue, data) => {
    const { newFilterMap, newData } = await pullConditionalFields(mapName, conditionalMapClient, selectValue, data)
    setFilterMap(newFilterMap)
    setData(newData)
    // setData(prev => ({ ...prev, ...newData }))
  }

  const handleSelectChange = async (e, name, conditional, isMultiselect, valueKey = 'id') => {
    if (isMultiselect) {
      if (name === CLIENT_CONNECTION_TB.productsBuy) {
        const newGoods = e.map(x => {
          return { proizvodId: x.id, tip: GOODS_TYPE.buy }
        })
        setProductsBuy(newGoods)
      } else if (name === CLIENT_CONNECTION_TB.productsSell) {
        const newGoods = e.map(x => {
          return { proizvodId: x.id, tip: GOODS_TYPE.sell }
        })
        setProductsSell(newGoods)
      }
    } else {
      const tempData = { ...data }
      if (name === 'drzavaId') {
        // automatically add prefix to phone number when creating client
        setIsDomestic(e.naziv.toLocaleLowerCase().includes('srbija'))
        if (!id) {
          tempData.kontaktTelefon = e?.pozivniBroj
          tempData.direktorTelefon = e?.pozivniBroj
        }
      }
      const tempMultiPhone = newMultiConatctInitial.map(el => {
        if (el.type === 'phone') el.defaultValue = e?.pozivniBroj
        return el
      })
      setNewMultiConatctInitial(tempMultiPhone)
      if (conditional) {
        await getOptions(conditional, e, tempData)
      } else {
        setData(prev => ({ ...prev, [name]: e[valueKey] }))
      }
    }
  }
  const handleSelectType = async value => {
    setData(prev => ({ ...prev, tipLicaSifra: value.sifra, tipLicaId: value.id }))
  }
  // const onRemarkChange = message => {
  //   setData(prev => ({ ...prev, remark: message }))
  // }

  // const onRemarkEdit = async data => {
  //   const res = await updateOne(queryUpdateRemark({ poruka: data.poruka }, data.id))
  //   if (!res.error) dispatch(closeModal(1))
  // }

  const onMultipleChange = async (e, index, field, name) => {
    const { type, dbName, collection, conditional } = field
    const multiList = cloneDeep(multiDataList)
    const temp = multiList[name]
    if (type === 'input') {
      const { name, value } = e.target
      temp[index][name] = value
    } else if (type === 'checkbox') {
      const { name, checked } = e.target
      temp[index][name] = checked
    } else if (type === 'select') {
      if (conditional) {
        const { newFilterMap, newData } = await pullConditionalFields(collection, conditional, e, temp[index])
        temp[index] = newData
        temp[index].filter = newFilterMap
      } else {
        temp[index][dbName] = e.id
      }
    } else if (type === 'datapicker') {
      temp[index][dbName] = e ? getCurrentTime(e) : null
    } else if (type === 'noyeardatapicker') {
      temp[index][dbName] = e ? getCurrentTime(e) : null
    } else if (type === 'phone') {
      const phoneNum = e.startsWith('+') ? e : '+' + e
      temp[index][dbName] = phoneNum
    }
    setMultiDataList(multiList)
  }

  const checkIsMultiValid = (name, data) => {
    const isValidArr = []
    if (requiredMultiMap[name]) {
      data.forEach(e => {
        Object.keys(requiredMultiMap[name]).forEach(key => {
          if (!e[key]) isValidArr.push(false)
        })
      })
    }
    if (!isValidArr.every(Boolean)) {
      setMultiError(prev => ({ ...prev, [name]: true }))
      return
    } else {
      setMultiError(prev => ({ ...prev, [name]: false }))
    }
    return isValidArr.every(Boolean)
  }

  const onAddField = name => {
    const temp = cloneDeep(multiDataList[name])
    if (!checkIsMultiValid(name, temp)) return
    const foundDrzava = countries?.find(el => el.id === data.drzavaId)
    const tempMultiPhone = newMultiConatctInitial.map(e => {
      if (e.type === 'phone') e.defaultValue = foundDrzava?.pozivniBroj
      return e
    })
    setNewMultiConatctInitial(tempMultiPhone)

    temp.push(multiDataInitial[name])
    setMultiDataList(prev => ({ ...prev, [name]: temp }))
  }

  const handleDelete = async (answer, data, name) => {
    let res = {}
    if (answer) {
      if (name === CLIENT_CONNECTION_TB.activity) {
        res = await deleteOne(queryDeleteActivityById(data.id))
      } else if (name === CLIENT_CONNECTION_TB.contact) {
        res = await deleteOne(queryDeleteContactsById(data.id))
      } else if (name === CLIENT_CONNECTION_TB.clientCelebration) {
        res = await deleteOne(queryDeleteCelebrationsById(data.id))
      }
      if (!res.error) dispatch(closeModal(1))
    } else {
      dispatch(closeModal(1))
    }
  }

  const onRemoveMultiselect = (index, item, name) => {
    if (item.id) {
      dispatch(
        openModal({
          arrItem: {
            content: <ModalYesNo handleSubmit={(e, data) => handleDelete(e, data, name)} data={item} question={label.masgDeleteAClient} />,
            order: 1,
            size: 'sm'
          },
          active: 1
        })
      )
    } else {
      const temp = cloneDeep(multiDataList[name])
      temp.splice(index, 1)
      setMultiDataList(prev => ({ ...prev, [name]: temp }))
    }
  }
  return (
    <div className={styles.clientForm}>
      <div>
        {!(data.tipLicaSifra && data.drzavaId) ? (
          <div className={styles.topForm}>
            <div className={styles.entityType}>
              <SelectInput
                options={entityTypes}
                handleChangeSelect={e => handleSelectType(e)}
                selectedOption={entityTypes.find(el => el.id === data.tipLicaId)}
                customLabel="naziv"
                customValue="tipLicaSifra"
                label={label.typeEntityInput}
                stylesClass="selectLabelNext"
                globalClass="mb-3"
                // errorTxt={error && !data.tipLicaSifra && label.typeEntityErr}
              />
              <SelectInput
                options={countries || []}
                filter={filterMap?.country}
                handleChangeSelect={e => handleSelectChange(e, 'drzavaId', 'drzava')}
                selectedOption={countries.find(el => el.id === data.drzavaId) || null}
                customLabel="naziv"
                customValue="id"
                label={label.countryInput}
                stylesClass="selectLabelNext"
                // errorTxt={error && !data.drzavaId && label.countryErr}
              />
            </div>
          </div>
        ) : (
          !data.id && (
            <FormRow>
              <h1> {entityTypes?.find(e => e.sifra === data.tipLicaSifra)?.naziv}</h1>
              <Button
                label={label.save}
                iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
                stylesClass="btnWarning"
                onClick={handleSubmit}
                globalClass="my-2"
              />
            </FormRow>
          )
        )}
        {id && (
          <div className={goBack ? styles.goBack : styles.btnGroups}>
            {goBack && (
              <b onClick={goBack}>
                <FontAwesomeIcon icon="fa-chevron-left" />
              </b>
            )}
            {!goBack && (
              <Button
                label={label.save}
                iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
                stylesClass="btnWarning"
                onClick={handleSubmit}
                globalClass="mb-2"
              />
            )}
          </div>
        )}
        <div>
          {data.tipLicaSifra && data.drzavaId && (
            <>
              {data.tipLicaSifra === 'GZ' ? (
                <FormClientsFarm
                  data={data}
                  handleSelectChange={handleSelectChange}
                  handleChange={handleChange}
                  handleCheckboxChange={handleCheckboxChange}
                  handlePhoneChange={handlePhoneChange}
                  onAddField={onAddField}
                  onMultipleChange={onMultipleChange}
                  onRemoveMultiselect={onRemoveMultiselect}
                  // onRemarkChange={onRemarkChange}
                  // onRemarkEdit={onRemarkEdit}
                  // remarkList={remarkData}
                  error={error}
                  multiErrorRow={multiError}
                  filterMap={filterMap}
                  bank={bank}
                  products={products}
                  countries={countries}
                  cities={cities}
                  region={region}
                  township={township}
                  workFieldData={workFieldData}
                  salesSpecialist={salesSpecialist}
                  productsBuy={productsBuy}
                  productsSell={productsSell}
                  isDomestic={isDomestic}
                  multiFieldsContactsInitial={newMultiConatctInitial}
                  multiDataList={multiDataList}
                  entityStatus={entityStatus}
                />
              ) : (
                <FormClientsLegal
                  data={data}
                  handleSelectChange={handleSelectChange}
                  handleChange={handleChange}
                  handleCheckboxChange={handleCheckboxChange}
                  handlePhoneChange={handlePhoneChange}
                  onAddField={onAddField}
                  onMultipleChange={onMultipleChange}
                  onRemoveMultiselect={onRemoveMultiselect}
                  // onRemarkChange={onRemarkChange}
                  // onRemarkEdit={onRemarkEdit}
                  // remarkList={remarkData}
                  error={error}
                  multiErrorRow={multiError}
                  filterMap={filterMap}
                  bank={bank}
                  products={products}
                  countries={countries}
                  cities={cities}
                  region={region}
                  township={township}
                  workFieldData={workFieldData}
                  salesSpecialist={salesSpecialist}
                  productsBuy={productsBuy}
                  productsSell={productsSell}
                  isDomestic={isDomestic}
                  multiFieldsContactsInitial={newMultiConatctInitial}
                  multiDataList={multiDataList}
                  entityStatus={entityStatus}
                />
              )}

              <div className={styles.btnGroups}>
                <Button
                  label={label.save}
                  iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
                  disabled={isLoading}
                  onClick={handleSubmit}
                  loading={isLoading}
                  stylesClass="btnWarning"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FormClients
