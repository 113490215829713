import { useEffect, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-multi-date-picker'
import { setDeliveryDate } from 'utils'
import ErrorMessage from '../errorMessage/index'
import styles from './dataPicker.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const currentDate = new Date()
const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)

const MultiDatePicker = ({
  stylesClass = 'defaultMultiPicker',
  globalClass = '',
  name,
  label,
  errorTxt,
  disabled = false,
  isPreview,
  onChange,
  value,
  customValue
}) => {
  const [customInput, setCustomInput] = useState()

  useEffect(() => {
    if (value) {
      setCustomInput(value)
    }
  }, [value])

  const triggerOnChange = (date, name) => {
    onChange && onChange(date, name)
    const formattedDates = setDeliveryDate(date)
    setCustomInput(formattedDates)
  }

  const CustomDatePickerInput = ({ openCalendar }) => {
    const inputRef = useRef()
    const handleInputClick = () => {
      openCalendar()
    }
    return (
      <div className={styles.customInput}>
        <input ref={inputRef} value={customInput} onClick={handleInputClick} readOnly />
        <FontAwesomeIcon
          icon="fa-close"
          onClick={() => {
            onChange(null, name)
            setCustomInput('')
          }}
        />
      </div>
    )
  }

  return (
    <div className={`${styles[stylesClass]} ${globalClass} ${disabled && styles.disabled}   ${isPreview && styles.preview} g-multi-picker`}>
      {label && <label htmlFor={name}>{label}</label>}
      <DatePicker
        value={customValue || value}
        onChange={date => triggerOnChange(date, name)}
        multiple
        minDate={firstDayOfMonth}
        onlyMonthPicker
        format={'YYYY-MMM'}
        className={styles.picker}
        disabled={disabled}
        render={<CustomDatePickerInput />}
      />
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

export default MultiDatePicker
