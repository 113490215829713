import { createSlice } from '@reduxjs/toolkit'

const socketSlice = createSlice({
  name: 'socket',
  initialState: { events: {} },
  reducers: {
    setSocket: (state, action) => {
      const { type, data } = action.payload
      state.events = { ...state.events, [type]: data }
    }
  }
})

export const { setSocket } = socketSlice.actions

export default socketSlice.reducer
