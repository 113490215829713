import React, { useState } from 'react'
import styles from './previewImages.module.scss'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/reducers/modalSlice'
import noImage from 'assets/images/icons/noImg.jpg'
import Image from 'components/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SingleImg = ({ src, i, imgs }) => {
  const [active, setActive] = useState(src)
  const [index, setIndex] = useState(i)
  const length = imgs.length

  const changeImages = type => {
    if (type === 'prev') {
      setActive(imgs[index - 1])
      setIndex(index - 1)
    } else {
      setActive(imgs[index + 1])
      setIndex(index + 1)
    }
  }

  return (
    <div className={styles.singleImg}>
      {length > 1 && index !== 0 && (
        <div className={styles.prev} onClick={() => changeImages('prev')}>
          <FontAwesomeIcon icon="fa-chevron-left" />
        </div>
      )}
      <Image src={active} />
      {length > 1 && index + 1 !== length && (
        <div className={styles.next} onClick={() => changeImages('next')}>
          <FontAwesomeIcon icon="fa-chevron-right" />
        </div>
      )}
    </div>
  )
}

const ImagesModal = ({ src, imgs = [] }) => {
  const dispatch = useDispatch()
  const showImage = (src, index) => {
    dispatch(
      openModal({
        arrItem: { content: <SingleImg src={src} i={index} imgs={imgs} />, order: 2, size: 'vh-100' },
        active: 2
      })
    )
  }
  return (
    <div className={styles.imagesModal}>
      {imgs.length > 1 ? (
        <div className={styles.grid}>
          {imgs.map((e, i) => (
            <div key={`imgList_${i}`} className={styles.imgBox}>
              <Image src={e} onClick={() => showImage(e, i)} />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.oneImage}>
          <Image src={src} />
        </div>
      )}
    </div>
  )
}

const PreviewImages = ({ imgs }) => {
  const dispatch = useDispatch()
  const length = imgs && imgs.length

  const showImages = e => {
    e.stopPropagation()
    dispatch(
      openModal({
        arrItem: { content: <ImagesModal src={imgs[0]} imgs={imgs} />, order: 1, size: length > 1 ? 'lg' : 'vh-100' },
        active: 1
      })
    )
  }

  return (
    <div className={styles.preview}>
      {imgs && imgs.length ? (
        <div onClick={showImages} className={styles.img}>
          <Image src={imgs[0]} />
        </div>
      ) : (
        <img src={noImage} className={styles.noImg} />
      )}
    </div>
  )
}
export default PreviewImages
