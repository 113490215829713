/** Queries for 'Svetska luka view' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
export const queryGetPagedWorldSeaPortSearch = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'svetskaLuka_search',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.worldSeaPortSerach
    }
  }
}

export const queryGetAllWorldSeaPortSearch = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'svetskaLuka_search',
      data: {
        options: {
          ...options,
          distinct: true
        }
      }
    },
    providesTags: {
      type: tag.worldSeaPortSerach
    }
  }
}
