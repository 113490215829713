import Table from 'components/table'
import { label } from 'constants/labels'
import React from 'react'
import styles from './announcment.module.scss'

const AnnouncementTables = ({
  announcementsSupply,
  announcementsDemand,
  onRowClick,
  onPaginationChange,
  pageNumber,
  pageSize,
  heading
}) => {
  return (
    <div className={styles.tables}>
      <div>
        <h4>{label.bidSupply}</h4>
        <Table
          heading={heading}
          data={announcementsSupply?.rows || []}
          onRowClick={onRowClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={announcementsSupply?.count}
        />
      </div>
      <div>
        <h4>{label.bidDemand}</h4>
        <Table
          heading={heading}
          data={announcementsDemand?.rows || []}
          onRowClick={onRowClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={announcementsDemand?.count}
        />
      </div>
    </div>
  )
}

export default AnnouncementTables
