/** Queries for 'Organizacija' */
import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialOrganizationField } from 'constants/initialValues'
import { label } from 'constants/labels'

/**
@param {string} id
*/
export const queryGetOrganization = id => {
  return {
    query: {
      name: 'data',
      mode: 'get',
      type: 'organizacija',
      data: {
        options: {
          where: {
            id
          }
        }
      }
    },
    providesTags: {
      type: tag.organization
    }
  }
}

/**
* @param {initialOrganizationField} data
@param {string} id
*/
export const queryUpdateOrganization = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'organizacija',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.organization],
    successMsg: label.successMsgEdit + ' ' + label.msgOrganization
  }
}
