/** Page  'Proizvodi Luka' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useLazyGetOneQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormProductFutures from 'components/forms/formProductFutures'
import HistoryTable from 'components/historyTable'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { DATA_TYPES, PAGE_SIZE } from 'constants/other'
import { productCommonFields } from 'constants/searchFields'
import { headingProductFutures } from 'constants/tbHeading'
import { queryGetAllCategoriesFutures } from 'query/queryCategoryFutures'
import { queryGetAllCurrency } from 'query/queryCurrency'
import { queryDeleteProductCurrency } from 'query/queryCurrencyProducts'
import { queryGetAllMeasureUnits } from 'query/queryMeasureUnit'
import { queryDeleteMeasureUnitProduct } from 'query/queryMeasureUnitProduct'
import {
  queryAddProductFutures,
  queryDeleteProductFutures,
  queryGetAllProductsFutures,
  queryGetPagedProductsFutures,
  queryUpdateProductFutures,
  queryGetProduct
} from 'query/queryProductsFutures'
import { queryGetAllStockExchange } from 'query/queryStockExchange'
import { queryGetAllUsers } from 'query/queryUser'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'

const ProductsFutures = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: productsFutures = [] } = useGetAllQuery(queryGetPagedProductsFutures(pageNumber, pageSize, searchOptions))
  const [createData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [getOne] = useLazyGetOneQuery()

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const fetchOne = async id => {
        try {
          const res = await getOne(queryGetProduct(id)).unwrap()
          setData(res)
        } catch (e) {
          console.log(e)
        }
      }
      const found = productsFutures.rows && productsFutures.rows.find(e => e.id === +id)
      if (found) setData(found)
      else fetchOne(id)
    } else {
      setData({})
    }
  }, [id, productsFutures])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await createData(queryUpdateProductFutures(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddProductFutures(data))
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/proizvod?tip=futures&&id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/proizvod?tip=futures`)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteProductFutures(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/proizvod?tip=futures`)
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteSeaPortProduct} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  const showModal = () => {
    navigate(`/proizvod?tip=futures`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.addFuturesProduct}</h2>
              <FormProductFutures onSubmit={onSubmit} id={null} />
            </FormWrapper>
          ),
          order: 1,
          size: 'lmg'
        },
        active: 1
      })
    )
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="proizvod"
                type={DATA_TYPES.productsFutures}
                link="/proizvod?tip=futures&&id="
                connectionTb={[
                  { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
                  { rtkQuery: queryGetAllMeasureUnits(), dbName: 'jedinicaMereId' },
                  { rtkQuery: queryGetAllCurrency(), dbName: 'valutaId' },
                  { rtkQuery: queryGetAllStockExchange(), dbName: 'berzaId' },
                  { rtkQuery: queryGetAllCategoriesFutures(), dbName: 'kategorijaId' },
                  { rtkQuery: queryGetAllProductsFutures(), dbName: 'proizvodId' }
                ]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }

  const handleMultiDelete = async (answer, data) => {
    if (data.item) {
      if (answer && data.name === 'measureUnit') {
        const res = await deleteOne(queryDeleteMeasureUnitProduct(data.item.id, DATA_TYPES.productsFutures))
        if (!res.error) {
          onSubmit(data.data, id)
          dispatch(closeModal(1))
        }
      } else if (answer && data && data.name === 'productsCurrency') {
        const res = await deleteOne(queryDeleteProductCurrency(data.item.id, DATA_TYPES.productsFutures))
        if (!res.error) {
          onSubmit(data.data, id)
          dispatch(closeModal(1))
        }
      } else {
        dispatch(closeModal(1))
      }
    } else {
      if (answer) {
        if (data.data?.deleteCurrency.length) {
          await deleteOne(queryDeleteProductCurrency(data.data.deleteCurrency, DATA_TYPES.productsFutures))
        }
        if (data.data?.deleteJm.length) {
          await deleteOne(queryDeleteMeasureUnitProduct(data.data.deleteJm, DATA_TYPES.productsFutures))
        }
      }
      dispatch(closeModal(1))
    }
  }

  const openDeleteMultiModal = data => {
    const question = data.item
      ? data.name === 'productsCurrency'
        ? label.msgDeleteCurrency
        : label.msgDeleteMeasureUnit
      : label.msgDeleteDependentTb
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleMultiDelete} data={data} question={question} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  return (
    <div>
      <Title title={label.productFuturesInput} total={productsFutures?.count}>
        <Button onClick={showModal} label={label.add} iconLeft={<FontAwesomeIcon icon="fa-plus" />} accessAction="ADD" accessCode={code} />
      </Title>
      <Search fields={productCommonFields} onSearch={onSubmitSearch} />
      {!!productsFutures?.rows?.length && (
        <h3 className="history-link" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns stylesClass="width4060">
        <Table
          heading={headingProductFutures}
          data={productsFutures?.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={productsFutures?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormProductFutures
              existingData={data}
              header={true}
              id={id}
              onSubmit={onSubmit}
              onDelete={openDeleteModal}
              onMultiDelete={openDeleteMultiModal}
              code={code}
            />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default ProductsFutures
