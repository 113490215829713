import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import styles from './uploadMedia.module.scss'

import { getMediaFromFileOrString } from 'utils'

const getFiles = files => {
  const images = []
  Object.keys(files).forEach(key => {
    images.push(files[key])
  })
  return images
}

const UploadMedia = ({
  stylesClass = 'uploadMedia',
  globalClass = '',
  acceptedFileType = 'image/png, image/gif, image/jpeg, image/jpg,image/svg+xml',
  onImageChange,
  images = [],
  label = {},
  removable = true,
  removeUploadedImage,
  onImagePreview,
  disabled = false
}) => {
  const ref = useRef(null)
  const [dragActive, setDragActive] = useState(false)
  const existingImages = useSelector(state => state.static.images)

  const onSelectedFile = e => {
    const files = e.target.files
    if (!files || files.length === 0) return
    const imgs = getFiles(files)
    onImageChange([...existingImages, ...imgs])
  }

  const handleDrag = e => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    const files = e.dataTransfer.files
    if (files && files[0]) {
      const imgs = getFiles(files)
      onImageChange([...existingImages, ...imgs])
    }
  }

  const removeImages = index => {
    if (removeUploadedImage) {
      removeUploadedImage(index)
    } else {
      const imgs = existingImages.filter((e, i) => i !== index)
      onImageChange(imgs)
    }
  }

  return (
    <div className={`${styles[stylesClass]} ${globalClass} g-uploadMedia`}>
      <div className={styles.header}>
        <h3 className="mb-05">{label}</h3>
        <p>PNG,JPG,GIF i SVG su formati koje podrzavamo</p>
      </div>
      <input disabled={disabled} ref={ref} type="file" accept={acceptedFileType} onChange={onSelectedFile} multiple="multiple" />
      <div
        className={`${styles.box} ${dragActive ? styles.dragActive : disabled ? styles.disabled : ''}`}
        onClick={() => ref.current.click()}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <FontAwesomeIcon icon="fa-cloud-arrow-up" />
        <h4>Prevucite ili izaberite vase slike</h4>
      </div>
      <div className={styles.images}>
        {images.length > 0 &&
          images.map((img, i) => (
            <div key={i} className={styles.img}>
              <img src={getMediaFromFileOrString(img)} onClick={() => onImagePreview(i)} />
              {removable && <FontAwesomeIcon icon="fa-circle-xmark" onClick={() => removeImages(i)} />}
            </div>
          ))}
      </div>
    </div>
  )
}

UploadMedia.propTypes = {
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  type: PropTypes.string,
  acceptedFileType: PropTypes.object,
  onImageChange: PropTypes.func,
  images: PropTypes.array
}

export default UploadMedia
