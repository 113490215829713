import Tabs from 'components/tabs'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Products from './products'
import ProductsBosnia from './productsBosnia'
import ProductsFutures from './productsFutures'
import ProductsPort from './productsPort'
import ProductsMilano from './productsMilano'

export const tabs = [
  {
    label: 'Proizvod Srbija',
    type: 'srbija'
  },
  {
    label: 'Proizvod luka',
    type: 'luka'
  },

  {
    label: 'Proizvod futures',
    type: 'futures'
  },
  {
    label: 'Proizvod Milano',
    type: 'milano'
  },
  {
    label: 'Proizvod Bosna',
    type: 'bih'
  }
]

const ProductTabs = ({ code }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const category = searchParams.get('tip')
  const id = searchParams.get('id')
  const [activeTab, setActiveTab] = useState(category)

  useEffect(() => {
    if (category) setActiveTab(category)
  }, [category])

  const navigateTo = (type, id) => {
    const link = id ? `/proizvod?tip=${type}&&id=${id}` : `/proizvod?tip=${type}`
    navigate(link)
  }
  const onTabChange = async tab => {
    setActiveTab(tab.type)
    if (tab !== activeTab) navigateTo(tab.type)
    else navigateTo(tab.type, id)
  }
  return (
    <div>
      <Tabs tabs={tabs} active={activeTab} onClick={tab => onTabChange(tab)} stylesClass="horizontalTabs" globalClass="mb-3" />
      {activeTab === 'srbija' && <Products code={code} />}
      {activeTab === 'bih' && <ProductsBosnia code={code} />}
      {activeTab === 'futures' && <ProductsFutures code={code} />}
      {activeTab === 'luka' && <ProductsPort code={code} />}
      {activeTab === 'milano' && <ProductsMilano code={code} />}
    </div>
  )
}
export default ProductTabs
