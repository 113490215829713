/** Page  'Activities' */

import { useGetAllQuery } from 'api/dataApiSlice'
import ActivityTable from 'components/activityTable'
import { OneColumn } from 'components/containers/containers'
import Search from 'components/search'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { activitiClientFields } from 'constants/searchFields'
import { headingActivitiesClient } from 'constants/tbHeading'
import { queryGetPagedActivities } from 'query/queryActivity'
import { useState } from 'react'

const ClientActivities = () => {
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: activities = {} } = useGetAllQuery(
    queryGetPagedActivities(pageNumber, pageSize, { ...searchOptions, where: { tip: 'klijent' } }, 'klijent')
  )
  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  return (
    <div>
      <Title title={label.activitiesClients} total={activities?.count} />
      <Search fields={activitiClientFields} onSearch={onSubmitSearch} />
      <OneColumn>
        <ActivityTable
          data={activities}
          heading={headingActivitiesClient}
          type={'klijent'}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
        />
      </OneColumn>
    </div>
  )
}
export default ClientActivities
