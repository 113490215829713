/** Queries for 'Report type/Tip izvestaja' */

import { tag } from 'api/rtkTags'
import { label } from 'constants/labels'

export const queryGetPagedReportTypes = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'tipIzvestaja',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.reportTypes
    }
  }
}

export const queryGetAllReportTypes = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'tipIzvestaja',
      data: {
        options: {
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.reportTypes
    }
  }
}
/**
 * @param {Object} data
 * * @param {String} data.naziv
 * * @param {String} data.id
 */

export const queryAddReportType = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'tipIzvestaja',
      data: { values: data }
    },
    invalidatesTags: [tag.reportTypes],
    successMsg: label.successMsgCreate + ' ' + label.msgReportType
  }
}

/**
 * @param {Object} data
 * * @param {String} data.naziv
 * * @param {String} data.id
 * @param {String} id
 *
 */

export const queryUpdateReportType = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'tipIzvestaja',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.reportTypes],
    successMsg: label.successMsgEdit + ' ' + label.msgReportType
  }
}
/**
@param {string} id
*/
export const queryDeleteReportType = id => {
  return {
    query: {
      name: 'data',
      mode: 'tipIzvestaja',
      type: 'banka',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.reportTypes],
    successMsg: label.successMsgDelete + ' ' + label.msgReportType
  }
}
