import React from 'react'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import 'react-quill/dist/quill.snow.css'
import styles from './reactquill.module.scss'
import ErrorMessage from '../errorMessage'

const Wysiwyg = ({
  onChange,
  stylesClass = 'wysiwygDefault',
  globalClass = '',
  name,
  label,
  value,
  placeholder,
  disabled = false,
  errorTxt,
  isPreview = false
}) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass} ${isPreview && styles.preview} g-textarea`}>
      {label && <label htmlFor={name}>{label}</label>}
      <ReactQuill theme="snow" id={name} name={name} value={value} placeholder={placeholder} onChange={onChange} readOnly={disabled} />
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

Wysiwyg.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default Wysiwyg
