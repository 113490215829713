import Button from 'components/button'
import React, { useRef, useState } from 'react'
import styles from './xlsxImport.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import { FIELD_TYPES } from 'constants/other'
import { dateFormat } from 'constants/date'
import { openModal } from 'redux/reducers/modalSlice'
import { setData } from 'redux/reducers/dataSlice'
import Table from 'components/table'
import FormXLSXExample from 'components/forms/formXLSXExample'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const XLSXImport = ({ dataSource, customGoodsImport = false, handleFileUpload }) => {
  dayjs.extend(customParseFormat)
  const dispatch = useDispatch()
  const inputFile = useRef(null)
  const temp = useSelector(state => state.data.setData)
  const [tempData, setTempData] = useState(temp)
  const [fileExist, setFileExist] = useState('')

  const handleUpload = e => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setFileExist(file)
      importFile(file)
    }
  }

  const fileUpload = data => {
    handleFileUpload && handleFileUpload({ importovaneRobe: data?.importovaneRobe, header: data?.header })
  }

  const importFile = file => {
    const reader = new FileReader()
    reader.onload = () => {
      const arrayBuffer = reader.result
      const data = new Uint8Array(arrayBuffer)
      const arr = new Array()
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i])
      }
      const bstr = arr.join('')
      const workbook = XLSX.read(bstr, { type: 'binary' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]

      const dataNew = XLSX.utils.sheet_to_json(worksheet, { raw: true })
      const header = customGoodsImport ? XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0] : ''

      const temp = []
      dataNew.forEach(element => {
        if (customGoodsImport) {
          const values = Object.values(element)
          const shouldSkip = values.some(value => typeof value === 'string' && (value === '*' || value.includes('---')))
          if (!shouldSkip) {
            temp.push(element)
          }
        } else {
          dataSource.fields.forEach(field => parseFieldValue(element, field))
          temp.push(element)
        }
      })

      if (customGoodsImport) {
        const dataWithoutHeader = temp.slice(1)
        let lastValue
        const modifiedData = dataWithoutHeader.map(row => {
          if (header in row) {
            lastValue = row[header]
          } else {
            row[header] = lastValue
          }
          return row
        })

        const importedGoods = modifiedData.map(element => {
          return {
            kod: element[header],
            zemlja: element.__EMPTY,
            netoMasaUKg: element.__EMPTY_1,
            vrednostEur: element.__EMPTY_2
          }
        })

        importedGoods.shift()

        setTempData(importedGoods)
        fileUpload({ importovaneRobe: importedGoods, header })
      } else {
        setTempData(temp)
        dispatch(setData(temp))
      }
    }
    reader.readAsArrayBuffer(file)
  }

  const parseFieldValue = (object, field) => {
    const dbName = field.nameInDb
    const value = object[field.name] || object[dbName]
    if (value === undefined || value === null) {
      return ''
    }
    if (field.type === FIELD_TYPES.date) {
      if (typeof value === 'string') {
        object[dbName] = dayjs(value).format(dateFormat)
      } else if (value < 1.1) {
        // time
        object[dbName] = dayjs(86400000 * value - 3600000).format(dateFormat)
      } else {
        // date
        object[dbName] = dayjs('01/01/1900')
          .add(value - 2, 'day')
          .format(dateFormat)
      }
    }
  }

  const onRemove = () => {
    setFileExist('')
  }

  const onFileClick = () => inputFile.current.click()

  const onAction = (data, type) => {
    console.log(type, data, 'data iz on action')
    dispatch(openModal({ arrItem: { content: <FormXLSXExample receivedData={data} />, order: 1, size: 'sm' }, active: 1 }))
  }

  return (
    <div className={styles.importWrapper}>
      <div className="mb-5">
        <div className="ta-c">
          <div className="ta-c ">
            <h3 className="mb-05">Uvezi datoteku iz Excel-a</h3>
            <p>.xls, .xlsx i .ods su formati koji su dozvoljeni</p>
          </div>
          <input
            type="file"
            name="file"
            accept={'.xlsx, .xls,.ods'}
            ref={inputFile}
            className={styles.file}
            onChange={handleUpload}
            onClick={e => (e.target.value = null)}
          />
          <Button
            btnClass="btnGrey"
            label="Izaberi datoteku"
            iconLeft={<i className="fa fa-download" aria-hidden="true"></i>}
            onClick={onFileClick}
            disabled={fileExist}
          />
        </div>
        <div className={styles.importedFile}>
          {fileExist && customGoodsImport && (
            <div className={styles.swichFile}>
              {<FontAwesomeIcon icon="fa-file-csv" />}
              <div className={styles.removeSvg}>{<FontAwesomeIcon icon="fa-circle-xmark" onClick={onRemove} />}</div>
            </div>
          )}
        </div>
      </div>
      {!customGoodsImport && dataSource?.headings && tempData && tempData.length ? (
        <div className={styles.table}>
          <Table
            heading={dataSource?.headings}
            columnWidth={`${100 / Object.keys(dataSource?.headings).length}%`}
            data={tempData}
            onAction={onAction}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default XLSXImport
