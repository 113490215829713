/** Page  'Svetske berze' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, OneColumn } from 'components/containers/containers'
import FormAddMultipleWorldStockExchange from 'components/forms/formAddMultipleFiedls/formAddMultipleWorldStockExchange'
// import HistoryTable from 'components/historyTable'
import MessageNoData from 'components/messageNoData'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { worldStockExchangeFields } from 'constants/searchFields'
import { headingWorldStockExchange } from 'constants/tbHeading'
import {
  queryAddWorldStockExchangeMultiple,
  queryDeleteWorldStockExchange,
  queryGetLastInsertWorldStockExchange,
  queryGetPagedWorldStockExchange,
  queryUpdateWorldStockExchange
} from 'query/queryWorldStockExchange'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'

const WorldStockExchange = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const [lastData, setLastData] = useState([])
  const { data: worldStockExchange = [] } = useGetAllQuery(
    queryGetPagedWorldStockExchange(
      pageNumber,
      pageSize,
      {
        ...searchOptions,
        where: { isMilano: false }
      },
      false
    )
  )
  const { data: lastInsertWorldStockExchange = [] } = useGetAllQuery(
    queryGetLastInsertWorldStockExchange({ where: { isMilano: false } }, false)
  )

  const [createData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [preview, setPreview] = useState('table')

  useEffect(() => {
    if (lastInsertWorldStockExchange) setLastData(lastInsertWorldStockExchange)
  }, [lastInsertWorldStockExchange])

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (worldStockExchange.rows && worldStockExchange.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, worldStockExchange.rows])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await createData(queryUpdateWorldStockExchange(data, id, false))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddWorldStockExchangeMultiple(data, false))
      if (!res.error) {
        dispatch(closeModal(1))
        setPreview('table')
      }
    }
    dispatch(setIsLoadingData(false))
  }

  // const onRowClick = data => {
  //   dispatch(setIsDisabled(true))
  //   navigate(`/svetske-berze?id=${data.id}`)
  //   setPreview('form')
  //   setData(data)
  // }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteWorldStockExchange(data.id, false))
      if (!res.error) dispatch(closeModal(1))
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteWorldStockExchange} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/svetske-berze`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  // const openHistory = () => {
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: (
  //           <div>
  //             <HistoryTable
  //               collection="svetskaBerza"
  //               link="/svetske-berze?id="
  //               connectionTb={[
  //                 { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
  //                 { rtkQuery: queryGetAllProducts(), dbName: 'proizvodId' },
  //                 { rtkQuery: queryGetAllStockExchange(), dbName: 'berzaId' },
  //                 { rtkQuery: queryGetAllCurrency(), dbName: 'valutaId' },
  //                 { rtkQuery: queryGetAllMeasureUnits(), dbName: 'jedinicaMereId' },
  //                 { rtkQuery: queryGetAllCity(), dbName: 'gradId' }
  //               ]}
  //             />
  //           </div>
  //         ),
  //         order: 1,
  //         size: 'xlg'
  //       },
  //       active: 1
  //     })
  //   )
  // }

  const goBack = () => {
    if (!data.id) setPreview('table')
    else {
      navigate('/svetske-berze')
      setPreview('table')
    }
  }

  switch (preview) {
    case 'table':
      return (
        <div>
          <Title title={label.wroldStockExchange} total={worldStockExchange?.count}>
            <Button
              onClick={() => setPreview('addLast')}
              label={label.lastInsert}
              iconLeft={<FontAwesomeIcon icon="fa-cloud-arrow-up" />}
              accessAction="ADD"
              accessCode={code}
            />
            <Button
              onClick={() => setPreview('add')}
              label={label.add}
              iconLeft={<FontAwesomeIcon icon="fa-plus" />}
              accessAction="ADD"
              accessCode={code}
            />
          </Title>
          <Search fields={worldStockExchangeFields} onSearch={onSubmitSearch} />
          {/* {!!worldStockExchange?.rows?.length && (
              <h3 className="history-link" onClick={openHistory}>
                {label.historyChanges}
              </h3>
            )} */}
          <OneColumn classWidth="width5050">
            <Table
              heading={headingWorldStockExchange}
              data={worldStockExchange.rows}
              // onRowClick={onRowClick}
              onAction={onActionClick}
              havePagination={true}
              onPaginationChange={onPaginationChange}
              pageNumber={pageNumber}
              pageSize={pageSize}
              total={worldStockExchange?.count}
            />
          </OneColumn>
        </div>
      )
    case 'addLast':
      return (
        <div>
          <Title title={label.wroldStockExchange} goBackClick={goBack} globalClass="mb-2" />
          <FormWrapper>
            <FormAddMultipleWorldStockExchange existingData={lastData} id={id} onSubmit={onSubmit} />
          </FormWrapper>
        </div>
      )
    case 'add':
      return (
        <div>
          <Title title={label.wroldStockExchange} goBackClick={goBack} globalClass="mb-2" />
          <FormWrapper>
            <FormAddMultipleWorldStockExchange existingData={data} id={id} onSubmit={onSubmit} />
          </FormWrapper>
        </div>
      )
    default:
      return <MessageNoData message={label.messageNoData} />
  }
}

export default WorldStockExchange
