import { localStorageService } from 'services/localStorage.service'

export const getVisitedPath = location => {
  const page =
    localStorage.getItem('page') && localStorage.getItem('page') !== 'undefined' ? JSON.parse(localStorage.getItem('page')) : null
  let path = location.pathname
  if (location.search) path = path + location.search
  if (!page) {
    localStorageService.set('page', path)
    return false
  } else if (page !== path) {
    localStorageService.set('page', path)
    return path
  }
}
