import Button from 'components/button'
import { TwoColumns } from 'components/containers/containers'
import Input from 'components/input'
import Title from 'components/title'
import { label } from 'constants/labels'

const RegistrationsDetails = ({ existingData = {}, goBack, openOkModal }) => {
  return (
    <div>
      <Title title={label.registrerDetails} globalClass="mb-2" goBackClick={() => goBack()}>
        <Button onClick={() => openOkModal(existingData, 'add')} label={label.insertClientInSystem} />
        <Button onClick={() => openOkModal(existingData)} stylesClass="btnWarning" label={label.rejectRegApplication} />
      </Title>
      <TwoColumns>
        <div>
          <Input label={label.nameInput} value={existingData?.naziv} name="naziv" disabled />
          <Input label={label.orgAddress} value={existingData?.adresa} name="adresa" disabled />
          <Input label={label.orgCity} value={existingData?.grad.naziv} name="grad" disabled />
          <Input label={label.orgWeb} value={existingData?.sajt} disabled name="website" />
          <Input label={label.orgEmail} value={existingData?.email} disabled name="email" />
        </div>
        <div>
          <Input label={label.typeEntityInput} value={existingData?.tipLica.naziv} name="pib" disabled />
          <Input label={label.descriptionInput} value={existingData?.opisPoslovanja} disabled name="opisPoslovanja" />
          <Input label={label.orgPib} value={existingData?.pib} name="pib" disabled />
          <Input label={label.orgFirmNum} value={existingData?.maticniBroj} disabled name="maticniBr" />
          <Input label={label.contactNumber} value={existingData?.kontaktTelefon} disabled name="kontaktTelefon" />
        </div>
      </TwoColumns>
    </div>
  )
}

export default RegistrationsDetails
