/** Queries for 'Opis robe' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialProductDesc } from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES } from 'constants/other'

export const queryGetAllProductDesc = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'opisProizvoda',
      data: {
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.productDescription
    }
  }
}

/**
@param {string} id
*/
export const queryDeleteProductDesc = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'opisProizvoda',
      subType: DATA_TYPES.productsSerbia,
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.productDescription],
    successMsg: label.successMsgDelete + ' ' + label.msgProductDesc
  }
}

/**
@param {string} id
*/
export const queryGetProductDescBuProductId = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'opisProizvoda',
      data: {
        options: {
          where: { proizvodId: id }
        }
      }
    }
  }
}
