import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { wsUrl } from 'environment'
import { setSocket } from 'redux/reducers/socketSlice'
import { selectCurrentId } from 'redux/reducers/authSlice'

const Socket = () => {
  let socket
  let live = true
  let reconnectTime = 500
  const id = useSelector(selectCurrentId)

  const dispatch = useDispatch()

  const disconnect = () => {
    live = false
    if (socket) socket.close()
  }

  const connect = () => {
    console.log('Connecting to WebSockets')
    socket = new WebSocket(`${wsUrl}/${id}`)

    socket.onopen = () => {
      console.log('Connected to WebSockets')
      reconnectTime = 1000
    }

    socket.onclose = err => {
      console.log('Closing WebSockets', err)
      if (!live) return
      setTimeout(() => {
        if (live) connect()
      }, reconnectTime)
    }

    socket.onmessage = message => {
      try {
        const event = JSON.parse(message.data)
        if (event) dispatch(setSocket(event))
      } catch (err) {
        console.log('socket error parsing websocket data')
      }
    }

    socket.onerror = err => {
      console.log('WebSockets error', err)
      reconnectTime *= 2
      if (reconnectTime > 30000) reconnectTime = 30000
    }
  }

  useEffect(() => {
    connect()
    return () => disconnect()
  }, [])

  return <div />
}

export default Socket
