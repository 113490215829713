/** Form for  add/edit/remove  'Opstina' */

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { validateTownship as valdateForm } from './helpers/validate'
import { errTownship as errorMsg } from './helpers/errorMsg'
import FormHeader from './formHeader'
import { initialTownship } from 'constants/initialValues'
import { isObjectEmpty } from 'utils'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { label } from 'constants/labels'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllRegion } from 'query/queryRegion'
import SelectInput from 'components/selectInput'
import { queryGetAllCountry } from 'query/queryCountry'
import { pullConditionalFields } from './helpers/utils'
import { conditionalMapTownship } from 'constants/conditionalFields'
import FormBtnSave from './formHeader/formButton'

const FormTownship = ({ existingData = {}, id, onSubmit, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [isDomestic, setIsDomestic] = useState(true)
  const [data, setData] = useState({ ...initialTownship })
  const { data: regions = [] } = useGetAllQuery(queryGetAllRegion())
  const { data: countries = [] } = useGetAllQuery(queryGetAllCountry())
  const [filterMap, setFilterMap] = useState({})

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) {
      setData({ ...existingData })
      const foundDrzava = countries?.find(el => existingData.drzavaId === el.id && el.naziv?.toLocaleLowerCase().includes('srbija'))
      if (foundDrzava) setIsDomestic(true)
      else setIsDomestic(false)
    }
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      naziv: data.naziv,
      aktivan: data.aktivan,
      regionId: data.regionId,
      drzavaId: data.drzavaId,
      sort: data.sort
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData, isDomestic)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const onSelectChange = async (e, name, conditional) => {
    if (name === 'drzavaId') setIsDomestic(e.naziv.toLocaleLowerCase().includes('srbija'))

    if (conditional) {
      await getOptions(conditional, e)
    } else {
      setData(prev => ({ ...prev, [name]: e?.id || null }))
    }
  }

  const getOptions = async (mapName, selectValue) => {
    const { newFilterMap, newData } = await pullConditionalFields(mapName, conditionalMapTownship, selectValue, data)
    setFilterMap(newFilterMap)
    setData(newData)
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.township}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <SelectInput
          options={countries || []}
          filter={filterMap?.country}
          handleChangeSelect={e => onSelectChange(e, 'drzavaId', 'drzava')}
          selectedOption={countries.find(el => el.id === data.drzavaId || null)}
          customValue="id"
          customLabel="naziv"
          label={label.countryInput}
          errorTxt={error && !data.drzavaId && errorMsg('drzavaId')}
          disabled={disabled}
          isPreview={disabled}
        />
        {data.drzavaId && (
          <>
            <Input
              name="naziv"
              label={label.nameInput}
              placeholder={label.placeholder}
              value={data.naziv}
              onChange={handleChange}
              errorTxt={error && !data.naziv && errorMsg('naziv', data)}
              disabled={disabled}
              isPreview={disabled}
            />
            <div style={{ display: isDomestic ? 'block' : 'none' }}>
              <SelectInput
                filter={filterMap?.region}
                options={regions || []}
                handleChangeSelect={e => onSelectChange(e, 'regionId', 'region')}
                selectedOption={regions.find(el => el.id === data.regionId || null)}
                customValue="id"
                customLabel="naziv"
                label={label.regionInput}
                errorTxt={error && !data.regionId && isDomestic && errorMsg('regionId')}
                disabled={disabled}
                isPreview={disabled}
              />
            </div>
            <Input
              name="sort"
              label={label.sortInput}
              placeholder={label.placeholder}
              value={data.sort}
              onChange={handleChange}
              disabled={disabled}
              type="number"
              isPreview={disabled}
            />
            <Checkbox
              name="aktivan"
              label={label.activeInput}
              checked={data.aktivan}
              onChange={e => setData(formData => ({ ...formData, aktivan: e.target.checked }))}
              globalClass="mt-3 "
              disabled={disabled}
              isPreview={disabled}
            />
            {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
          </>
        )}
      </div>
    </div>
  )
}

export default FormTownship
