import App from 'App'
import Login from 'pages/auth/login'
import Register from 'pages/auth/register'
import BasicComponents from 'pages/basicComponents'
import SlateBillReport from 'pages/basicComponents/slate'
import Clients from 'pages/client'
import CreateClient from 'pages/client/create'
import CompanyRecord from 'pages/client/record'
import Bank from 'pages/codebook/bank'
import BusinessActivity from 'pages/codebook/businessActivity'
import City from 'pages/codebook/city'
import Country from 'pages/codebook/country'
import Currency from 'pages/codebook/currency'
import EconomyBranch from 'pages/codebook/economyBranch'
import EntitiesType from 'pages/codebook/entitiesType'
import EntityStatus from 'pages/codebook/entityStatus'
import MeasureUnit from 'pages/codebook/measureUnit'
import MeasureUnitRatio from 'pages/codebook/measureUnitRatio'
import Package from 'pages/codebook/package'
import Region from 'pages/codebook/region'
import Roles from 'pages/codebook/roles'
import AddEditRole from 'pages/codebook/roles/addEdit'
import SeaPort from 'pages/codebook/seaPort'
import Service from 'pages/codebook/service'
import StockExchange from 'pages/codebook/stockExchange'
import SubBisinessActivity from 'pages/codebook/subBisinessActivity'
import Township from 'pages/codebook/township'
import TypeOfContact from 'pages/codebook/typeOfContact'
import User from 'pages/codebook/user'
import WorkField from 'pages/codebook/workField'
import CustomGoodsExport from 'pages/customGoods/customGoodsExport'
import CustomGoodsImport from 'pages/customGoods/customGoodsImport'
import Dashboard from 'pages/dashboard'
import NotFound from 'pages/notFound'
import Organization from 'pages/organization'
import CustomGoodsExportReport from 'pages/reports/customGoodsExportReport'
import CustomGoodsImportReport from 'pages/reports/customGoodsImportReport'
import HistoryReports from 'pages/reports/historyReports'
import PriceMovementBihReport from 'pages/reports/priceMovementBihReport'
import WorldSeaPortReport from 'pages/reports/worldSeaPortReport'
import WorldStockExchangeReport from 'pages/reports/worldStockExchangeReport'
import PriceMovementBih from 'pages/seaPortAndStockExchange/priceMovementBih'
import WorldSeaPort from 'pages/seaPortAndStockExchange/worldSeaPort'
import WorldStockExchange from 'pages/seaPortAndStockExchange/worldStockExchange'
import WorldStockExchangeMilano from 'pages/seaPortAndStockExchange/worldStockExchangeMilano'
import { Outlet, useRoutes } from 'react-router-dom'
import Auth from './auth'
import NonAuth from './nonAuth'
// import Public from './public'
import EnterSmsCode from 'pages/auth/enterSmsCode'
import ForgotPassword from 'pages/auth/forgotpassword'
import ResetPassword from 'pages/auth/resetpassword'
import Blog from 'pages/codebook/blog'
import PositionInCompany from 'pages/codebook/positionincompany'
import Home from 'pages/home'
import Public from './public'
import Delivery from 'pages/codebook/delivery'
import Realizations from 'pages/preview/realizations'
import ServiceCategory from 'pages/codebook/serviceCategory'
import CurrencyList from 'pages/codebook/currencyList'
import Ips from 'pages/ips'
import ClientActivities from 'pages/activities/clientActivities'
import UserActivities from 'pages/activities/userActivities'
import Registrations from 'pages/registrations'
import Status from 'pages/codebook/status'
import CategoryTabs from 'pages/codebook/categoryTabs'
import ProductTabs from 'pages/codebook/productsTabs'
import PortDelivery from 'pages/codebook/portDelivery'
import ReportType from 'pages/codebook/reportType'
import AnnouncementReports from 'pages/reports/announcement'
import WorldStockExchangeMilanoReport from 'pages/reports/worldStockExchangeMilano'
import AccessRights from './access'
import { FORM_CODE } from 'constants/other'
import AddEditPackage from 'pages/codebook/package/addEdit'
import AddEditBlog from 'pages/codebook/blog/addEditBlog'
// import Home from 'pages/home'

const AppRoutes = () => {
  const element = useRoutes([
    {
      path: '/',
      element: <App />,
      children: [
        // routes that can be accessed by both auth and non-auth users
        {
          element: <Public />,
          children: [
            { path: 'home', element: <Home /> } // it will be the base component because have path equal to ''
          ]
        },
        {
          // routes that can be accessed just by non-auth users
          // wrapped with NonAuth
          element: <NonAuth />,
          children: [
            { path: '', element: <Login /> },
            { path: 'register', element: <Register /> },
            { path: 'forgotpassword', element: <ForgotPassword /> },
            { path: 'reset/:forgotToken/:email/:type', element: <ResetPassword /> },
            { path: 'enter-sms-code', element: <EnterSmsCode /> }
          ]
        },
        {
          // routes that can access just auth users
          // wrapped with Auth
          element: <Auth />,
          children: [
            {
              path: 'pocetni-ekran',
              element: (
                <AccessRights code={FORM_CODE.dashboard}>
                  <Dashboard code={FORM_CODE.dashboard} />
                </AccessRights>
              )
            },
            { path: 'slate', element: <SlateBillReport /> },
            {
              path: 'svetske-luke',
              element: (
                <AccessRights code={FORM_CODE.worldSeaPort}>
                  <WorldSeaPort code={FORM_CODE.worldSeaPort} />
                </AccessRights>
              )
            },
            {
              path: 'svetske-berze',
              element: (
                <AccessRights code={FORM_CODE.worldStockExchange}>
                  <WorldStockExchange code={FORM_CODE.worldStockExchange} />
                </AccessRights>
              )
            },
            {
              path: 'svetska-berza-milano',
              element: (
                <AccessRights code={FORM_CODE.worldStockExchangeMilano}>
                  <WorldStockExchangeMilano code={FORM_CODE.worldStockExchangeMilano} />
                </AccessRights>
              )
            },
            {
              path: 'kretanje-cena-bih',
              element: (
                <AccessRights code={FORM_CODE.priceMovementBih}>
                  <PriceMovementBih code={FORM_CODE.priceMovementBih} />
                </AccessRights>
              )
            },
            {
              path: 'tipovi-lica',
              element: (
                <AccessRights code={FORM_CODE.entitiesType}>
                  <EntitiesType code={FORM_CODE.entitiesType} />
                </AccessRights>
              )
            },
            {
              path: 'grana-privrede',
              element: (
                <AccessRights code={FORM_CODE.economyBranch}>
                  <EconomyBranch code={FORM_CODE.economyBranch} />
                </AccessRights>
              )
            },
            {
              path: 'kategorija',
              element: (
                <AccessRights code={FORM_CODE.subGroup}>
                  <CategoryTabs code={FORM_CODE.subGroup} />
                </AccessRights>
              )
            },
            {
              path: 'proizvod',
              element: (
                <AccessRights code={FORM_CODE.goods}>
                  <ProductTabs code={FORM_CODE.goods} />
                </AccessRights>
              )
            },
            {
              path: 'luka-uslovi-utovara',
              element: (
                <AccessRights code={FORM_CODE.producstSeaPort}>
                  <PortDelivery code={FORM_CODE.producstSeaPort} />
                </AccessRights>
              )
            },
            {
              path: 'klijenti',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: (
                    <AccessRights code={FORM_CODE.client}>
                      <Clients code={FORM_CODE.client} />
                    </AccessRights>
                  )
                },
                {
                  path: 'dodaj',
                  element: (
                    <AccessRights code={FORM_CODE.client} action="ADD">
                      <CreateClient code={FORM_CODE.client} />
                    </AccessRights>
                  )
                },
                {
                  path: 'karton/:id',
                  element: (
                    <AccessRights code={FORM_CODE.client} action="UPDATE">
                      <CompanyRecord code={FORM_CODE.client} />
                    </AccessRights>
                  )
                }
              ]
            },
            {
              path: 'uloge',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: (
                    <AccessRights code={FORM_CODE.roles}>
                      <Roles code={FORM_CODE.roles} />
                    </AccessRights>
                  )
                },
                {
                  path: ':id',
                  element: (
                    <AccessRights code={FORM_CODE.roles} action="UPDATE">
                      <AddEditRole code={FORM_CODE.roles} />
                    </AccessRights>
                  )
                },
                {
                  path: 'new',
                  element: (
                    <AccessRights code={FORM_CODE.roles} action="ADD">
                      <AddEditRole code={FORM_CODE.roles} />
                    </AccessRights>
                  )
                }
              ]
            },
            {
              path: 'paket',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: (
                    <AccessRights code={FORM_CODE.packages}>
                      <Package code={FORM_CODE.packages} />
                    </AccessRights>
                  )
                },
                {
                  path: ':id',
                  element: (
                    <AccessRights code={FORM_CODE.packages} action="UPDATE">
                      <AddEditPackage code={FORM_CODE.packages} />
                    </AccessRights>
                  )
                },
                {
                  path: 'dodaj',
                  element: (
                    <AccessRights code={FORM_CODE.packages} action="ADD">
                      <AddEditPackage code={FORM_CODE.packages} />
                    </AccessRights>
                  )
                }
              ]
            },

            {
              path: 'delatnost',
              element: (
                <AccessRights code={FORM_CODE.businessActivity}>
                  <BusinessActivity code={FORM_CODE.businessActivity} />
                </AccessRights>
              )
            },
            {
              path: 'poddelatnost',
              element: (
                <AccessRights code={FORM_CODE.subBusinessActiviti}>
                  <SubBisinessActivity code={FORM_CODE.subBusinessActiviti} />
                </AccessRights>
              )
            },
            {
              path: 'banka',
              element: (
                <AccessRights code={FORM_CODE.bank}>
                  <Bank code={FORM_CODE.bank} />
                </AccessRights>
              )
            },
            {
              path: 'korisnici',
              element: (
                <AccessRights code={FORM_CODE.users}>
                  <User code={FORM_CODE.users} />
                </AccessRights>
              )
            },
            {
              path: 'jedinica-mere',
              element: (
                <AccessRights code={FORM_CODE.measureUnit}>
                  <MeasureUnit code={FORM_CODE.measureUnit} />
                </AccessRights>
              )
            },
            {
              path: 'drzava',
              element: (
                <AccessRights code={FORM_CODE.country}>
                  <Country code={FORM_CODE.country} />
                </AccessRights>
              )
            },
            {
              path: 'status-lica',
              element: (
                <AccessRights code={FORM_CODE.entityStatus}>
                  <EntityStatus code={FORM_CODE.entityStatus} />
                </AccessRights>
              )
            },
            {
              path: 'opstina',
              element: (
                <AccessRights code={FORM_CODE.township}>
                  <Township code={FORM_CODE.township} />
                </AccessRights>
              )
            },
            {
              path: 'region',
              element: (
                <AccessRights code={FORM_CODE.region}>
                  <Region code={FORM_CODE.region} />
                </AccessRights>
              )
            },
            {
              path: 'grad',
              element: (
                <AccessRights code={FORM_CODE.city}>
                  <City code={FORM_CODE.city} />
                </AccessRights>
              )
            },
            {
              path: 'usluge',
              element: (
                <AccessRights code={FORM_CODE.services}>
                  <Service code={FORM_CODE.services} />
                </AccessRights>
              )
            },
            {
              path: 'kategorija-usluge',
              element: (
                <AccessRights code={FORM_CODE.servicesCategory}>
                  <ServiceCategory code={FORM_CODE.servicesCategory} />
                </AccessRights>
              )
            },
            {
              path: 'vrsta-kontakta',
              element: (
                <AccessRights code={FORM_CODE.typeOfContact}>
                  <TypeOfContact code={FORM_CODE.typeOfContact} />
                </AccessRights>
              )
            },

            // { path: 'oglas', element: <Announcement /> },
            {
              path: 'blog',
              element: (
                <AccessRights code={FORM_CODE.blog}>
                  <Blog code={FORM_CODE.blog} />
                </AccessRights>
              )
            },
            {
              path: 'blog',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: (
                    <AccessRights code={FORM_CODE.blog}>
                      <Blog code={FORM_CODE.blog} />
                    </AccessRights>
                  )
                },
                {
                  path: ':id',
                  element: (
                    <AccessRights code={FORM_CODE.blog} action="UPDATE">
                      <AddEditBlog code={FORM_CODE.blog} />
                    </AccessRights>
                  )
                },
                {
                  path: 'dodaj',
                  element: (
                    <AccessRights code={FORM_CODE.blog} action="ADD">
                      <AddEditBlog code={FORM_CODE.blog} />
                    </AccessRights>
                  )
                }
              ]
            },
            {
              path: 'pozicijafirma',
              element: (
                <AccessRights code={FORM_CODE.positionInCompany}>
                  <PositionInCompany code={FORM_CODE.positionInCompany} />
                </AccessRights>
              )
            },
            // { path: 'kontakt-osoba', element: <ContactPerson /> },
            {
              path: 'isporuka',
              element: (
                <AccessRights code={FORM_CODE.delivery}>
                  <Delivery code={FORM_CODE.delivery} />
                </AccessRights>
              )
            },
            {
              path: 'oblast',
              element: (
                <AccessRights code={FORM_CODE.workField}>
                  <WorkField code={FORM_CODE.workField} />
                </AccessRights>
              )
            },
            {
              path: 'carinske-robe-uvoz',
              element: (
                <AccessRights code={FORM_CODE.customGoodsImport}>
                  <CustomGoodsImport code={FORM_CODE.customGoodsImport} />
                </AccessRights>
              )
            },
            {
              path: 'carinske-robe-izvoz',
              element: (
                <AccessRights code={FORM_CODE.customGoodsExport}>
                  <CustomGoodsExport code={FORM_CODE.customGoodsExport} />
                </AccessRights>
              )
            },
            {
              path: 'organizacija',
              element: (
                <AccessRights code={FORM_CODE.organization}>
                  <Organization code={FORM_CODE.organization} />
                </AccessRights>
              )
            },
            {
              path: 'luka',
              element: (
                <AccessRights code={FORM_CODE.seaPort}>
                  <SeaPort code={FORM_CODE.seaPort} />
                </AccessRights>
              )
            },
            {
              path: 'berza',
              element: (
                <AccessRights code={FORM_CODE.stockExchange}>
                  <StockExchange code={FORM_CODE.stockExchange} />
                </AccessRights>
              )
            },
            {
              path: 'valuta',
              element: (
                <AccessRights code={FORM_CODE.currency}>
                  <Currency code={FORM_CODE.currency} />
                </AccessRights>
              )
            },
            {
              path: 'kursna-lista',
              element: (
                <AccessRights code={FORM_CODE.currencyList}>
                  <CurrencyList code={FORM_CODE.currencyList} />
                </AccessRights>
              )
            },
            {
              path: 'components',
              element: <BasicComponents />
            },
            {
              path: 'izvestaji',
              element: <Outlet />,
              children: [
                {
                  path: 'carinske-robe-uvoz',
                  element: (
                    <AccessRights code={FORM_CODE.customGoodsImportReport}>
                      <CustomGoodsImportReport code={FORM_CODE.customGoodsImportReport} />
                    </AccessRights>
                  )
                },
                {
                  path: 'carinske-robe-izvoz',
                  element: (
                    <AccessRights code={FORM_CODE.customGoodsExportReport}>
                      <CustomGoodsExportReport code={FORM_CODE.customGoodsExportReport} />
                    </AccessRights>
                  )
                },
                {
                  path: 'svetske-luke',
                  element: (
                    <AccessRights code={FORM_CODE.worldSeaPortReport}>
                      <WorldSeaPortReport code={FORM_CODE.worldSeaPortReport} />
                    </AccessRights>
                  )
                },
                {
                  path: 'svetske-berze',
                  element: (
                    <AccessRights code={FORM_CODE.wroldStockExchangeReport}>
                      <WorldStockExchangeReport code={FORM_CODE.wroldStockExchangeReport} />
                    </AccessRights>
                  )
                },
                {
                  path: 'svetske-berze-milano',
                  element: (
                    <AccessRights code={FORM_CODE.wroldStockExchangeMilanoReport}>
                      <WorldStockExchangeMilanoReport code={FORM_CODE.wroldStockExchangeMilanoReport} />
                    </AccessRights>
                  )
                },
                // { path: 'svetske-berze-dvonedeljni-osvrt', element: <TwoWeeksWorldStockExchangeReport /> },
                {
                  path: 'kretanje-cena-bih',
                  element: (
                    <AccessRights code={FORM_CODE.priceMovementBihReport}>
                      <PriceMovementBihReport code={FORM_CODE.priceMovementBihReport} />
                    </AccessRights>
                  )
                },
                {
                  path: 'oglasi',
                  element: (
                    <AccessRights code={FORM_CODE.izvestajOglasi}>
                      <AnnouncementReports code={FORM_CODE.izvestajOglasi} />
                    </AccessRights>
                  )
                },
                {
                  path: 'istorija-izvestaja',
                  element: (
                    <AccessRights code={FORM_CODE.listOfReports}>
                      <HistoryReports code={FORM_CODE.listOfReports} />
                    </AccessRights>
                  )
                }
              ]
            },
            {
              path: 'pregled',
              element: <Outlet />,
              children: [
                {
                  path: 'realizacije',
                  element: (
                    <AccessRights code={FORM_CODE.realizations}>
                      <Realizations code={FORM_CODE.realizations} />
                    </AccessRights>
                  )
                }
              ]
            },
            {
              path: 'registracije',
              element: <Outlet />,
              children: [
                {
                  path: '',
                  element: (
                    <AccessRights code={FORM_CODE.registration}>
                      <Registrations code={FORM_CODE.registration} />
                    </AccessRights>
                  )
                }
              ]
            },
            {
              path: 'ips',
              element: (
                <AccessRights code={FORM_CODE.ipAddress}>
                  <Ips code={FORM_CODE.ipAddress} />
                </AccessRights>
              )
            },
            {
              path: 'aktivnosti-klijenta',
              element: (
                <AccessRights code={FORM_CODE.activitiesClients}>
                  <ClientActivities code={FORM_CODE.activitiesClients} />
                </AccessRights>
              )
            },
            {
              path: 'aktivnosti-korisnika',
              element: (
                <AccessRights code={FORM_CODE.activitiesUsers}>
                  <UserActivities code={FORM_CODE.activitiesUsers} />
                </AccessRights>
              )
            },
            {
              path: 'status',
              element: (
                <AccessRights code={FORM_CODE.status}>
                  <Status code={FORM_CODE.status} />
                </AccessRights>
              )
            },
            {
              path: 'odnos-jedinica-mera',
              element: (
                <AccessRights code={FORM_CODE.measureUnitRatio}>
                  <MeasureUnitRatio code={FORM_CODE.measureUnitRatio} />
                </AccessRights>
              )
            },
            {
              path: 'tip-izvestaja',
              element: (
                <AccessRights code={FORM_CODE.typeOfReport}>
                  <ReportType code={FORM_CODE.typeOfReport} />
                </AccessRights>
              )
            }
          ]
        },
        { path: '*', element: <NotFound /> }
      ]
    }
  ])

  return element
}

export { AppRoutes }
