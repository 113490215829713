/** Form for  add/edit/remove  'Luka' */

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { validateSeaPort as valdateForm } from './helpers/validate'
import { errSeaPort as errorMsg } from './helpers/errorMsg'
import FormHeader from './formHeader'
import { initialSeaPort } from 'constants/initialValues'
import { isObjectEmpty } from 'utils'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { label } from 'constants/labels'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllCity } from 'query/queryCity'
import { queryGetAllCountry } from 'query/queryCountry'
import SelectInput from 'components/selectInput'
import { pullConditionalFields } from './helpers/utils'
import { conditionalMapSeaCountryCity } from 'constants/conditionalFields'
import FormBtnSave from './formHeader/formButton'

const FormSeaPort = ({ existingData = {}, id, onSubmit, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialSeaPort })
  const { data: cities = [] } = useGetAllQuery(queryGetAllCity())
  const { data: countries = [] } = useGetAllQuery(queryGetAllCountry())
  const [filterMap, setFilterMap] = useState({})

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      naziv: data.naziv,
      aktivan: data.aktivan,
      drzavaId: data.drzavaId,
      gradId: data.gradId
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const onSelectChange = async (e, name, conditional) => {
    if (conditional) {
      await getOptions(conditional, e)
    } else {
      setData(prev => ({ ...prev, [name]: e?.id || null }))
    }
  }

  const getOptions = async (mapName, selectValue) => {
    const { newFilterMap, newData } = await pullConditionalFields(mapName, conditionalMapSeaCountryCity, selectValue, data)
    setFilterMap(newFilterMap)
    setData(newData)
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.seaPort}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.placeholder}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={countries}
          filter={filterMap?.country}
          handleChangeSelect={e => onSelectChange(e, 'drzavaId', 'drzava')}
          selectedOption={countries.find(el => el.id === data.drzavaId || null)}
          customValue="id"
          customLabel="naziv"
          label={label.countryInput}
          // errorTxt={error && !data.drzavaId && errorMsg('drzavaId', { countries: countries })}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={cities}
          filter={filterMap?.city}
          handleChangeSelect={e => onSelectChange(e, 'gradId', 'grad')}
          selectedOption={cities.find(el => el.id === data.gradId || null)}
          customValue="id"
          customLabel="naziv"
          label={label.cityInput}
          // errorTxt={error && !data.gradId && errorMsg('gradId', { cities: cities })}
          disabled={disabled}
          isPreview={disabled}
        />
        <Checkbox
          name="aktivan"
          label={label.activeInput}
          checked={data.aktivan}
          onChange={e => setData(formData => ({ ...formData, aktivan: e.target.checked }))}
          globalClass="mt-3 "
          disabled={disabled}
        />
        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormSeaPort
