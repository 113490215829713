import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { logOut, selectCurrentUser } from 'redux/reducers/authSlice'
import logoImg from 'assets/images/logo/logoIT.png'
import styles from './navbar.module.scss'
import Button from '../button/index'
import { label } from 'constants/labels'

const DropDownList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const logoutUser = async () => {
    await dispatch(logOut())
    navigate('/', { replace: true })
  }
  return (
    <div className={styles.dropdown}>
      <ul>
        {/* <li>
          <FontAwesomeIcon icon="fa-dashboard" />
          <NavLink to="/pocetni-ekran">{label.dashboard}</NavLink>
        </li> */}
        <li>
          <FontAwesomeIcon icon="fa-sliders" />
          <NavLink to="organizacija">Organizacija</NavLink>
        </li>
        <li>
          <FontAwesomeIcon icon="fa-user" />
          <NavLink to="korisnici">Zaposleni</NavLink>
        </li>
      </ul>
      <Button onClick={logoutUser} label={label.logout} iconRight={<FontAwesomeIcon icon="fa-sign-out" />} globalClass="mt-2 w-100" />
    </div>
  )
}
const Navbar = () => {
  const token = useSelector(state => state.auth.token)
  const user = useSelector(selectCurrentUser)

  return (
    <nav className={styles.wrapper}>
      <div className={styles.content}>
        <NavLink to="/">
          <img src={logoImg} />
        </NavLink>
        <ul>
          {/* {!token && (
            <li>
              <Button onClick={() => navigate('login')} label={label.login} iconLeft={<FontAwesomeIcon icon="fa-sign-in" />} />
            </li>
          )} */}

          <li className={styles.userBox}>
            {token && (
              <div className={styles.user}>
                {user?.korisnickoIme}
                <FontAwesomeIcon icon="fa-circle-user" />
                <div className={styles.visible}>
                  <DropDownList />
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
