/** Page  'Delatnost ' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormBusinessActivity from 'components/forms/formBusinessActivity'
import HistoryTable from 'components/historyTable'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { bussinesActivityFields } from 'constants/searchFields'
import { headingBusinessActivity } from 'constants/tbHeading'
import {
  queryAddBusinessActivity,
  queryDeleteBusinessActivity,
  queryGetPagedBusinessActivity,
  queryUpdateBusinessActivity
} from 'query/queryBusinessActivity'
import { queryGetAllUsers } from 'query/queryUser'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'

const BusinessActivity = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: businessActivity = [] } = useGetAllQuery(queryGetPagedBusinessActivity(pageNumber, pageSize, searchOptions))
  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (businessActivity.rows && businessActivity.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, businessActivity?.rows])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(queryUpdateBusinessActivity(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddBusinessActivity(data))
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/delatnost?id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/delatnost`)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteBusinessActivity(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/delatnost`)
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteBussinesActivity} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  const showModalForForm = () => {
    navigate(`/delatnost`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.addBusinessActivity}</h2>
              <FormBusinessActivity onSubmit={onSubmit} id={null} />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="delatnost"
                link="/delatnost?id="
                connectionTb={[{ rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' }]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.businessActivity} total={businessActivity?.count}>
        <Button
          onClick={showModalForForm}
          label={label.add}
          iconLeft={<FontAwesomeIcon icon="fa-plus" />}
          accessAction="ADD"
          accessCode={code}
        />
      </Title>
      <Search fields={bussinesActivityFields} onSearch={onSubmitSearch} />
      {!!businessActivity?.rows?.length && (
        <h3 className="history-link" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns>
        <Table
          heading={headingBusinessActivity}
          data={businessActivity?.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={businessActivity?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormBusinessActivity existingData={data} header={true} id={id} onSubmit={onSubmit} onDelete={openDeleteModal} code={code} />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default BusinessActivity
