import { createSlice } from '@reduxjs/toolkit'

// In components open modal like this
// order is number of modal ,
// for example when from first modal open one more modal but dont close first one
// const openNotifyModal = () =>dispatch(openModal({ arrItem: { content: <NotificationModal />, order: 1, size: 'lg' }, active: 1 }))
// const close = () =>  dispatch(closeModal(1))

const initialState = {
  arr: [],
  active: 0
}
export const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { arrItem, active } = action.payload
      state.arr = [...state.arr, arrItem]
      state.active = active
    },
    closeModal: (state, action) => {
      const filter = state.arr.filter(e => e.order !== action.payload)
      state.arr = filter
      state.active = state.active - 1
    }
  }
})

export const { openModal, closeModal } = slice.actions

export default slice.reducer
