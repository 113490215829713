import { BILL_TYPES } from 'constants/other'

const getByType = (data = {}, type) => {
  let arr = []
  if (type === BILL_TYPES.bill) {
    arr = [
      {
        type: 'paragraph',
        children: [{ text: 'Datum prometa dobara:' }, { text: data?.paymentDate?.toString() || 'N/A' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Napomena u poreskom oslobođenju:' }, { text: data?.taxRemark?.toString() || 'N/A' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Za sporove povodom ovog računa utvrđuje se nadležnost suda u Novom Sadu' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Rok uplate:' }, { text: data?.paymentDeadline?.toString() || 'N/A' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Način plaćanja:' }, { text: data?.paymentType?.toString() || 'N/A' }]
      },

      {
        type: 'paragraph',
        children: [{ text: 'Datum izdavanja:' }, { text: data?.issueDate?.toString() || 'N/A' }]
      }
    ]
  } else if (type === BILL_TYPES.preBill) {
    arr = [
      {
        type: 'paragraph',
        children: [{ text: 'Rok plaćanja:' }, { text: data?.paymentDeadline?.toString() || 'N/A' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Način plaćanja:' }, { text: data?.paymentType?.toString() || 'N/A' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Dana:' }, { text: data?.paymentDate?.toString() || 'N/A' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Predračun je rađen na računaru elektronskim putem i važeći je bez pečata i potpisa' }]
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Uplatom iznosa po ovom predračunu Korisnik usluga potvrđuje da je upoznat i da prihvata OUP i UK, kao minimalne hardverske i softverske zahteve za korišćenje usluge, koji se nalazi na internet stranici www.infotim.rs'
          }
        ]
      },
      {
        type: 'paragraph',
        class: 'spaceAround',
        children: [{ text: '' }]
      },
      {
        type: 'h3',
        children: [{ text: 'NAPOMENA: paket aplikacija sa izveštajima po ceni paketa samo aplikacija' }]
      }
    ]
  } else if (type === BILL_TYPES.avans) {
    arr = [
      {
        type: 'paragraph',
        children: [{ text: 'Datum plaćanja' }, { text: data?.paymentDate?.toString() || 'N/A' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Napomena u poreskom oslobođenju' }, { text: data?.taxRemark?.toString() || 'N/A' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Način plaćanja' }, { text: 'bezgotovinski' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Za sporove povodom ovog računa utvrđuje se nadležnost suda u Novom Sadu' }]
      },
      {
        type: 'paragraph',
        children: [{ text: 'Datum izdavanja' }, { text: data?.issueDate?.toString() || 'N/A' }]
      }
    ]
  }
  return arr
}
export const getCommonText = (data = {}, type) => {
  const { report = {} } = data
  return [
    {
      type: 'h3',
      class: 'headingH3x',
      children: [
        {
          text: 'Hvala vam što izmirujete svoje obaveze i time doprinosite boljem kvalitetu pruženih usluga i stabilnom funkcionisanju Info Tim Logistike doo.'
        }
      ]
    },
    {
      type: 'paragraph',
      class: 'spaceAround',
      children: [{ text: '' }]
    },
    ...getByType(report, type)
  ]
}
