import { useState } from 'react'
import { label } from 'constants/labels'
import Input from 'components/input'
import Button from 'components/button'
import { validateForgotPasswordMail as validateForm } from './helpers/validate'
import { errForgotPassword as errorMsg } from './helpers/errorMsg'

const FormMailResetPassword = ({ onSubmit }) => {
  const [data, setData] = useState({ email: '' })
  const [error, setError] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setData(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm(data)) onSubmit(data)
    else setError(true)
  }

  return (
    <div>
      <div>
        <h2>Unesite email</h2>
      </div>
      <Input
        name="email"
        label={label.emailInput}
        placeholder={label.emailPlaceholder}
        value={data.email}
        onChange={handleChange}
        errorTxt={error && errorMsg('email', data)}
      />
      <div className="ta-r mt-2">
        <Button label={label.send} onClick={handleSubmit} />
      </div>
    </div>
  )
}

export default FormMailResetPassword
