import { ANNOUNCEMENT_TYPE, PACKAGE_DURATION, STATUS_CLIENT } from 'constants/staticOptions'
import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import { queryGetAllCategories } from 'query/querycategory'
import { queryGetAllCategoriesBosnia } from 'query/queryCategoryBosnia'
import { queryGetAllCategoriesFutures } from 'query/queryCategoryFutures'
import { queryGetAllPortCategories } from 'query/queryCategoryPort'
import { queryGetAllCity } from 'query/queryCity'
import { queryGetAllClients } from 'query/queryClient'
import { queryGetAllCountry } from 'query/queryCountry'
import { queryGetAllCategoriesWhereProductId, queryGetAllEconomyBranches } from 'query/queryEconomyBranch'
import { queryGetAllEntityStatus } from 'query/queryEntityStatus'
import { queryGetAllEntityTypes } from 'query/queryEntityType'
import { queryGetAllMeasureUnits } from 'query/queryMeasureUnit'
import { queryGetAllPortDelivery } from 'query/queryPortDelivery'
import { queryGetAllProducts } from 'query/queryProducts'
import { queryGetAllProductsBosnia } from 'query/queryProductsBosnia'
import { queryGetAllProductsByBerzaIdSearch, queryGetAllProductsFutures } from 'query/queryProductsFutures'
import { queryGetAllProductsMilano } from 'query/queryProductsMilano'
import { queryGetAllProductsPort } from 'query/queryProductsPort'
import { queryGetAllRegion } from 'query/queryRegion'
import { queryGetAllStockExchangeNotMilano } from 'query/queryStockExchange'
import { queryGetAllSubBusinessActivity } from 'query/querySubBusinessActivity'
import { queryGetAllTownship } from 'query/queryTownship'
import { queryGetAllUsers } from 'query/queryUser'
import { queryGetAllWorkField } from 'query/queryWorkField'
import { label } from './labels'
import { queryGetAllReportTypes } from 'query/queryReportType'
import { queryGetAllProductDesc } from 'query/queryProductDescription'

export const entitiesTypeFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const economyBranchSearchFIelds = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const categorySearchFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const productSearchFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'granaPrivredeId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllEconomyBranches(),
    label: label.economyBranchInput,
    dependentField: {
      havePostScript: true,
      dependentField: 'granaPrivredeId',
      dbField: 'id',
      updateOptionsDbField: 'kategorijaId',
      rtkQuery: queryGetAllCategoriesWhereProductId
    }
  },
  {
    type: 'multiselect',
    dbField: 'kategorijaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCategories(),
    label: label.categoryInput
  }
  // {
  //   type: 'select',
  //   dbField: 'veznaJedinicaMereId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllMeasureUnits(),
  //   label: label.measureUnitsInput
  // }
]

export const measureUnitFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const measureUnitsRatioFields = [
  {
    type: 'select',
    dbField: 'izJedinicaMereId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllMeasureUnits(),
    label: label.measureUnitsInput
  }
]

export const regionFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const entityStatusFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const countryFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const cityFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'text',
    dbField: 'postanskiBroj',
    label: label.zipCodeInput
  },
  {
    type: 'select',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  },
  {
    type: 'select',
    dbField: 'opstinaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllTownship(),
    label: label.townshipInput
  },
  {
    type: 'select',
    dbField: 'drzavaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCountry(),
    label: label.countryInput
  }
]

export const bankFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]
export const ipsFields = [
  {
    type: 'multiselect',
    dbField: 'klijentId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllClients(),
    label: label.clients
  }
]

export const poductsPortFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'kategorijaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllPortCategories(),
    label: label.subGroup
  }
]

export const productBosniaFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'kategorijaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCategoriesBosnia(),
    label: label.subGroup
  }
]

export const productCommonFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'kategorijaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCategoriesFutures(),
    label: label.subGroup
  }
]

export const productMilanoFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]
export const portDeliveryFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]
export const deliveryFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const positionInCompanyFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const bussinesActivityFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const subBussinesActivityFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'delatnostId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllBusinessActivity(),
    label: label.businessActivity
  }
]

export const townshipFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  },
  {
    type: 'select',
    dbField: 'drzavaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCountry(),
    label: label.countryInput
  }
]

export const userFields = [
  {
    type: 'text',
    dbField: 'korisnickoIme',
    label: label.usernameInput
  },
  {
    type: 'text',
    dbField: 'ime',
    label: label.firstNameInput
  },
  // {
  //   type: 'text',
  //   dbField: 'prezime',
  //   label: label.lastNameInput
  // },
  {
    type: 'text',
    dbField: 'email',
    label: label.emailInput
  },
  {
    type: 'text',
    dbField: 'mobilni',
    label: label.mobileInput
  },
  {
    type: 'text',
    dbField: 'poslovniBroj',
    label: label.businessPhone
  }
]

export const productDescSearchFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProducts(),
    label: label.products
  }
]

export const packageFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'tipTrajanja',
    label: label.durationTypeInput
  },
  {
    type: 'select',
    dbField: 'tipTrajanja',
    keyLabel: 'label',
    keyValue: 'id',
    options: PACKAGE_DURATION,
    label: label.durationTypeInput
  },
  {
    type: 'number',
    dbField: 'trajanje',
    label: label.durationInput
  }
]

export const serviceFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'text',
    dbField: 'kod',
    label: label.kodInput
  }
]

export const statusFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const typeOfContactFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const workFieldFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
]

export const seaPortFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'drzavaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCountry(),
    label: label.countryInput
  },
  {
    type: 'select',
    dbField: 'gradId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCity(),
    label: label.cityInput
  }
]

export const stockExchangeFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'select',
    dbField: 'drzavaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCountry(),
    label: label.countryInput
  }
  // {
  //   type: 'select',
  //   dbField: 'gradId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllCity(),
  //   label: label.cityInput
  // }
]

export const historyReportFields = [
  {
    type: 'select',
    dbField: 'kod',
    keyLabel: 'naziv',
    keyValue: 'kod',
    rtkQuery: queryGetAllReportTypes(),
    label: label.typeOfReport
  }
]

export const currencyListFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.currency
  },
  {
    type: 'date',
    subType: 'date',
    dbField: 'datum',
    label: label.date
  }
]
export const currencyFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  }
  // {
  //   type: 'select',
  //   dbField: 'drzavaId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllCountry(),
  //   label: label.countryInput
  // }
]
export const worldSeaPortFields = [
  // {
  //   type: 'select',
  //   dbField: 'lukaId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllSeaPort(),
  //   label: label.seaPort
  // },
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductsPort(),
    label: label.products
  },
  {
    type: 'select',
    dbField: 'lukaUsloviUtovaraId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllPortDelivery(),
    label: label.productSeaPortInput
  },
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'datumUnosa',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
  // {
  //   type: 'select',
  //   dbField: 'jedinicaMereId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllMeasureUnits(),
  //   label: label.measureUnitsInput
  // },
]

export const worldStockExchangeFields = [
  {
    type: 'select',
    dbField: 'berzaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllStockExchangeNotMilano(),
    label: label.stockExchange,
    uniqueDbName: 'proizvodId',
    dependentField: {
      dependentField: 'berzaId',
      dbField: 'id',
      updateOptionsDbField: 'proizvodId',
      rtkQuery: queryGetAllProductsByBerzaIdSearch
    }
  },
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'proizvodNaziv',
    keyValue: 'proizvodId',
    label: label.products
  },
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'datumUnosa',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
  // {
  //   type: 'range',
  //   subType: 'inputRange',
  //   inputType: 'number',
  //   dbField: 'cena',
  //   labelTo: 'Cena do',
  //   labelFrom: 'Cena od'
  // }
  // {
  //   type: 'select',
  //   dbField: 'jedinicaMereId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllMeasureUnits(),
  //   label: label.measureUnitsInput
  // }
]

export const worldStockExchangeMilanoFields = [
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductsMilano(),
    label: label.products
  },
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'datumUnosa',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
  // {
  //   type: 'lookup',
  //   dbField: 'jedinicaMereId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllMeasureUnits(),
  //   label: label.measureUnitsInput
  // }
]

export const priceMovementBihFields = [
  {
    type: 'select',
    dbField: 'kategorijaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCategoriesBosnia(),
    label: label.subGroupBosnia,
    dependentField: {
      dependentField: 'kategorijaId',
      dbField: 'id',
      updateOptionsDbField: 'proizvodId',
      rtkQuery: queryGetAllProductsBosnia
    }
  },
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductsBosnia(),
    label: label.productBosniaInput
  },
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'datumUnosa',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
  // {
  //   type: 'number',
  //   dbField: 'cena',
  //   label: label.priceInput
  // },
  // {
  //   type: 'select',
  //   dbField: 'jedinicaMereId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllMeasureUnits(),
  //   label: label.measureUnitsInput
  // },
  // {
  //   type: 'range',
  //   subType: 'datapicker',
  //   dbField: 'datum',
  //   labelTo: label.dateTo,
  //   labelFrom: label.dateFrom
  // }
]

export const clientsSearchFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'text',
    dbField: 'pib',
    label: label.PIBFarmInput
  },
  {
    type: 'text',
    dbField: 'maticniBroj',
    label: label.identificationNumberInput
  },
  {
    type: 'text',
    dbField: 'bpg',
    label: label.BPGFarmInputShort
  },

  {
    type: 'select',
    dbField: 'drzavaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCountry(),
    label: label.countryInput
  },
  {
    type: 'select',
    dbField: 'gradId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCity(),
    label: label.cityInput
  },
  {
    type: 'select',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  },
  {
    type: 'select',
    dbField: 'korisnikId',
    keyLabel: 'ime',
    keyValue: 'id',
    rtkQuery: queryGetAllUsers(),
    label: label.salesSpecialist
  },

  {
    type: 'select',
    dbField: 'oblastId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllWorkField(),
    label: label.workField
  },
  {
    type: 'select',
    dbField: 'statusId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllEntityStatus(),
    label: label.statusInput
  },
  {
    type: 'select',
    dbField: 'delatnostId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllBusinessActivity(),
    label: label.businessActivity
  },
  {
    type: 'select',
    dbField: 'podDelatnostId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllSubBusinessActivity(),
    label: label.subBusinessActiviti
  },
  {
    type: 'select',
    dbField: 'tipLicaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllEntityTypes(),
    label: label.typeEntityInput
  },
  {
    type: 'text',
    dbField: 'imeKontakta',
    label: label.person
  },
  {
    type: 'text',
    dbField: 'emailKontakta',
    label: label.emailInput
  },
  {
    type: 'text',
    dbField: 'telefonKontakta',
    label: label.tel
  },
  {
    type: 'text',
    dbField: 'napomena',
    label: label.term
  }
]
export const registerSearchFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },

  {
    type: 'select',
    dbField: 'status',
    keyLabel: 'label',
    keyValue: 'id',
    options: STATUS_CLIENT,
    label: label.statusInput
  },

  {
    type: 'select',
    dbField: 'tipLicaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllEntityTypes(),
    label: label.typeEntityInput
  },

  // {
  //   type: 'text',
  //   dbField: 'email',
  //   label: label.emailInput
  // },
  // {
  //   type: 'text',
  //   dbField: 'kontaktTelefon',
  //   label: label.tel
  // }
  {
    type: 'date',
    subType: 'date',
    dbField: 'createdAt',
    label: label.date
  }
]
export const customGoodsImportAndExportFields = [
  {
    type: 'text',
    dbField: 'naziv',
    label: label.nameInput
  },
  {
    type: 'text',
    dbField: 'zemlja',
    label: label.countryInput
  },
  {
    type: 'text',
    dbField: 'kod',
    label: label.kodInput
  },
  {
    type: 'date',
    subType: 'month',
    dbField: 'datum',
    label: label.month
  }
]

export const worldSeaPortReportFields = [
  // {
  //   type: 'select',
  //   dbField: 'lukaId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllSeaPort(),
  //   label: label.seaPort
  // },
  {
    type: 'multiselect',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductsPort(),
    label: label.products
  },
  // {
  //   type: 'lookup',
  //   dbField: 'unetaJedinicaMereId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllMeasureUnits(),
  //   label: label.measureUnitsInput
  // },
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'datumUnosa',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
]

export const annReportFieldsDemand = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'createdAt',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'cena',
    labelTo: 'Cena do',
    labelFrom: 'Cena od'
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'kolicina',
    labelTo: 'Kolicina do',
    labelFrom: 'Kolicina od'
  },
  {
    type: 'multiselect',
    dbField: 'gradId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCity(),
    label: label.cityInput
  },
  {
    type: 'multiselect',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  },
  {
    type: 'multiselect',
    dbField: 'tip',
    keyLabel: 'label',
    keyValue: 'id',
    label: label.bidDemand,
    options: ANNOUNCEMENT_TYPE
  }
]
export const annReportFieldsPrice = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'createdAt',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  },
  {
    type: 'multiselect',
    dbField: 'gradId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCity(),
    label: label.cityInput
  },
  {
    type: 'multiselect',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  }
]
export const buyersAndSellersAgricultureReportFields = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'createdAt',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  },
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProducts(),
    label: label.products
  }
]

export const buyersAndSellersNutritionReportFields = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'createdAt',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  },
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProducts(),
    label: label.products
  }
]

export const worldStockExchangeReportFields = [
  {
    type: 'multiselect',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductsFutures(),
    label: label.products
  },
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'datumUnosa',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
]

export const worldStockExchangeMilanoReportFields = [
  {
    type: 'select',
    dbField: 'proizvodLukaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductsPort(),
    label: label.products
  },
  {
    type: 'select',
    dbField: 'unetaJedinicaMereId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllMeasureUnits(),
    label: label.measureUnitsInput
  },

  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'periodVazenjaCenaStart',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
]

export const TwoWeeksWorldStockExchangeReportFields = [
  {
    type: 'multiselect',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductsMilano(),
    label: label.products
  },
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'datumUnosa',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
]

export const priceMovementBihReportFields = [
  // {
  //   type: 'number',
  //   dbField: 'cena',
  //   label: label.priceInput
  // },
  {
    type: 'multiselect',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductsBosnia(),
    label: label.products
  },
  // {
  //   type: 'select',
  //   dbField: 'unetaJedinicaMereId',
  //   keyLabel: 'naziv',
  //   keyValue: 'id',
  //   rtkQuery: queryGetAllMeasureUnits(),
  //   label: label.measureUnitsInput
  // },
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'periodStart',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  }
]

export const priceMovementSrbReportFields = [
  {
    type: 'number',
    dbField: 'cena',
    label: label.priceInput
  },
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProducts(),
    label: label.products
  },
  {
    type: 'select',
    dbField: 'unetaJedinicaMereId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllMeasureUnits(),
    label: label.measureUnitsInput
  }
]
export const announcementFilterOne = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'createdAt',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  },

  {
    type: 'multiselect',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProducts(),
    label: label.products
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'cena',
    labelTo: 'Cena do',
    labelFrom: 'Cena od'
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'kolicina',
    labelTo: 'Kolicina do',
    labelFrom: 'Kolicina od'
  },

  {
    type: 'multiselect',
    dbField: 'gradId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCity(),
    label: label.cityInput
  },
  {
    type: 'multiselect',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  }
]
export const announcementFilter = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'createdAt',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  },

  {
    type: 'multiselect',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProducts(),
    label: label.products
  },
  {
    type: 'multiselect',
    dbField: 'opisProizvodaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductDesc(),
    label: label.products
  },
  {
    type: 'multiselect',
    dbField: 'klijentId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllClients(),
    label: label.clients
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'cena',
    labelTo: 'Cena do',
    labelFrom: 'Cena od'
  },
  {
    type: 'select',
    dbField: 'tipLicaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllEntityTypes(),
    label: label.typeEntityInput
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'kolicina',
    labelTo: 'Kolicina do',
    labelFrom: 'Kolicina od'
  },

  {
    type: 'multiselect',
    dbField: 'gradId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCity(),
    label: label.cityInput
  },
  {
    type: 'multiselect',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  }
  // {
  //   type: 'select',
  //   dbField: 'tip',
  //   keyLabel: 'label',
  //   keyValue: 'id',
  //   label: label.bidDemand,
  //   options: ANNOUNCEMENT_TYPE
  // },
]

export const blogFilter = [
  {
    type: 'text',
    dbField: 'naslov',
    label: label.blogTitle
  }
]

export const realizationsSerachFields = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'datum',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  },
  {
    type: 'select',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProducts(),
    label: label.products
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'cena',
    labelTo: 'Cena do',
    labelFrom: 'Cena od'
  },
  {
    type: 'select',
    dbField: 'kupacId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllClients(),
    label: label.buyer
  },
  {
    type: 'select',
    dbField: 'prodavacId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllClients(),
    label: label.seller
  },
  {
    type: 'select',
    dbField: 'gradId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCity(),
    label: label.cityInput
  },
  {
    type: 'select',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  }
]
export const activitiClientFields = [
  {
    type: 'multiselect',
    dbField: 'klijentId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllClients(),
    label: label.clients
  },
  {
    type: 'date',
    subType: 'date',
    dbField: 'createdAt',
    label: label.date
  }
]
export const activitiUserFields = [
  {
    type: 'multiselect',
    dbField: 'korisnikId',
    keyLabel: 'ime',
    keyValue: 'id',
    rtkQuery: queryGetAllUsers(),
    label: label.korisnik
  },
  {
    type: 'date',
    subType: 'date',
    dbField: 'createdAt',
    label: label.date
  }
]
