import { dateFormat } from './date'
import { label } from './labels'
import { FIELD_TYPES } from './other'

export const headingReportCustomGoodsImportAndExport = [
  { label: label.kod, dbName: 'kod', type: FIELD_TYPES.text },
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.dateInput, dbName: 'datumUnosa', type: FIELD_TYPES.date, format: dateFormat },
  { label: label.countryInput, dbName: 'zemlja', type: FIELD_TYPES.text },
  { label: label.netMass, dbName: 'netoMasaUKg', type: FIELD_TYPES.text },
  { label: label.worthInEuro, dbName: 'vrednostEur', type: FIELD_TYPES.text }
]

export const headingReportWorldSeaPort = [
  { label: label.products, dbName: 'proizvodNaziv', type: FIELD_TYPES.text },
  { label: label.dateOfImportInput, dbName: 'datumUnosa', type: FIELD_TYPES.date },
  { label: label.productSeaPortInput, dbName: 'lukaUsloviNaziv', type: FIELD_TYPES.text },
  { label: label.delivery, dbName: 'isporuka', type: FIELD_TYPES.text },
  { label: 'Cena uneta', dbName1: 'cena', dbName2: 'valutum', dbName3: 'jedinicaMere', type: FIELD_TYPES.join, numFormat: 'money' },
  { label: 'Cena konvertovana', dbName1: 'cenaKonvertovana', dbName2: 'valutaPlacanja', type: FIELD_TYPES.joinTwo, numFormat: 'money' }
]

export const headingForReportsWorldSeaPort = [
  { label: 'proizvod', dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text, style: { width: '30%' } },
  { label: 'datum', dbName: 'datumUnosa', type: FIELD_TYPES.date, style: { width: '15%' } },
  { label: 'uslovi', dbName: 'lukaUsloviUtovara', nestedObj: 'naziv', type: FIELD_TYPES.text, style: { width: '25%' } },
  { label: 'isporuka', dbName: 'isporuka', type: FIELD_TYPES.text, style: { width: '15%' } },
  { label: 'cena', dbName: 'cena', type: FIELD_TYPES.text, style: { width: '15%' } },
  { label: 'promena', dbName: 'proslaCena', type: FIELD_TYPES.text, style: { width: '10%' } }
]

export const headingReportWorldStockExchange = [
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.stockExchange, dbName: 'berza', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.dateOfImportInput, dbName: 'datumUnosa', type: FIELD_TYPES.date, format: dateFormat },
  { label: label.delivery, dbName: 'isporuka', type: FIELD_TYPES.text },
  { label: 'Cena uneta', dbName1: 'cena', dbName2: 'valutum', dbName3: 'jedinicaMere', type: FIELD_TYPES.join, numFormat: 'money' },
  { label: 'Cena konvertovana', dbName1: 'cenaKonvertovana', dbName2: 'valutaPlacanja', type: FIELD_TYPES.joinTwo, numFormat: 'money' }
]

export const headingForReportWorldStockExchange = [
  { label: 'proizvod', dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text, style: { width: '30%' } },
  { label: 'datum', dbName: 'datum', type: FIELD_TYPES.date, style: { width: '15%' } },
  { label: 'isporuka', dbName: 'isporuka', type: FIELD_TYPES.text },
  { label: 'cena', dbName: 'cenaKonvertovana', type: FIELD_TYPES.text, style: { width: '20%' } }
]

export const headingReportPriceMovementBih = [
  { label: label.subGroup, dbName: 'kategorija', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  // { label: label.measureUnitTitle, dbName: 'unetaJedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: 'datum', dbName: 'datum', type: FIELD_TYPES.date },
  // { label: label.priceDatePeriodStart, dbName: 'periodStart', type: FIELD_TYPES.date },
  // { label: label.priceDatePeriodEnd, dbName: 'periodEnd', type: FIELD_TYPES.date },
  { label: label.measureUnit, dbName: 'jedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.currency, dbName: 'valutum', nestedObj: 'kod', type: FIELD_TYPES.text },
  {
    label: 'Cena MIN/MAX',
    dbName1: 'cenaMin',
    dbName2: 'cenaMax',
    dbName3: 'valutum',
    dbName4: 'jedinicaMere',
    type: FIELD_TYPES.joinFour
  },
  {
    label: 'Cena Konvertovana MIN/MAX',
    dbName1: 'cenaMinKonvertovana',
    dbName2: 'cenaMaxKonvertovana',
    dbName3: 'valutaPlacanja',
    type: FIELD_TYPES.joinThree
  },
  { label: 'Period', dbName1: 'periodStart', dbName2: 'periodEnd', type: FIELD_TYPES.range }
]

export const headingForReportsPriceMovementBih = [
  { label: 'proizvod', dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text, style: { width: '30%' } },
  { label: 'datum', dbName: 'datum', type: FIELD_TYPES.date, style: { width: '15%' } },
  { label: 'cena Max', dbName: 'cenaMaxKonvertovana', type: FIELD_TYPES.text, style: { width: '15%' } },
  { label: 'cena Min', dbName: 'cenaMinKonvertovana', type: FIELD_TYPES.text, style: { width: '15%' } },
  { label: 'avg', dbName: 'avg', type: FIELD_TYPES.text, style: { width: '10%' } }
]

export const headingReportWorldStockExchangeMilano = [
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: 'datum Unosa', dbName: 'datum', type: FIELD_TYPES.date },
  { label: label.measureUnit, dbName: 'jedinicaMere', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.currency, dbName: 'valutum', nestedObj: 'kod', type: FIELD_TYPES.text },
  {
    label: 'Cena MIN/MAX',
    dbName1: 'cenaMin',
    dbName2: 'cenaMax',
    dbName3: 'valutum',
    dbName4: 'jedinicaMere',
    type: FIELD_TYPES.joinFour
  },
  {
    label: 'Cena Konvertovana MIN/MAX',
    dbName1: 'cenaMinKonvertovana',
    dbName2: 'cenaMaxKonvertovana',
    dbName3: 'valutaPlacanja',
    type: FIELD_TYPES.joinThree
  }
]

export const headingForReportsWorldStockExchangeMilano = [
  { label: 'proizvod', dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text, style: { width: '30%' } },
  { label: 'datum', dbName: 'datum', type: FIELD_TYPES.date, style: { width: '15%' } },
  { label: 'cena Max', dbName: 'cenaMaxKonvertovana', type: FIELD_TYPES.text, style: { width: '15%' } },
  { label: 'cena Min', dbName: 'cenaMinKonvertovana', type: FIELD_TYPES.text, style: { width: '15%' } },
  { label: 'avg', dbName: 'avg', type: FIELD_TYPES.text, style: { width: '10%' } }
]

export const headingAnnouncementReports = [
  // { label: 'ID', dbName: 'id', type: FIELD_TYPES.text },
  { label: label.date, dbName: 'createdAt', type: FIELD_TYPES.date, style: { width: '100px' } },
  // { label: label.type, dbName: 'tip', type: FIELD_TYPES.text, style: { width: '100px' } },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.productsDescInput, dbName: 'opisProizvodaNaziv', type: FIELD_TYPES.text },
  { label: label.amountInput, dbName1: 'kolicina', dbName3: 'unetaJedinicaMere', type: FIELD_TYPES.join },
  { label: label.priceInput, dbName1: 'cena', dbName2: 'valuta', dbName3: 'unetaCenaJedinicaMere', type: FIELD_TYPES.join },
  { label: label.cities, dbName: 'gradovi', type: FIELD_TYPES.text },
  { label: label.phoneInput, dbName: 'kontakti', type: FIELD_TYPES.arrOfObject, dbObjProp: 'mobilni' },
  { label: label.client, dbName: 'klijent', nestedObj: 'naziv', type: FIELD_TYPES.text }
]
export const headingAnnouncementPriceReports = [
  { label: label.products, dbName: 'proizvodNaziv', type: FIELD_TYPES.text },
  { label: label.productsDescInput, dbName: 'opisProizvodNaziv', type: FIELD_TYPES.text },
  { label: label.amountInput, dbName1: 'kolicina', dbName2: 'kodJm', type: FIELD_TYPES.joinTwo },
  { label: label.priceMinInput, dbName1: 'cenaOd', dbName2: 'kodValuta', type: FIELD_TYPES.joinTwo, numFormat: 'money' },
  { label: label.priceMinInput, dbName1: 'cenaDo', dbName2: 'kodValuta', type: FIELD_TYPES.joinTwo, numFormat: 'money' },
  { label: label.amountNum, dbName: 'kolObjava', type: FIELD_TYPES.decimal },
  { label: label.amountInputReali, dbName1: 'kolicinaRealizovana', dbName2: 'kodJm', type: FIELD_TYPES.joinTwo },
  { label: label.priceMinInputReali, dbName1: 'cenaOdRealizovana', dbName2: 'kodValuta', type: FIELD_TYPES.joinTwo, numFormat: 'money' },
  { label: label.priceMaxInputReali, dbName1: 'cenaDoRealizovana', dbName2: 'kodValuta', type: FIELD_TYPES.joinTwo, numFormat: 'money' }
]

export const headingHistoryReport = [
  { label: label.link, dbName: 'link', type: FIELD_TYPES.link, linkText: 'Link do izvestaja' },
  { label: label.nameInput, dbName: 'naziv', type: FIELD_TYPES.text },
  { label: label.typeOfReport, dbName: 'kod', type: FIELD_TYPES.maped, map: 'historyReport', mapLabel: 'naziv' },
  { label: label.dateOfImportInput, dbName: 'datumUnosa', type: FIELD_TYPES.date }
]
