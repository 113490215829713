/** Queries for 'Oblast' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialWorkField } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedWorkFields = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'oblast',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.workField
    }
  }
}

export const queryGetAllWorkField = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'oblast',
      data: {
        ...options,
        options: {
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.workField
    }
  }
}
/**
 * @param {initialWorkField} data
 */
export const queryAddWorkField = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'oblast',
      data: { values: data }
    },
    invalidatesTags: [tag.workField],
    successMsg: label.successMsgCreate + ' ' + label.msgWorkField
  }
}

/**
* @param {initialWorkField} data
@param {string} id
*/
export const queryUpdateWorkField = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'oblast',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.workField],
    successMsg: label.successMsgEdit + ' ' + label.msgWorkField
  }
}
/**
@param {string} id
*/
export const queryDeleteWorkField = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'oblast',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.workField],
    successMsg: label.successMsgDelete + ' ' + label.msgWorkField
  }
}
