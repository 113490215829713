/** Page  'Category Futures/Kategorija Futures' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { useUploadMediaMutation } from 'api/fileApiSlice'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormCategoryFutures from 'components/forms/formcategoryFutures'
import HistoryTable from 'components/historyTable'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { DATA_TYPES, PAGE_SIZE } from 'constants/other'
import { categorySearchFields } from 'constants/searchFields'
import { headingCategory } from 'constants/tbHeading'
import {
  queryAddCategoryFutures,
  queryDeleteCategoryFutures,
  queryGetPagedCategoriesFutures,
  queryUpdateCategoryFutures
} from 'query/queryCategoryFutures'
import { queryGetAllEconomyBranches } from 'query/queryEconomyBranch'
import { queryGetAllUsers } from 'query/queryUser'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData, setUploadImages } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'

const CategoryFutures = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: categories = [] } = useGetAllQuery(queryGetPagedCategoriesFutures(pageNumber, pageSize, searchOptions))
  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [uploadMedia] = useUploadMediaMutation()

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (categories.rows && categories.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, categories.rows])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, fileIds) => {
    let uploadRes = []
    dispatch(setIsLoadingData(true))
    if (data.id) {
      if (fileIds?.length) {
        uploadRes = await uploadMedia(fileIds)
      }
      const res = await updateData(queryUpdateCategoryFutures({ ...data, uploadRes }, data.id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      if (fileIds?.length) {
        uploadRes = await uploadMedia(fileIds)
      }
      const res = await createData(queryAddCategoryFutures({ ...data, uploadRes }))
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/kategorija?tip=futures&&id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteCategoryFutures(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/kategorija?tip=futures`)
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteCategory} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const showModal = () => {
    dispatch(setUploadImages([]))
    navigate(`/kategorija?tip=futures`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.addCategoryFutures}</h2>
              <FormCategoryFutures onSubmit={onSubmit} id={null} />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/kategorija?tip=futures`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="kategorija"
                type={DATA_TYPES.categoryFutures}
                link="/kategorija?tip=futures&&id="
                connectionTb={[
                  { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
                  { rtkQuery: queryGetAllEconomyBranches(), dbName: 'granaPrivredeId' }
                ]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.categoryFuturesTitle} total={categories?.count}>
        <Button onClick={showModal} label={label.add} iconLeft={<FontAwesomeIcon icon="fa-plus" />} accessAction="ADD" accessCode={code} />
      </Title>
      <Search fields={categorySearchFields} onSearch={onSubmitSearch} />
      {!!categories?.rows?.length && (
        <h3 className="history-link" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns>
        <Table
          heading={headingCategory}
          data={categories.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={categories?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormCategoryFutures existingData={data} header={true} id={id} onSubmit={onSubmit} onDelete={openDeleteModal} code={code} />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default CategoryFutures
