/** Queries for 'Odnos jedinica mere' */

import { tag } from 'api/rtkTags'
import { label } from 'constants/labels'

export const queryGetPagedMeasureUnitsRatio = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'odnosJediniceMera',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          order: [['izJedinicaMereId', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.measureUnitRatio
    }
  }
}

export const queryGetAllMeasureUnitsRatio = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'odnosJediniceMera',
      data: {}
    },
    providesTags: {
      type: tag.measureUnitRatio
    }
  }
}

export const queryAddMeasureUnitsRatio = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'odnosJediniceMera',
      data: { values: data }
    },
    invalidatesTags: [tag.measureUnitRatio],
    successMsg: label.successMsgCreate + ' ' + label.msgMeasureUnit
  }
}

export const queryUpdateMeasureUnitsRatio = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'odnosJediniceMera',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.measureUnitRatio],
    successMsg: label.successMsgEdit + ' ' + label.msgMeasureUnit
  }
}

export const queryDeleteMeasureUnitsRatio = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'odnosJediniceMera',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.measureUnitRatio],
    successMsg: label.successMsgDelete + ' ' + label.msgMeasureUnit
  }
}
