import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropTypes } from 'prop-types'
import styles from './input.module.scss'
import ErrorMessage from '../errorMessage'

const InputPassword = ({
  onChange,
  stylesClass = 'defaultInput',
  globalClass = '',
  name,
  label,
  value,
  placeholder,
  disabled = false,
  id,
  errorTxt
}) => {
  const [type, setType] = useState('password')

  const switchTypes = e => {
    setType(e)
  }

  return (
    <div className={`${styles[stylesClass]} ${globalClass} g-password`}>
      {label && <label>{label}</label>}
      <div className={styles.inputWrapper}>
        <input
          className={styles.input}
          id={id ?? name}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          autoComplete="new-password"
        />
        {value?.length ? (
          type === 'password' ? (
            <FontAwesomeIcon icon="fa-eye" onClick={() => switchTypes('text')}/>
          ) : (
            <FontAwesomeIcon icon="fa-eye-slash" onClick={() => switchTypes('password')}/>
          )
        ) : null}
      </div>
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

InputPassword.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}
export default InputPassword
