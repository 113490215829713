import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import { BoxContainer } from 'components/containers/containers'
import { label } from 'constants/labels'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCurrentUser } from 'redux/reducers/authSlice'

const Home = () => {
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const token = useSelector(state => state.auth.token)
  return (
    <div>
      <BoxContainer globalClass="p-4 ta-c">
        <h1 className="mb-4"> Welcome to infotim admin panel</h1>
        {token ? (
          <h2 className="c-bright t-upper">{user?.korisnickoIme}</h2>
        ) : (
          <div className="d-flex">
            <Button
              onClick={() => navigate('login')}
              label={label.login}
              iconLeft={<FontAwesomeIcon icon="fa-sign-in" />}
              globalClass="mx-2"
            />
          </div>
        )}
      </BoxContainer>
    </div>
  )
}

export default Home
