import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Input from 'components/input'
import Button from '../../components/button/index'
import InputPassword from '../../components/inputPassword/index'
import { BoxContainer } from 'components/containers/containers'
import styles from './auth.module.scss'
import toastService from 'services/toastService'
import { label } from 'constants/labels'

const Register = () => {
  const [error, setError] = useState(false)
  const [user, setUser] = useState({
    email: '',
    password: '',
    username: ''
  })
  const handleChange = e => {
    setError(false)
    const { name, value } = e.target
    setUser(userData => ({ ...userData, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm()) {
      toastService.show('error', 'Missing api')
    } else {
      setError(true)
    }
  }

  const validateForm = () => [user.email, user.password].every(Boolean)

  return (
    <div className={styles.wrapper}>
      <BoxContainer globalClass="p-3">
        <div>
          <h2>Sing up</h2>
          <Input
            className="form-control"
            name="email"
            value={user.email}
            label={label.emailInput}
            onChange={handleChange}
            placeholder={label.emailPlaceholder}
            errorTxt={error && !user.email && label.emailErr}
          />
          <Input
            className="form-control"
            name="username"
            value={user.username}
            label={label.usernameInput}
            onChange={handleChange}
            placeholder={label.placeholder}
            errorTxt={error && !user.username && label.usernameErr}
          />
          <InputPassword
            className="form-control"
            name="password"
            value={user.password}
            label={label.passwordInput}
            onChange={handleChange}
            placeholder={label.placeholder}
            errorTxt={error && !user.password && label.passwordErr}
          />
        </div>
        <div className={styles.btn}>
          <Button onClick={e => handleSubmit(e)} label={label.submit} globalClass="mt-2 w-100" />
        </div>
        <div className="ta-c ">
          Go back to <NavLink to="/">Login page!</NavLink>
        </div>
      </BoxContainer>
    </div>
  )
}
export default Register
