import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormStatus from 'components/forms/formStatus'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
// import Search from "components/search"
// import Table from "components/table"
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { statusFields } from 'constants/searchFields'
import { headingStatus } from 'constants/tbHeading'
import { queryAddStatus, queryDeleteStatus, queryGetPagedStatus, queryUpdateStatus } from 'query/queryStatus'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'

const Status = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const [searchOptions, setSearchOptions] = useState({})
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const { data: statuses = [] } = useGetAllQuery(queryGetPagedStatus(pageNumber, pageSize, searchOptions))
  const [deleteOne] = useDeleteOneMutation()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const statusesForMaping = statuses?.rows || []

  const onSubmitSearch = async data => {
    setSearchOptions(data)
    setPageNumber(1)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/status`)
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/status?id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteService} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteStatus(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/status`)
    } else {
      dispatch(closeModal(1))
    }
  }

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(queryUpdateStatus(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddStatus(data))
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
  }

  const showModal = () => {
    navigate(`/status`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.addStatus}</h2>
              <FormStatus existingData={data} onSubmit={onSubmit} id={null} statuses={statuses?.rows} />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  return (
    <div>
      <Title title={label.statusInput} total={statuses?.count}>
        <Button onClick={showModal} label={label.add} iconLeft={<FontAwesomeIcon icon="fa-plus" />} accessAction="ADD" accessCode={code} />
      </Title>
      <Search fields={statusFields} onSearch={onSubmitSearch} globalClass="border-b-none" />
      <TwoColumns>
        <Table
          heading={headingStatus}
          data={statuses?.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={statuses?.count}
          map={{
            statuses: statusesForMaping.reduce((e, x) => {
              return { ...e, [x.id]: x }
            }, {})
          }}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormStatus
              existingData={data}
              header={true}
              id={id}
              onSubmit={onSubmit}
              onDelete={openDeleteModal}
              statuses={statuses?.rows}
              code={code}
            />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}

export default Status
