/** Form for  add 'Svetske luke' */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import MultipleInputs from 'components/inputMultiple'
import { initialWorldSeaPort, multiFieldsWorldSeaPortInitial } from 'constants/initialValues'
import { label } from 'constants/labels'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { convertStringToDates, getCurrentTime, isObjectEmpty, setDeliveryDate, updateMapCurrencyAndMeasure } from 'utils'
import { validateWorldSeaPort } from '../helpers/validate'
import toastService from 'services/toastService'

const FormAddMultipleWorldSeaPort = ({ existingData = {}, id, onSubmit }) => {
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [convertString, setConvertString] = useState()
  const [data, setData] = useState({
    svetskeLuke: [{ ...initialWorldSeaPort, datumUnosa: getCurrentTime(new Date()) }]
  })
  const [multiError, setMultiError] = useState({})
  const [dinamicMultiData, setDinamicMultiData] = useState(multiFieldsWorldSeaPortInitial)
  const requiredMultiMap = useSelector(state => state.static.multipleRequiredFields)

  useEffect(() => {
    if (!isObjectEmpty(existingData)) {
      const updatedData = existingData.map(item => {
        const formattedString = convertStringToDates(item.isporuka)
        setConvertString(formattedString)
        return {
          ...item,
          datumUnosa: getCurrentTime(new Date())
        }
      })
      pullMapedMultiFields(existingData)
      setData({ svetskeLuke: updatedData })
    }
  }, [id, existingData])

  const pullMapedMultiFields = async data => {
    const mapedOptionsCurrency = {}
    const mapedOptionsMeasure = {}

    data.map(async (el, i) => {
      mapedOptionsCurrency[i] = el.currencyList
      mapedOptionsMeasure[i] = el.unitMeasureList
    })

    const cloneFields = cloneDeep(multiFieldsWorldSeaPortInitial)
    const indx = cloneFields.findIndex(e => e.dbName === 'valutaId')
    cloneFields[indx].mapOptions = mapedOptionsCurrency
    const indx2 = cloneFields.findIndex(e => e.dbName === 'jedinicaMereId')
    cloneFields[indx2].mapOptions = mapedOptionsMeasure
    setDinamicMultiData(cloneFields)
  }
  const handleSubmit = async e => {
    e.preventDefault()
    const reqDataArray = data.svetskeLuke.map(item => ({
      proizvodId: item.proizvodId,
      datumUnosa: item.datumUnosa,
      lukaUsloviUtovaraId: item.lukaUsloviUtovaraId,
      isporuka: item.isporuka,
      cena: item.cena,
      valutaId: item.valutaId,
      jedinicaMereId: item.jedinicaMereId,
      kategorijaId: item.kategorijaId,
      aktivan: item.aktivan,
      podrazumevanaJedinicaMereId: item.podrazumevanaJedinicaMereId,
      podrazumevanaCenaJedinicaMereId: item.podrazumevanaCenaJedinicaMereId,
      podrazumevanValutaId: item.podrazumevanValutaId,
      id: null
    }))
    const isValid = reqDataArray.every(reqData => validateWorldSeaPort(reqData))

    if (isValid) {
      onSubmit(reqDataArray)
    } else {
      setError(true)
    }
  }

  const checkIsMultiValid = (name, data) => {
    const isValidArr = []
    if (requiredMultiMap[name]) {
      data.forEach(e => {
        Object.keys(requiredMultiMap[name]).forEach(key => {
          if (!e[key]) isValidArr.push(false)
        })
      })
    }
    if (!isValidArr.every(Boolean)) {
      setMultiError(prev => ({ ...prev, [name]: true }))
      return
    } else {
      setMultiError(prev => ({ ...prev, [name]: false }))
    }
    return isValidArr.every(Boolean)
  }

  const onAddField = name => {
    const temp = cloneDeep(data.svetskeLuke)
    if (!checkIsMultiValid(name, temp)) return
    temp.push({ ...initialWorldSeaPort, datumUnosa: getCurrentTime(new Date()) })
    setData(prev => ({ ...prev, [name]: temp }))
  }

  const onMultipleChange = async (e, index, field, name, fields) => {
    const { type, dbName, selectValue } = field
    setDinamicMultiData(fields)
    const temp = cloneDeep(data.svetskeLuke)
    if (type === 'input') {
      const { name, value } = e.target
      temp[index][name] = value
    } else if (type === 'select') {
      if (dbName === 'lukaUsloviUtovaraId') {
        temp[index].valutaId = e.valutaId
        temp[index].jedinicaMereId = e.jedinicaMereId
      }
      if (dbName === 'proizvodId') {
        temp[index].podrazumevanaJedinicaMereId = e.jedinicaMereId
        temp[index].podrazumevanValutaId = e.valutaId
        temp[index].podrazumevanaCenaJedinicaMereId = e.cenaJedinicaMereId
        temp[index].kategorijaId = e.kategorijaId
      }
      if (selectValue) temp[index][dbName] = e[selectValue]
      else temp[index][dbName] = e.id
    } else if (type === 'multiselect') {
      if (Array.isArray(e)) {
        const selectedItems = e.map(item => item.id)
        temp[index][dbName] = selectedItems
      }
    } else if (type === 'datapicker') {
      temp[index][dbName] = e ? getCurrentTime(e) : null
    } else if (type === 'multidatepicker') {
      if (!e) setConvertString(null)
      const formattedDates = setDeliveryDate(e)
      temp[index][dbName] = formattedDates
    }
    setData(prev => ({ ...prev, [name]: temp }))
  }

  const onKeyPress = e => {
    if (e && e.target && e.target.name === 'jedinicaMereId' && e.key === 'Enter') {
      onAddField('svetskeLuke')
    }
  }

  const onRemoveMultiselect = (index, item, name) => {
    const temp = cloneDeep(data[name])
    if (temp.length === 1) return toastService.show('warn', 'Morate imati barem jedan unos')
    temp.splice(index, 1)
    setData(prev => ({ ...prev, [name]: temp }))
    const updateMap = updateMapCurrencyAndMeasure(index, dinamicMultiData)
    setDinamicMultiData(updateMap)
  }

  return (
    <div>
      <div>
        <MultipleInputs
          stylesClass="multiThreeFields"
          name="svetskeLuke"
          fields={dinamicMultiData}
          data={data.svetskeLuke}
          onAdd={onAddField}
          onRemove={onRemoveMultiselect}
          onChange={onMultipleChange}
          label={label.multiFieldLabelImport}
          labelForm={label.worldSeaPort}
          errorGroup={error && 'Postoje obavezna polja'}
          errorRow={multiError}
          onKeyPress={onKeyPress}
          customValue={convertString}
        />
      </div>
      {!disabled && (
        <div className="ta-r mt-2">
          <Button
            label={label.save}
            iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
            disabled={disabled}
            onClick={handleSubmit}
            loading={isLoading}
            stylesClass="btnWarning"
          />
        </div>
      )}
    </div>
  )
}

export default FormAddMultipleWorldSeaPort
