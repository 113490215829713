import { monthDay, timeFormat, yearFormat } from 'constants/date'
import { label } from 'constants/labels'
import { FIELD_TYPES } from 'constants/other'

export const defaultHeadingTb = [
  { label: label.korisnik, dbName: 'korisnikId', type: 'korisnikId' },
  { label: label.changes, dbName: 'promena', type: FIELD_TYPES.history },
  { label: label.date, dbName: 'createdAt', type: FIELD_TYPES.date, format: timeFormat },
  { label: 'ID', dbName: 'podatakId', type: 'podatakId' }
]

export const collectionLabelsHistory = {
  korisnik: { message: 'je korisnik sa nazivom', dbName: 'ime' },
  jedinicaMere: { message: 'je jedinica mere sa nazivom', dbName: 'naziv', suffix: 'a' },
  granaPrivrede: { message: 'je grana privrede sa nazivom', dbName: 'naziv', suffix: 'a' },
  kategorija: { message: 'je kategorija sa nazivom', dbName: 'naziv', suffix: 'a' },
  kategorijaLuka: { message: 'je kategorija sa nazivom', dbName: 'naziv', suffix: 'a' },
  kategorijaFutures: { message: 'je kategorija sa nazivom', dbName: 'naziv', suffix: 'a' },
  kategorijaBosna: { message: 'je kategorija sa nazivom', dbName: 'naziv', suffix: 'a' },
  proizvod: { message: 'je proizvod sa nazivom', dbName: 'naziv' },
  proizvodBosna: { message: 'je proizvod sa nazivom', dbName: 'naziv' },
  proizvodLuka: { message: 'je proizvod sa nazivom', dbName: 'naziv' },
  isporuka: { message: 'je isporuka sa nazivom', dbName: 'naziv' },
  delatnost: { message: 'je delatnost sa nazivom', dbName: 'naziv', suffix: 'a' },
  podDelatnost: { message: 'je poddelatnost sa nazivom', dbName: 'naziv', suffix: 'a' },
  drzava: { message: 'je država sa nazivom', dbName: 'naziv', suffix: 'a' },
  grad: { message: 'je grad sa nazivom', dbName: 'naziv' },
  region: { message: 'je region sa nazivom', dbName: 'naziv' },
  opstina: { message: 'je opština sa nazivom', dbName: 'naziv', suffix: 'a' },
  oblast: { message: 'je oblast sa nazivom', dbName: 'naziv', suffix: 'a' },
  usluga: { message: 'je usluga sa nazivom', dbName: 'naziv', suffix: 'a' },
  banka: { message: 'je banka sa nazivom', dbName: 'naziv', suffix: 'a' },
  statusLica: { message: 'je status lica sa nazivom', dbName: 'naziv' },
  vrstaKontakta: { message: 'je vrsta kontakta sa nazivom', dbName: 'naziv', suffix: 'a' },
  valuta: { message: 'je valuta sa nazivom', dbName: 'naziv', suffix: 'a' },
  luka: { message: 'je luka sa nazivom', dbName: 'naziv', suffix: 'a' },
  berza: { message: 'je berza sa nazivom', dbName: 'naziv', suffix: 'a' },
  paket: { message: 'je paket sa nazivom', dbName: 'naziv' },
  tipIzvestaja: { message: 'je tip izveštaj sa nazivom', dbName: 'naziv' },
  blog: { message: 'je blog sa nazivom', dbName: 'naziv' },
  pozicijaFirma: { message: 'je pozicija firma sa nazivom', dbName: 'naziv', suffix: 'a' },
  svetskaBerza: { message: 'je svetska berza sa nazivom', dbName: 'naziv', suffix: 'a' },
  svetskaLuka: { message: 'je svetska luka sa nazivom', dbName: 'naziv', suffix: 'a' },
  kretanjeCenaBih: { message: 'je cena kretanja BIH sa nazivom', dbName: 'naziv', suffix: 'a' },
  objava: { message: 'je objava sa nazivom', dbName: 'naziv', suffix: 'a' },
  klijent: { message: 'je klijent sa nazivom', dbName: 'naziv' },
  klijentNapomene: { message: 'je napomena za klijenta', dbName: 'poruka', foreignKey: 'klijentId', foreignDbName: 'naziv', suffix: 'a' },
  klijentProslave: { message: 'je proslava klijenta', dbName: 'ime', foreignKey: 'klijentId', foreignDbName: 'naziv', suffix: 'a' },
  kontaktOsoba: { message: 'je kontakt osoba klijenta', dbName: 'ime', foreignKey: 'klijentId', foreignDbName: 'naziv', suffix: 'a' },
  proizvodKlijenta: {
    message: 'je proizvod koji klijent prati',
    dbName: 'naziv',
    map: 'proizvodId',
    foreignKey: 'klijentId',
    foreignDbName: 'naziv'
  },
  vremeKontaktaKlijenta: {
    message: 'je vreme kontakta klijenta',
    dbName: 'datumKontakta',
    foreignKey: 'klijentId',
    foreignDbName: 'naziv',
    suffix: 'o'
  },
  delatnostKlijent: {
    message: 'je nova delatnost/podelatnost sa nazivom',
    dbName: 'naziv',
    map: 'delatnostId',
    foreignKey: 'klijentId',
    foreignDbName: 'naziv'
  },
  jedinicaMereProizvod: {
    message: 'je jedinica mere',
    dbName: 'naziv',
    map: 'jedinicaMereId',
    foreignKey: 'proizvodId',
    foreignDbName: 'naziv',
    suffix: 'a'
  },
  opisProizvoda: {
    message: 'je opis proizvoda',
    dbName: 'naziv',
    foreignKey: 'proizvodId',
    foreignDbName: 'naziv'
  },
  tipLica: {
    message: 'je tip lica',
    dbName: 'naziv'
  },
  proizvodFutures: { message: 'je proizvod sa nazivom', dbName: 'naziv' },
  odnosJediniceMera: { message: 'je odnos jedinica mere', dbName: 'naziv' },
  lukaUsloviUtovara: { message: 'je luka-uslovi utovara', dbName: 'naziv' },
  berzaProizvodFutures: {
    message: 'je za proizvod berza',
    dbName: 'naziv',
    suffix: 'a',
    map: 'berzaId',
    foreignKey: 'proizvodId',
    foreignDbName: 'naziv'
  },
  granaPrivredeProizvod: {
    message: 'je za proizvod grana privrede',
    dbName: 'naziv',
    suffix: 'a',
    map: 'granaPrivredeId',
    foreignKey: 'proizvodId',
    foreignDbName: 'naziv'
  },
  proizvodiValute: {
    message: 'je za proizvod valuta',
    dbName: 'naziv',
    suffix: 'a',
    map: 'valutaId',
    foreignKey: 'proizvodId',
    foreignDbName: 'naziv'
  }
}

const commonDbNameLabels = {
  korisnickoIme: { label: 'Korisničko ime', suffix: 'o' },
  lozinka: { label: 'Lozinka', suffix: 'a' },
  ime: { label: 'Ime korisnika', suffix: 'o' },
  prezime: { label: 'Prezime korisnika', suffix: 'o' },
  email: { label: 'Email' },
  mobilni: { label: 'Mobilni' },
  aktivan: { label: 'Status aktivan', type: 'boolean' },
  verifikovan: { label: 'Status verifikovan', type: 'boolean' },
  godinaRodjenja: { label: 'Godina rođenja', suffix: 'a', type: 'date', format: yearFormat },
  maticniBroj: { label: 'Matični broj' },
  datumPocetkaRadnogOdnosa: { label: 'Datum početka radnog odnosa', type: 'date' },
  ulogaId: { label: 'Uloga', map: 'ulogaId', dbName: 'naziv', suffix: 'a' },
  pozicijaFirmaId: { label: 'Pozicija u firmi', map: 'pozicijaFirmaId', dbName: 'naziv', suffix: 'a' },
  naziv: { label: 'Naziv' },
  oznaka: { label: 'Oznaka' },
  sifra: { label: 'Šifra', suffix: 'a', type: 'boolean' },
  rod: { label: 'Rod', type: 'boolean' },
  sort: { label: 'Sort' },
  pozivniBroj: { label: 'Pozivni broj' },
  postanskiBroj: { label: 'Poštanski broj' },
  kod: { label: 'Kod' },
  simbol: { label: 'Simbol' },
  trajanje: { label: 'Tip trajanja' },
  tipTrajanja: { label: 'Trajanja' },
  naslov: { label: 'Naslov' },
  podnaslov: { label: 'Podnaslov' },
  text: { label: 'Text' },
  cenaMax: { label: 'MAX cena', suffix: 'a' },
  cenaMin: { label: 'MIN cena', suffix: 'a' },
  datumUnosa: { label: 'Datum unosa', type: 'date' },
  periodVazenjaCenaStart: { label: 'Početak važenja cene', type: 'date' },
  periodVazenjaCenaEnd: { label: 'Kraj važenja cene', type: 'date' },
  gradNaziv: { label: 'Grad' },
  cena: { label: 'Cena', suffix: 'a' },
  datum: { label: 'Datum unosa', type: 'date' },
  periodStart: { label: 'Početak važenja cene', type: 'date' },
  periodEnd: { label: 'Kraj važenja cene', type: 'date' },
  tip: { label: 'Tip' },
  opis: { label: 'Opis' },
  kolicina: { label: 'Količina', suffix: 'a' },
  kolicinaUneta: { label: 'Količina uneta', suffix: 'a' },
  status: { label: 'Status' },
  napomena: { label: 'Napomena', suffix: 'a' },
  adresa: { label: 'Adresa', suffix: 'a' },
  pib: { label: 'PIB' },
  racun: { label: 'Tekući račun' },
  devizniRacun: { label: 'Devizni račun' },
  sajt: { label: 'Web stranica' },
  direktor: { label: 'Ime direktora', suffix: 'o' },
  direktorTelefon: { label: 'Telefon direktora' },
  kontaktTelefon: { label: 'Kontakt telefon firme' },
  fax: { label: 'Fax firme' },
  opisPoslovanja: { label: 'Opis poslovanja' },
  obradivaPovrsina: { label: 'Obradiva povrsina', suffix: 'a' },
  poreskiObaveznik: { label: 'Poreski obaveznik', type: 'boolean' },
  bpg: { label: 'Broj poljoprivrednog gazdinstva', type: 'boolean' },
  granaPrivredeId: { label: 'Privredna grana', map: 'granaPrivredeId', dbName: 'naziv', suffix: 'a' },
  kategorijaId: { label: 'Kategorija', map: 'kategorijaId', dbName: 'naziv', suffix: 'a' },
  jedinicaMereId: { label: 'Jedinica mere', map: 'jedinicaMereId', dbName: 'naziv', suffix: 'a' },

  delatnostId: { label: 'Delatnost', map: 'delatnostId', dbName: 'naziv', suffix: 'a' },
  podDelatnostId: { label: 'Poddelatnost', map: 'podDelatnostId', dbName: 'naziv', suffix: 'a' },
  opstinaId: { label: 'Opština', map: 'opstinaId', dbName: 'naziv', suffix: 'a' },
  regionId: { label: 'Region', map: 'regionId', dbName: 'naziv' },
  drzavaId: { label: 'Država', map: 'drzavaId', dbName: 'naziv', suffix: 'a' },
  gradId: { label: 'Grad', map: 'gradId', dbName: 'naziv' },
  proizvodId: { label: 'Proizvod', map: 'proizvodId', dbName: 'naziv' },
  opisProizvodaId: { label: 'Opis proizvoda', map: 'opisProizvodaId', dbName: 'naziv' },
  valutaId: { label: 'Valuta', map: 'valutaId', dbName: 'naziv', suffix: 'a' },
  berzaId: { label: 'Berza', map: 'berzaId', dbName: 'naziv', suffix: 'a' },
  klijentId: { label: 'Klijent', map: 'klijentId', dbName: 'naziv' },
  bankaId: { label: 'Banka', map: 'bankaId', dbName: 'naziv', suffix: 'a' },
  korisnikId: { label: 'Komercijalista', map: 'korisnikId', dbName: 'ime' },
  statusId: { label: 'Status', map: 'statusId', dbName: 'naziv' },
  oblastId: { label: 'Oblast', map: 'oblastId', dbName: 'naziv', suffix: 'a' },
  kategorijaBosnaId: { label: 'Kategorija', map: 'kategorijaBosnaId', dbName: 'naziv', suffix: 'a' },
  kategorijaLukaId: { label: 'Kategorija', map: 'kategorijaLukaId', dbName: 'naziv', suffix: 'a' },
  kategorijaFuturesId: { label: 'Kategorija', map: 'kategorijaFuturesId', dbName: 'naziv', suffix: 'a' },
  valutaPlacanja: { label: 'Valuta Plaćanja', dbName: 'valutaPlacanja', suffix: 'a' },
  futuresValutaPlacanja: { label: 'Valuta Plaćanja', dbName: 'futuresValutaPlacanja', suffix: 'a' },
  bosnaValutaPlacanja: { label: 'Valuta Plaćanja', dbName: 'bosnaValutaPlacanja', suffix: 'a' }
}
export const dbLabelsHistory = {
  korisnik: {
    ...commonDbNameLabels,
    ime: { label: 'Ime i prezime', suffix: 'a' }
  },
  jedinicaMere: {
    ...commonDbNameLabels
  },
  granaPrivrede: {
    ...commonDbNameLabels
  },
  kategorija: {
    ...commonDbNameLabels
  },
  kategorijaLuka: {
    ...commonDbNameLabels
  },
  kategorijaBosna: {
    ...commonDbNameLabels
  },
  kategorijaFutures: {
    ...commonDbNameLabels
  },
  proizvod: {
    ...commonDbNameLabels,
    cenaJedinicaMereId: { label: 'Podrazumevana JM za valutu', map: 'jedinicaMereId', dbName: 'naziv', suffix: 'a' },
    jedinicaMereId: { label: 'Podrazumevana JM ', map: 'jedinicaMereId', dbName: 'naziv', suffix: 'a' }
  },
  proizvodBosna: {
    ...commonDbNameLabels
  },
  proizvodLuka: {
    ...commonDbNameLabels
  },
  proizvodFutures: {
    ...commonDbNameLabels
  },
  isporuka: {
    ...commonDbNameLabels
  },
  delatnost: {
    ...commonDbNameLabels
  },
  podDelatnost: {
    ...commonDbNameLabels
  },
  drzava: {
    ...commonDbNameLabels
  },
  grad: {
    ...commonDbNameLabels
  },
  region: {
    ...commonDbNameLabels
  },
  opstina: {
    ...commonDbNameLabels
  },
  oblast: {
    ...commonDbNameLabels
  },
  usluga: {
    ...commonDbNameLabels
  },
  banka: {
    ...commonDbNameLabels
  },
  statusLica: {
    ...commonDbNameLabels
  },
  vrstaKontakta: {
    ...commonDbNameLabels
  },
  valuta: {
    ...commonDbNameLabels
  },
  luka: {
    ...commonDbNameLabels
  },
  berza: {
    ...commonDbNameLabels
  },
  paket: {
    ...commonDbNameLabels
  },
  blog: {
    ...commonDbNameLabels,
    blogStatus: { label: 'Status', map: 'blogStatus', dbName: 'label', suffix: 'a' }
  },
  pozicijaFirma: {
    ...commonDbNameLabels
  },
  svetskaBerza: {
    ...commonDbNameLabels
  },
  svetskaLuka: {
    ...commonDbNameLabels
  },
  kretanjeCenaBih: {
    ...commonDbNameLabels
  },
  objava: {
    ...commonDbNameLabels,
    tip: { label: 'Tip', map: 'tip', dbName: 'label', suffix: 'a' },
    status: { label: 'Status', map: 'status', dbName: 'label', suffix: 'a' }
  },
  klijent: {
    ...commonDbNameLabels,
    status: { label: 'Status', map: 'status', dbName: 'label', suffix: 'a' }
  },
  klijentNapomene: {
    poruka: { label: 'Napomena', suffix: 'a' },
    korisnikId: { label: 'Komercijalista', map: 'korisnikId', dbName: 'ime' }
  },
  klijentProslave: {
    ime: { label: 'Ime i prezime/Slava klijenta', suffix: 'a' },
    datumProslave: { label: 'Datum proslave klijenta', type: 'date', format: monthDay },
    tipProslave: { label: 'Tip proslave klijenta', map: 'tipProslave', dbName: 'label', suffix: 'a' }
  },
  delatnostKlijent: {
    delatnostId: { label: 'Delatnost klijenta', map: 'delatnostId', dbName: 'naziv', suffix: 'a' },
    podDelatnostId: { label: 'Poddelatnost klijenta', map: 'podDelatnostId', dbName: 'naziv', suffix: 'a' }
  },
  kontaktOsoba: {
    ime: { label: 'Ime kontakt osobe', suffix: 'o' },
    email: { label: 'E-mail kontakt osobe' },
    mobilni: { label: 'Mobilni broj kontakt osobe' },
    funkcija: { label: 'Funkcija kontakt osobe', suffix: 'a' },
    kontaktOglas: { label: 'Kontakt za oglas', type: 'boolean' }
  },
  proizvodKlijenta: {
    proizvodId: { label: 'Tip proizvoda koji prati', map: 'proizvodId', dbName: 'naziv' }
  },
  vremeKontaktaKlijenta: {
    korisnikId: { label: 'Komercijalista koji treba kontaktirati klijenta', map: 'korisnikId', dbName: 'ime' },
    vrstaKontaktaId: { label: 'Razlog kontakta klijenata', map: 'vrstaKontaktaId', dbName: 'naziv' },
    datumKontakta: { label: 'Datum kontakta klijenata' }
  },
  jedinicaMereProizvod: {
    ...commonDbNameLabels,
    jedinicaMereId: { label: 'JM proizvoda', map: 'jedinicaMereId', dbName: 'naziv' },
    odnos: { label: 'Odnos jedinice mere' },
    podrazumevana: { label: 'Podrazumevana JM', suffix: 'a', type: 'boolean' },
    podrazumevanaValutaJedinica: { label: 'JM za valutu', type: 'boolean' }
  },
  opisProizvoda: {
    ...commonDbNameLabels,
    naziv: { label: 'Opis proizvoda' }
  },
  odnosJediniceMera: {
    ...commonDbNameLabels,
    izJedinicaMereId: { label: 'Jedninice mere iz koju se konvertuje', map: 'jedinicaMereId', dbName: 'naziv', suffix: 'a' },
    uJedinicaMereId: { label: 'Jedninice mere u koju se konvertuje', map: 'jedinicaMereId', dbName: 'naziv', suffix: 'a' }
  },
  tipLica: {
    ...commonDbNameLabels,
    sifra: { label: 'Vrsta', map: 'sifra', dbName: 'label', suffix: 'a' }
  },
  lukaUsloviUtovara: { ...commonDbNameLabels },
  berzaProizvodFutures: { ...commonDbNameLabels },
  granaPrivredeProizvod: {
    ...commonDbNameLabels
  },
  tipIzvestaja: { ...commonDbNameLabels },
  proizvodiValute: {
    ...commonDbNameLabels
  }
}
