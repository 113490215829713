/** Form for  add 'Kretanje cena BiH' */
import { cloneDeep } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import MultipleInputs from 'components/inputMultiple'
import { initialPriceMovement, multiFieldsPriceMovementBihInitial } from 'constants/initialValues'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentTime, isObjectEmpty, updateMapCurrencyAndMeasure } from 'utils'
import { validatePriceMovementBih } from '../helpers/validate'
import DatePickerComp from 'components/dataPicker'
import toastService from 'services/toastService'

const FormAddMultiplePriceMovementBih = ({ existingData = {}, id, onSubmit, range = { start: null, end: null } }) => {
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [dataRange, setDataRange] = useState(range)
  const [dinamicMultiData, setDinamicMultiData] = useState(multiFieldsPriceMovementBihInitial)
  const [data, setData] = useState({
    kretanjeCenaBih: [{ ...initialPriceMovement, datum: getCurrentTime(new Date()) }]
  })
  const [multiError, setMultiError] = useState({})
  const requiredMultiMap = useSelector(state => state.static.multipleRequiredFields)

  useEffect(() => {
    if (!isObjectEmpty(existingData)) {
      const updatedData = existingData.map(item => ({
        ...item,
        datum: getCurrentTime(new Date())
      }))
      pullMapedMultiFields(existingData)
      setData({ kretanjeCenaBih: updatedData })
    }
  }, [id, existingData])

  const pullMapedMultiFields = async data => {
    const mapedOptionsProducts = {}
    const mapedOptionsCurrency = {}
    const mapedOptionsMeasure = {}
    data.map(async (el, i) => {
      mapedOptionsCurrency[i] = el.currencyList
      mapedOptionsMeasure[i] = el.unitMeasureList
      mapedOptionsProducts[i] = el.productsList
    })
    const cloneFields = cloneDeep(multiFieldsPriceMovementBihInitial)
    const indx = cloneFields.findIndex(e => e.dbName === 'proizvodId')
    cloneFields[indx].mapOptions = mapedOptionsProducts
    const indx1 = cloneFields.findIndex(e => e.dbName === 'valutaId')
    cloneFields[indx1].mapOptions = mapedOptionsCurrency
    const indx2 = cloneFields.findIndex(e => e.dbName === 'jedinicaMereId')
    cloneFields[indx2].mapOptions = mapedOptionsMeasure
    setDinamicMultiData(cloneFields)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const reqDataArray = data.kretanjeCenaBih.map(item => ({
      kategorijaId: item.kategorijaId,
      proizvodId: item.proizvodId,
      datum: item.datum,
      cenaMin: item.cenaMin,
      cenaMax: item.cenaMax,
      jedinicaMereId: item.jedinicaMereId,
      valutaId: item.valutaId,
      aktivan: item.aktivan,
      periodStart: dataRange.start,
      periodEnd: dataRange.end,
      podrazumevanaJedinicaMereId: item.podrazumevanaJedinicaMereId,
      podrazumevanaCenaJedinicaMereId: item.podrazumevanaCenaJedinicaMereId,
      podrazumevanValutaId: item.podrazumevanValutaId,
      id: null
    }))

    const isValid = reqDataArray.every(reqData => validatePriceMovementBih(reqData))
    if (isValid && dataRange.start && dataRange.end) {
      onSubmit(reqDataArray)
    } else {
      setError(true)
    }
  }

  const checkIsMultiValid = (name, data) => {
    const isValidArr = []
    if (requiredMultiMap[name]) {
      data.forEach(e => {
        Object.keys(requiredMultiMap[name]).forEach(key => {
          if (!e[key]) isValidArr.push(false)
        })
      })
    }
    if (!isValidArr.every(Boolean)) {
      setMultiError(prev => ({ ...prev, [name]: true }))
      return
    } else {
      setMultiError(prev => ({ ...prev, [name]: false }))
    }
    return isValidArr.every(Boolean)
  }

  const onAddField = name => {
    const temp = cloneDeep(data.kretanjeCenaBih)
    if (!checkIsMultiValid(name, temp)) return

    temp.push({ ...initialPriceMovement, datum: getCurrentTime(new Date()) })
    setData(prev => ({ ...prev, [name]: temp }))
  }

  const onMultipleChange = async (e, index, field, name, fields) => {
    const { type, dbName, selectValue } = field
    setDinamicMultiData(fields)
    const temp = cloneDeep(data.kretanjeCenaBih)
    if (type === 'input') {
      const { name, value } = e.target
      temp[index][name] = value
    } else if (type === 'select') {
      if (dbName === 'proizvodId') {
        temp[index].jedinicaMereId = e.jedinicaMereId
        temp[index].valutaId = e.valutaId
        temp[index].podrazumevanaJedinicaMereId = e.jedinicaMereId
        temp[index].podrazumevanValutaId = e.valutaId
        temp[index].podrazumevanaCenaJedinicaMereId = e.cenaJedinicaMereId
      }
      if (selectValue) temp[index][dbName] = e[selectValue]
      else temp[index][dbName] = e.id
    } else if (type === 'datapicker') {
      temp[index][dbName] = e ? getCurrentTime(e) : null
    }

    setData(prev => ({ ...prev, [name]: temp }))
  }

  const onKeyPress = e => {
    if (e && e.target && e.target.name === 'cena' && e.key === 'jedinicaMereId') {
      onAddField('kretanjeCenaBih')
    }
  }

  const onDateChange = date => {
    const [start, end] = date
    setDataRange(prev => ({
      ...prev,
      start: start ? getCurrentTime(start) : null,
      end: end ? getCurrentTime(end) : null
    }))
  }

  const onRemoveMultiselect = (index, item, name) => {
    const temp = cloneDeep(data[name])
    if (temp.length === 1) return toastService.show('warn', 'Morate imati barem jedan unos')
    temp.splice(index, 1)
    setData(prev => ({ ...prev, [name]: temp }))
    const updateMap = updateMapCurrencyAndMeasure(index, dinamicMultiData)
    setDinamicMultiData(updateMap)
  }

  return (
    <div>
      <div className="border-b mb-2">
        <div style={{ maxWidth: '400px' }}>
          <DatePickerComp
            startDate={dataRange.start ? new Date(dataRange.start) : null}
            endDate={dataRange.end ? new Date(dataRange.end) : null}
            selectsRange
            onChange={onDateChange}
            label={label.priceDatePeriodInput}
            globalClass="mt-2"
            errorTxt={error && (!dataRange.start || !dataRange.end) && label.reaquiredFiled}
          />
        </div>
      </div>

      {dataRange.start && dataRange.end && (
        <>
          <div>
            <MultipleInputs
              stylesClass="multiThreeFields"
              name="kretanjeCenaBih"
              fields={dinamicMultiData}
              data={data.kretanjeCenaBih}
              onAdd={onAddField}
              onChange={onMultipleChange}
              onRemove={onRemoveMultiselect}
              label={label.multiFieldLabelImport}
              labelForm={label.priceMovementBih}
              errorGroup={error && 'Postoje obavezna polja'}
              errorRow={multiError}
              onKeyPress={onKeyPress}
            />
          </div>
          {!disabled && (
            <div className="ta-r mt-2">
              <Button
                label={label.save}
                iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
                disabled={disabled}
                onClick={handleSubmit}
                loading={isLoading}
                stylesClass="btnWarning"
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FormAddMultiplePriceMovementBih
