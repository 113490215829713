import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import DatePickerComp from 'components/dataPicker'
import Input from 'components/input'
import SelectInput from 'components/selectInput'
import { dateDBFormat } from 'constants/date'
import { validateXLSXImport as valdateForm } from './helpers/validate'
import { errMsgXLSXImport as errorMsg } from './helpers/errorMsg'

const FormXLSXExample = ({ receivedData = {}, onSubmit, isLoading }) => {
  const [error, setError] = useState(false)
  const [data, setData] = useState({
    firstName: receivedData['First Name'] || '',
    lastName: receivedData['Last Name'] || '',
    gender: receivedData.Gender || '',
    age: receivedData.Age || '',
    date: receivedData.Date || '',
    country: receivedData.Country || ''
  })

  useEffect(() => {
    if (valdateForm(data)) setError(false)
    else setError(true)
  }, [data])
  const handleSubmit = async e => {
    e.preventDefault()
    if (valdateForm(data)) onSubmit(data)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onDateChange = (date, name) => {
    const formatted = date ? dayjs(date).format(dateDBFormat) : ''
    setData(formData => ({ ...formData, [name]: formatted }))
  }

  const onSelectChange = (e, name, isMultiselect, key) => {
    if (isMultiselect) {
      setData(prev => ({ ...prev, [name]: Array.isArray(e) ? e.map(x => x[key]) : [] }))
    } else {
      setData(prev => ({ ...prev, [name]: e?.[key] || null }))
    }
  }

  const gender = [
    { label: 'Female', id: 1 },
    { label: 'Male', id: 2 }
  ]

  return (
    <>
      <Input
        name="firstName"
        label="First Name"
        placeholder="Enter First Name"
        value={data.firstName}
        onChange={handleChange}
        errorTxt={error && data.firstName === '' && errorMsg('First Name', data)}
      />
      <Input
        name="lastName"
        label="Last Name"
        placeholder="Enter Last Name"
        value={data.lastName}
        onChange={handleChange}
        errorTxt={error && data.lastName === '' && errorMsg('lastName', data)}
      />
      <SelectInput
        options={gender}
        handleChangeSelect={e => onSelectChange(e, 'gender', false, 'label')}
        selectedOption={gender.filter(el => (data.gender ? data.gender === el.label : null))}
        customValue="label"
        label="Gender:"
        errorTxt={error && data.gender === '' && errorMsg('gender', data)}
      />
      <Input
        name="age"
        label="Age"
        placeholder="Enter age"
        value={data.age}
        onChange={handleChange}
        type="number"
        errorTxt={error && data.age === '' && errorMsg('age', data)}
      />
      <Input
        name="country"
        label="Country"
        placeholder="Enter country"
        value={data.country}
        onChange={handleChange}
        errorTxt={error && data.country === '' && errorMsg('country', data)}
      />
      <DatePickerComp
        startDate={data.date && data.date !== 'Invalid Date' ? new Date(data.date) : null}
        onChange={date => onDateChange(date, 'date')}
        label="Date:"
        errorTxt={(error && data.date === '') || (data.date === 'Invalid Date' && errorMsg('date', data))}
      />
      <div className="ta-r mt-2">
        <Button
          label="Submit"
          iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
          disabled={isLoading}
          onClick={handleSubmit}
          loading={isLoading}
          stylesClass="btnWarning"
        />
      </div>
    </>
  )
}

export default FormXLSXExample
