import React, { useEffect, useState } from 'react'
import { label } from '../../constants/labels'
import styles from './listTimeOfContacts.module.scss'
import { CLIENT_CONNECTION_TB, FIELD_TYPES } from 'constants/other'
import { multiFieldsContactTimeInitial } from 'constants/initialValues'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllContactsTimeByClientId } from 'query/queryClientConectionTables'
import Table from 'components/table'
import MultipleInputs from 'components/inputMultiple'

const ListTimeOfContacts = ({
  data,
  clientTimeOfContacts = [],
  onAddField,
  onRemoveMultiselect,
  onMultipleChange,
  // error,
  // errorMsgFn,
  errorRow
}) => {
  const [upcomingContacts, setUpcomingContacts] = useState([])
  const { data: timeOfContacts = [] } = useGetAllQuery(queryGetAllContactsTimeByClientId(data.id), {
    skip: !data.id
  })
  useEffect(() => {
    if (timeOfContacts.length) {
      const now = Date.now()
      const futureDates = timeOfContacts.filter(el => {
        return el.datumKontakta && new Date(el.datumKontakta).getTime() > now
      })
      setUpcomingContacts(futureDates)
    }
  }, [timeOfContacts])

  return (
    <div className={styles.wrapper}>
      {data.id && (
        <div className={styles.box}>
          {!!upcomingContacts.length && (
            <Table
              data={upcomingContacts}
              heading={[
                { dbName: 'korisnik', label: label.salesSpecialist, type: FIELD_TYPES.text, nestedObj: 'korisnickoIme' },
                { dbName: 'datumKontakta', label: label.contactReasonNextInput, type: FIELD_TYPES.date },
                { dbName: 'vrstaKontaktum', label: label.contactReasonInput, type: FIELD_TYPES.text, nestedObj: 'naziv' }
              ]}
            />
          )}
        </div>
      )}
      <MultipleInputs
        name={CLIENT_CONNECTION_TB.timeOfContacts}
        fields={multiFieldsContactTimeInitial}
        data={clientTimeOfContacts}
        onAdd={onAddField}
        onRemove={onRemoveMultiselect}
        onChange={onMultipleChange}
        label={label.multiFieldLabelTimeContact}
        hideDataWithId={true}
        stylesClass="multiFieldsRow"
        // errorGroup={error && !clientTimeOfContacts.length && errorMsgFn('clientTimeOfContacts', data)}
        errorRow={errorRow}
      />
    </div>
  )
}

export default ListTimeOfContacts
