/** Queries for 'Announcement' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialAnnouncement, initialRealizations } from 'constants/initialValues'
import { label } from 'constants/labels'
export const queryGetPagedAnnouncementSearch = (pageNumber, pageSize, options = {}, type) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'objava_search',
      data: {
        pageNumber,
        pageSize,
        options: { ...options, distinct: true }
      }
    },
    providesTags: {
      type: type === 'demand' ? tag.announcementDemand : tag.announcementSupplay
    }
  }
}

export const queryGetPagedAnnouncement = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'objava',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.announcement
    }
  }
}

export const queryGetAllAnnouncement = (options = {}, values) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'objava',
      data: { options, values }
    },
    providesTags: {
      type: tag.announcement
    }
  }
}
export const queryGetAllAnnouncementRawQuery = (options = {}, values) => {
  return {
    query: {
      name: 'data',
      mode: 'query',
      type: 'objava',
      data: { options, values }
    },
    providesTags: {
      type: tag.announcement
    }
  }
}
export const queryGetAllAnnouncementSearch = (options = {}, values) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'objava_search',
      data: { options, values }
    },
    providesTags: {
      type: tag.announcement
    }
  }
}
/**
 * @param {initialAnnouncement} data
 */
export const queryAddAnnouncement = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'objava',
      data: { values: data }
    },
    invalidatesTags: [tag.announcementSupplay, tag.announcementDemand],
    successMsg: label.successMsgCreate + ' ' + label.msgAnnouncement
  }
}

/**
* @param {initialAnnouncement} data
@param {string} id
*/
export const queryUpdateAnnouncement = data => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'objava',
      data: {
        values: data,
        options: {
          where: {
            id: data.id
          }
        }
      }
    },
    invalidatesTags: [tag.announcementSupplay, tag.announcementDemand, tag.imageAnnoucmentCategory, tag.oneAnnouncement], // tag.oneAnnouncement
    successMsg: label.successMsgEdit + ' ' + label.msgAnnouncement
  }
}
/**
@param {string} id
*/
export const queryDeleteAnnouncement = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'objava',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.announcementSupplay, tag.announcementDemand],
    successMsg: label.successMsgDelete + ' ' + label.msgAnnouncement
  }
}

export const queryGetOneAnnouncement = id => {
  return {
    query: {
      name: 'data',
      mode: 'get',
      type: 'objava',
      data: {
        options: {
          where: { id }
        }
      }
    },
    providesTags: {
      type: tag.oneAnnouncement
    }
  }
}
export const queryGetAllFca = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'frankoUtovar',
      data: {
        options
      }
    },
    providesTags: {
      type: tag.announcementFca
    }
  }
}

export const queryGetAllRealizations = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'objavaRealizacija',
      data: {
        options
      }
    },
    providesTags: {
      type: tag.realizations
    }
  }
}

export const queryGetAllPagedRealizations = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'objavaRealizacija',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.realizations
    }
  }
}
/**
 * @param {initialRealizations} data
 */
export const queryAddRealizations = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'objavaRealizacija',
      data: { values: data }
    },
    invalidatesTags: [tag.realizations, tag.announcementDemand, tag.announcementSupplay, tag.oneAnnouncement],
    successMsg: label.successMsgCreate + ' ' + label.msgRealizations
  }
}
