/** Queries for 'Ips' */

import { tag } from 'api/rtkTags'
import { label } from 'constants/labels'

export const queryGetPagedIps = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'ip',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.ips
    }
  }
}

/**
* @param {boolean} disable
@param {string} id
*/
export const queryDisableIp = (disable, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'ip',
      data: {
        values: { blokiran: disable },
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.ips],
    successMsg: label.successMsgEdit + ' ' + label.msgIp
  }
}
