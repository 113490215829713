import { useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { FormWrapper } from 'components/containers/containers'
import FormClients from 'components/forms/formClient'
import Title from 'components/title'
import { label } from 'constants/labels'
import { queryAddClient } from 'query/queryClient'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setIsLoadingData } from 'redux/reducers/staticSlice'

const CreateClient = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [createData] = useUpdateCreateOneMutation()

  const onSubmit = async data => {
    dispatch(setIsLoadingData(true))
    const res = await createData(queryAddClient(data))
    if (!res.error) navigate(`/klijenti/karton/${res.data.id}`)
    dispatch(setIsLoadingData(false))
  }

  return (
    <div>
      <Title title={label.addClient} goBackUrl="/klijenti" />
      <FormWrapper>
        <FormClients header={true} onSubmit={onSubmit} />
      </FormWrapper>
    </div>
  )
}

export default CreateClient
