import { useState } from 'react'
import { label } from 'constants/labels'
// import Input from 'components/input'
import Button from 'components/button'
import PhoneInputComp from 'components/phoneInput'
import { errForgotPassword as errorMsg } from './helpers/errorMsg'
import { validateForgotPasswordSms as validateForm } from './helpers/validate'

const FormPhoneResetPasword = ({ onSubmit }) => {
  const [data, setData] = useState({ mobilni: '' })
  const [error, setError] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm(data)) onSubmit(data)
    else setError(true)
  }

  const handlePhoneChange = (type, number) => {
    setError(false)
    const phoneNum = number.startsWith('+') ? number : '+' + number
    setData(formData => ({ ...formData, [type]: phoneNum || null }))
  }

  return (
    <div>
      <div>
        <h2>Unesite telefonski broj</h2>
      </div>
      <PhoneInputComp
        value={data.mobilni}
        label={label.mobileInput}
        onChange={phone => handlePhoneChange('mobilni', phone)}
        errorTxt={error && errorMsg('mobilni', data)}
      />
      <div className="ta-r mt-2">
        <Button label={label.send} onClick={handleSubmit} />
      </div>
    </div>
  )
}

export default FormPhoneResetPasword
