import logoImg from 'assets/images/logo/logoIT.png'

export const getCommonHeader = data => {
  const { owner = {} } = data
  return [
    {
      type: 'table',
      class: 'headerTable',
      children: [
        {
          type: 'tr',
          class: 'headerTableTrImg',
          contentEditable: false,
          children: [
            {
              type: 'td',
              children: [{ text: owner?.name?.toString() || 'N/A', img: true, url: logoImg, class: 'headerTbImg' }]
            }
          ]
        },
        {
          type: 'tr',
          class: 'headerTableTrFirst',
          children: [
            {
              type: 'td',
              children: [{ text: owner?.name?.toString() || 'N/A', bold: true }]
            },
            {
              type: 'td',
              children: [{ text: owner?.address?.toString() || 'N/A' }]
            },
            {
              type: 'td',
              children: [
                {
                  text: 'Mob:',
                  bold: true
                },
                {
                  text: `${owner?.mobile?.toString() || 'N/A'} `
                },
                {
                  text: 'Tel:',
                  bold: true
                },
                {
                  text: `${owner?.tel?.toString() || 'N/A'} `
                },
                {
                  text: 'Fax:',
                  bold: true
                },
                {
                  text: `${owner?.fax?.toString() || 'N/A'}`
                }
              ]
            },
            {
              type: 'td',
              children: [{ text: `Web:`, bold: true }, { text: `${owner?.web?.toString() || 'N/A'}` }]
            },
            {
              type: 'td',
              children: [{ text: `E-mail:`, bold: true }, { text: `${owner?.email?.toString() || 'N/A'}` }]
            }
          ]
        },
        {
          type: 'tr',
          class: 'headerTableTrSecond',
          children: [
            {
              type: 'td',
              children: [{ text: `PIB:`, bold: true }, { text: `${owner?.pib?.toString() || 'N/A'}` }]
            },
            {
              type: 'td',
              children: [{ text: `Mat br:`, bold: true }, { text: `${owner?.firmNum?.toString() || 'N/A'}` }]
            },
            {
              type: 'td',
              children: [{ text: `Sifra delatnosti:`, bold: true }, { text: `${owner?.code?.toString() || 'N/A'}` }]
            },
            {
              type: 'td',
              children: [{ text: `Žiro račun:`, bold: true }]
            },
            {
              type: 'td',
              children: [{ text: `${owner?.bankNum1?.toString() || 'N/A'}` }]
            },
            {
              type: 'td',
              children: [{ text: `${owner?.bankNum2?.toString() || 'N/A'}` }]
            }
          ]
        }
      ]
    }
  ]
}
