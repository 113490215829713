// import Button from "components/button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetAllMapQuery, useGetAllQuery, useLazyGetAllQuery } from 'api/dataApiSlice'
import { useGeneratePdfMutation } from 'api/fileApiSlice'
import Button from 'components/button'
import Checkbox from 'components/checkbox'
import { OneColumn } from 'components/containers/containers'
import { LoaderWithText } from 'components/loader'
import Search from 'components/search'
import SelectInput from 'components/selectInput'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { annReportFieldsDemand, annReportFieldsPrice } from 'constants/searchFields'
import { REPORT_ANN_TYPE } from 'constants/staticOptions'
import { headingAnnouncementPriceReports, headingAnnouncementReports } from 'constants/reportHeading'
import { cloneDeep } from 'lodash'
import { queryGetAllAnnouncementRawQuery, queryGetAllAnnouncementSearch } from 'query/queryAnnouncment'
import { queryGetAllCategories } from 'query/querycategory'
import { queryGetAllEconomyBranches } from 'query/queryEconomyBranch'
import { queryGetAllProductDesc } from 'query/queryProductDescription'
import { queryGetAllProducts } from 'query/queryProducts'
import { queryGetAllReportTypes } from 'query/queryReportType'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/reducers/modalSlice'
import { isObjectEmpty } from 'utils'
import styles from './announcment.module.scss'
import Tabs from 'components/tabs'
import FormCreateReport from 'components/forms/formCreateReport'
import dayjs from 'dayjs'
import { dateFormat } from 'constants/date'
import toastService from 'services/toastService'
import { queryGetAllCurrency } from 'query/queryCurrency'
import { queryGetAllMeasureUnits } from 'query/queryMeasureUnit'

export const emailDataExample = {
  subject: 'Izvestaj oglasi',
  title: 'Izvestaj oglasi',
  text: 'Ovaj email sadrzi link sa dostupnim pdf-om'
  // previewName: 'Izvestaj MAJ'
}

const dataReportExportImport = {
  email: emailDataExample,
  tbInfo: {
    name: 'Infotim Logistika',
    description: `Izvestaj oglasi`
  },
  heading: {}
}

const AnnouncementReports = ({ code }) => {
  const dispatch = useDispatch()
  const [tip, setTip] = useState(null)
  const [tipIzvestaja, setTipIzvestaja] = useState(null)
  const [dataTb, setDataTb] = useState([])
  const [pdfUrl, setPdfUrl] = useState([])
  const [filterCategories, setFilterCategories] = useState([])
  const [tabsList, setTabsList] = useState([])
  const [filterEconomy, setFilterEconomy] = useState([])
  const [filterProductsDesc, setFilterProductsDesc] = useState([])
  const [filterProducts, setFilterProducts] = useState([])
  const [period, setPeriod] = useState('')
  const [productsMap, setProductsMap] = useState([])
  const [getPreview, { isLoading }] = useGeneratePdfMutation()
  const { data: economyBranchData = [] } = useGetAllQuery(queryGetAllEconomyBranches())
  const { data: categories = [] } = useGetAllQuery(queryGetAllCategories())
  const { data: products = [] } = useGetAllQuery(queryGetAllProducts())
  const { data: reportTypes = [] } = useGetAllQuery(queryGetAllReportTypes())
  const { data: currencies } = useGetAllMapQuery(queryGetAllCurrency())
  const { data: measureUnits } = useGetAllMapQuery(queryGetAllMeasureUnits())

  const [getLazy] = useLazyGetAllQuery()

  useEffect(() => {
    if (reportTypes.length) {
      const tab = reportTypes
        .filter(e => e.kod === 'PIPO' || e.kod === 'KCS')
        .map(e => {
          return {
            label: e.naziv,
            naziv: e.naziv,
            kod: e.kod,
            type: e.kod
          }
        })
      setTabsList(tab)
      setTipIzvestaja(tab[0])
    }
  }, [reportTypes])

  useEffect(() => {
    if (filterProducts.length) {
      const fetch = async () => {
        try {
          const selectedProducts = filterProducts.map(e => e.id)
          const res = await getLazy(queryGetAllProductDesc({ where: { proizvodId: selectedProducts } })).unwrap()
          const mapProducts = {}
          res.forEach(e => {
            if (mapProducts[e.proizvodId]) mapProducts[e.proizvodId].push(e)
            else mapProducts[e.proizvodId] = [e]
          })
          setFilterProductsDesc(mapProducts)
        } catch (error) {}
      }
      fetch()
      const productsMap = {}
      filterProducts.forEach(e => {
        productsMap[e.id] = e
      })
      setProductsMap(productsMap)
    }
  }, [filterProducts])

  const onDescriptionChange = (e, productId, i) => {
    const temp = cloneDeep(filterProductsDesc)
    temp[productId][i].checked = e.target.checked
    setFilterProductsDesc(temp)
  }

  const onSearch = async data => {
    if (isObjectEmpty(data)) return resetFields()
    setPdfUrl([])
    const searchData = {}
    if (tip === 1) {
      searchData.tip = 'granePrivrede'
      searchData.granePrivrede = filterEconomy.map(e => e.id)
    }
    if (tip === 2) {
      searchData.tip = 'kategorije'
      searchData.kategorije = filterCategories.map(e => e.id)
    }
    if (tip === 3) {
      searchData.tip = 'proizvod'
      const temp = {}
      Object.keys(productsMap).forEach(key => {
        if (filterProductsDesc[key]) {
          const desc = filterProductsDesc[key].filter(e => e.checked)
          temp[key] = desc
        } else temp[key] = []
      })
      searchData.proizvodi = temp
    }
    const dateFound = data.search?.find(e => e.dbField === 'createdAt')
    const dataForFilter = { start: null, end: null }
    if (dateFound?.value) {
      const { start, end } = cloneDeep(dateFound.value)
      const formattedStart = start ? dayjs(start).format(dateFormat) : ''
      const formattedEnd = end ? dayjs(end).format(dateFormat) : ''
      setPeriod(`${formattedStart} - ${formattedEnd}`)
      dataForFilter.start = start
      dataForFilter.end = end
    }

    try {
      if (!dataForFilter.start || !dataForFilter.end) return toastService.show('error', 'Datum od i datum do su obavezna polja')
      if (tipIzvestaja.kod === 'PIPO') {
        const res = await getLazy(
          queryGetAllAnnouncementSearch(
            { reportAnnouncement: { ...searchData }, ...data },
            { tipIzvestaja: tipIzvestaja.kod, datum: dataForFilter }
          )
        ).unwrap()
        setDataTb(res)
      } else {
        const res = await getLazy(
          queryGetAllAnnouncementRawQuery(
            { reportAnnouncement: { ...searchData }, ...data },
            { tipIzvestaja: tipIzvestaja.kod, datum: dataForFilter }
          )
        ).unwrap()
        setDataTb(res)
      }
    } catch (error) {}
  }
  const resetFields = () => {
    setFilterCategories([])
    setFilterProducts([])
    setFilterProductsDesc([])
    setFilterEconomy([])
    setDataTb([])
  }
  const openModalReport = async () => {
    dispatch(
      openModal({
        arrItem: {
          content: <FormCreateReport period={period} selectedReport={tipIzvestaja} typeOfReports={reportTypes} onSubmit={getPreviewPdf} />,
          order: 1,
          size: 'lg'
        },
        active: 1
      })
    )
  }

  const getPreviewPdf = async dataForm => {
    const res = await getPreview({
      values: {
        ...dataReportExportImport,
        tbInfo: {
          ...dataReportExportImport.tbInfo,
          description: dataForm.description
        },
        arr: [...dataTb]
      },
      template: tipIzvestaja && tipIzvestaja.kod === 'KCS' ? 'priceMovement' : 'annoucmentTemplate',
      dataForm
    })
    if (res && res.data && res.data.pdf && res.data.pdf.data) {
      const uint8Array = new Uint8Array(res.data.pdf.data)
      const blob = new Blob([uint8Array], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      setPdfUrl(pdfUrl)
      setDataTb([])
    }
  }

  const onReportChange = tab => {
    setTipIzvestaja(tab)
    setTip(null)
    setDataTb([])
  }

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <LoaderWithText text={label.pleaseWait} />
        </div>
      ) : (
        <>
          <Title title={label.reportAnn} globalClass="mb-2">
            {!!dataTb?.length && (
              <Button
                onClick={openModalReport}
                label={'Kreirajte izveštaj'}
                stylesClass="btnWarning"
                accessAction="ADD"
                accessCode={code}
              />
            )}
          </Title>
          {tabsList.length && (
            <Tabs
              tabs={tabsList}
              active={tipIzvestaja.type}
              onClick={tab => onReportChange(tab)}
              stylesClass="horizontalTabs"
              globalClass="mb-3"
            />
          )}
          {tipIzvestaja && (
            <>
              <div className={styles.filterRow}>
                <div className={styles.firstRow}>
                  <SelectInput
                    options={REPORT_ANN_TYPE || []}
                    handleChangeSelect={e => setTip(e.id)}
                    selectedOption={REPORT_ANN_TYPE.find(el => el.id === tip || null)}
                    customValue="value"
                    customLabel="label"
                    label={label.type}
                  />
                  <div>
                    {tip === 1 && (
                      <SelectInput
                        options={economyBranchData || []}
                        customValue="id"
                        customLabel="naziv"
                        label={label.economyBranch}
                        handleChangeSelect={e => setFilterEconomy(e)}
                        selectedOption={filterEconomy}
                        multiselect
                      />
                    )}
                    {tip === 2 && (
                      <SelectInput
                        options={categories || []}
                        customValue="id"
                        customLabel="naziv"
                        label={label.subGroup}
                        handleChangeSelect={e => setFilterCategories(e)}
                        selectedOption={filterCategories}
                        multiselect
                      />
                    )}
                    {tip === 3 && (
                      <>
                        <SelectInput
                          options={products || []}
                          customValue="id"
                          customLabel="naziv"
                          label={label.products}
                          handleChangeSelect={e => {
                            setFilterProducts(e)
                          }}
                          selectedOption={filterProducts}
                          multiselect
                        />

                        {!isObjectEmpty(filterProductsDesc) && (
                          <div className={styles.productDesc}>
                            {Object.keys(filterProductsDesc).map(key => (
                              <div key={key}>
                                <h4 className="mb-1">{productsMap[key]?.naziv} </h4>
                                {filterProductsDesc[key].length &&
                                  filterProductsDesc[key].map((e, i) => (
                                    <div key={e.id}>
                                      <Checkbox
                                        name={`name_${e.id}`}
                                        label={e.naziv}
                                        onChange={e => onDescriptionChange(e, key, i)}
                                        checked={e.checked}
                                        globalClass="mb-1"
                                      />
                                    </div>
                                  ))}
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {tip ? (
                  tipIzvestaja.kod === 'PIPO' ? (
                    <Search fields={annReportFieldsDemand} onSearch={onSearch} />
                  ) : (
                    <Search fields={annReportFieldsPrice} onSearch={onSearch} />
                  )
                ) : null}
              </div>

              {tip && (
                <OneColumn>
                  <Table
                    heading={tipIzvestaja.kod === 'PIPO' ? headingAnnouncementReports : headingAnnouncementPriceReports}
                    data={dataTb}
                    total={dataTb?.length}
                    map={{
                      jedinicaMere: measureUnits?.entities || {},
                      valute: currencies?.entities || {}
                    }}
                  />
                </OneColumn>
              )}

              {pdfUrl && pdfUrl.length > 0 && (
                <div>
                  <div className={styles.buttonPosition}>
                    <div>
                      <Button onClick={() => setPdfUrl([])} globalClass={'mb-2'} iconLeft={<FontAwesomeIcon icon="fa-trash-can" />} />
                    </div>
                  </div>
                  <embed src={pdfUrl} width="100%" height="800px" alt="pdf" type="application/pdf"></embed>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default AnnouncementReports
