export const ANNOUNCEMENT_STATUS = [
  { label: 'Razmatranje', id: 1 },
  { label: 'Nedovršeno', id: 5 },
  { label: 'Odobreno', id: 2 },
  { label: 'Neaktivan', id: 4 },
  { label: 'Odbijeno', id: 3 }
]
export const ANNOUNCEMENT_TYPE = [
  { label: 'Ponuda', id: 1 },
  { label: 'Potraznja', id: 2 }
]

export const CELEBRATION_TYPE = [
  { label: 'Rođendan', id: 1 },
  { label: 'Slava', id: 2 }
]
export const BLOGS_STATUS = [
  { label: 'Objavljeno', id: 1 },
  { label: 'Neobjavljeno', id: 2 },
  { label: 'Arhiva', id: 3 }
]

export const PAYMENT_TYPE = [
  { id: 1, label: 'Žiralno plaćanje' },
  { id: 2, label: 'Avansno plaćanje' },
  { id: 3, label: 'Dogovor' },
  { id: 4, label: 'Kompezacija' },
  { id: 5, label: 'Odloženo plaćanje' },
  { id: 6, label: 'Akontaciono plaćanje' }
]
export const statusPackage = [
  { label: 'Status-1', id: 1 },
  { label: 'Status-2', id: 2 },
  { label: 'Status-3', id: 3 },
  { label: 'Status-4', id: 4 }
]
export const PACKAGE_DURATION = [
  { label: 'dan', id: 1 },
  { label: 'mesec', id: 2 },
  { label: 'godina', id: 3 }
]
export const ENTITY = [
  { sifra: 'GZ', label: 'Gazdinstvo' },
  { sifra: 'PL', label: 'Pravno lice' }
]

export const STATUS_CLIENT_OPTIONS = [
  { label: 'Dodat u sistem', id: 3 },
  { label: 'Odbijen', id: 4 },
  { label: 'Aktivan', id: 5 },
  { label: 'Neaktivan', id: 6 }
]

export const STATUS_CLIENT = [
  { label: 'Novo', id: 1 },
  { label: 'Pregledan', id: 2 },
  { label: 'Dodat u sistem', id: 3 },
  { label: 'Odbijen', id: 4 },
  { label: 'Aktivan', id: 5 },
  { label: 'Neaktivan', id: 6 }
]

export const STATUS_CLIENT_ID = {
  novi: 1,
  pregledan: 2,
  dodat: 3,
  odbijen: 4,
  aktivan: 5,
  neaktivan: 6
}
export const REPORT_ANN_TYPE = [
  { label: 'Privredna grana', id: 1 },
  { label: 'Kategorija', id: 2 },
  { label: 'Proizvod', id: 3 }
]
