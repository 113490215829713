import { useDeleteOneMutation, useGetOneQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { FormWrapper } from 'components/containers/containers'
import FormPackage from 'components/forms/formPackage'
import ModalYesNo from 'components/modal/modalYesNo'
import Title from 'components/title'
import { label } from 'constants/labels'
import { queryAddPackage, queryDeletePackage, queryGetPackageById, queryUpdatePackage } from 'query/queryPackage'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsLoadingData } from 'redux/reducers/staticSlice'

const AddEditPackage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [createData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const { data = {}, refetch } = useGetOneQuery(queryGetPackageById(id), { skip: !id })

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await createData(queryUpdatePackage(data, id))
      if (!res.error) {
        navigate(`/paket`)
        dispatch(setIsLoadingData(false))
      }
    } else {
      const res = await createData(queryAddPackage(data))
      if (!res.error) {
        dispatch(closeModal(1))
        navigate(`/paket`)
        dispatch(setIsLoadingData(false))
      }
    }
    dispatch(setIsLoadingData(false))
    refetch()
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeletePackage(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/paket`)
    } else {
      dispatch(closeModal(1))
    }
  }

  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeletePackage} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.packageInput} goBackClick={() => navigate('/paket')} globalClass="mb-2" />
      <FormWrapper>
        <FormPackage existingData={data} id={id} onSubmit={onSubmit} onDelete={openDeleteModal} />
      </FormWrapper>
    </div>
  )
}

export default AddEditPackage
