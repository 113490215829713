/** Page  'Uloge' */
import { useGetOneQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { LoaderText } from 'components/loader'
import MessageNoData from 'components/messageNoData'
import Title from 'components/title'
import { queryAddRole, queryGetAllForms, queryGetRoleById, queryUpdateRole } from 'query/queryRole'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { deepClone, isObjectEmpty } from 'utils'
import styles from './addEdit.module.scss'
import { label } from 'constants/labels'
import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import toastService from 'services/toastService'
import { initialRole } from 'constants/initialValues'
import { triggerUserApi } from 'redux/reducers/staticSlice'
import { useDispatch } from 'react-redux'

export const isDisabledRole = (actions, action) => actions.indexOf(action) === -1

export const isCheckedRole = (role, formCode, action) => {
  return [
    role && role.pravaPristupa && role.pravaPristupa[formCode] !== undefined && role.pravaPristupa[formCode].indexOf(action) !== -1
  ].every(Boolean)
}

const headingRoles = {
  VIEW: label.roleView,
  ADD: label.roleAdd,
  UPDATE: label.roleEdit,
  DELETE: label.roleDelete
}

const AddEditRole = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [forms, setForms] = useState([])
  const [role, setRole] = useState(initialRole)
  const [allChecked, setAllChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [err, setError] = useState(false)
  const { data: roleData = {}, isSuccess: isSuccessRole, refetch } = useGetOneQuery(queryGetRoleById(id), { skip: !id })
  const { data: formsData = {}, isSuccess: isSuccessForms, isLoading: isLoadingForms } = useGetOneQuery(queryGetAllForms())
  const [createRole] = useUpdateCreateOneMutation()

  useEffect(() => {
    if (isSuccessForms) setForms(formsData)
  }, [isSuccessForms, formsData])

  useEffect(() => {
    if (isSuccessRole) setRole(roleData)
  }, [isSuccessRole, roleData])

  const onCheckAll = e => {
    const { checked } = e.target
    setAllChecked(checked)
    const temp = deepClone(role)
    const rules = {}
    temp.pravaPristupa = {}
    if (checked) {
      forms.forEach(element => {
        rules[element.sifra] = element.akcije
      })
      temp.pravaPristupa = rules
    } else {
      temp.pravaPristupa = {}
    }
    setRole(temp)
  }

  const onCheckboxChange = (e, formCode, action) => {
    const { checked } = e.target
    const temp = deepClone(role)
    if (!temp.pravaPristupa) temp.pravaPristupa = {}
    if (checked) {
      if (!temp.pravaPristupa[formCode]) {
        temp.pravaPristupa[formCode] = []
      }
      if (action !== 'VIEW' && !temp.pravaPristupa[formCode].find(e => e === 'VIEW')) temp.pravaPristupa[formCode].push('VIEW')
      temp.pravaPristupa[formCode].push(action)
    } else {
      const index = temp.pravaPristupa[formCode].indexOf(action)
      temp.pravaPristupa[formCode].splice(index, 1)
      if (action === 'VIEW') temp.pravaPristupa[formCode] = []
      if (temp.pravaPristupa[formCode].length === 0) {
        delete temp.pravaPristupa[formCode]
      }
    }
    setRole(temp)
  }

  const handleSubmit = async () => {
    if (!role.naziv) return setError(true)
    if (isObjectEmpty(role.pravaPristupa)) return toastService.show('error', label.roleErrAccess)
    setLoading(true)
    if (!id) {
      const res = await createRole(queryAddRole(role))
      if (!res.error) navigate('/uloge')
    } else {
      await createRole(queryUpdateRole(role, id))
    }
    refetch()
    dispatch(triggerUserApi(true))
    setLoading(false)
  }

  if (isLoadingForms) return <LoaderText text="Loading..." />
  return (
    <div className={styles.wrapper}>
      <Title title={!id ? label.roleAddNew : role?.naziv || 'N/A'} goBackUrl={'/uloge'} globalClass="mb-2" />
      <div className={styles.body}>
        <div className={styles.tableHead}>
          <Input
            name="name"
            label={label.nameInput}
            value={role?.naziv}
            onChange={e => setRole(prev => ({ ...prev, naziv: e.target.value }))}
            placeholder={label.namePlaceholder}
            errorTxt={err && !role.naziv && label.nameInputErr}
          />
          <Checkbox name="checkAll" label={label.roleCheckAll} isSelected={allChecked} onChange={e => onCheckAll(e)} globalClass="mb-1" />
        </div>
        {forms && forms.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th className={styles.firstColumn}>{label.nameInput}</th>
                {Object.keys(headingRoles).map(key => (
                  <th key={key} className={styles.otherColumn}>
                    {headingRoles[key]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {forms.map(form => (
                <tr key={form.sifra}>
                  <td className={styles.firstColumn}>{form.naziv}</td>
                  {Object.keys(headingRoles).map(key =>
                    form.akcije.map(action => (
                      <React.Fragment key={form.sifra + action}>
                        {key === action && (
                          <td className={styles.otherColumn}>
                            <Checkbox
                              name={form.sifra + action}
                              checked={isCheckedRole(role, form.sifra, action)}
                              onChange={e => onCheckboxChange(e, form.sifra, action)}
                              isDisabled={isDisabledRole(form.akcije, action)}
                            />
                          </td>
                        )}
                      </React.Fragment>
                    ))
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <MessageNoData message={label.messageNoData} />
        )}
        <div className="flex-row justify-end mt-3">
          <Button
            label={label.save}
            iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
            stylesClass="btnWarning"
          />
        </div>
      </div>
    </div>
  )
}

export default AddEditRole
