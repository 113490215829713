import ErrorMessage from '../errorMessage'
import ReactCodeInput from 'react-code-input'
import styles from './input.module.scss'

const InputCode = ({ onChange, stylesClass = 'defaultCodeInput', globalClass = '', name, label, value, errorTxt, type, fields }) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass}`}>
      {label && <label>{label}</label>}
      <div className={styles.inputWrapper}>
        <ReactCodeInput type={type} fields={fields} className={styles.input} name={name} value={value} onChange={onChange} />
      </div>
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

export default InputCode
