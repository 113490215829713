/** Queries for 'Istorija/History' */
import { tag } from 'api/rtkTags'

export const queryGetPagedHistoryByType = (pageNumber, pageSize, collection, id, type) => {
  let orQuery = {}
  if (id) {
    orQuery = {
      sqlType: {
        OR: [
          { kolekcija: collection, podatakId: id, tip: type },
          { straniKljucId: id, zavisnaKolekcija: collection, tip: type }
        ]
      }
    }
  } else {
    orQuery = {
      sqlType: {
        OR: [
          { kolekcija: collection, tip: type },
          { zavisnaKolekcija: collection, tip: type }
        ]
      }
    }
  }

  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'istorija',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...orQuery,
          order: [['createdAt', 'DESC']]
        }
      }
    },
    providesTags: {
      type: tag.history
    }
  }
}
