import React from 'react'
import { PropTypes } from 'prop-types'
import styles from './errorMsg.module.scss'

const ErrorMessage = ({ message, globalClass = '' }) => {
  return <span className={`${styles.errMsg} ${globalClass} g-error-msg`}>{message}</span>
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
  globalClass: PropTypes.string
}
export default ErrorMessage
