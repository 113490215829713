/** Form for  add/edit/remove  'User' */

import Input from 'components/input'
import SelectInput from 'components/selectInput'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { isObjectEmpty, getCurrentTime } from 'utils'
import FormHeader from './formHeader'
import { errMsgFormUser } from './helpers/errorMsg'
import { initialUser } from 'constants/initialValues'
import { validateUserForm as valdateForm } from './helpers/validate'
import FormAdminChangePassword from './formAdminChangePassword'
import { openModal } from 'redux/reducers/modalSlice'
import InputPassword from 'components/inputPassword'
import YearPickerComp from 'components/dataPicker/yearPicker'
import DatePickerComp from 'components/dataPicker'
import PhoneInputComp from 'components/phoneInput'
import FormBtnSave from './formHeader/formButton'

const FormUser = ({
  roles = [],
  positionInCompany = [],
  existingData = {},
  id,
  onSubmit,
  onPasswordChange,
  onDelete,
  header = false,
  code
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialUser, mobilni: !id && '+381' })

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      aktivan: data.aktivan ? data.aktivan : true,
      korisnickoIme: data.korisnickoIme,
      lozinka: data.lozinka,
      ime: data.ime,
      prezime: data.prezime,
      email: data.email,
      mobilni: data.mobilni,
      poslovniBroj: data.poslovniBroj,
      ulogaId: data.ulogaId,
      godinaRodjenja: data.godinaRodjenja,
      pozicijaFirmaId: data.pozicijaFirmaId,
      maticniBroj: data.maticniBroj,
      datumPocetkaRadnogOdnosa: data.datumPocetkaRadnogOdnosa
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) {
      onSubmit(reqData, id)
    } else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const onSelectChange = (e, name) => {
    setData(prev => ({ ...prev, [name]: e?.id || null }))
  }

  const showModal = () => {
    dispatch(
      openModal({
        arrItem: { content: <FormAdminChangePassword id={id} onSubmit={onPasswordChange} />, order: 1, size: 'md' },
        active: 1
      })
    )
  }

  const handlePhoneChange = (type, number) => {
    const phoneNum = number.startsWith('+') ? number : '+' + number
    setData(formData => ({ ...formData, [type]: phoneNum || null }))
  }

  const onDateChange = (date, name) => {
    setData(formData => ({ ...formData, [name]: date ? getCurrentTime(date) : null }))
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.korisnickoIme}
            label={label.korisnik}
            disabled={disabled}
            code={code}
            component={
              data.id && (
                <h6 className="" onClick={showModal}>
                  {label.forgotPassword}
                </h6>
              )
            }
          />
        </div>
      )}
      <Input
        name="korisnickoIme"
        label={label.usernameInput}
        placeholder={label.placeholder}
        value={data.korisnickoIme}
        onChange={handleChange}
        disabled={disabled}
        isPreview={disabled}
        errorTxt={error && !data.korisnickoIme && errMsgFormUser('korisnickoIme', data)}
      />
      {!data.id && (
        <InputPassword
          name="lozinka"
          label={label.passwordInput}
          placeholder={label.placeholder}
          value={data.lozinka}
          onChange={handleChange}
          errorTxt={error && !data.lozinka && errMsgFormUser('lozinka', data)}
          type="password"
          isPreview={disabled}
          disabled={disabled}
        />
      )}
      <Input
        name="ime"
        label={label.companyFarmNameInput}
        placeholder={label.placeholder}
        value={data.ime}
        onChange={handleChange}
        disabled={disabled}
        isPreview={disabled}
        errorTxt={error && !data.ime && errMsgFormUser('ime', data)}
      />
      {/* <Input
        name="prezime"
        label={label.lastNameInput}
        placeholder={label.placeholder}
        
        value={data.prezime}
        onChange={handleChange}
        disabled={disabled}
        isPreview={disabled}
        errorTxt={error && !data.prezime && errMsgFormUser('prezime', data)}
      /> */}
      <YearPickerComp
        name="godinaRodjenja"
        startDate={data.godinaRodjenja ? new Date(data.godinaRodjenja) : null}
        onChange={date => setData(prev => ({ ...prev, godinaRodjenja: new Date(date) }))}
        label={label.birthYear}
        isPreview={disabled}
        disabled={disabled}
        errorTxt={error && !data.godinaRodjenja && errMsgFormUser('godinaRodjenja', data)}
      />
      <Input
        id="userEmail"
        name="email"
        label={label.emailInput}
        placeholder={label.placeholder}
        value={data.email}
        onChange={handleChange}
        disabled={disabled}
        isPreview={disabled}
        errorTxt={error && errMsgFormUser('email', data)}
      />
      {/* <Input
        name="mobilni"
        label={label.mobileInput}
        placeholder={label.placeholder}
        
        value={data.mobilni}
        disabled={disabled}
        isPreview={disabled}
        onChange={handleChange}
        errorTxt={error && errMsgFormUser('mobilni', data)}
      /> */}
      <PhoneInputComp
        value={data.mobilni}
        label={label.mobileInput}
        onChange={phone => handlePhoneChange('mobilni', phone)}
        errorTxt={error && errMsgFormUser('mobilni', data)}
        disabled={disabled}
        isPreview={disabled}
      />
      <DatePickerComp
        name="datumPocetkaRadnogOdnosa"
        startDate={data.datumPocetkaRadnogOdnosa ? new Date(data.datumPocetkaRadnogOdnosa) : null}
        onChange={date => onDateChange(date, 'datumPocetkaRadnogOdnosa')}
        label={label.workingInCompanyStartDate}
        errorTxt={error && !data.datumPocetkaRadnogOdnosa && errMsgFormUser('datumPocetkaRadnogOdnosa', data)}
        disabled={disabled}
        isPreview={disabled}
      />
      {/* <Input
        name="pozicijaUFirmi"
        label={label.positionInCompany}
        placeholder={label.placeholder}
        
        value={data.pozicijaUFirmi}
        onChange={handleChange}
        disabled={disabled}
        isPreview={disabled}
        errorTxt={error && !data.pozicijaFirmaId && errMsgFormUser('pozicijaUFirmi', data)}
      /> */}
      <SelectInput
        options={positionInCompany}
        handleChangeSelect={e => onSelectChange(e, 'pozicijaFirmaId')}
        selectedOption={positionInCompany.find(el => el.id === data.pozicijaFirmaId)}
        customValue="id"
        customLabel="naziv"
        label={label.positionInCompany}
        disabled={disabled}
        isPreview={disabled}
        errorTxt={error && !data.pozicijaFirmaId && errMsgFormUser('pozicijaUFirmi', data)}
      />
      <Input
        name="maticniBroj"
        label={label.maticniBroj}
        placeholder={label.placeholder}
        value={data.maticniBroj}
        onChange={handleChange}
        disabled={disabled}
        isPreview={disabled}
        errorTxt={error && !data.maticniBroj && errMsgFormUser('maticniBroj', data)}
      />
      <Input
        name="poslovniBroj"
        label={label.businessPhone}
        placeholder={label.placeholder}
        value={data.poslovniBroj}
        disabled={disabled}
        isPreview={disabled}
        onChange={handleChange}
        errorTxt={error && errMsgFormUser('poslovniBroj', data)}
      />
      <SelectInput
        options={roles}
        handleChangeSelect={e => onSelectChange(e, 'ulogaId')}
        selectedOption={roles.find(el => el.id === data.ulogaId)}
        customValue="id"
        customLabel="naziv"
        label={label.ulogaInput}
        disabled={disabled}
        isPreview={disabled}
        errorTxt={error && !data.ulogaId && errMsgFormUser('ulogaId', data)}
      />
      {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
    </div>
  )
}

export default FormUser
