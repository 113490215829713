/** Form for  add/edit/remove  'Valuta' */

import Checkbox from 'components/checkbox'
import Input from 'components/input'
import { initialCurrency } from 'constants/initialValues'
import { label } from 'constants/labels'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import { errCurrency as errorMsg } from './helpers/errorMsg'
import { validateCurrency as valdateForm } from './helpers/validate'
import FormBtnSave from './formHeader/formButton'

const FormCurrency = ({ existingData = {}, id, onSubmit, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialCurrency })

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      naziv: data.naziv,
      kod: data.kod,
      simbol: data.simbol,
      aktivan: data.aktivan
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.currency}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.placeholder}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <Input
          name="kod"
          label={label.kodInput}
          placeholder={label.placeholder}
          value={data.kod}
          onChange={handleChange}
          errorTxt={error && !data.kod && errorMsg('kod', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <Input
          name="simbol"
          label={label.simbolInput}
          placeholder={label.placeholder}
          value={data.simbol}
          onChange={handleChange}
          errorTxt={error && !data.simbol && errorMsg('simbol', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <Checkbox
          name="aktivan"
          label={label.activeInput}
          checked={data.aktivan}
          onChange={e => setData(formData => ({ ...formData, aktivan: e.target.checked }))}
          globalClass="mt-3 "
          disabled={disabled}
        />
        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormCurrency
