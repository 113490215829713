/** Page  'Carinske Robe Izvoz-Izvestaj' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLazyGetAllQuery } from 'api/dataApiSlice'
import { useGeneratePdfMutation } from 'api/fileApiSlice'
import Button from 'components/button'
import { OneColumn } from 'components/containers/containers'
import { LoaderWithText } from 'components/loader'
import ReportCustomGoodsImportAndExport from 'components/reports/reportCustomGoodsImportAndExport/reportCustomGoodsImportAndExportAndExport'
import Search from 'components/search'
import Title from 'components/title'
import { dateFormat } from 'constants/date'
import { label } from 'constants/labels'
import { headingReportCustomGoodsImportAndExport } from 'constants/reportHeading'
import { customGoodsImportAndExportFields } from 'constants/searchFields'
import dayjs from 'dayjs'
import { queryGetAllCustomGoodsExport } from 'query/queryCustomGoodsExport'
import { useState } from 'react'
import { isObjectEmpty } from 'utils'
import styles from './customGoodsExportReport.module.scss'

export const emailDataExample = {
  subject: 'Izvestaj carinske robe',
  title: 'Izvestaj carinske robe',
  text: 'Ovaj email sadrzi link sa dostupnim pdf-om'
  // previewName: 'Izvestaj MAJ'
}

const CustomGoodsExportReport = ({ code }) => {
  const [, setSearchOptions] = useState({})
  const [pdfData, setPdfData] = useState({})
  const [runSearch] = useLazyGetAllQuery()
  const [getPreview, { isLoading }] = useGeneratePdfMutation()
  const [data, setData] = useState([])
  const [pdfUrl, setPdfUrl] = useState([])
  const [period, setPeriod] = useState('')

  const dataReportExportImport = {
    email: emailDataExample,
    tbInfo: {
      name: 'Infotim Logistika',
      description: `Izvestaj Carinske robe izvoz ${period ? ` za ${period}` : ''}`
    },
    heading: {}
  }

  headingReportCustomGoodsImportAndExport?.forEach(item => {
    dataReportExportImport.heading[item.dbName] = item.label
  })

  const getPreviewPdf = async () => {
    const dataForm = {
      naziv: `Izvestaj Carinske robe izvoz ${period ? ` za ${period}` : ''}`,
      datumUnosa: new Date(),
      kod: 'CRI'
    }
    const res = await getPreview({ values: pdfData, template: 'tableReportCustomGoods', dataForm })
    if (res && res.data && res.data.pdf && res.data.pdf.data) {
      const uint8Array = new Uint8Array(res.data.pdf.data)
      const blob = new Blob([uint8Array], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      setPdfUrl(pdfUrl)
      setData([])
    }
  }

  const onSubmitSearch = async data => {
    if (!isObjectEmpty(data)) setData([])
    setPdfUrl([])
    const res = await runSearch(queryGetAllCustomGoodsExport(data))
    if (!res.error) {
      setData(res.data)
      const { search } = data
      if (Array.isArray(search) && search.length > 0) {
        for (const item of search) {
          if (item.type === 'date' && item.dbField === 'datum') {
            const { start, end } = item.value
            const formattedStart = start ? dayjs(start).format(dateFormat) : ''
            const formattedEnd = end ? dayjs(end).format(dateFormat) : ''
            setPeriod(`${formattedStart} - ${formattedEnd}`)
            break
          }
        }
      }
    }
    setSearchOptions(data)
  }

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <LoaderWithText text={label.pleaseWait} />
        </div>
      ) : (
        <>
          <Title title={label.customGoodsExport} total={data.length}>
            {!!data?.length && (
              <Button
                onClick={() => getPreviewPdf()}
                label={label.createReport}
                iconLeft={<FontAwesomeIcon icon="fa-save" />}
                accessAction="ADD"
                accessCode={code}
                stylesClass="btnWarning"
              />
            )}
          </Title>
          <Search fields={customGoodsImportAndExportFields} onSearch={onSubmitSearch} />
          <OneColumn>
            <ReportCustomGoodsImportAndExport
              heading={headingReportCustomGoodsImportAndExport}
              data={data}
              total={data?.length}
              setPdfData={prev => setPdfData({ ...prev, ...dataReportExportImport })}
            />
          </OneColumn>
          {pdfUrl && pdfUrl.length > 0 && (
            <div>
              <div className={styles.buttonPosition}>
                <div>
                  <Button onClick={() => setPdfUrl([])} globalClass={'mb-2'} iconLeft={<FontAwesomeIcon icon="fa-trash-can" />} />
                </div>
              </div>
              <embed src={pdfUrl} width="100%" height="800px" alt="pdf" type="application/pdf"></embed>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default CustomGoodsExportReport
