import React from 'react'
import styles from './line.module.scss'
import { Line as LineChart } from 'react-chartjs-2'
import variables from '../../../styles/variables.scss'
import { faker } from '@faker-js/faker'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'

const Line = ({
  titleText = 'Line grafikon',
  labelName1 = 'Dataset 1',
  labelName2 = 'Dataset 2',
  legendPosition = 'top',
  globalClass = '',
  dataset1,
  dataset2
}) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: legendPosition
      },
      title: {
        display: true,
        text: titleText
      }
    }
  }

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

  const data = {
    labels,
    datasets: [
      {
        label: labelName1,
        data: !dataset1 && labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: variables.green,
        backgroundColor: variables.greenLight
      },
      {
        label: labelName2,
        data: !dataset2 && labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: variables.red,
        backgroundColor: variables.redLight
      }
    ]
  }

  return (
    <div className={styles.container}>
      <LineChart options={options} data={data} className={globalClass} />
    </div>
  )
}

export default Line
