/** Queries for 'Proizvod Milano' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialProductMilano } from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES } from 'constants/other'

export const queryGetPagedProductsMilano = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'proizvod',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          where: { tipPodatka: DATA_TYPES.productsMilano },
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.productsMilano
    }
  }
}

export const queryGetAllProductsMilano = (options = {}) => {
  const query = { tipPodatka: DATA_TYPES.productsMilano }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'proizvod',
      data: {
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.productsMilano
    }
  }
}
export const queryGetProduct = id => {
  return {
    query: {
      name: 'data',
      mode: 'get',
      type: 'proizvod',
      data: {
        options: {
          where: { id }
        }
      }
    },
    providesTags: {
      type: tag.productsMilano
    }
  }
}

/**
 * @param {initialProductMilano} data
 */
export const queryAddProductsMilano = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'proizvod',
      subType: DATA_TYPES.productsMilano,
      data: { values: data }
    },
    invalidatesTags: [tag.productsMilano],
    successMsg: label.successMsgCreate + ' ' + label.msgFuturesMilano
  }
}

/**
* @param {initialProductMilano} data
@param {string} id
*/
export const queryUpdateProductMilano = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'proizvod',
      subType: DATA_TYPES.productsMilano,
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.productsMilano, tag.stockExchangeCatProductFutures, tag.measureUnitProduct, tag.productCurency],
    successMsg: label.successMsgEdit + ' ' + label.msgFuturesMilano
  }
}

/**
@param {string} id
*/
export const queryDeleteProductMilano = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'proizvod',
      subType: DATA_TYPES.productsMilano,
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.productsMilano],
    successMsg: label.successMsgDelete + ' ' + label.msgFuturesMilano
  }
}

export const queryGetAllValutesByProductIdSearchMilano = (options = {}) => {
  const query = { tipPodatka: DATA_TYPES.productsMilano }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'proizvodiValute_search',
      data: {
        options
      }
    },
    providesTags: {
      type: tag.productCurency
    }
  }
}
export const queryGetAllMeasureUnitProductsSearchMilano = options => {
  const query = { tipPodatka: DATA_TYPES.productsMilano }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'jedinicaMereProizvod_search',
      data: { options }
    },
    providesTags: {
      type: tag.measureUnitProduct
    }
  }
}
