import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { calendarMonthPreviewFormat, calendarPreviewTimeFormat } from 'constants/date'
import { PropTypes } from 'prop-types'
import { useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ErrorMessage from '../errorMessage/index'
import styles from './dataPicker.module.scss'

const MonthPickerComp = ({
  onChange,
  stylesClass = 'defaultPickerMont',
  globalClass = '',
  name,
  label,
  showTime = false,
  format = calendarMonthPreviewFormat,
  timeFormat = calendarPreviewTimeFormat,
  placeholder = 'MM',
  errorTxt,
  haveIcon = true,
  showTimeSelectOnly = false,
  disabled = false,
  isPreview,
  isClearable = true,
  startDate
}) => {
  const monthPickerRef = useRef(null)
  function handleClickDatepickerIcon() {
    const datepickerElement = monthPickerRef.current
    datepickerElement.setFocus(true)
  }
  return (
    <div className={`${styles[stylesClass]} ${globalClass} ${disabled && styles.disabled} ${isPreview && styles.preview} g-picker`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`${styles.picker}`}>
        <DatePicker
          selected={startDate}
          showMonthYearPicker
          showTimeSelectOnly={showTimeSelectOnly}
          placeholderText={placeholder}
          onChange={date => onChange(date, name)}
          timeFormat={timeFormat}
          dateFormat={format}
          showTimeSelect={showTime}
          isClearable={isClearable}
          ref={monthPickerRef}
        />

        {haveIcon && (
          <span className={styles.icon} onClick={() => handleClickDatepickerIcon()}>
            <FontAwesomeIcon icon="fa-calendar-days" />
          </span>
        )}
      </div>
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

MonthPickerComp.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  showTime: PropTypes.bool,
  isClearable: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  haveIcon: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  startDate: PropTypes.any
}
export default MonthPickerComp
