import { useGetAllMapQuery, useGetAllQuery, useGetOneQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { useUploadMediaMutation } from 'api/fileApiSlice'
import { TwoColumns } from 'components/containers/containers'
import FormAnnouncementModal from 'components/forms/formAnnouncments/formModal'
import { Loader } from 'components/loader'
import Search from 'components/search'
import { PAGE_SIZE } from 'constants/other'
import { announcementFilterOne } from 'constants/searchFields'
import { headingAnnouncementModal } from 'constants/tbHeading'
import {
  queryAddAnnouncement,
  queryGetOneAnnouncement,
  queryGetPagedAnnouncementSearch,
  queryUpdateAnnouncement
} from 'query/queryAnnouncment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsLoadingData, setUploadImages } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'
import styles from './announcment.module.scss'
import AnnouncementTables from './tables'
import Realizations from './realizations'
import { queryGetAllMeasureUnits } from 'query/queryMeasureUnit'

const ModalAnnouncement = ({ existingData = {}, clientId }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({ ...existingData, klijentId: +clientId })
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(false)
  const [searchOptions, setSearchOptions] = useState({})
  const [createUpdateData] = useUpdateCreateOneMutation()
  const [uploadMedia] = useUploadMediaMutation()
  const { data: oneAnnouncment = {} } = useGetOneQuery(queryGetOneAnnouncement(existingData.id), { skip: !existingData.id })
  const { data: measureUnits } = useGetAllMapQuery(queryGetAllMeasureUnits())
  const { data: announcementsSupply = [] } = useGetAllQuery(
    queryGetPagedAnnouncementSearch(pageNumber, pageSize, { ...searchOptions, where: { tip: 1, klijentId: clientId } }, 'supplay')
  )
  const { data: announcementsDemand = [] } = useGetAllQuery(
    queryGetPagedAnnouncementSearch(pageNumber, pageSize, { ...searchOptions, where: { tip: 2, klijentId: clientId } }, 'demand')
  )

  useEffect(() => {
    if (!isObjectEmpty(existingData)) setData({ ...existingData })
  }, [existingData])

  useEffect(() => {
    if (!isObjectEmpty(oneAnnouncment)) setData({ ...oneAnnouncment })
  }, [oneAnnouncment])

  const onSubmit = async (data, fileIds) => {
    let uploadRes = []
    dispatch(setIsLoadingData(true))
    setLoading(true)
    if (data.id) {
      if (fileIds.length) {
        uploadRes = await uploadMedia(fileIds)
      }
      const res = await createUpdateData(queryUpdateAnnouncement({ ...data, uploadRes }))
      setLoading(false)
      if (!res.error) dispatch(closeModal(1))
    } else {
      if (fileIds.length) {
        uploadRes = await uploadMedia(fileIds)
      }
      const res = await createUpdateData(queryAddAnnouncement({ ...data, uploadRes }))
      setLoading(false)
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
    dispatch(setUploadImages([]))
  }
  const onRowClick = data => {
    setData(data)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }
  const openRealizations = async data => {
    dispatch(
      openModal({
        arrItem: {
          content: <Realizations announcement={data} />,
          order: 2,
          size: 'lg'
        },
        active: 2
      })
    )
  }

  return (
    <div className={styles.wrapper}>
      {loading && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
      <TwoColumns stylesClass="width2070">
        <FormAnnouncementModal existingData={data} header={true} onSubmit={onSubmit} openRealizations={openRealizations} />
        <div>
          <Search fields={announcementFilterOne} onSearch={onSubmitSearch} />
          <h3 className={styles.tbTitle}>Arhiva oglasa</h3>
          <AnnouncementTables
            announcementsSupply={announcementsSupply}
            announcementsDemand={announcementsDemand}
            onRowClick={onRowClick}
            havePagination={true}
            onPaginationChange={onPaginationChange}
            pageNumber={pageNumber}
            pageSize={pageSize}
            heading={headingAnnouncementModal}
            map={{
              jedinicaMere: measureUnits?.entities || {}
            }}
          />
        </div>
      </TwoColumns>
    </div>
  )
}

export default ModalAnnouncement
