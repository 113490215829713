/** Queries for 'Svetska luka' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialWorldSeaPort } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedWorldSeaPort = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'svetskaLuka',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.worldSeaPort
    }
  }
}

export const queryGetAllWorldSeaPort = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'svetskaLuka',
      data: { options }
    },
    providesTags: {
      type: tag.worldSeaPort
    }
  }
}
/**
 * @param {initialWorldSeaPort} data
 */
export const queryAddWorldSeaPort = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'svetskaLuka',
      data: { values: data }
    },
    invalidatesTags: [tag.worldSeaPort],
    successMsg: label.successMsgCreate + ' ' + label.msgWordPort
  }
}

export const queryAddWorldSeaPortMultiple = data => {
  return {
    query: {
      name: 'data',
      mode: 'newMulti',
      type: 'svetskaLuka',
      data: { values: data }
    },
    invalidatesTags: [tag.worldSeaPort],
    successMsg: label.successMsgCreate + ' ' + label.msgWordPort
  }
}

/**
* @param {initialWorldSeaPort} data
@param {string} id
*/
export const queryUpdateWorldSeaPort = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'svetskaLuka',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.worldSeaPort],
    successMsg: label.successMsgEdit + ' ' + label.msgWordPort
  }
}
/**
@param {string} id
*/
export const queryDeleteWorldSeaPort = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'svetskaLuka',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.worldSeaPort],
    successMsg: label.successMsgDelete + ' ' + label.msgWordPort
  }
}

export const queryGetLastInsertWorldSeaPort = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'svetskaLuka',
      data: { options, subMode: 'lastInsert' }
    },
    providesTags: {
      type: tag.worldSeaPort
    }
  }
}
