export const FIELD_TYPES = {
  text: 'TEXT',
  integer: 'INTEGER',
  boolean: 'BOOLEAN',
  date: 'DATETIME',
  decimal: 'DECIMAL',
  image: 'IMAGE',
  actions: 'ACTIONS',
  history: 'HISTORY',
  customGoodsImportAndExport: 'CUSTOM_GOOODS_IMPORT_AND_EXPORT',
  maped: 'MAPED',
  status: 'STATUS',
  range: 'RANGE',
  join: 'JOIN',
  joinTwo: 'JOINTWO',
  joinThree: 'JOINTHREE',
  joinFour: 'JOINFOUR',
  link: 'LINK',
  arrOfObject: 'ARR_OBJ'
}
export const PAGE_SIZE = 30
export const FORM_CODE = {
  dashboard: 'PC1', // pocetki ekran
  registration: 'RC1', // registracija
  client: 'KLI1', // klijent
  worldSeaPort: 'SL1', // svetske luke
  worldStockExchange: 'SB1', // svetske berze
  worldStockExchangeMilano: 'SBM1', // svetske berze milano
  priceMovementBih: 'KCB1', // kretanje cena bih
  entitiesType: 'TL1', // tipLica
  currency: 'VA1', // valuta
  measureUnit: 'JM1', // jedinice mere
  measureUnitRatio: 'MUR1', // odnos jedince mere
  economyBranch: 'GP1', // grana privrede
  // category: 'KT1', // kategorija
  subGroup: 'KT1', // kategorija srbija
  goods: 'PR1', // proizvod
  // productFOB: 'PRF1', // proizvodFOB
  // productBosnia: 'PRB1', // proizvodBosnia
  // producstSeaPort: 'PRL1', //
  // producstFutures: 'PF1', //
  // producstMilano: 'PM1', //
  // productDescripton: 'PO1', // opis proizvoda
  producstSeaPort: 'LUU1', // luka uslovi utovara
  delivery: 'IS1', // isporuka
  seaPort: 'LU1', // luka
  stockExchange: 'BE1', // berza
  businessActivity: 'DL1', // delatnosti
  subBusinessActiviti: 'PDL1', // poddelatnosti
  country: 'DR1', // drzava
  region: 'RG1', // region
  township: 'OP1', // opstina
  city: 'GD1', // grad
  workField: 'OB1', // oblast
  bank: 'BK1', // banka
  entityStatus: 'STL1', // status lica
  typeOfContact: 'VK1', // vrsta kontakta
  blog: 'BL1', // blog add to db
  positionInCompany: 'PUF1', // pozicija u firmi
  typeOfReport: 'TI1', // tip izvestaja
  customGoodsImport: 'CRU1', // carinske robe uvoz
  customGoodsExport: 'CRI1', // carinske robe izvoz
  reports: 'IZV1', // izvestaji
  listOfReports: 'II1', // istorija izvestaja
  customGoodsImportReport: 'CRUI1', // carinske robe uvoz izvestaj
  customGoodsExportReport: 'CRII1', // carinske robe izvoz izvestaj
  worldSeaPortReport: 'SVI1', //  svetske luke izvestaj
  wroldStockExchangeReport: 'SBI1', // svetske berze izvestaj
  wroldStockExchangeMilanoReport: 'SBMI1', // svetske berze milano izvestaj
  priceMovementBihReport: 'KCBI1', // kretanje cena bih izvestaj
  realizations: 'RE1', // realizacije
  currencyList: 'KL1', // kursna lista
  ipAddress: 'IPS1', // ips
  activitiesUsers: 'AU1', // aktivnost usera
  activitiesClients: 'AK1', // aktivnost klijenta
  services: 'US1', // usluge
  servicesCategory: 'KU1', // kategorija usluge
  packages: 'PK1', // paketi
  status: 'STAT1', // status
  users: 'KR1', // korisnik
  organization: 'ORG1', // organizacija,
  roles: 'UL1', // uloge
  izvestajOglasi: 'OI1'
}

export const BILL_TYPES = {
  bill: 'bill',
  preBill: 'preBill',
  avans: 'avans'
}

export const CLIENT_CONNECTION_TB = {
  activity: 'clientActivity',
  contact: 'clientContacts',
  timeOfContacts: 'timeOfContacts',
  clientCelebration: 'clientCelebration',
  productsBuy: 'productsBuy',
  productsSell: 'productsSell'
}

export const PRODUCT_CONNECTION_TB = {
  measureUnit: 'measureUnit',
  descriptionProduct: 'descriptionProduct'
}
export const PRODUCTS_CONNECTION_TB = {
  measureUnit: 'measureUnit',
  productsCurrency: 'productsCurrency'
}
export const GOODS_TYPE = {
  buy: 'kupovina',
  sell: 'prodaja'
}
export const ANNOUNCMENT_TYPE = {
  ponuda: 1, // prodavac
  potraznja: 2 // kupac
}

export const PAYMENT_TYPE = {
  ziralno: 1,
  avansno: 2,
  dogovor: 3,
  kompezacija: 4,
  odlozeno: 5,
  akontaciono: 6
}

export const ENTITY = {
  GZ: 'Gazdinstvo',
  PL: 'Pravno lice'
}

export const DATA_TYPES = {
  categorySerbia: 'kategorijaSrbija',
  categoryBih: 'kategorijaBih',
  categoryFutures: 'kategorijaFutures',
  categoryPort: 'kategorijaLuka',
  productsSerbia: 'proizvodSrbija',
  productsBih: 'proizvodBih',
  productsFutures: 'proizvodFutures',
  productsPort: 'proizvodLuka',
  productsMilano: 'proizvodMilano'
}
