import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialClients } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedClientSearch = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'klijent_search',
      data: {
        pageNumber,
        pageSize,
        options: { ...options, distinct: true }
      }
    },
    providesTags: {
      type: tag.clients
    }
  }
}
export const queryAllClientSearch = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'klijent_search',
      data: {
        options: { ...options, distinct: true }
      }
    },
    providesTags: {
      type: tag.clients
    }
  }
}
export const queryGetPagedClients = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'klijent',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.clients
    }
  }
}

export const queryGetAllClients = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'klijent',
      data: {
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.clients
    }
  }
}

/**
 * @param {initialClients} data
 */
export const queryAddClient = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'klijent',
      data: { values: data }
    },
    invalidatesTags: [
      tag.clients,
      tag.client,
      tag.clientsCelebrations,
      tag.clientActivity,
      tag.clientContacts,
      tag.clientTimeContacts,
      tag.clientsRemark,
      tag.clientsProducts
    ],
    successMsg: label.successMsgCreate + ' ' + label.msgClient
  }
}

/**
* @param {initialClients} data
@param {string} id
*/
export const queryUpdateClient = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'klijent',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [
      tag.clients,
      tag.client,
      tag.clientsCelebrations,
      tag.clientActivity,
      tag.clientContacts,
      tag.clientTimeContacts,
      tag.clientsRemark,
      tag.clientsProducts
    ],
    successMsg: label.successMsgEdit + ' ' + label.msgClient
  }
}
/**
@param {string} id
*/
export const queryDeleteClient = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'klijent',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.clients, tag.client],
    successMsg: label.successMsgDelete + ' ' + label.msgClient
  }
}

/**
@param {string} id
*/
export const queryGetByIdClient = id => {
  return {
    query: {
      name: 'data',
      mode: 'get',
      type: 'klijent',
      data: {
        options: {
          where: { id }
        }
      }
    },
    providesTags: {
      type: tag.client
    }
  }
}
