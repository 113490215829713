import styles from './notFound.module.scss'

const NotFound = () => {
  return (
    <div className={styles.wrapper}>
      <h2>Page not found!</h2>
    </div>
  )
}

export default NotFound
