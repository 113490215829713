export const ACTIVITY_ACTIONS = {
  logged: 'Ulogovan',
  visited: 'Pregledao'
}

export const PAGE_PATH = {
  klijent: {
    '/uvoz-izvoz': 'Uvoz/Izvoz',
    '/kretanje-cena': 'Kretanje cena',
    '/berze': 'Berze',
    '/dnevni-oglasi': 'Dnevni oglasi',
    ids: []
  },
  korisnik: {
    '/kursna-lista': 'Kursna lista',
    '/aktivnosti-klijenta': 'Aktivnosti klijenta',
    '/aktivnosti-korisnika': 'Aktivnosti zaposlenog',
    '/korisnici': 'Zaposleni',
    '/ips': 'IP adrese klijenata',
    '/organizacija': 'Organizacija',
    '/uloge': 'Uloge',
    '/uloge/new': 'Kreiranje uloga',
    '/pregled/realizacije': 'Pregled realizacije',
    '/izvestaji/carinske-robe-uvoz': 'Izveštaji-Carinska roba uvoz',
    '/izvestaji/carinske-robe-izvoz': 'Izveštaji-Carinska roba izvoz',
    '/izvestaji/svetske-luke': 'Izveštaji-Svetske luke',
    '/izvestaji/svetske-berze': 'Izveštaji-Svetske berze',
    '/izvestaji/svetske-berze-dvonedeljni-osvrt': 'Izveštaji-Dvonedeljni osvrt na Svetske berze',
    '/izvestaji/kretanje-cena-bih': 'Izveštaji-Kretanje cena BiH',
    '/izvestaji/kretanje-cena-srb': 'Izveštaji-Kretanje cena SRB',
    '/izvestaji/svetske-berze-milano': 'Izveštaji-Poljoprivreda-kupci',
    '/izvestaji/istorija-izvestaja': 'Izveštaji-Istorija',
    '/carinske-robe-uvoz': 'Carinska roba uvoz',
    '/carinske-robe-izvoz': 'Carinska roba izvoz',
    '/tipovi-lica': 'Tipovi lica',
    '/grana-privrede': 'Privredne grane',
    '/kategorija': 'Kategorije',
    '/kategorija?tip=srbija': 'Kategorije Srbija',
    '/kategorija?tip=bih': 'Kategorije Bosna',
    '/kategorija?tip=luka': 'Kategorije luka',
    '/kategorija?tip=futures': 'Kategorije futures',
    '/proizvod?tip=srbija': 'Proizvodi Srbija',
    '/proizvod?tip=bih': 'Proizvodi Bosna',
    '/proizvod?tip=luka': 'Proizvodi luka',
    '/proizvod?tip=milano': 'Proizvodi Milano',
    '/proizvod?tip=futures': 'Proizvodi futures',
    '/jedinica-mere': 'Jedinice mere',
    '/luka-uslovi-utovara': 'Luka-uslovi utovara',
    '/delatnost': 'Delatnosti',
    '/poddelatnost': 'Poddelatnosti',
    '/drzava': 'Države',
    '/region': 'Regioni',
    '/opstina': 'Opština',
    '/grad': 'Gradovi',
    '/oblast': 'Oblasti',
    '/usluge': 'Usluge',
    '/banka': 'Banke',
    '/status-lica': 'Status lica',
    '/vrsta-kontakta': 'Vrsta kontakta',
    '/valuta': 'Valute',
    '/luka': 'Luke',
    '/berza': 'Berze',
    '/paket': 'Paketi',
    '/blog': 'Blog',
    '/pozicijafirma': 'Pozicije u firmi',
    '/isporuka': 'Isporuke',
    '/svetske-luke': 'Svetske luke',
    '/svetske-berze': 'Svetske berze',
    '/svetska-berza-milano': 'Svetska berza Milano',
    '/kretanje-cena-bih': 'Kretanje cena BiH',
    '/klijenti': 'Klijenti',
    '/pocetni-ekran': 'Pocetni ekran - planer',
    '/registracije': 'Pregled svih registracija',
    '/izvestaji/oglasi': 'Lista izveštaja',
    '/odnos-jedinica-mera': 'Odnos jedinica mera',
    '/tip-izvestaja': 'Tip izveštaja',
    '/status': 'Status',
    '/kategorija-usluge': 'Kategorije usluga',
    '/klijenti/dodaj': 'Dodavanje novog klijenta',

    ids: [
      { key: '/klijenti/karton/', label: 'Karton klijenta', id: 'params' },
      // { key: '/proizvod', label: 'Proizvod', id: 'search' },
      { key: '/tipovi-lica', label: 'Tip lica', id: 'search' },
      { key: '/jedinica-mere', label: 'Jedinica mere ', id: 'search' },
      { key: '/grana-privrede', label: 'Privredna grana', id: 'search' },
      { key: '/kategorija?tip=srbija', label: 'Kategorija Srbija', id: 'search' },
      { key: '/kategorija?tip=bih', label: 'Kategorija Bosna', id: 'search' },
      { key: '/kategorija?tip=luka', label: 'Kategorija luka', id: 'search' },
      { key: '/kategorija?tip=futures', label: 'Kategorija futures', id: 'search' },
      { key: '/proizvod?tip=srbija', label: 'Proizvod Srbija', id: 'search' },
      { key: '/proizvod?tip=bih', label: 'Proizvod Bosna', id: 'search' },
      { key: '/proizvod?tip=luka', label: 'Proizvod luka', id: 'search' },
      { key: '/proizvod?tip=futures', label: 'Proizvod futures', id: 'search' },
      { key: '/proizvod?tip=milano', label: 'Proizvod Milano', id: 'search' },
      { key: '/luka-uslovi-utovara', label: 'Luka-uslovi utovara', id: 'search' },
      { key: '/delatnost', label: 'Delatnost', id: 'search' },
      { key: '/poddelatnost', label: 'Poddelatnost', id: 'search' },
      { key: '/drzava', label: 'Drzava', id: 'search' },
      { key: '/region', label: 'Region', id: 'search' },
      { key: '/opstina', label: 'Opstina', id: 'search' },
      { key: '/grad', label: 'Grad', id: 'search' },
      { key: '/oblast', label: 'Oblast', id: 'search' },
      { key: '/usluge', label: 'Usluga', id: 'search' },
      { key: '/odnos-jedinica-mera', label: 'Odnos jedinica mera', id: 'search' },
      { key: '/banka', label: 'Banka', id: 'search' },
      { key: '/status-lica', label: 'Status lica', id: 'search' },
      { key: '/status', label: 'Status', id: 'search' },
      { key: '/kategorija-usluge', label: 'Kategorije usluga', id: 'search' },
      { key: '/vrsta-kontakta', label: 'Vrsta kontakta', id: 'search' },
      { key: '/valuta', label: 'valuta', id: 'search' },
      { key: '/luka', label: 'Luka', id: 'search' },
      { key: '/berza', label: 'Berza', id: 'search' },
      { key: '/paket', label: 'Paket', id: 'params' },
      { key: '/blog', label: 'Blog', id: 'params' },
      { key: '/pozicijafirma', label: 'Pozicija u firmi', id: 'search' },
      { key: '/isporuka', label: 'Isporuka', id: 'search' },
      { key: '/korisnici', label: 'Zaposleni', id: 'search' },
      { key: '/uloge', label: 'Uloge izmena', id: 'params' }
    ]
  }
}
