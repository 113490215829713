import React from 'react'
import styles from './container.module.scss'

export const SectionContainer = ({ children }) => {
  return <div className={styles.container}>{children}</div>
}

export const BoxContainer = ({ wrapperClass = 'whiteWrapper', globalClass = '', children }) => {
  return <div className={`${styles[wrapperClass]} ${globalClass} box-container`}>{children}</div>
}

export const BoxBorder = ({ borderClass = 'border', globalClass = '', children }) => {
  return <div className={`${styles[borderClass]} ${globalClass} border-container`}>{children}</div>
}

export const FormWrapper = ({ formClass = 'formWhite', globalClass = '', children }) => {
  return <div className={`${styles.formContainer} ${styles[formClass]} ${globalClass} form-container`}>{children}</div>
}

export const TwoColumns = ({ stylesClass = 'width5050', globalClass = '', children }) => {
  return (
    <div className={`${styles.twoColumns} ${styles[stylesClass]}  ${globalClass} `}>
      <div className="first-column">{children[0] && children[0]}</div>
      <div className="second-column">{children[1] && children[1]}</div>
    </div>
  )
}

export const OneColumn = ({ columnClass = 'oneColumn', globalClass = '', children }) => {
  return <div className={`${styles[columnClass]} ${globalClass}`}>{children}</div>
}

export const FormRow = ({ formClass = 'frTwoColumns', globalClass = '', children }) => {
  return <div className={`${styles.formRow} ${styles[formClass]} ${globalClass} form-row`}>{children}</div>
}

export const FormGroup = ({ formClass = 'formGroup', globalClass = '', children, label }) => {
  return (
    <div className={`${styles[formClass]} ${globalClass}`}>
      {label && <h4>{label}</h4>}
      {children}
    </div>
  )
}
