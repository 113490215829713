/** Form for  add/edit/remove  'Poddelatnost' */

import { useGetAllQuery } from 'api/dataApiSlice'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import SelectInput from 'components/selectInput'
import { initialSubBusinessActivity } from 'constants/initialValues'
import { label } from 'constants/labels'
import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import { errSubBusinessActivity as errorMsg } from './helpers/errorMsg'
import { validateSubBusinessActivity as valdateForm } from './helpers/validate'
import FormBtnSave from './formHeader/formButton'

const FormSubBisinessActivity = ({ existingData = {}, id, onSubmit, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const { data: businessActivity = [] } = useGetAllQuery(queryGetAllBusinessActivity())
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialSubBusinessActivity })

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) setData({ ...existingData })
  }, [id, existingData])

  const handleSubmit = async e => {
    e.preventDefault()
    const reqData = {
      naziv: data.naziv,
      aktivan: data.aktivan,
      delatnostId: data.delatnostId
    }
    if (data.id) reqData.id = data.id
    if (valdateForm(reqData)) onSubmit(reqData, id)
    else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const onSelectChange = (e, name) => {
    setData(prev => ({ ...prev, [name]: e?.id || null }))
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.subBusinessActiviti}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.nazivPlaceholder}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={businessActivity}
          handleChangeSelect={e => onSelectChange(e, 'delatnostId')}
          selectedOption={businessActivity.find(el => el.id === data.delatnostId)}
          customValue="id"
          customLabel="naziv"
          label={label.businessActivity}
          errorTxt={error && !data.delatnostId && errorMsg('delatnostId', businessActivity)}
          disabled={disabled}
          isPreview={disabled}
        />
        <Checkbox
          name="aktivan"
          label={label.activeInput}
          checked={data.aktivan}
          onChange={e => setData(formData => ({ ...formData, aktivan: e.target.checked }))}
          globalClass="mt-3 "
          disabled={disabled}
        />
        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormSubBisinessActivity
