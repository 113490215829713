/** Queries for 'luka' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialSeaPort } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedSeaPort = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'luka',
      data: {
        pageNumber,
        pageSize,
        options: {
          ...options,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.seaPort
    }
  }
}

export const queryGetAllSeaPort = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'luka',
      data: {
        options: {
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.seaPort
    }
  }
}
/**
 * @param {initialSeaPort} data
 */
export const queryAddSeaPort = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'luka',
      data: { values: data }
    },
    invalidatesTags: [tag.seaPort],
    successMsg: label.successMsgCreate + ' ' + label.msgPort
  }
}

/**
* @param {initialSeaPort} data
@param {string} id
*/
export const queryUpdateSeaPort = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'luka',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.seaPort],
    successMsg: label.successMsgEdit + ' ' + label.msgPort
  }
}
/**
@param {string} id
*/
export const queryDeleteSeaPort = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'luka',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.seaPort],
    successMsg: label.successMsgDelete + ' ' + label.msgPort
  }
}
