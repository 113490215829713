import Checkbox from 'components/checkbox'
import DatePickerComp from 'components/dataPicker'
import MultiDatePicker from 'components/dataPicker/multiDatePicker'
import NoYearPickerComp from 'components/dataPicker/noYearPicker'
import Input from 'components/input'
import PhoneInputComp from 'components/phoneInput'
import SelectInput from 'components/selectInput'
import styles from './inputMultiple.module.scss'
import { removeDuplicates } from 'utils'

const Fields = ({
  fields = [],
  data = {},
  index,
  keyIndex = 0,
  name,
  disabled,
  isPreview,
  onChange,
  errorRow,
  onKeyPress,
  customValue
}) => {
  return (
    <div className={styles.inputs}>
      {fields.map((field, i) => {
        const selectOptions = field.mapOptions ? field.mapOptions[index] : field.options
        return (
          <div className={`${styles.item} ${field.type}`} key={`child_${i}_${field.dbName}`} style={{ ...field.style }}>
            {field.type === 'input' && (
              <Input
                type={field.inputType}
                name={field.dbName}
                label={field.label}
                placeholder={field.placeholder || ''}
                id={`${field.dbName}_${i}_${index}`}
                value={data[field.dbName]}
                onChange={e => onChange(e, index, field, name)}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
                onKeyPress={onKeyPress}
              />
            )}

            {field.type === 'select' && (
              <SelectInput
                keyName={`select_${name}_${field.dbName}_${i}_${keyIndex}`}
                options={removeDuplicates(selectOptions, field.uniqueDbName)}
                handleChangeSelect={e => onChange(e, index, field, name)}
                selectedOption={selectOptions?.find(
                  el => (field.selectValue ? el[field.selectValue] : el.id) === data[field.dbName] || null
                )}
                customLabel={field.selectLabel}
                customValue={field.selectValue}
                label={field.label}
                disabled={field.disabledOne ? field.disabledOne : disabled}
                isPreview={isPreview}
                filter={data?.filter && data.filter[field.collection]}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.selectValue || field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'multiselect' && (
              <SelectInput
                keyName={`select_${name}_${field.dbName}_${i}_${keyIndex}`}
                options={selectOptions}
                handleChangeSelect={e => onChange(e, index, field, name)}
                selectedOption={selectOptions?.filter(option => data[field.dbName]?.some(some => some === option?.id))}
                label={field.label}
                globalClass=""
                disabled={disabled}
                customLabel="naziv"
                customValue="id"
                isPreview={isPreview}
                filter={data?.filter && data.filter[field.collection]}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
                multiselect
                isClearable={false}
              />
            )}
            {field.type === 'datapicker' && (
              <DatePickerComp
                startDate={data[field.dbName] ? new Date(data[field.dbName]) : null}
                onChange={date => onChange(date, index, field, name)}
                label={field.label}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}

            {field.type === 'datapickerrange' && (
              <DatePickerComp
                startDate={data[field.start] ? new Date(data[field.start]) : null}
                endDate={data[field.end] ? new Date(data[field.end]) : null}
                selectsRange
                onChange={date => onChange(date, index, field, name)}
                label={field.label}
                globalClass="mb-2"
                // showTime={true}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'noyeardatapicker' && (
              <NoYearPickerComp
                startDate={data[field.dbName] ? new Date(data[field.dbName]) : null}
                onChange={date => onChange(date, index, field, name)}
                label={field.label}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'multidatepicker' && (
              <MultiDatePicker
                value={customValue && data[field.dbName]}
                customValue={customValue}
                onChange={date => onChange(date, index, field, name)}
                label={field.label}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'phone' && (
              <PhoneInputComp
                value={data[field.dbName] || field.defaultValue}
                label={field.label}
                placeholder={field.placeholder}
                onChange={phone => onChange(phone, index, field, name)}
                isPreview={disabled}
                disabled={disabled}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'checkbox' && (
              <Checkbox
                heading={field.label}
                id={`${field.dbName}_${name}_${keyIndex}_${index}`}
                name={field.dbName}
                label={field.label}
                checked={data[field.dbName]}
                onChange={e => onChange(e, index, field, name)}
                disabled={disabled}
                globalClass="mb-0"
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Fields
