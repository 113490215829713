/** Form for  add/edit  'Proizvod/Products' */
import { useGetAllQuery } from 'api/dataApiSlice'
import Checkbox from 'components/checkbox'
import Input from 'components/input'
import MultipleInputs from 'components/inputMultiple'
import SelectInput from 'components/selectInput'
import {
  initialDescriptionProduct,
  initialMeasureForProducts,
  initialProduct,
  multiFieldsDescriptionProductInitial,
  multiFieldsMeasureUnitIntial
} from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES, PRODUCT_CONNECTION_TB } from 'constants/other'
import { cloneDeep } from 'lodash'
import { queryGetAllCategories } from 'query/querycategory'
import { queryGetAllCurrency } from 'query/queryCurrency'
import { queryGetAllEconomyBranches } from 'query/queryEconomyBranch'
import { queryGetAllMeasureUnitProductByProductId } from 'query/queryMeasureUnitProduct'
import { queryGetAllMeasureUnitsRatio } from 'query/queryMeasureUnitRatio'
import { queryGetAllDescriptionProductByProductId, queryGetAllEconomyBranchCatProductByProductId } from 'query/queryProducts'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDisabled } from 'redux/reducers/staticSlice'
import toastService from 'services/toastService'
import { deepClone, getJMRationsMap, isObjectEmpty } from 'utils'
import FormHeader from './formHeader'
import { errProducts as errorMsg } from './helpers/errorMsg'
import { getDeleteUpdateConectionTbDataForProduct } from './helpers/utils'
import { validateProducts as validateForm } from './helpers/validate'
import FormBtnSave from './formHeader/formButton'
const _findJedinicaMereId = jediniceMere => {
  const defaultJedinicaMere = jediniceMere.find(el => el.podrazumevana === true || el.podrazumevana === 1)
  return defaultJedinicaMere ? defaultJedinicaMere.jedinicaMereId : null
}
const _findJedinicaMereValutaId = jediniceMere => {
  const defaultJedinicaMere = jediniceMere.find(el => el?.podrazumevanaValutaJedinica === true || el?.podrazumevanaValutaJedinica === 1)
  return defaultJedinicaMere ? defaultJedinicaMere.jedinicaMereId : null
}

const FormProduct = ({ existingData = {}, id, onSubmit, onMultiDelete, onDelete, header = false, code }) => {
  const dispatch = useDispatch()
  const { data: economyBranchData = [] } = useGetAllQuery(queryGetAllEconomyBranches())
  const { data: categoryData = [] } = useGetAllQuery(queryGetAllCategories())
  const isLoading = useSelector(state => state.static.isLoading)
  const requiredMultiMap = useSelector(state => state.static.multipleRequiredFields)
  const disabled = useSelector(state => (id ? state.static.isDisabled : false))
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialProduct })
  const [odnosMera, setOdnosMera] = useState({})
  const [multiError, setMultiError] = useState({})
  const { data: currencies = [] } = useGetAllQuery(queryGetAllCurrency())
  const { data: measureUnitRatio = [] } = useGetAllQuery(queryGetAllMeasureUnitsRatio())
  const [multiDataList, setMultiDataList] = useState({
    [PRODUCT_CONNECTION_TB.measureUnit]: [{ ...initialMeasureForProducts }],
    [PRODUCT_CONNECTION_TB.descriptionProduct]: [{ ...initialDescriptionProduct }]
  })
  const { data: descriptionProduct = [] } = useGetAllQuery(queryGetAllDescriptionProductByProductId(existingData.id), {
    skip: !existingData.id
  })
  const { data: measureUnitProduct = [] } = useGetAllQuery(
    queryGetAllMeasureUnitProductByProductId(existingData.id, { order: [['createdAt', 'ASC']] }),
    {
      skip: !existingData.id
    }
  )
  const { data: existingEconomyBranch = [] } = useGetAllQuery(queryGetAllEconomyBranchCatProductByProductId(existingData.id), {
    skip: !existingData.id
  })

  let ratioMap = getJMRationsMap(measureUnitRatio)
  useEffect(() => {
    if (measureUnitRatio.length && id) {
      ratioMap = getJMRationsMap(measureUnitRatio)
    }
  }, [measureUnitRatio])

  useEffect(() => {
    if (!isObjectEmpty(existingData) && id) {
      setData(prev => ({ ...prev, ...existingData }))
      setOdnosMera(ratioMap[existingData.jedinicaMereId])
    }
  }, [id, existingData, measureUnitRatio])

  useEffect(() => {
    if (existingEconomyBranch && existingData.id) {
      setData(prev => ({ ...prev, granaPrivrede: existingEconomyBranch }))
    }
  }, [existingEconomyBranch])

  useEffect(() => {
    if (descriptionProduct && existingData.id) {
      setMultiDataList(prev => ({ ...prev, [PRODUCT_CONNECTION_TB.descriptionProduct]: descriptionProduct }))
    }
  }, [descriptionProduct])

  useEffect(() => {
    if (measureUnitProduct.length && existingData.id) {
      const convertTyNumber = cloneDeep(measureUnitProduct).map(e => {
        if (e.odnos) e.odnos = Number(e.odnos)
        return e
      })
      setMultiDataList(prev => ({ ...prev, [PRODUCT_CONNECTION_TB.measureUnit]: convertTyNumber }))
    }
  }, [measureUnitProduct])

  const getSubmitData = multiData => {
    const measureUnitNew = cloneDeep(multiData[PRODUCT_CONNECTION_TB.measureUnit])
      .map(e => {
        if (e.odnos) e.odnos = (+e.odnos).toFixed(6)
        return e
      })
      .filter(e => e.jedinicaMereId)

    const reqData = {
      naziv: data.naziv,
      aktivan: data.aktivan,
      kategorijaId: data.kategorijaId,
      jedinicaMereId: _findJedinicaMereId(multiData[PRODUCT_CONNECTION_TB.measureUnit]),
      rod: data.rod,
      valutaId: data.valutaId,
      tipPodatka: data.tipPodatka || DATA_TYPES.productsSerbia,
      cenaJedinicaMereId: _findJedinicaMereValutaId(multiData[PRODUCT_CONNECTION_TB.measureUnit]),
      connectingTables: getDeleteUpdateConectionTbDataForProduct(
        id,
        measureUnitNew,
        measureUnitProduct,
        multiData[PRODUCT_CONNECTION_TB.descriptionProduct],
        descriptionProduct,
        data.granaPrivrede,
        existingEconomyBranch
      )
    }
    if (data.id) reqData.id = data.id
    return reqData
  }

  const handleSubmit = async () => {
    const reqData = getSubmitData(multiDataList)
    if (checkIsMultiValid('measureUnit', multiDataList[PRODUCT_CONNECTION_TB.measureUnit])) {
      if (validateForm(reqData)) {
        onSubmit(reqData, id)
      } else {
        setError(true)
        if (multiDataList[PRODUCT_CONNECTION_TB.measureUnit].length !== 0) {
          if (!reqData.cenaJedinicaMereId) return toastService.show('warn', 'Morate dodati podrazumevanu JM za cenu')
        }
      }
    }
  }
  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value.toUpperCase() }))
  }

  const onSelectChange = async (e, name) => {
    setData(prev => ({ ...prev, [name]: e?.id || null }))
  }

  const onEdit = () => {
    dispatch(setIsDisabled(false))
  }

  const onMultiSelectChange = e => {
    const temp = e.map(x => {
      return { granaPrivredeId: x.id }
    })
    setData(prev => ({ ...prev, granaPrivrede: temp }))
  }

  const onAddField = name => {
    const temp = deepClone(multiDataList[name])
    if (!checkIsMultiValid(name, temp)) return
    temp.push(name === 'descriptionProduct' ? initialDescriptionProduct : initialMeasureForProducts)
    setMultiDataList(prev => ({ ...prev, [name]: temp }))
  }

  const setOnlyOneCheckboxToTrue = (temp, currentIndex, dbName) => {
    const idJediniceMere = temp[currentIndex].jedinicaMereId
    const odnosMeraTemp = ratioMap[idJediniceMere]
    if (dbName === 'podrazumevana' && odnosMeraTemp) {
      setOdnosMera(odnosMeraTemp)
    }
    if (temp.length) {
      temp.forEach((item, i) => {
        temp[i][dbName] = i === currentIndex
        if (dbName === 'podrazumevana') {
          if (i === currentIndex) {
            temp[i].odnos = 1
          } else {
            temp[i].odnos = odnosMeraTemp ? Number(odnosMeraTemp[temp[i].jedinicaMereId]) : null
          }
        }
      })
    }
  }

  const onMultipleChange = async (e, index, field, name) => {
    const { type, dbName } = field

    const temp = deepClone(multiDataList[name])

    if (name === 'measureUnit') {
      const isObjectWithIdTestPresent = multiDataList[name].some(item => item.jedinicaMereId === e.id)
      if (isObjectWithIdTestPresent) return toastService.show('error', 'Ovu jedinicu mere ste vec dodali!')
    }

    if (type === 'input') {
      const { name, value } = e.target
      temp[index][name] = value.toUpperCase()
    } else if (type === 'select') {
      temp[index][dbName] = e.id
      if (temp.length === 1 && dbName === 'jedinicaMereId') {
        temp[index].odnos = 1
        temp[index].podrazumevana = true
        temp[index].podrazumevanaValutaJedinica = true
        setOdnosMera(ratioMap[temp[index].jedinicaMereId])
      } else {
        temp[index].odnos = odnosMera ? odnosMera[e.id] : null
      }
    } else if (type === 'checkbox') {
      const { checked } = e.target
      if (checked) {
        setOnlyOneCheckboxToTrue(temp, index, dbName)
      } else {
        if (temp[index][dbName] && !checked) temp[index][dbName] = true
        else temp[index][dbName] = false
      }
    }
    setMultiDataList(prev => ({ ...prev, [name]: temp }))
  }

  const checkIsMultiValid = (name, data) => {
    const isValidArr = []
    if (requiredMultiMap[name]) {
      data.forEach(e => {
        Object.keys(requiredMultiMap[name]).forEach(key => {
          if (!e[key]) isValidArr.push(false)
        })
      })
    }
    if (!isValidArr.every(Boolean)) {
      setMultiError(prev => ({ ...prev, [name]: true }))
      return
    } else setMultiError(prev => ({ ...prev, [name]: false }))

    return isValidArr.every(Boolean)
  }

  const onRemove = (index, item, name) => {
    const temp = cloneDeep(multiDataList[name])
    if (temp[index].podrazumevana) return toastService.show('warn', 'Ne mozete izbrisati podrazumevanu JM')
    if (temp.length === 1) return toastService.show('warn', 'Morate imati bar jednu jedinicu mere')
    if (temp[index].podrazumevanaValutaJedinica) return toastService.show('warn', `Ne mozete izbrisati podrazumevanu JM za cenu`)
    temp.splice(index, 1)
    const newMulti = { ...multiDataList, [name]: temp }
    const reqData = getSubmitData(newMulti)
    if (item.id) onMultiDelete({ item, data: reqData })
    else {
      temp.splice(index, 1)
      setMultiDataList(prev => ({ ...prev, [name]: temp }))
    }
  }

  return (
    <div>
      {header && (
        <div>
          <FormHeader
            onEdit={onEdit}
            onSave={handleSubmit}
            onDelete={() => onDelete(data)}
            title={data.naziv}
            label={label.productsSerbia}
            disabled={disabled}
            code={code}
          />
        </div>
      )}
      <div>
        <Input
          name="naziv"
          label={label.nameInput}
          placeholder={label.nazivPlaceholder}
          value={data.naziv}
          onChange={handleChange}
          errorTxt={error && !data.naziv && errorMsg('naziv', data)}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={categoryData}
          handleChangeSelect={e => onSelectChange(e, 'kategorijaId')}
          selectedOption={categoryData.find(el => el.id === data.kategorijaId || null)}
          customValue="id"
          customLabel="naziv"
          label={label.categoryInput}
          errorTxt={error && !data.kategorijaId && errorMsg('kategorija', { categoryData })}
          disabled={disabled}
          isPreview={disabled}
        />
        <SelectInput
          options={economyBranchData}
          handleChangeSelect={onMultiSelectChange}
          selectedOption={economyBranchData.filter(option => data.granaPrivrede?.some(e => e.granaPrivredeId === option.id))}
          customValue="id"
          customLabel="naziv"
          label={label.economyBranchInput}
          globalClass=""
          // errorTxt={error && !data.granaPrivredeId && errorMsg('granaPrivrede', { economyBranchData })}
          disabled={disabled}
          isPreview={disabled}
          multiselect
          isClearable={false}
        />
        {/* <FormRow> */}
        <SelectInput
          options={currencies}
          handleChangeSelect={e => onSelectChange(e, 'valutaId')}
          selectedOption={currencies.find(el => el.id === data.valutaId || null)}
          customValue="id"
          customLabel="kod"
          label={label.defaultCurrency}
          errorTxt={error && !data.valutaId && errorMsg('valutaId')}
          disabled={disabled}
          isPreview={disabled}
        />

        {/* <SelectInput
                  options={measureUnit}
                  handleChangeSelect={e => onSelectChange(e, 'cenaJedinicaMereId')}
                  selectedOption={measureUnit.find(el => el.id === data.cenaJedinicaMereId || null)}
                  customValue="id"
                  customLabel="naziv"
                  label={label.measureUnitsValute}
                  errorTxt={error && !data.cenaJedinicaMereId && errorMsg('cenaJedinicaMereId')}
                /> */}
        {/* </FormRow> */}
        <Checkbox
          name="rod"
          label={label.categoryInputRod + '?'}
          checked={data.rod}
          onChange={e => setData(formData => ({ ...formData, rod: e.target.checked }))}
          globalClass="mt-3 "
          disabled={disabled}
          isPreview={disabled}
        />

        <MultipleInputs
          name={PRODUCT_CONNECTION_TB.descriptionProduct}
          fields={multiFieldsDescriptionProductInitial}
          data={multiDataList[PRODUCT_CONNECTION_TB.descriptionProduct]}
          onAdd={onAddField}
          onRemove={onRemove}
          onChange={onMultipleChange}
          label={label.addProductDesc}
          errorRow={multiError}
          globalClass="mb-3"
          disabled={disabled}
          isPreview={disabled}
          errorGroup={multiDataList[PRODUCT_CONNECTION_TB.descriptionProduct].length === 0 && error && errorMsg('opisProizvoda', data)}
        />

        <MultipleInputs
          name={PRODUCT_CONNECTION_TB.measureUnit}
          fields={multiFieldsMeasureUnitIntial}
          data={multiDataList[PRODUCT_CONNECTION_TB.measureUnit]}
          onAdd={onAddField}
          onRemove={onRemove}
          onChange={onMultipleChange}
          label={label.addMeasureUnit}
          stylesClass="multiFourEl"
          errorRow={multiError}
          disabled={disabled}
          isPreview={disabled}
          errorGroup={multiDataList[PRODUCT_CONNECTION_TB.measureUnit].length === 0 && error && errorMsg('jedinicaMere', data)}
        />
        <Checkbox
          name="aktivan"
          label={label.activeInput + '?'}
          checked={data.aktivan}
          onChange={e => setData(formData => ({ ...formData, aktivan: e.target.checked }))}
          globalClass="mt-3 "
          disabled={disabled}
        />
        {!disabled && <FormBtnSave disabled={disabled} handleSubmit={handleSubmit} isLoading={isLoading} id={id} code={code} />}
      </div>
    </div>
  )
}

export default FormProduct
