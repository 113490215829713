import accounting from 'accounting-js'
import dayjs from 'dayjs'
import { dateDBFormat, monthFormat, yearFormat } from 'constants/date'
import { cloneDeep } from 'lodash'

export const deepClone = obj => {
  if (typeof obj !== 'object' || obj === null) return obj
  const newObject = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    const value = obj[key]

    newObject[key] = typeof value === 'object' ? deepClone(value) : value
  }

  return newObject
}
export const sortDescendingNum = (data, key) => {
  const arrayForSort = [...data]
  return arrayForSort.sort((a, b) => {
    return parseFloat(b[key]) - parseFloat(a[key])
  })
}

export const sortAscendingNum = (data = [], key) => {
  const arrayForSort = [...data]
  return arrayForSort.sort((a, b) => {
    return parseFloat(a[key]) - parseFloat(b.order)
  })
}

export const isObjectEmpty = obj => {
  if (!obj) return true
  return Object.keys(obj).length === 0
}

export const trimObjectData = obj => {
  Object.keys(obj).map(k => {
    obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]
  })
  return obj
}

export const getMediaFromFileOrString = image => {
  if (!image && image === '') return ''
  else if (image instanceof File) {
    return URL.createObjectURL(image)
  } else if (image instanceof Element) {
    return image.toDataURL()
  } else {
    return image.link
  }
}

export const getCurrentTime = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  const today = new Date()
  const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
  const dateTime = formated + ' ' + time
  return new Date(dateTime)
}
export const getDayRange = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  const start = new Date(formated + ':00:00:00')
  const end = new Date(formated + ':23:59:59')
  return { start, end }
}

export const getMonthRange = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  const dayInMonth = dayjs(formated).daysInMonth()
  const month = dayjs(formated).format(monthFormat)
  const year = dayjs(formated).format(yearFormat)
  const start = new Date(`${year}-${month}-01:00:00:00`)
  const end = new Date(`${year}-${month}-${dayInMonth}:23:59:59`)
  return { start, end }
}

export const getYearRange = date => {
  if (!date) return null
  const year = dayjs(date).format(yearFormat)
  const start = new Date(`${year}-01-01:00:00:00`)
  const end = new Date(`${year}-12-31:23:59:59`)
  return { start, end }
}
export const getRangeStart = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  return new Date(formated + ':00:00:00')
}

export const getRangeEnd = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  return new Date(formated + ':23:59:59')
}

export const isObject = value => {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

const _setOdnos = (izJedinicaMereId, uJedinicaMereId, odnos, ratioMap) => {
  if (!ratioMap[izJedinicaMereId]) ratioMap[izJedinicaMereId] = {}
  const odnosi = ratioMap[izJedinicaMereId]
  if (!odnosi[uJedinicaMereId]) odnosi[uJedinicaMereId] = odnos
}

export const getJMRationsMap = (ratios = []) => {
  const ratioMap = {}
  ratios.forEach(({ izJedinicaMereId, uJedinicaMereId, odnos }) => {
    _setOdnos(izJedinicaMereId, uJedinicaMereId, Number(odnos), ratioMap)
    _setOdnos(uJedinicaMereId, izJedinicaMereId, Number(1 / odnos), ratioMap)
  })

  Object.keys(ratioMap).forEach(izJMId => {
    const baseRatios = ratioMap[izJMId]
    Object.keys(baseRatios).forEach(uJMId => {
      const ratio = baseRatios[uJMId]
      const secondaryRatio = ratioMap[uJMId]
      if (secondaryRatio) {
        Object.keys(secondaryRatio).forEach(secUJMId => {
          const secRatio = secondaryRatio[secUJMId]
          if (!baseRatios[secUJMId]) baseRatios[secUJMId] = Number(ratio * secRatio)
          if (!ratioMap[secUJMId][izJMId]) ratioMap[secUJMId][izJMId] = Number(((1 / ratio) * 1) / secRatio)
        })
      }
    })
  })
  return ratioMap
}

export const setDeliveryDate = e => {
  if (!e) return []
  const groupedDates = e.reduce((acc, date) => {
    const year = date.format('YYYY')
    const month = date.format('MMM')
    if (!acc[year]) {
      acc[year] = []
    }
    acc[year].push(month)
    return acc
  }, {})

  // Format the grouped dates as desired
  const formattedDates = Object.entries(groupedDates).map(([year, months]) => {
    return `${year} ${months.join(', ')}`
  })
  return formattedDates.join(', ')
}

export const convertStringToDates = dateString => {
  if (!dateString || typeof dateString !== 'string') {
    return []
  }

  let prevYear = null

  const dateArray = dateString.split(',').map(dateStr => {
    const [year, ...months] = dateStr.trim().split(' ')

    if (months.length === 0) {
      if (prevYear) {
        return [new Date(`${prevYear} ${year}`)]
      } else {
        return [new Date(`${year}`)]
      }
    }

    prevYear = year

    return months.map(month => new Date(`${year} ${month}`))
  })

  return dateArray.reduce((accumulator, currentDates) => {
    return [...accumulator, ...currentDates]
  }, [])
}

export const getAccessRights = (user, code, action) => {
  if (!user?.uloga?.pravaPristupa?.[code] || !user?.uloga?.pravaPristupa?.[code]?.find(e => e === action)) return true
  else return false
}

export const formatNumber = (num, type) => {
  if (!num) return '-'
  if (type === 'money') return accounting.formatMoney(parseFloat(num), { symbol: '', precision: 2 })
  else if (type === 'twoDecimals') return accounting.toFixed(parseFloat(num), 2)
  else return Number(parseFloat(num))
}

export const removeDuplicates = (data, uniqueDbName) => {
  if (!uniqueDbName) return data
  const uniqueList = []
  const seenIds = new Set()
  data?.forEach(item => {
    if (!seenIds.has(item[uniqueDbName])) {
      seenIds.add(item[uniqueDbName])
      uniqueList.push(item)
    }
  })
  return uniqueList
}

const getNewOptionsMap = e => {
  const newOptions = {}
  Object.keys(e.mapOptions).forEach((key, i) => {
    newOptions[i] = e.mapOptions[key]
  })
  return newOptions
}
export const updateMapCurrencyAndMeasure = (index, dinamicMultiData) => {
  const dinamicFields = cloneDeep(dinamicMultiData)
  dinamicFields.map(e => {
    if (e.mapOptions) {
      if (e.dbName === 'valutaId' || e.dbName === 'jedinicaMereId' || e.dbName === 'proizvodId') {
        delete e.mapOptions[index]
        e.mapOptions = getNewOptionsMap(e)
      }
    }
  })
  return dinamicFields
}
