import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import styles from './selector.module.scss'
import ErrorMessage from 'components/errorMessage'
import { v4 as uuidv4 } from 'uuid'
const filterOptions = (options, filter) => {
  const { filterKey, filterValue } = filter
  const filtered = options.filter(e => e[filterKey] === filterValue)
  return filtered
}
const SelectInput = ({
  handleChangeSelect,
  stylesClass = 'selectPrimary',
  globalClass = '',
  options = [],
  label,
  selectedOption,
  placeholder,
  multiselect = false,
  disabled = false,
  isClearable = false,
  customLabel,
  customValue,
  customComponents = null,
  errorTxt,
  isPreview,
  filter
}) => {
  // eslint-disable-next-line
  const selectorClass = stylesClass.split("'").join('')

  const convertToCamelCase = input => {
    if (input !== '') {
      return input
        ?.split(' ')
        ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join('')
        ?.replace(/[^a-zA-Z0-9]/g, '')
    }
  }

  const newLabel = convertToCamelCase(label)

  return (
    <>
      <div className={`${styles[stylesClass]} ${globalClass}  ${isPreview && styles.preview}  g-select ${newLabel}`}>
        {label && <label>{label}</label>}
        <Select
          key={uuidv4()}
          isMulti={multiselect}
          className={'selectCustom'}
          classNamePrefix={'selectGlobal'}
          value={selectedOption}
          onChange={handleChangeSelect}
          // options={options}
          options={(filter && filterOptions(options, filter)) || options}
          getOptionValue={option => option[customValue || 'value']}
          getOptionLabel={option => option[customLabel || 'label']}
          placeholder={!disabled ? placeholder : selectedOption ?? '-'}
          isDisabled={disabled}
          isOptionDisabled={option => option.isdisabled}
          isClearable={isClearable}
          components={customComponents}
          // menuIsOpen={true}
        />
        {errorTxt && <ErrorMessage message={errorTxt} />}
      </div>
    </>
  )
}

SelectInput.propTypes = {
  handleChangeSelect: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  selectedOption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  multiselect: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  customLabel: PropTypes.string,
  customValue: PropTypes.string,
  customComponents: PropTypes.object,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelNext: PropTypes.bool
}
export default SelectInput
