import InputCode from 'components/inputCode'
import { useState } from 'react'
import Button from 'components/button'
import { label } from 'constants/labels'
import { validateForgotCode as validateForm } from './helpers/validate'
import { errForgotPassword as errorMsg } from './helpers/errorMsg'

const FormSmsCode = ({ onSubmit }) => {
  const [data, setData] = useState({ forgotCode: '' })
  const [error, setError] = useState(false)

  const handleForgotCodeChange = e => {
    if (e && e.target) {
      const { name, value } = e.target
      setData(formData => ({ ...formData, [name]: value }))
    } else {
      setData(formData => ({ ...formData, forgotCode: e }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm(data)) onSubmit(data)
    else setError(true)
  }

  return (
    <div>
      <InputCode
        name="forgotCode"
        type="number"
        fields={4}
        value={data.forgotCode}
        onChange={handleForgotCodeChange}
        errorTxt={error && (!data.forgotCode || data.forgotCode.length < 4) && errorMsg('forgot-sms-code', data)}
        label={label.forgotSmsCode}
      />
      <div className="ta-r mt-2">
        <Button label={label.send} onClick={handleSubmit} />
      </div>
    </div>
  )
}

export default FormSmsCode
