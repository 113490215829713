export const getSigniture = () => {
  return [
    {
      type: 'h3',
      class: 'signitureLetter',
      children: [{ text: '(M.P)' }]
    },
    {
      type: 'paragraph',
      class: 'signitureLabel',
      children: [{ text: '' }, { text: 'potpis ovlašćenog lica' }]
    }
  ]
}
