/** Page  'Paketi' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetAllQuery } from 'api/dataApiSlice'
import Button from 'components/button'
import { OneColumn } from 'components/containers/containers'

import { PACKAGE_DURATION } from 'constants/staticOptions'

import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { packageFields } from 'constants/searchFields'
import { headingPackage } from 'constants/tbHeading'
import { queryGetPagedPackage } from 'query/queryPackage'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setIsDisabled } from 'redux/reducers/staticSlice'

const Package = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: packages = [] } = useGetAllQuery(queryGetPagedPackage(pageNumber, pageSize, searchOptions))

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`${data.id}`)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/paket`)
  }

  // const showModal = () => {
  //   navigate(`/paket`)
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: (
  //           <FormWrapper>
  //             <h2>{label.addPackage}</h2>
  //             <FormPackage existingData={data} onSubmit={onSubmit} id={null} />
  //           </FormWrapper>
  //         ),
  //         order: 1,
  //         size: 'md'
  //       },
  //       active: 1
  //     })
  //   )
  // }

  // const openHistory = () => {
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: (
  //           <div>
  //             <HistoryTable collection="paket" link="/paket?id=" connectionTb={[{ rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' }]} />
  //           </div>
  //         ),
  //         order: 1,
  //         size: 'xlg'
  //       },
  //       active: 1
  //     })
  //   )
  // }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  return (
    <div>
      <Title title={label.packageInput} total={packages?.count}>
        <Button
          onClick={() => navigate('dodaj')}
          label={label.add}
          iconLeft={<FontAwesomeIcon icon="fa-plus" />}
          accessAction="ADD"
          accessCode={code}
        />
      </Title>
      <Search fields={packageFields} onSearch={onSubmitSearch} />
      <OneColumn>
        <Table
          heading={headingPackage}
          data={packages?.rows}
          onRowClick={onRowClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={packages?.count}
          map={{
            durationType: PACKAGE_DURATION.reduce((e, x) => {
              return { ...e, [x.id]: x }
            }, {})
          }}
        />
      </OneColumn>
    </div>
  )
}
export default Package
