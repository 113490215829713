import React, { useRef } from 'react'
import { PropTypes } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './dataPicker.module.scss'
import ErrorMessage from '../errorMessage/index'
import { calendarNoYearFormat, calendarPreviewTimeFormat } from 'constants/date'

const NoYearPickerComp = ({
  onChange,
  stylesClass = 'noYearPicker',
  globalClass = '',
  startDate,
  name,
  label,
  showTime = false,
  haveIcon = true,
  showTimeSelectOnly = false,
  isClearable = true,
  format = calendarNoYearFormat,
  timeFormat = calendarPreviewTimeFormat,
  placeholder = 'YYYY-MM-DD',
  errorTxt,
  disabled = false,
  // selectsRange,
  // endDate,
  isPreview
}) => {
  // const [startDate, setStartDate] = useState(new Date())
  // const years = range(1990, getYear(new Date()) + 1, 1)
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const datepickerRef = useRef(null)
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current
    datepickerElement.setFocus(true)
  }

  return (
    <div className={`${styles[stylesClass]} ${globalClass} ${disabled && styles.disabled} ${isPreview && styles.preview} g-picker`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`${styles.picker}`}>
        <DatePicker
          renderCustomHeader={({
            date,
            // changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div className="customHeaderDatePicker">
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <FontAwesomeIcon icon="fa-chevron-left" />
              </button>
              <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <FontAwesomeIcon icon="fa-chevron-right" />
              </button>
            </div>
          )}
          selected={startDate}
          onChange={date => onChange(date, name)}
          placeholderText={placeholder}
          startDate={startDate}
          showTimeSelect={showTime}
          showTimeSelectOnly={showTimeSelectOnly}
          timeFormat={timeFormat}
          dateFormat={format}
          isClearable={isClearable}
          // onChange={date => setStartDate(date)}
          ref={datepickerRef}
        />
        {haveIcon && (
          <span className={styles.icon} onClick={() => handleClickDatepickerIcon()}>
            <FontAwesomeIcon icon="fa-calendar-days" />
          </span>
        )}
      </div>
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

NoYearPickerComp.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  startDate: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  showTime: PropTypes.bool,
  isClearable: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  haveIcon: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool
}
export default NoYearPickerComp
