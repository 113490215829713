import { validateEmail } from 'utils'

export const validateClientsLegal = (data, existingConnectionTb, isDomestic) => {
  const { connectingTables } = data
  const basic = [
    data.naziv,
    data.pib,
    data.maticniBroj,
    data.adresa,
    data.gradId,
    data.drzavaId,
    data.oblastId,
    data.statusLicaId,
    data.kontaktTelefon,
    data.kontaktTelefon.length >= 10,
    data.direktor,
    data.email,
    validateEmail(data.email),
    data.korisnikId,
    data.opisPoslovanja
  ].every(Boolean)
  let domesticFields = true
  if (isDomestic) {
    domesticFields = [data.regionId, data?.pib?.length === 9].every(Boolean)
  }
  const productSell =
    (existingConnectionTb.clientProductsSellData?.length &&
      connectingTables.productsSellDelete.length < existingConnectionTb.clientProductsSellData?.length) ||
    connectingTables.productsSell?.length
  const productBuy =
    (existingConnectionTb.clientProductsBuyData?.length &&
      connectingTables.productsBuyDelete.length < existingConnectionTb.clientProductsBuyData?.length) ||
    connectingTables.productsBuy?.length
  const connectTb = data.id
    ? [
        existingConnectionTb.clientActivityData?.length || connectingTables.clientActivity?.length,
        existingConnectionTb.clientContactData?.length || connectingTables.clientContacts?.length,
        // existingConnectionTb.clientTimeContactData?.length || connectingTables.clientTimeOfContacts?.length,
        productSell,
        productBuy
      ].every(Boolean)
    : [
        connectingTables.clientActivity?.length,
        connectingTables.clientContacts?.length,
        // connectingTables.clientTimeOfContacts?.length,
        connectingTables.productsBuy?.length,
        connectingTables.productsSell?.length
      ].every(Boolean)

  return [basic, connectTb, domesticFields].every(Boolean)
}
export const validateClientsFarm = (data, existingConnectionTb, isDomestic) => {
  const { connectingTables } = data
  const basic = [
    data.naziv,
    data.adresa,
    data.gradId,
    data.drzavaId,
    data.oblastId,
    data.statusLicaId,
    data.kontaktTelefon,
    data.kontaktTelefon.length >= 10,
    data.direktor,
    data.email,
    validateEmail(data.email),
    data.korisnikId,
    data.opisPoslovanja
  ].every(Boolean)

  let domesticFields = true
  if (isDomestic) domesticFields = [data.regionId].every(Boolean)

  const productSell =
    (existingConnectionTb.clientProductsSellData?.length &&
      connectingTables.productsSellDelete.length < existingConnectionTb.clientProductsSellData?.length) ||
    connectingTables.productsSell?.length
  const productBuy =
    (existingConnectionTb.clientProductsBuyData?.length &&
      connectingTables.productsBuyDelete.length < existingConnectionTb.clientProductsBuyData?.length) ||
    connectingTables.productsBuy?.length
  const connectTb = data.id
    ? [
        existingConnectionTb.clientActivityData?.length || connectingTables.clientActivity?.length,
        existingConnectionTb.clientContactData?.length || connectingTables.clientContacts?.length,
        //  existingConnectionTb.clientTimeContactData?.length || connectingTables.clientTimeOfContacts?.length,
        productSell,
        productBuy
      ].every(Boolean)
    : [
        connectingTables.clientActivity?.length,
        connectingTables.clientContacts?.length,
        // connectingTables.clientTimeOfContacts?.length,
        connectingTables.productsBuy?.length,
        connectingTables.productsSell?.length
      ].every(Boolean)

  return [basic, connectTb, domesticFields].every(Boolean)
}

export const validateUserForm = data => {
  return (
    data.korisnickoIme &&
    data.lozinka &&
    data.ime &&
    // data.prezime &&
    data.mobilni &&
    data.poslovniBroj &&
    data.ulogaId &&
    validateEmail(data.email) &&
    data.godinaRodjenja &&
    data.pozicijaFirmaId &&
    data.maticniBroj &&
    data.datumPocetkaRadnogOdnosa
  )
}

export const validateXLSXImport = data => {
  return data.firstName && data.lastName && data.age && data.country && data.date && data.date !== 'Invalid Date' && data.gender
}

export const validateTipoviLica = data => {
  return data.naziv
}

export const validateBusinessActivity = data => {
  return data.naziv
}

export const validateSubBusinessActivity = data => {
  return data.naziv && data.delatnostId
}

export const validateBanka = data => {
  return data.naziv
}

export const validateProductWithCategory = (data, existingConnectionTb) => {
  const {
    connectingTables: { productsCurrency = [], jediniceMere = [] }
  } = data
  const { existingCurrency, measureUnitProduct } = existingConnectionTb
  const currencyValid = existingCurrency?.length || productsCurrency?.length
  const mesureLength = measureUnitProduct.length || jediniceMere.length
  return data.naziv && data.kategorijaId && data.jedinicaMereId && data.cenaJedinicaMereId && data.valutaId && currencyValid && mesureLength
}

export const validatePortDelivery = data => {
  return data.naziv && data.valutaId && data.jedinicaMereId
}

export const validateProductFutures = (data, existingConnectionTb) => {
  const {
    connectingTables: { productsCurrency = [], jediniceMere = [] }
  } = data
  const { existingCurrency, measureUnitProduct } = existingConnectionTb

  const currencyValid = existingCurrency?.length || productsCurrency?.length
  const mesureLength = measureUnitProduct.length || jediniceMere.length
  return data.naziv && data.kategorijaId && currencyValid && mesureLength
}

export const validateProductMilano = (data, existingConnectionTb) => {
  const {
    connectingTables: { productsCurrency = [], jediniceMere = [] }
  } = data
  const { existingCurrency, measureUnitProduct } = existingConnectionTb
  const currencyValid = existingCurrency?.length || productsCurrency?.length
  const mesureLength = measureUnitProduct.length || jediniceMere.length
  return data.naziv && data.jedinicaMereId && data.cenaJedinicaMereId && data.valutaId && currencyValid && mesureLength
}

export const validateDelivery = data => {
  return data.naziv
}

export const validateMeasureUnit = data => {
  return data.naziv && data.oznaka
}

export const validateCreateReport = data => {
  return data.description && data.naziv
}

export const validateMeasureUnitsRatio = data => {
  return data.izJedinicaMereId && data.uJedinicaMereId && data.odnos
}

export const validateProducts = data => {
  return data.naziv && data.kategorijaId && data.cenaJedinicaMereId && data.valutaId
}
export const validateProductDesc = data => {
  return data.naziv && data.proizvodId
}
export const validateEconomyBranch = data => {
  return data.naziv && data.sifra
}

export const validateCategory = data => {
  return data.naziv
}

export const validateCountry = data => {
  return data.naziv && data.pozivniBroj
}

export const validateEntityStatus = data => {
  return data.naziv
}
export const validateRegion = data => {
  return data.naziv && data.drzavaId
}

export const validateTownship = (data, isDomestic) => {
  if (isDomestic) return data.naziv && data.regionId && data.drzavaId
  else return data.naziv && data.drzavaId
}

export const validateCity = (data, isDomestic) => {
  if (isDomestic) return data.naziv && data.postanskiBroj && data.regionId && data.drzavaId && data.opstinaId
  else return data.naziv && data.postanskiBroj && data.drzavaId
}

export const validateService = data => {
  return data.naziv && data.kod
}

export const validateStatus = data => {
  return data.naziv && data.trajanje
}

export const validateServiceCategory = data => {
  return data.naziv && data.sort
}

export const validateTypeOfContact = data => {
  return data.naziv
}

export const validatePackage = data => {
  return data.naziv && data.tipTrajanja && data.trajanje && data.opis && data.kratakOpis && data.status && data.usluge.length > 0
}

export const validateAnnouncement = (data, existingFca) => {
  let basic = [
    data.tip,
    data.status,
    data.proizvodId,
    data.unetaJedinicaMereId,
    data.kolicina,
    data.datumVazenja,
    data.kontakti.length,
    data.tipPlacanja
  ]

  const fca =
    (existingFca?.length > 0 && data?.connectingTables?.fcaDelete.length < existingFca?.length) ||
    data?.connectingTables?.fcaCreate?.length > 0
  if (data.tip === 1) {
    // eslint-disable-next-line
    const additional = fca || data.fcaKupac
    basic = [...basic, data.cena, data.valutaId, data.unetaCenaJedinicaMereId, additional]
  } else if (data.tip === 2) {
    // eslint-disable-next-line
    const additional = fca || data.fcaKupac || data.fcaDogovor
    basic.push(additional)
  }
  return basic.every(Boolean)
}

export const validateBlog = data => {
  return data.naslov && data.podnaslov && data.tekst && data.blogStatus
}

export const validateContactPerson = data => {
  return data.ime && data.prezime && data.email && data.klijentId
}

export const validateWorkField = data => {
  return data.naziv
}

export const validateSeaPort = data => {
  return data.naziv
}

export const validateStockExchange = data => {
  return data.naziv && data.valutaId
}

export const validateHistoryReport = data => {
  return data.singleEmail
}

export const validateCurrency = data => {
  return data.naziv && data.kod && data.simbol
}

export const validateWorldSeaPort = data => {
  return (
    data.proizvodId && data.datumUnosa && data.lukaUsloviUtovaraId && data.isporuka && data.cena && data.valutaId && data.jedinicaMereId
  )
}

export const validateWorldStockExchange = data => {
  return data.berzaId && data.jedinicaMereId && data.proizvodId && data.valutaId && data.cena && data.isporuka && data.datumUnosa
}

export const validateWorldStockExchangeMilano = data => {
  return data.jedinicaMereId && data.proizvodId && data.valutaId && data.cenaMax && data.cenaMin && data.datumUnosa
}

export const validatePriceMovementBih = data => {
  return data.kategorijaId && data.proizvodId && data.datum && data.cenaMin && data.cenaMax && data.valutaId && data.jedinicaMereId
}

export const validateCustomGoodsImportAndExport = data => {
  return data.datum
}

export const validateForgotPasswordMail = data => {
  return data.email
}

export const validateForgotPasswordSms = data => {
  return data.mobilni && data.mobilni.length >= 10
}

export const validateForgotCode = data => {
  return data.forgotCode && data.forgotCode.length === 4
}

export const validateChangeForgotPasswordMail = data => {
  return data.lozinka && data.lozinkaPotvrda && data.lozinka === data.lozinkaPotvrda
}
