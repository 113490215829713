/** Page  'Status lica' */
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormEntityStatus from 'components/forms/formEntityStatus'
import Table from 'components/table'
import Title from 'components/title'
import { headingEntityStatus } from 'constants/tbHeading'
import { isObjectEmpty } from 'utils'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import ModalYesNo from 'components/modal/modalYesNo'
import { queryAddEntityStatus, queryDeleteEntityStatus, queryGetPagedEntityStatus, queryUpdateEntityStatus } from 'query/queryEntityStatus'
import { label } from 'constants/labels'
import Search from 'components/search'
import { entityStatusFields } from 'constants/searchFields'
import { PAGE_SIZE } from 'constants/other'
import HistoryTable from 'components/historyTable'
import { queryGetAllUsers } from 'query/queryUser'

const EntityStatus = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: entityStatusData = [] } = useGetAllQuery(queryGetPagedEntityStatus(pageNumber, pageSize, searchOptions))
  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (entityStatusData.rows && entityStatusData.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, entityStatusData.rows])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(queryUpdateEntityStatus(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddEntityStatus(data))
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/status-lica?id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteEntityStatus(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/status-lica`)
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteEntityStatus} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  const showModal = () => {
    navigate(`/status-lica`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.addEntityStatus}</h2>
              <FormEntityStatus onSubmit={onSubmit} id={null} />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/status-lica`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="statusLica"
                link="/status-lica?id="
                connectionTb={[{ rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' }]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.entityStatus} total={entityStatusData?.count}>
        <Button onClick={showModal} label={label.add} iconLeft={<FontAwesomeIcon icon="fa-plus" />} accessAction="ADD" accessCode={code} />
      </Title>
      <Search fields={entityStatusFields} onSearch={onSubmitSearch} />
      {!!entityStatusData?.rows?.length && (
        <h3 className="history-link" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns>
        <Table
          heading={headingEntityStatus}
          data={entityStatusData.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={entityStatusData?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormEntityStatus existingData={data} header={true} id={id} onSubmit={onSubmit} onDelete={openDeleteModal} code={code} />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default EntityStatus
