import { components } from 'react-select'
export const CustomOptionSelect = props => {
  const { innerProps, data } = props
  return (
    <div {...innerProps} className="customOption">
      <span>
        {data.naziv}, {data.postanskiBroj}
      </span>
    </div>
  )
}
export const SingleValue = props => {
  const { data } = props
  return (
    <components.SingleValue {...props}>
      <div className="customSelect">
        <span>
          {data.naziv}, {data.postanskiBroj}
        </span>
      </div>
    </components.SingleValue>
  )
}
