import Checkbox from 'components/checkbox'
import { FormGroup, OneColumn, TwoColumns } from 'components/containers/containers'
import Input from 'components/input'
import MultipleInputs from 'components/inputMultiple'
import PhoneInputComp from 'components/phoneInput'
import SelectInput from 'components/selectInput'
import { multiFieldsCelebrationsInitial, multiFieldsDelatnostInitial } from 'constants/initialValues'
import { label } from 'constants/labels'
import { CLIENT_CONNECTION_TB } from 'constants/other'
import { errMsgLegalEntities as errorMsg } from '../helpers/errorMsg'
// import ListMessages from 'components/listMessages'
import ListTimeOfContacts from 'components/listTimeOfContacts'
import dayjs from 'dayjs'
import { dateFormat } from 'constants/date'
import TextArea from 'components/textarea'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import { CustomOptionSelect, SingleValue } from './helpers'

const FormClientsLegal = ({
  data,
  handleSelectChange,
  handleChange,
  handleCheckboxChange,
  onAddField,
  onMultipleChange,
  onRemoveMultiselect,
  // onRemarkChange,
  // onRemarkEdit,
  // remarkList = [],
  handlePhoneChange,
  error,
  multiErrorRow,
  filterMap = {},
  bank = [],
  products = [],
  countries = [],
  cities = [],
  region = [],
  township = [],
  workFieldData = [],
  salesSpecialist = [],
  productsBuy = [],
  productsSell = [],
  isDomestic,
  multiFieldsContactsInitial,
  multiDataList,
  entityStatus
}) => {
  const { poljoprivreda } = useGetAllQuery(queryGetAllBusinessActivity(), {
    selectFromResult: ({ data }) => {
      return {
        poljoprivreda: data?.find(e => e.naziv?.toLocaleLowerCase().includes('poljoprivreda')) || null
      }
    }
  })
  return (
    <div>
      <TwoColumns stylesClass="width5050">
        <div>
          <FormGroup label={label.groupFormTitleBasic}>
            <SelectInput
              options={entityStatus || []}
              handleChangeSelect={e => handleSelectChange(e, 'statusLicaId')}
              selectedOption={entityStatus.find(el => el.id === data.statusLicaId) || null}
              customLabel="naziv"
              customValue="id"
              label={label.statusInput}
              errorTxt={error && !data.statusLicaId && errorMsg('status', data)}
            />
            <Input
              name="naziv"
              label={label.companyNameInput}
              placeholder={label.placeholder}
              value={data.naziv}
              onChange={handleChange}
              errorTxt={error && !data.naziv && errorMsg('naziv', data)}
            />
            <Input
              size={isDomestic ? 9 : undefined}
              name="pib"
              label={label.PIBInput}
              placeholder={label.placeholder}
              value={data.pib}
              onChange={handleChange}
              errorTxt={error && errorMsg('pib', data, isDomestic)}
            />
            <Input
              name="maticniBroj"
              label={label.identificationNumberInput}
              placeholder={label.placeholder}
              value={data.maticniBroj}
              onChange={handleChange}
              errorTxt={error && !data.maticniBroj && errorMsg('maticniBroj', data)}
            />
            <Checkbox
              name="poreskiObaveznik"
              label={label.obveznikPDVa}
              checked={data.poreskiObaveznik}
              onChange={handleCheckboxChange}
              globalClass="mt-3 "
            />

            <Input
              name="adresa"
              label={label.adresaInput}
              placeholder={label.placeholder}
              value={data.adresa}
              onChange={handleChange}
              errorTxt={error && !data.adresa && errorMsg('adresa', data)}
            />
            {data.id && (
              <SelectInput
                options={countries || []}
                filter={filterMap?.country}
                handleChangeSelect={e => handleSelectChange(e, 'drzavaId', 'drzava')}
                selectedOption={countries.find(el => el.id === data.drzavaId) || null}
                customLabel="naziv"
                customValue="id"
                label={label.countryInput}
                errorTxt={error && !data.drzavaId && errorMsg('drzava', data)}
              />
            )}

            <SelectInput
              options={cities || []}
              filter={filterMap?.city}
              handleChangeSelect={e => handleSelectChange(e, 'gradId', 'grad')}
              selectedOption={cities.find(el => el.id === data.gradId) || null}
              customLabel="name"
              label={label.cityInput}
              additionalClass="selectLabelNext"
              customComponents={{ Option: CustomOptionSelect, SingleValue: SingleValue }}
              errorTxt={error && !data.gradId && errorMsg('grad', data)}
            />
            <SelectInput
              options={region || []}
              filter={filterMap?.region}
              handleChangeSelect={e => handleSelectChange(e, 'regionId', 'region')}
              selectedOption={region.find(el => el.id === data.regionId) || null}
              customLabel="naziv"
              customValue="id"
              label={label.regionInput}
              errorTxt={error && isDomestic && !data.regionId && errorMsg('region', data)}
            />
            <SelectInput
              options={township || []}
              filter={filterMap?.township}
              handleChangeSelect={e => handleSelectChange(e, 'opstinaId', 'opstina')}
              selectedOption={township.find(el => el.id === data.opstinaId) || null}
              customLabel="naziv"
              customValue="id"
              label={label.townshipInput}
              // disabled={disabled}
              // isPreview={disabled}
              // errorTxt={error && data.opstinaId === '' && errorMsg('opstina', data)}
            />

            <SelectInput
              options={workFieldData}
              handleChangeSelect={e => handleSelectChange(e, 'oblastId')}
              selectedOption={workFieldData.find(el => el.id === data.oblastId)}
              customLabel="naziv"
              customValue="id"
              label={label.areaInput}
              errorTxt={error && !data.oblastId && errorMsg('oblast', data)}
            />
            <SelectInput
              options={salesSpecialist}
              handleChangeSelect={e => handleSelectChange(e, 'korisnikId')}
              selectedOption={salesSpecialist.find(el => el.id === data.korisnikId)}
              customLabel="ime"
              customValue="id"
              label={label.salesSpecialist}
              errorTxt={error && !data.korisnikId && errorMsg('korisnik', data)}
            />
            {data.id && (
              <Input
                name="createdAt"
                label={label.dateOfEnteringCompany}
                value={dayjs(data.createdAt).format(dateFormat)}
                disabled={true}
              />
            )}
          </FormGroup>
          <FormGroup label={label.multiFieldLabelFormActivity}>
            <MultipleInputs
              name={CLIENT_CONNECTION_TB.activity}
              fields={multiFieldsDelatnostInitial}
              data={multiDataList[CLIENT_CONNECTION_TB.activity]}
              onAdd={onAddField}
              onRemove={onRemoveMultiselect}
              onChange={onMultipleChange}
              label={label.multiFieldLabelActivity}
              // labelForm={label.multiFieldLabelFormActivity}
              stylesClass="multiTwoFields"
              errorGroup={error && !multiDataList[CLIENT_CONNECTION_TB.activity].length && errorMsg('clientActivity', data)}
              errorRow={multiErrorRow}
            />
            {multiDataList[CLIENT_CONNECTION_TB.activity] &&
              multiDataList[CLIENT_CONNECTION_TB.activity].find(el => el.delatnostId === poljoprivreda?.id) && (
                <>
                  <Input
                    type="number"
                    name="obradivaPovrsina"
                    label={label.ownedLend}
                    placeholder={label.placeholder}
                    value={data.obradivaPovrsina}
                    onChange={handleChange}
                    globalClass="mt-3"
                  />
                </>
              )}
            <TextArea
              rows={5}
              globalClass="mt-3"
              name="opisPoslovanja"
              label={label.bussinesDesc}
              placeholder={label.placeholder}
              value={data.opisPoslovanja}
              onChange={handleChange}
              errorTxt={error && !data.opisPoslovanja && errorMsg('opisPoslovanja')}
            />
          </FormGroup>
          <FormGroup label={label.groupLabelProducts}>
            <SelectInput
              options={products || []}
              handleChangeSelect={e => handleSelectChange(e, CLIENT_CONNECTION_TB.productsBuy, false, true)}
              selectedOption={
                products.length && productsBuy.length ? products.filter(x => productsBuy.some(y => y.proizvodId === x.id)) : null
              }
              customLabel="naziv"
              customValue="id"
              label={label.purchaseProductsInput}
              multiselect={true}
              isClearable={false}
              errorTxt={error && !productsBuy.length && errorMsg('productsBuy', data)}
            />
            <SelectInput
              options={products || []}
              handleChangeSelect={e => handleSelectChange(e, CLIENT_CONNECTION_TB.productsSell, false, true)}
              selectedOption={
                products.length && productsSell.length ? products.filter(x => productsSell.some(y => y.proizvodId === x.id)) : null
              }
              customLabel="naziv"
              customValue="id"
              label={label.saleProductsInput}
              multiselect={true}
              isClearable={false}
              errorTxt={error && !productsSell.length && errorMsg('productsSell', data)}
            />
          </FormGroup>
        </div>
        <div>
          {/* <FormGroup label={label.noteInput}>
            <ListMessages listMessages={remarkList} onChange={onRemarkChange} />
          </FormGroup> */}

          <FormGroup label={label.groupFormContact}>
            <PhoneInputComp
              value={data.kontaktTelefon}
              label={label.companyPhoneInput}
              onChange={phone => handlePhoneChange('kontaktTelefon', phone)}
              errorTxt={error && errorMsg('kontaktTelefon', data)}
            />
            <Input
              name="email"
              label={label.emailInput}
              placeholder={label.placeholder}
              value={data.email}
              onChange={handleChange}
              errorTxt={error && errorMsg('email', data)}
            />
            <Input name="sajt" label={label.webInput} placeholder={label.placeholder} value={data.sajt} onChange={handleChange} />
            <Input
              name="direktor"
              label={label.directorInput}
              placeholder={label.placeholder}
              value={data.direktor}
              onChange={handleChange}
              errorTxt={error && !data.direktor && errorMsg('direktor', data)}
            />
            <PhoneInputComp
              value={data.direktorTelefon}
              label={label.directorTelInput}
              onChange={phone => handlePhoneChange('direktorTelefon', phone)}
            />
          </FormGroup>
          <FormGroup label={label.groupFormBank}>
            <SelectInput
              options={bank}
              handleChangeSelect={e => handleSelectChange(e, 'bankaId')}
              selectedOption={bank.find(el => el.id === data.bankaId)}
              customLabel="naziv"
              customValue="id"
              label={label.bank}
            />
            <Input name="racun" label={label.bankAccountInput} placeholder={label.placeholder} value={data.racun} onChange={handleChange} />
            <Input
              name="devizniRacun"
              label={label.bankAccountForeignInput}
              placeholder={label.placeholder}
              value={data.devizniRacun}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup label={label.multiFieldLabelFormCelebration}>
            <MultipleInputs
              name={CLIENT_CONNECTION_TB.clientCelebration}
              fields={multiFieldsCelebrationsInitial}
              data={multiDataList[CLIENT_CONNECTION_TB.clientCelebration]}
              onAdd={onAddField}
              onRemove={onRemoveMultiselect}
              onChange={onMultipleChange}
              label={label.multiFieldLabelCelebration}
              stylesClass="multiThreeFields"
              errorRow={multiErrorRow}
            />
          </FormGroup>
        </div>
      </TwoColumns>

      <OneColumn globalClass="p-0">
        <MultipleInputs
          name={CLIENT_CONNECTION_TB.contact}
          fields={multiFieldsContactsInitial}
          data={multiDataList[CLIENT_CONNECTION_TB.contact]}
          onAdd={onAddField}
          onRemove={onRemoveMultiselect}
          onChange={onMultipleChange}
          label={label.multiFieldLabelContact}
          labelForm={label.multiFieldLabelFormContact}
          stylesClass="multiFieldsRow"
          errorGroup={error && !multiDataList[CLIENT_CONNECTION_TB.contact].length && errorMsg('clientContacts', data)}
          errorRow={multiErrorRow}
        />
      </OneColumn>

      <OneColumn globalClass="p-0">
        <ListTimeOfContacts
          data={data}
          clientTimeOfContacts={multiDataList[CLIENT_CONNECTION_TB.timeOfContacts]}
          onAddField={onAddField}
          onRemoveMultiselect={onRemoveMultiselect}
          onMultipleChange={onMultipleChange}
          error={error}
          errorMsgFn={errorMsg}
          errorRow={multiErrorRow}
        />
      </OneColumn>
    </div>
  )
}

export default FormClientsLegal
