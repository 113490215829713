import React, { useEffect, useState } from 'react'
import Input from 'components/input'
import Title from 'components/title'
import { label } from 'constants/labels'
import { useGetOneQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { queryGetOrganization, queryUpdateOrganization } from 'query/quieryOrganizations'
import { FormRow, TwoColumns } from 'components/containers/containers'
import styles from './organization.module.scss'
import { initialOrganizationField, multiFieldsOrgInitial } from 'constants/initialValues'
import { deepClone, getAccessRights, isObjectEmpty } from 'utils'
import Button from 'components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MultipleInputs from 'components/inputMultiple'
import { selectCurrentUser } from 'redux/reducers/authSlice'
import { useSelector } from 'react-redux'

const initialBankNum = [{ banka: '', ziroRacun: '' }]

const Organization = ({ code }) => {
  const [data, setData] = useState(initialOrganizationField)
  const user = useSelector(selectCurrentUser)
  const { data: organization = {} } = useGetOneQuery(queryGetOrganization(1))
  const [updateOrg] = useUpdateCreateOneMutation()

  useEffect(() => {
    if (!isObjectEmpty(organization)) setData(organization)
  }, [organization])

  const handleSubmit = async () => {
    await updateOrg(queryUpdateOrganization(data, 1)).unwrap()
  }

  const onChange = e => {
    const { name, value } = e.target
    setData(prev => ({ ...prev, [name]: value }))
  }

  const onMultipleChange = (e, index) => {
    const temp = deepClone(data)
    const { name, value, type } = e.target
    if (temp.ziroRacuni) temp.ziroRacuni[index][name] = type === 'number' ? parseInt(value) : value
    setData(temp)
  }

  const onAddField = () => {
    const temp = deepClone(data)
    if (temp.ziroRacuni) temp.ziroRacuni.push({ ...initialBankNum, timestamp: new Date().getTime() })
    setData(temp)
  }

  const onRemove = index => {
    const temp = deepClone(data)
    if (temp.ziroRacuni) temp.ziroRacuni.splice(index, 1)
    setData(temp)
  }

  return (
    <div className={styles.wrapper}>
      <Title title={label.organization} globalClass="mb-2">
        <Button
          label={label.save}
          iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
          // disabled={disabled}
          onClick={handleSubmit}
          // loading={isLoading}
          stylesClass="btnWarning"
          accessAction="UPDATE"
          accessCode={code}
        />
      </Title>

      <TwoColumns>
        <div>
          <Input label={label.orgName} value={data.naziv} name="naziv" onChange={onChange} />
          <Input label={label.orgCity} value={data.grad} name="grad" onChange={onChange} />
          <Input label={label.orgAddress} value={data.adresa} name="adresa" onChange={onChange} />
          <FormRow>
            <Input label={label.orgMob} value={data.mobilni} name="mobilni" onChange={onChange} />
            <Input label={label.orgTel} onChange={onChange} value={data.telefon} name="telefon" />
          </FormRow>
          <Input label={label.orgWeb} value={data.website} onChange={onChange} name="website" />
          <Input label={label.orgEmail} value={data.email} onChange={onChange} name="email" />
        </div>
        <div>
          <Input label={label.orgPib} value={data.pib} name="pib" onChange={onChange} />
          <Input label={label.orgFirmNum} value={data.maticniBr} onChange={onChange} name="maticniBr" />
          <Input label={label.orgCode} value={data.sifraDelatnosti} onChange={onChange} name="sifraDelatnosti" />
          <div>
            <MultipleInputs
              stylesClass="multiTwoFields"
              name="organization"
              fields={multiFieldsOrgInitial}
              data={data.ziroRacuni}
              onAdd={onAddField}
              onRemove={!getAccessRights(user, code, 'DELETE') && onRemove}
              onChange={onMultipleChange}
              label={label.orgAddBankNum}
              labelForm={label.multiFieldLabelFormBank}
              showDesapiringMsg={true}
            />
          </div>
        </div>
      </TwoColumns>
    </div>
  )
}

export default Organization
