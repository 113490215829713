import { BoxContainer } from 'components/containers/containers'
import styles from './auth.module.scss'
import FormForgotPassword from '../../components/forms/formForgotPassword'
import { NavLink, useNavigate } from 'react-router-dom'
import { useChangeForgotPasswordMutation } from 'api/authApiSlice'

const ResetPassword = () => {
  const [changeForgotPassword] = useChangeForgotPasswordMutation()
  const navigate = useNavigate()

  const onSubmit = async data => {
    await changeForgotPassword(data)
    navigate('/')
  }

  return (
    <div className={styles.wrapper}>
      <BoxContainer globalClass="p-3">
        <FormForgotPassword onSubmit={onSubmit} />
        <div className="ta-c ">
          Nazad na <NavLink to="/">Login stranicu!</NavLink>
        </div>
      </BoxContainer>
    </div>
  )
}
export default ResetPassword
