/** Page  'Blog' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetAllQuery } from 'api/dataApiSlice'
import Button from 'components/button'
import { OneColumn } from 'components/containers/containers'
import { BLOGS_STATUS } from 'constants/staticOptions'
import HistoryTable from 'components/historyTable'

import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { blogFilter } from 'constants/searchFields'
import { headingBlog } from 'constants/tbHeading'
import { queryGetPagedBlog } from 'query/queryBlog'
import { queryGetAllUsers } from 'query/queryUser'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { openModal } from 'redux/reducers/modalSlice'

const Blog = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: blogs = [] } = useGetAllQuery(queryGetPagedBlog(pageNumber, pageSize, searchOptions))

  const onRowClick = data => navigate(`${data.id}`)

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/blog`)
  }

  // const showModal = () => {
  //   navigate(`/oglas`)
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: (
  //           <FormWrapper>
  //             <h2>{label.addAnnouncement}</h2>
  //             <FormAnnouncement existingData={data} onSubmit={onSubmit} id={null} />
  //           </FormWrapper>
  //         ),
  //         order: 1,
  //         size: 'md'
  //       },
  //       active: 1
  //     })
  //   )
  // }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="blog"
                link="/blog?id="
                connectionTb={[
                  { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
                  { staticData: BLOGS_STATUS, dbName: 'blogStatus' }
                ]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.blogInput} total={blogs?.count}>
        <Button
          onClick={() => navigate('dodaj')}
          label={label.add}
          iconLeft={<FontAwesomeIcon icon="fa-plus" />}
          accessAction="ADD"
          accessCode={code}
        />
      </Title>
      <Search fields={blogFilter} onSearch={onSubmitSearch} />
      {!!blogs?.rows?.length && (
        <h3 className="history-link-border" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <OneColumn>
        <Table
          heading={headingBlog}
          data={blogs?.rows}
          onRowClick={onRowClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={blogs?.count}
        />
      </OneColumn>
    </div>
  )
}
export default Blog
