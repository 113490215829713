/** Page  'Product/Proizvod' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllMapQuery, useGetAllQuery, useLazyGetOneQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormProduct from 'components/forms/formProduct'
import HistoryTable from 'components/historyTable'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { DATA_TYPES, PAGE_SIZE } from 'constants/other'
import { productSearchFields } from 'constants/searchFields'
import { headingProducts } from 'constants/tbHeading'
import { queryGetAllCategories } from 'query/querycategory'
import { queryGetAllCurrency } from 'query/queryCurrency'
import { queryGetAllEconomyBranches } from 'query/queryEconomyBranch'
import { queryGetAllMeasureUnits } from 'query/queryMeasureUnit'
import { queryDeleteMeasureUnitProduct, queryGetAllMeasureUnitProducts } from 'query/queryMeasureUnitProduct'
import { queryDeleteProductDesc } from 'query/queryProductDescription'
import {
  queryAddProduct,
  queryDeleteProduct,
  queryGetAllProducts,
  queryGetPagedProductsSearch,
  queryUpdateProduct,
  queryGetProduct
} from 'query/queryProducts'
import { queryGetAllUsers } from 'query/queryUser'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import toastService from 'services/toastService'
import { isObjectEmpty } from 'utils'

const Products = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: productsData = [] } = useGetAllQuery(queryGetPagedProductsSearch(pageNumber, pageSize, searchOptions))
  const [createData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()
  const [getOne] = useLazyGetOneQuery()
  const { data: measureUnits = {} } = useGetAllMapQuery(queryGetAllMeasureUnits())

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const fetchOne = async id => {
        try {
          const res = await getOne(queryGetProduct(id)).unwrap()
          setData(res)
        } catch (e) {
          console.log(e)
        }
      }
      const found = productsData.rows && productsData.rows.find(e => e.id === +id)
      if (found) setData(found)
      else fetchOne(id)
    } else {
      setData({})
    }
  }, [id, productsData])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, id) => {
    if (!data.jedinicaMereId) return toastService.show('warn', 'Morate dodati podrazumevanu JM za kolicinu')
    if (!data.cenaJedinicaMereId) return toastService.show('warn', 'Morate dodati podrazumevanu JM za kolicinu')
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await createData(queryUpdateProduct(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddProduct(data))
      if (!res.error) {
        dispatch(closeModal(1))
      }
    }
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/proizvod?tip=srbija&&id=${data.id}`)
    setData(data)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteProduct(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/proizvod?tip=srbija`)
    } else {
      dispatch(closeModal(1))
    }
    goBack()
  }

  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteProduct} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/proizvod?tip=srbija`)
  }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const goBack = () => {
    navigate('/proizvod?tip=srbija')
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="proizvod"
                type={DATA_TYPES.productsSerbia}
                link="/proizvod?tip=srbija&&id="
                connectionTb={[
                  { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
                  { rtkQuery: queryGetAllCategories(), dbName: 'kategorijaId' },
                  { rtkQuery: queryGetAllEconomyBranches(), dbName: 'granaPrivredeId' },
                  { rtkQuery: queryGetAllMeasureUnits(), dbName: 'jedinicaMereId' },
                  { rtkQuery: queryGetAllMeasureUnitProducts(), dbName: 'jedinicaMereProizvod' },
                  { rtkQuery: queryGetAllCurrency(), dbName: 'valutaId' },
                  { rtkQuery: queryGetAllProducts(), dbName: 'proizvodId' }
                ]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }

  const showModal = () => {
    navigate(`/proizvod?tip=srbija`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.addProduct}</h2>
              <FormProduct id={null} onSubmit={onSubmit} />
            </FormWrapper>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }

  const handleMultiDelete = async (answer, data) => {
    if (answer && data && !data.item.naziv) {
      const res = await deleteOne(queryDeleteMeasureUnitProduct(data.item.id, DATA_TYPES.productsSerbia))
      if (!res.error) {
        onSubmit(data.data, id)
        dispatch(closeModal(1))
      }
    } else if (data && data.item.naziv) {
      const res = await deleteOne(queryDeleteProductDesc(data.item.id, DATA_TYPES.productsSerbia))
      if (!res.error) {
        onSubmit(data.data, id)
        dispatch(closeModal(1))
      }
    } else {
      dispatch(closeModal(1))
    }
  }

  const openDeleteMultiModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <ModalYesNo
              handleSubmit={handleMultiDelete}
              data={data}
              question={data && data.naziv ? label.msgDeleteProductDesc : label.msgDeleteMeasureUnit}
            />
          ),
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }

  return (
    <div>
      <Title title={label.products} total={productsData?.count}>
        <Button
          onClick={() => showModal()}
          label={label.add}
          iconLeft={<FontAwesomeIcon icon="fa-plus" />}
          accessAction="ADD"
          accessCode={code}
        />
      </Title>
      <Search fields={productSearchFields} onSearch={onSubmitSearch} />
      {!!productsData?.rows?.length && (
        <h3 className="history-link-border" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns globalClass="mt-2">
        <Table
          heading={headingProducts}
          data={productsData.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={productsData?.count}
          map={{
            measureUnitsMaped: measureUnits?.entities
          }}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormProduct
              existingData={data}
              header={true}
              id={id}
              onSubmit={onSubmit}
              onDelete={openDeleteModal}
              onMultiDelete={openDeleteMultiModal}
              code={code}
            />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default Products
