import { label } from 'constants/labels'
import { validateEmail, validatePhone } from 'utils'

export const errMsgLegalEntities = (type, data, isDomestic) => {
  let message
  switch (type) {
    case 'pib':
      if (!data.pib) message = `${label.PIBErr}`
      else if (isDomestic && data.pib?.length < 9) message = `${label.PIBLengthErr}`
      break
    case 'maticniBroj':
      message = `${label.identificationNumberErr}`
      break
    case 'naziv':
      message = `${label.nameClientLegalInputErr}`
      break
    case 'adresa':
      message = `${label.adresaErr}`
      break
    case 'grad':
      message = `${label.cityErr}`
      break
    case 'drzava':
      message = `${label.countryErr}`
      break
    case 'region':
      message = `${label.regionErr}`
      break
    case 'clientActivity':
      message = `${label.clientActivityErr}`
      break
    case 'oblast':
      message = `${label.areaErr}`
      break
    // case 'tipLicaSifra':
    //   message = `${label.typeEntityErr}`
    //   break
    case 'kontaktTelefon':
      if (!data.kontaktTelefon) message = `${label.companyPhoneErr}`
      else if (data.kontaktTelefon && data.kontaktTelefon.length <= 10) message = label.companyPhoneErrLenth

      break
    case 'direktor':
      message = `${label.bossErr}`
      break
    case 'status':
      message = `${label.statusErr}`
      break
    case 'clientContacts':
      message = `${label.clientContactsErr}`
      break
    case 'email':
      if (!data.email) message = `${label.emailReq}`
      else if (data.email && !validateEmail(data.email)) message = `${label.emailErr}`
      break
    case 'clientTimeOfContacts':
      message = `${label.clientContactsTimeErr}`
      break
    case 'productsBuy':
      message = `${label.clientProductBuyErr}`
      break
    case 'productsSell':
      message = `${label.clientProductSellErr}`
      break
    case 'korisnik':
      message = `${label.clientUserErr}`
      break
    case 'opisPoslovanja':
      message = `${label.bussinesDescErr}`
      break

    default:
      message = 'Greska'
      break
  }
  return message
}

export const errMsgFarm = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.companyFarmNameErr}`
      break
    case 'adresa':
      message = `${label.adresaErr}`
      break
    case 'grad':
      message = `${label.cityErr}`
      break
    case 'drzava':
      message = `${label.countryErr}`
      break
    case 'region':
      message = `${label.regionErr}`
      break
    case 'clientActivity':
      message = `${label.clientActivityErr}`
      break
    case 'oblast':
      message = `${label.areaErr}`
      break
    case 'status':
      message = `${label.statusErr}`
      break
    case 'kontaktTelefon':
      if (!data.kontaktTelefon) message = `${label.companyPhoneErr}`
      else if (data.kontaktTelefon && data.kontaktTelefon.length <= 10) message = label.companyPhoneErrLenth
      break
    case 'direktor':
      message = `${label.bossErr}`
      break
    case 'clientContacts':
      message = `${label.clientContactsErr}`
      break
    case 'email':
      if (!data.email) message = `${label.emailReq}`
      else if (data.email && !validateEmail(data.email)) message = `${label.emailErr}`
      break
    case 'clientTimeOfContacts':
      message = `${label.clientContactsTimeErr}`
      break
    case 'productsBuy':
      message = `${label.clientProductBuyErr}`
      break
    case 'productsSell':
      message = `${label.clientProductSellErr}`
      break
    case 'korisnik':
      message = `${label.clientUserErr}`
      break
    case 'opisPoslovanja':
      message = `${label.bussinesDescErr}`
      break
    default:
      message = 'Greska'
      break
  }

  return message
}

export const errMsgFormUser = (type, data) => {
  let message
  switch (type) {
    case 'korisnickoIme':
      message = `${label.usernameErr}`
      break
    case 'lozinka':
      message = `${label.passwordErr}`
      break
    case 'ime':
      message = `${label.firstNameErr}`
      break
    case 'prezime':
      message = `${label.lastNameErr}`
      break
    case 'ulogaId':
      message = `${label.ulogaErr}`
      break
    case 'mobilni':
      if (!data.mobilni) message = `${label.mobileErr}`
      else if (data.mobilni && data.mobilni.length <= 10) message = label.forgotPasswordPhoneErrLenth
      break
    case 'poslovniBroj':
      if (!data.poslovniBroj) message = `${label.businessPhoneErr}`
      else if (data.poslovniBroj && !validatePhone(data.poslovniBroj)) message = `${label.businessPhoneValidErr}`
      break
    case 'email':
      if (!data.email) message = `${label.emailReq}`
      else if (data.email && !validateEmail(data.email)) message = `${label.emailErr}`
      break
    case 'godinaRodjenja':
      message = `${label.birthYearErr}`
      break
    case 'pozicijaUFirmi':
      message = `${label.positionInCompanyErr}`
      break
    case 'datumPocetkaRadnogOdnosa':
      message = `${label.workingInCompanyStartDateErr}`
      break
    case 'maticniBroj':
      message = `${label.maticniBrojErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errMsgXLSXImport = (type, data) => {
  let message
  switch (type) {
    case 'firstName':
      message = 'First name is required!'
      break
    case 'lastName':
      message = 'Last name is required!'
      break
    case 'gender':
      message = 'Gender is required!'
      break
    case 'age':
      message = 'Age is required!'
      break
    case 'date':
      if (data.date && data.date === 'Invalid Date') message = 'Invalid date!'
      message = 'Date is required!'
      break
    case 'country':
      message = 'Country is required'
      break
    default:
      message = 'Error'
      break
  }
  return message
}

export const errEntitiesType = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'sifra':
      message = `${label.codeErr}`
      break
    case 'vrsta':
      message = `${label.vrstaErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errCountry = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'pozivniBroj':
      message = `${label.phonePrefixInputErr}`
      break

    case 'sort':
      message = `${label.sortInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errRegion = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'drzavaId':
      if (data.countries.length === 0) {
        message = `${label.countrySelectErr}`
      } else {
        message = `${label.countryErr}`
      }
      break
    case 'sort':
      message = `${label.sortInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errTownship = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'postanskiBroj':
      message = `${label.zipCodeErr}`
      break
    case 'regionId':
      message = `${label.regionErr}`

      break
    case 'drzavaId':
      message = `${label.countryErr}`

      break
    case 'sort':
      message = `${label.sortInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errCity = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'postanskiBroj':
      message = `${label.zipCodeErr}`
      break
    case 'regionId':
      message = `${label.regionErr}`

      break
    case 'drzavaId':
      message = `${label.countryErr}`

      break
    case 'opstinaId':
      message = `${label.townshipErr}`

      break
    case 'sort':
      message = `${label.sortInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errBusinessActivity = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errSubBusinessActivity = (type, businessActivity) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'delatnostId':
      if (businessActivity.length === 0) {
        message = `${label.businessActivitiSelectErr}`
      } else {
        message = `${label.businessActivitiErr}`
      }
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errBank = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errProductPort = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'kategorijaLuka':
      message = `${label.measureCatInputErr}`
      break
    case 'valuta':
      message = `${label.currencyErr}`
      break
    case 'jedinica-mere':
      message = `${label.measureUnitsInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errProductBosnia = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'bosnaValutaPlacanja':
      message = `${label.bosnaValutaPlacanjaErr}`
      break
    case 'kategorija':
      message = `${label.categoryErr}`
      break
    case 'valuta':
      message = `${label.currencyErr}`
      break
    case 'jedinicaMere':
      message = `${label.measureUnitsInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errPortDelivery = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'valutaPlacanja':
      message = `${label.productSeaPortCurrencyErr}`
      break
    case 'valuta':
      message = `${label.currencyErr}`
      break
    case 'jedinicaMere':
      message = `${label.measureUnitsInputErr}`
      break

    default:
      message = 'Greska'
      break
  }
  return message
}

export const errProductFutures = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'kategorija':
      message = `${label.categoryErr}`
      break
    case 'valuta':
      message = `${label.currencyErr}`
      break
    case 'jedinicaMere':
      message = `${label.measureUnitsInputErr}`
      break
    case 'berza':
      message = `${label.stockExchangeSelectErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errProductMilano = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'valuta':
      message = `${label.currencyErr}`
      break
    case 'jedinicaMere':
      message = `${label.measureUnitsInputErr}`
      break
    case 'milanoValutaPlacanja':
      message = `${label.milanoValutaPlacanjaErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errDelivery = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

// export const errMsgFarmSrbija = (type, data) => {
//   let message
//   switch (type) {
//     case 'nazivFirme':
//       message = `${label.companyNameErr}`
//       break
//     case 'tipPravnogLica':
//       message = `${label.typeLegalEntitiesErr}`
//       break
//     case 'adresa':
//       message = `${label.adresaErr}`
//       break
//     case 'grad':
//       message = `${label.cityErr}`
//       break
//     case 'delatnostJedan':
//       message = `${label.businessActivityOneErr}`
//       break
//     case 'poddelatnost':
//       message = `${label.subBusinessActivitiErr}`
//       break
//     case 'oblast':
//       message = `${label.areaErr}`
//       break
//     case 'kontaktTelFirme':
//       message = `${label.companyPhoneErr}`
//       break
//     case 'imeZastupnika':
//       message = `${label.supplierNameErr}`
//       break
//     case 'email':
//       if (!data.email) message = `${label.emailReq}`
//       else if (data.email && !validateEmail(data.email)) message = `${label.emailErr}`
//       break
//     case 'datumKontakta':
//       message = `${label.dateOfContactErr}`
//       break
//     default:
//       message = 'Greska'
//       break
//   }
//   return message
// }

export const errEconomyBranch = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    case 'sifra':
      message = `${label.codeErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errCategory = (type, granaPrivrede = []) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    case 'granaPrivrede':
      if (granaPrivrede.length === 0) {
        message = `${label.economyBranchSelectErr}`
      } else {
        message = `${label.economyBranchErr}`
      }
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errMeasureUnit = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'oznaka':
      message = `${label.markErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errCreateReport = type => {
  let message
  switch (type) {
    case 'description':
      message = 'Naslov je obavezno polje'
      break
    case 'tipIzvestaja':
      message = 'Tip izvestaja je obavezno polje'
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errMeasureUnitsRatio = type => {
  let message
  switch (type) {
    case 'uJedinicaMereId':
      message = `${label.intoMeasureUnitRatioErr}`
      break
    case 'izJedinicaMereId':
      message = `${label.fromMeasureUnitRatioErr}`
      break
    case 'odnos':
      message = `${label.measureUnitRatioErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errService = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'kod':
      message = `${label.kodErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    case 'kategorijaUslugaId':
      message = `Kategorija usluga je obavezna`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errEntityStatus = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errTypeOfContact = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errProducts = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    case 'sifraGranaPrivrede':
      message = `${label.codeErr}`
      break
    case 'jedinicaMere':
      message = `${label.measureUnitsInputErr}`
      break
    case 'opisProizvoda':
      message = `${label.descriptionErr}`
      break
    case 'granaPrivrede':
      if (data && data.economyBranchData.length === 0) {
        message = `${label.economyBranchSelectErr}`
      } else {
        message = `${label.economyBranchErr}`
      }
      break
    case 'cenaJedinicaMereId':
      message = `${label.measureUnitsInputErr}`
      break
    case 'valutaId':
      message = `${label.currencyErr}`
      break
    case 'kategorija':
      if (data && data.categoryData.length === 0) {
        message = `${label.categorySelectErr}`
      } else {
        message = `${label.categoryErr}`
      }
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errProductDesc = (type, products = []) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'proizvodId':
      if (products.length === 0) {
        message = `${label.productDescSelectErr}`
      } else {
        message = `${label.productDesErr}`
      }
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errPackage = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'tipTrajanja':
      message = `${label.durationTypeErr}`
      break
    case 'trajanje':
      message = `${label.durationInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    case 'opis':
      message = `${label.descriptionErr}`
      break
    case 'kratakOpis':
      message = `${label.shortDescriptionErr}`
      break
    case 'status':
      message = `${label.statusErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errAnnouncement = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'opis':
      message = `${label.descriptionErr}`
      break
    case 'cena':
      message = `${label.priceErr}`
      break
    case 'utovar':
      message = `${label.francoRegionErr}`
      break

    case 'kolicina':
      message = `${label.amountErr}`
      break
    case 'tipPlacanja':
      message = `${label.paymentTypeErr}`
      break

    case 'status':
      message = `${label.statusErr}`
      break
    case 'proizvodId':
      message = `${label.productErr}`
      break
    case 'opisProizvodaId':
      message = `${label.productDesErr}`

      break
    case 'unetaJedinicaMereId':
      message = `${label.measureUnitsInputErr}`
      break
    case 'cenaJedinicaMereId':
      message = `${label.measureUnitsInputErr}`
      break

    case 'datumVazenja':
      message = `${label.dateDurationInputErr}`
      break

    case 'ponuda/potraznja':
      message = `${label.bidDemandErr}`
      break
    case 'valutaId':
      message = `${label.currencyErr}`
      break
    case 'kontakti':
      message = `${label.contactErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errBlog = type => {
  let message
  switch (type) {
    case 'naslov':
      message = `${label.blogTitleErr}`
      break
    case 'podnaslov':
      message = `${label.blogSubtitleErr}`
      break
    case 'tekst':
      message = `${label.blogTextErr}`
      break
    case 'blogStatus':
      message = `${label.statusErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}
export const errMsgContactPerson = (type, data) => {
  let message
  switch (type) {
    case 'ime':
      message = `${label.firstNameErr}`
      break
    case 'prezime':
      message = `${label.lastNameErr}`
      break
    case 'email':
      if (!data.email) message = `${label.emailReq}`
      else if (data.email && !validateEmail(data.email)) message = `${label.emailErr}`
      break
    case 'klijent':
      message = `${label.clientErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errWorkField = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errSeaPort = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'drzavaId':
      if (data.countries.length === 0) {
        message = `${label.countrySelectErr}`
      } else {
        message = `${label.countryErr}`
      }
      break
    case 'gradId':
      if (data.cities.length === 0) {
        message = `${label.citySelectErr}`
      } else {
        message = `${label.cityErr}`
      }
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errStockExchange = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'drzavaId':
      if (data.countries.length === 0) {
        message = `${label.countrySelectErr}`
      } else {
        message = `${label.countryErr}`
      }
      break
    case 'valuta':
      message = `${label.currencyErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errHistoryReport = type => {
  let message
  switch (type) {
    case 'singleEmail':
      message = `${label.clientErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errCurrency = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'kod':
      message = `${label.kodErr}`
      break
    case 'simbol':
      message = `${label.simbolErr}`
      break

    case 'drzavaId':
      if (data.countries.length === 0) {
        message = `${label.countrySelectErr}`
      } else {
        message = `${label.countryErr}`
      }
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errWorldSeaPort = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'lukaId':
      if (data.seaPort.length === 0) {
        message = `${label.seaPortSelectErr}`
      } else {
        message = `${label.seaPortErr}`
      }
      break
    case 'unetaJedinicaMereId':
      if (data && data.measureUnitsData.length === 0) {
        message = `${label.measureUnitsSelectErr}`
      } else {
        message = `${label.measureUnitsInputErr}`
      }
      break
    case 'proizvodId':
      if (data.products.length === 0) {
        message = `${label.productSelectErr}`
      } else {
        message = `${label.productErr}`
      }
      break
    case 'valutaId':
      if (data.currency.length === 0) {
        message = `${label.currencySelectErr}`
      } else {
        message = `${label.currencyErr}`
      }
      break
    case 'datumUnosa':
      message = `${label.dateErr}`
      break
    case 'periodVazenjaCena':
      message = `${label.priceDatePeriodErr}`
      break
    case 'cena':
      message = `${label.priceErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errWorldStockExchange = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'berzaId':
      if (data.stockExchange.length === 0) {
        message = `${label.stockExchangeSelectErr}`
      } else {
        message = `${label.stockExchangeErr}`
      }
      break
    case 'unetaJedinicaMereId':
      if (data && data.measureUnitsData.length === 0) {
        message = `${label.measureUnitsSelectErr}`
      } else {
        message = `${label.measureUnitsInputErr}`
      }
      break
    case 'proizvodId':
      if (data.products.length === 0) {
        message = `${label.productSelectErr}`
      } else {
        message = `${label.productErr}`
      }
      break
    case 'valutaId':
      if (data.currency.length === 0) {
        message = `${label.currencySelectErr}`
      } else {
        message = `${label.currencyErr}`
      }
      break
    case 'gradNaziv':
      message = `${label.cityErr}`
      break
    case 'datumUnosa':
      message = `${label.dateErr}`
      break
    case 'periodVazenjaCena':
      message = `${label.priceDatePeriodErr}`
      break
    case 'cena':
      message = `${label.priceErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errWorldStockExchangeMilano = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'berzaId':
      if (data.stockExchange.length === 0) {
        message = `${label.stockExchangeSelectErr}`
      } else {
        message = `${label.stockExchangeErr}`
      }
      break
    case 'jedinicaMereId':
      if (data && data.measureUnitsData.length === 0) {
        message = `${label.measureUnitsSelectErr}`
      } else {
        message = `${label.measureUnitsInputErr}`
      }
      break
    case 'proizvodId':
      if (data.products.length === 0) {
        message = `${label.productSelectErr}`
      } else {
        message = `${label.productErr}`
      }
      break
    case 'valutaId':
      if (data.currency.length === 0) {
        message = `${label.currencySelectErr}`
      } else {
        message = `${label.currencyErr}`
      }
      break
    case 'gradNaziv':
      message = `${label.cityMilanoSelectErr}`
      break
    case 'datumUnosa':
      message = `${label.dateErr}`
      break
    case 'periodVazenjaCena':
      message = `${label.priceDatePeriodErr}`
      break
    case 'cenaMin':
      message = `${label.priceMinErr}`
      break
    case 'cenaMax':
      message = `${label.priceMaxErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errPriceMovementBih = (type, data) => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'unetaJedinicaMereId':
      if (data && data.measureUnitsData.length === 0) {
        message = `${label.measureUnitsSelectErr}`
      } else {
        message = `${label.measureUnitsInputErr}`
      }
      break
    case 'proizvodId':
      if (data.products.length === 0) {
        message = `${label.productSelectErr}`
      } else {
        message = `${label.productErr}`
      }
      break
    case 'klijentId':
      if (data.client.length === 0) {
        message = `${label.clientSelectErr}`
      } else {
        message = `${label.clientErr}`
      }
      break
    case 'valutaId':
      if (data.currency.length === 0) {
        message = `${label.currencySelectErr}`
      } else {
        message = `${label.currencyErr}`
      }
      break
    case 'datum':
      message = `${label.dateErr}`
      break
    case 'period':
      message = `${label.periodInput}`
      break
    case 'cena':
      message = `${label.priceErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errCustomGoodsImportAndExport = type => {
  let message
  switch (type) {
    case 'datumUnosa':
      message = `${label.dateOfImportInput}`
      break
    case 'datum':
      message = `${label.dateErr}`
      break
    case 'aktivan':
      message = `${label.activeInputErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errForgotPassword = (type, data) => {
  let message
  switch (type) {
    case 'email':
      if (!data.email) message = `${label.emailReq}`
      else if (data.email && !validateEmail(data.email)) message = `${label.emailErr}`
      break
    case 'mobilni':
      message = `${label.mobileErr}`
      if (!data.mobilni) message = `${label.mobileErr}`
      else if (data.mobilni && data.mobilni.length <= 10) message = label.forgotPasswordPhoneErrLenth
      break
    case 'forgot-sms-code':
      if (!data.forgotCode) message = `${label.forgotSmsCodeErr}`
      else if (data.forgotCode && data.forgotCode.length !== 4) message = label.forgotSmsCodeErrLength
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errChangeForgotPassword = (type, data) => {
  let message
  switch (type) {
    case 'lozinka':
      message = `${label.passwordNewErr}`
      break
    case 'lozinkaPotvrda':
      if (!data.lozinkaPotvrda) message = `${label.passwordConfirmErr}`
      else if (data.lozinka && data.lozinkaPotvrda && data.lozinka !== data.lozinkaPotvrda) message = `${label.passwordConfirmEqualErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}
