import styles from './slate.module.scss'
const ImageElement = ({ attributes, children, element, imgClass }) => {
  return (
    <div {...attributes} className={styles[imgClass]}>
      {children}
      <img src={element?.url} className="image" />
    </div>
  )
}
export const Element = props => {
  const { attributes, children, element } = props
  const editable = element?.contentEditable ?? true
  switch (element.type) {
    case 'quote':
      return (
        <blockquote {...attributes} className={styles[element?.class || 'defaultQuote']}>
          {children}
        </blockquote>
      )
    case 'table': {
      return (
        <table {...attributes} className={styles[element?.class || 'defaultTable']}>
          <tbody {...attributes}>{children}</tbody>
        </table>
      )
    }
    case 'tr':
      return (
        <tr {...attributes} className={styles[element?.class || 'defaultTr']} contentEditable={editable}>
          {children}
        </tr>
      )
    case 'td':
      return (
        <td {...attributes} className={styles[element?.class || 'defaultTd']}>
          {children}
        </td>
      )
    case 'th':
      return (
        <th {...attributes} className={styles[element?.class || 'defaultTh']}>
          {children}
        </th>
      )
    case 'div':
      return (
        <div {...attributes} className={styles[element?.class || 'defaultDiv']}>
          {children}
        </div>
      )
    case 'paragraph':
      return (
        <p {...attributes} className={styles[element?.class || 'defaultParagraph']}>
          {children}
        </p>
      )
    case 'code':
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      )
    case 'bulleted-list':
      return (
        <ul {...attributes} className={styles[element?.class || 'defaultUl']}>
          {children}
        </ul>
      )
    case 'h1':
      return (
        <h1 {...attributes} className={styles[element?.class || 'defaultH1']}>
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2 {...attributes} className={styles[element?.class || 'defaultH2']}>
          {children}
        </h2>
      )
    case 'h3':
      return (
        <h3 {...attributes} className={styles[element?.class || 'defaultH3']}>
          {children}
        </h3>
      )
    case 'h4':
      return (
        <h4 {...attributes} className={styles[element?.class || 'defaultH4']}>
          {children}
        </h4>
      )
    case 'h5':
      return (
        <h5 {...attributes} className={styles[element?.class || 'defaultH5']}>
          {children}
        </h5>
      )
    case 'h6':
      return (
        <h6 {...attributes} className={styles[element?.class || 'defaultH6']}>
          {children}
        </h6>
      )
    case 'list-item':
      return (
        <li {...attributes} className={styles[element?.class || 'defaultLi']}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol {...attributes} className={styles[element?.class || 'defaultOl']}>
          {children}
        </ol>
      )
    case 'link':
      return (
        <a href={element.url} {...attributes} className={styles[element?.class || 'defaultLink']}>
          {children}
        </a>
      )
    case 'image':
      return <ImageElement {...props} imgClass={element?.class || 'defaultImg'} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong className={styles[leaf?.class] || styles.leafBold}>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>
  }
  if (leaf.small) {
    children = <small className={styles[leaf?.class] || styles.leafSmall}>{children}</small>
  }
  if (leaf.img) {
    children = <img src={leaf?.url} className={styles[leaf?.class] || styles.leafImg} />
  }
  return <span {...attributes}>{children}</span>
}
