import { BILL_TYPES } from 'constants/other'

const getReportType = (type, report = {}) => {
  let arr = []
  if (type === BILL_TYPES.bill) {
    arr = [
      {
        type: 'td',
        class: 'clientLabel',
        children: [{ text: 'RAČUN BR:', bold: true }]
      },
      {
        type: 'td',
        class: 'clientValue',
        children: [{ text: report?.billNum?.toString() || 'N/A', bold: true }]
      }
    ]
  } else if (type === BILL_TYPES.preBill) {
    arr = [
      {
        type: 'td',
        class: 'clientLabel',
        children: [{ text: 'PREDRAČUN BR:', bold: true }]
      },
      {
        type: 'td',
        class: 'clientValue',
        children: [{ text: report?.preBillNum?.toString() || 'N/A', bold: true }]
      }
    ]
  } else if (type === BILL_TYPES.avans) {
    arr = [
      {
        type: 'td',
        class: 'clientLabel',
        children: [{ text: 'AVANSNI RAČUN BR:', bold: true }, { text: `po predračunu: ${report?.preBillNum || 'N/A'}` }]
      },
      {
        type: 'td',
        class: 'clientValue',
        children: [{ text: report?.avansNum?.toString() || 'N/A', bold: true }]
      }
    ]
  }
  return arr
}

export const getClientHeader = (data = {}, type = 'preBill') => {
  const { client = {}, report = {}, owner = {} } = data

  return [
    {
      type: 'table',
      class: 'tableClient',
      children: [
        {
          type: 'tr',
          class: 'clientTrFirst',
          children: getReportType(type, report)
        },
        {
          type: 'tr',
          class: 'clientTrSecond',
          children: [
            {
              type: 'td',
              children: [{ text: client?.name?.toString() || 'N/A', bold: true }]
            },
            {
              type: 'td',
              children: [{ text: client?.address?.toString() || 'N/A' }]
            },
            {
              type: 'td',
              children: [{ text: client?.town?.toString() || 'N/A' }]
            },
            {
              type: 'td',
              children: [{ text: `Maticni br:` }, { text: `${owner?.firmNum?.toString() || 'N/A'}` }]
            },
            {
              type: 'td',
              children: [{ text: `PIB:` }, { text: `${owner?.pib?.toString() || 'N/A'}` }]
            }
          ]
        }
      ]
    }
  ]
}
