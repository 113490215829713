import React from 'react'
import { PropTypes } from 'prop-types'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'
import styles from './switchBtn.module.scss'

const SwitchBtn = ({ onChange, stylesClass = 'defaultSwitch', globalClass = '', label, checked, disabled }) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass} g-switch`}>
      {label && <label>{label}</label>}
      <Switch onChange={onChange} checked={checked} disabled={disabled} />
    </div>
  )
}

SwitchBtn.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
}
export default SwitchBtn
