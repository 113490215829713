import React, { useEffect, useState } from 'react'
import styles from './nestedServices.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox from 'components/checkbox'

const AccordionItem = ({ itemKey, label, children, onChangeHandleSelect, checkedAccordion, level }) => {
  const [isOpen, setIsOpen] = useState(checkedAccordion[itemKey] === true ? checkedAccordion[itemKey] : false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (checkedAccordion[itemKey]) {
      setIsOpen(true)
    }
  }, [checkedAccordion, itemKey])
  return (
    <div
      className={`${styles.accordionItem} ${styles.accordionLevel} ${level === 0 ? styles.firstLevel : styles.levels}  ${
        isOpen ? styles.opened : styles.closed
      }`}
      style={{ paddingLeft: `${level * 2.5}rem` }}
    >
      <div className={`${styles.accordionHeader}`}>
        {/* <span className="indent">{label}</span> */}
        <FontAwesomeIcon icon={isOpen ? 'fa-chevron-up' : 'fa-chevron-down'} onClick={toggleAccordion} />
        <Checkbox
          id={'chekbox-accordion-' + itemKey}
          label={label}
          name={label}
          globalClass="mt-0 mb-0"
          // disabled={disabled}
          // isPreview={disabled}
          onChange={event => onChangeHandleSelect(event, itemKey)}
          checked={checkedAccordion[itemKey]}
        />
      </div>

      <div className={`${styles.accordionContent} `} style={{ display: isOpen ? 'flex' : 'none' }}>
        {children}
      </div>
    </div>
  )
}

const NestedCheckboxes = ({ data, handleCheckboxChange, checkedItems, level }) => {
  if (!data?.length) return
  return (
    <div className={`${styles.checkboxList} ${styles.nestedCheckboxes} ${level === 1 ? styles.nestedFirstLevel : styles.nestedLevels} `}>
      {data.map(item => (
        <Checkbox
          label={item.naziv}
          name={item.naziv}
          id={item.id}
          onChange={handleCheckboxChange}
          checked={checkedItems[item.id]}
          globalClass="mt-0 mb-0"
          // disabled={disabled}
          // isPreview={disabled}
        />
      ))}
    </div>
  )
}

const NestedAccordion = ({ data, categories, handleCheckboxChange, level = 0, checkedItems, onChangeHandleSelect, checkedAccordion }) => {
  if (!data) return
  return (
    <div className={`${styles.accordion}`}>
      {Object.keys(data).map(key => {
        const matchingObject = categories.find(item => item.id === parseInt(key))
        const label = matchingObject ? matchingObject.naziv : ''
        const itemKey = key
        return key !== 'usluge' ? (
          <AccordionItem
            itemKey={itemKey}
            label={label}
            level={level}
            //  disabled={disabled}
            data={data}
            checkedItems={checkedItems}
            onChangeHandleSelect={onChangeHandleSelect}
            checkedAccordion={checkedAccordion}
          >
            {Object.keys(data[key]).length > 0 && (
              <NestedAccordion
                handleCheckboxChange={handleCheckboxChange}
                data={data[key]}
                categories={categories}
                level={level + 1}
                checkedItems={checkedItems}
                // disabled={disabled}
                onChangeHandleSelect={onChangeHandleSelect}
                checkedAccordion={checkedAccordion}
              />
            )}
          </AccordionItem>
        ) : (
          <React.Fragment key={key}>
            <NestedCheckboxes
              data={data[key]}
              level={level}
              handleCheckboxChange={handleCheckboxChange}
              checkedItems={checkedItems}
              // disabled={disabled}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}

const NestedServices = ({
  dataNested,
  servicesCategory,
  handleCheckboxChange,
  checkedItems,
  // disabled = false,
  onChangeHandleSelect,
  checkedAccordion
}) => {
  const [nestoNovo, setNestoNovo] = useState(checkedAccordion)

  useEffect(() => {
    setNestoNovo(checkedAccordion)
  }, [checkedAccordion])

  return (
    <div id="accordion-container">
      {/* {createAccordion(dataNested, servicesCategory)} */}
      <NestedAccordion
        data={dataNested}
        categories={servicesCategory}
        handleCheckboxChange={handleCheckboxChange}
        checkedItems={checkedItems}
        // disabled={disabled}
        onChangeHandleSelect={onChangeHandleSelect}
        checkedAccordion={nestoNovo}
      />
    </div>
  )
}

export default NestedServices
