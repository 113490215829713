/** Queries for 'Proizvod/Products' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialProduct } from 'constants/initialValues'
import { label } from 'constants/labels'
import { DATA_TYPES } from 'constants/other'

export const queryGetPagedProductsSearch = (pageNumber, pageSize, options = {}) => {
  const query = { tipPodatka: DATA_TYPES.productsSerbia }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'proizvod_search',
      data: {
        pageNumber,
        pageSize,
        options: { ...options, distinct: true, order: [['naziv', 'ASC']] }
      }
    },
    providesTags: {
      type: tag.products
    }
  }
}

export const queryGetPagedProducts = (pageNumber, pageSize, options = {}) => {
  const query = { tipPodatka: DATA_TYPES.productsSerbia }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'proizvod',
      data: {
        pageNumber,
        pageSize,
        options: { ...options, distinct: true }
      }
    },
    providesTags: {
      type: tag.products
    }
  }
}

export const queryGetProduct = id => {
  return {
    query: {
      name: 'data',
      mode: 'get',
      type: 'proizvod',
      data: {
        options: {
          where: { id }
        }
      }
    },
    providesTags: {
      type: tag.products
    }
  }
}
export const queryGetAllProductsSearch = (options = {}, dependentQuery) => {
  const query = { tipPodatka: DATA_TYPES.productsSerbia }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'proizvod_search',
      data: {
        options: {
          ...options,
          distinct: true,
          order: [['naziv', 'ASC']]
        },
        dependentQuery
      }
    },
    providesTags: {
      type: tag.products
    }
  }
}

export const queryGetAllProducts = (options = {}) => {
  const query = { tipPodatka: DATA_TYPES.productsSerbia }
  if (options.where) options.where = { ...options.where, ...query }
  else options.where = query
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'proizvod',
      data: {
        options: {
          ...options,

          distinct: true,
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.products
    }
  }
}

/**
 * @param {initialProduct } data
 */
export const queryAddProduct = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'proizvod',
      subType: DATA_TYPES.productsSerbia,
      data: { values: data }
    },
    invalidatesTags: [tag.products],
    successMsg: label.successMsgCreate + ' ' + label.msgProducts
  }
}

/**
* @param {initialProduct } data
@param {string} id
*/
export const queryUpdateProduct = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'proizvod',
      subType: DATA_TYPES.productsSerbia,
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.products, tag.productDescription, tag.measureUnitProduct, tag.economyBranchCatProduct],
    successMsg: label.successMsgEdit + ' ' + label.msgProducts
  }
}
/**
@param {string} id
*/
export const queryDeleteProduct = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'proizvod',
      subType: DATA_TYPES.productsSerbia,
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.products],
    successMsg: label.successMsgDelete + ' ' + label.msgProducts
  }
}

export const queryGetAllDescriptionProductByProductId = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'opisProizvoda',
      data: {
        options: {
          where: { proizvodId: id },
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.productDescription
    }
  }
}

export const queryGetAllEconomyBranchCatProductByProductId = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'granaPrivredeProizvod',
      data: {
        options: {
          where: { proizvodId: id }
        }
      }
    },
    providesTags: {
      type: tag.economyBranchCatProduct
    }
  }
}
