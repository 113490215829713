import { api } from '.'
import { logOut, setCredentials, setUser } from 'redux/reducers/authSlice'
import { tag } from './rtkTags'
import toastService from 'services/toastService'
import { label } from 'constants/labels'
import { triggerUserApi } from 'redux/reducers/staticSlice'

export const authApiSlice = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: user => ({
        url: 'api/auth',
        method: 'POST',
        body: {
          mode: 'login',
          socialType: 'base',
          data: {
            values: user
          }
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setCredentials(data))
          dispatch(triggerUserApi(true))
        } catch (err) {
          console.log(err)
        }
      }
    }),
    getUserById: builder.query({
      query: id => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'get',
          socialType: 'base',
          data: {
            options: { where: { id } }
          }
        }
      }),

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
        } catch (err) {
          console.log(err)
        }
      },
      providesTags: result => {
        return [{ type: tag.user, id: result._id }]
      }
    }),
    sendLogout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          console.log(data)
          dispatch(logOut())
          setTimeout(() => {
            dispatch(api.util.resetApiState())
          }, 1000)
        } catch (err) {
          console.log(err)
        }
      }
    }),
    // getMenu: builder.query({
    //   query: () => ({
    //     url: `/api/data/data/forma`,
    //     method: 'POST',
    //     body: {
    //       mode: 'all',
    //       data: {}
    //     }
    //   })
    // })

    registerByAdmin: builder.mutation({
      query: user => ({
        url: 'api/auth',
        method: 'POST',
        body: {
          mode: 'register-by-admin',
          socialType: 'base',
          data: {
            values: user
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successMsgCreate + ' ' + label.msgUser)
        } catch (err) {
          console.log(err)
        }
      },
      invalidatesTags: [tag.users]
    }),
    updateOne: builder.mutation({
      query: user => ({
        url: 'api/auth',
        method: 'POST',
        body: {
          mode: 'update',
          socialType: 'base',
          data: {
            values: user,
            options: { where: { id: user.id } }
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successMsgEdit + ' ' + label.msgUser)
        } catch (err) {
          console.log(err)
        }
      },
      invalidatesTags: [tag.users]
    }),
    changePasswordByAdmin: builder.mutation({
      query: data => ({
        url: 'api/auth',
        method: 'POST',
        body: {
          mode: 'change-pass-by-admin',
          socialType: 'base',
          data: {
            values: data,
            options: { where: { id: data.id } }
          }
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const initialId =
            localStorage.getItem('sci') && localStorage.getItem('sci') !== 'undefined' ? JSON.parse(localStorage.getItem('sci')) : null
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successMsgEdit + ' ' + label.msgAdminPass)
          if (initialId === data.id) {
            dispatch(setCredentials(data))
          }
        } catch (err) {
          console.log(err)
        }
      },
      invalidatesTags: [tag.users]
    }),
    forgotPassword: builder.mutation({
      query: email => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'forgot-password',
          socialType: 'base',
          name: 'auth',
          type: 'base',
          data: {
            values: email
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successForgotPasswordMail)
        } catch (err) {
          console.log(err)
        }
      }
    }),
    forgotPasswordSms: builder.mutation({
      query: mobilni => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'forgot-password-sms',
          socialType: 'base',
          name: 'auth',
          type: 'base',
          data: {
            values: mobilni
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successForgotPasswordSms)
        } catch (err) {
          console.log(err)
        }
      }
    }),
    forgotPasswordCheckSmsCode: builder.mutation({
      query: forgotCode => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'forgot-password-sms-check',
          socialType: 'base',
          name: 'auth',
          type: 'base',
          data: {
            values: forgotCode
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successForgotPasswordSmsCheck)
        } catch (err) {
          console.log(err)
        }
      }
    }),
    changeForgotPassword: builder.mutation({
      query: data => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'change-forgot-password',
          socialType: 'base',
          name: 'auth',
          type: 'base',
          data: {
            values: data
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successChangeForgotPasswordMail)
        } catch (err) {
          console.log(err)
        }
      }
    })
  })
})

export const {
  useLoginMutation,
  useSendLogoutMutation,
  useRefreshMutation,
  useGetUserByIdQuery,
  useForgotPasswordMutation,
  useForgotPasswordSmsMutation,
  useForgotPasswordCheckSmsCodeMutation,
  useChangeForgotPasswordMutation,
  useRegisterByAdminMutation,
  useUpdateOneMutation,
  useChangePasswordByAdminMutation
} = authApiSlice
