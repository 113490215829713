import { CELEBRATION_TYPE } from 'constants/staticOptions'
import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import { queryGetAllMeasureUnits } from 'query/queryMeasureUnit'
import { queryGetAllPortDelivery } from 'query/queryPortDelivery'
import {
  queryGetAllMeasureUnitProductsSearchBosna,
  queryGetAllProductsBosnia,
  queryGetAllValutesByProductIdSearchBosna
} from 'query/queryProductsBosnia'
import {
  queryGetAllMeasureUnitProductsSearchPort,
  queryGetAllProductsPort,
  queryGetAllValutesByProductIdSearchPort
} from 'query/queryProductsPort'
// import { queryGetAllSeaPort } from 'query/querySeaPort'
import { queryGetAllCategoriesBosnia } from 'query/queryCategoryBosnia'
import {
  queryGetAllMeasureUnitProductsSearchMilano,
  queryGetAllProductsMilano,
  queryGetAllValutesByProductIdSearchMilano
} from 'query/queryProductsMilano'
import { queryGetAllStockExchange, queryGetAllStockExchangeNotMilano } from 'query/queryStockExchange'
import { queryGetAllSubBusinessActivity } from 'query/querySubBusinessActivity'
import { queryGetAllTypeOfContact } from 'query/queryTypeOfContact'
import { queryGetAllUsers } from 'query/queryUser'
import { conditionalBusinesActivity } from './conditionalFields'
import { label } from './labels'

import { DATA_TYPES } from './other'
import { queryGetAllCurrency } from 'query/queryCurrency'
import {
  queryGetAllMeasureUnitProductsSearchFeatures,
  queryGetAllProductsByBerzaIdSearch,
  queryGetAllValutesByProductIdSearchFeatures
} from 'query/queryProductsFutures'

export const initialClients = {
  naziv: '',
  pib: '',
  maticniBroj: '',
  pdv: false,
  tipLicaSifra: '',
  adresa: '',
  gradId: null,
  opstinaId: null,
  regionId: null,
  drzavaId: null,
  oblastId: null,
  korisnikId: null,
  statusId: null,
  bankaId: null,
  racun: '',
  devizniRacun: '',
  kontaktTelefon: '',
  fax: '',
  email: '',
  sajt: '',
  direktor: '',
  direktorTelefon: '',
  rodjendan: null,
  slava: null,
  clientActivity: [],
  clientContacts: [],
  clientTimeOfContacts: [],
  goodsBuy: [],
  goodsSell: [],
  tipLicaId: null
}

export const initialClient = {
  naziv: '',
  pib: '',
  maticniBroj: '',
  pdv: false,
  tipLicaSifra: '',
  adresa: '',
  gradId: null,
  opstinaId: null,
  regionId: null,
  drzavaId: null,
  oblastId: null,
  korisnikId: null,
  statusId: null,
  bankaId: null,
  racun: '',
  devizniRacun: '',
  kontaktTelefon: '',
  email: '',
  sajt: '',
  direktor: '',
  direktorTelefon: '',
  obradivaPovrsina: null,
  opisPoslovanja: '',
  tipLicaId: null,
  connectingTables: {
    clientActivity: [],
    clientContacts: [],
    clientTimeOfContacts: [],
    goodsBuy: [],
    goodsSell: []
    // remark: ''
  }
}

export const initialFarm = {
  naziv: '',
  pib: '',
  bpg: '',
  maticniBroj: '',
  pdv: false,
  tipLicaSifra: '',
  adresa: '',
  gradId: null,
  opstinaId: null,
  regionId: null,
  drzavaId: null,
  oblastId: null,
  korisnikId: null,
  statusId: null,
  bankaId: null,
  racun: '',
  devizniRacun: '',
  kontaktTelefon: '',
  fax: '',
  email: '',
  sajt: '',
  direktor: '',
  direktorTelefon: '',
  rodjendan: null,
  slava: null,
  clientActivity: [],
  clientContacts: [],
  clientTimeOfContacts: [],
  goodsBuy: [],
  goodsSell: [],
  tipLicaId: null
}

export const initialEntityType = {
  naziv: '',
  sifra: '',
  aktivan: true
}

export const initialCategory = {
  naziv: '',
  aktivan: true,
  tipPodatka: DATA_TYPES.categorySerbia
}

export const initialCategoryPort = {
  naziv: '',
  aktivan: true,
  tipPodatka: DATA_TYPES.categoryPort
}

export const initialCategoryBosnia = {
  naziv: '',
  aktivan: true,
  tipPodatka: DATA_TYPES.categoryBih
}

export const initialCategoryFutures = {
  naziv: '',
  aktivan: true,
  tipPodatka: DATA_TYPES.categoryFutures
}

export const initialProduct = {
  naziv: '',
  aktivan: true,
  kategorijaId: null,
  jedinicaMereId: null,
  granaPrivrede: [],
  sifraGranaPrivrede: '',
  tipPodatka: DATA_TYPES.productsSerbia,
  rod: false
}
export const initialEconomyBranch = {
  naziv: '',
  sifra: '',
  aktivan: true
}

export const initialProductDesc = {
  naziv: '',
  proizvodId: null,
  aktivan: true
}

export const initialBusinessActivity = {
  naziv: '',
  aktivan: true
}

export const initialSubBusinessActivity = {
  naziv: '',
  delatnostId: null,
  aktivan: true
}

export const initialBank = {
  naziv: '',
  aktivan: true
}

export const initialPortDelivery = {
  naziv: '',
  aktivan: true
}

export const initialProductFutures = {
  naziv: '',
  tipPodatka: DATA_TYPES.productsFutures,
  aktivan: true,
  kategorijaId: null,
  connectingTables: {
    productsCurrency: [],
    jediniceMere: []
  }
}

export const initialProductMilano = {
  naziv: '',
  tipPodatka: DATA_TYPES.productsMilano,
  valutaId: null,
  jedinicaMereId: null,
  cenaJedinicaMereId: null,
  aktivan: true,
  connectingTables: {
    productsCurrency: [],
    jediniceMere: []
  }
}
export const initialProductsPort = {
  naziv: '',
  kategorijaId: null,
  valutaId: null,
  jedinicaMereId: null,
  cenaJedinicaMereId: null,
  tipPodatka: DATA_TYPES.productsPort,
  connectingTables: {
    productsCurrency: [],
    jediniceMere: []
  }
}

export const initialProductBosnia = {
  naziv: '',
  aktivan: true,
  kategorijaId: null,
  valutaId: null,
  jedinicaMereId: null,
  tipPodatka: DATA_TYPES.productsBih,
  pdv: false,
  cenaJedinicaMereId: null,
  connectingTables: {
    productsCurrency: [],
    jediniceMere: []
  }
}
export const initialDelivery = {
  naziv: '',
  aktivan: true
}

export const initialCountry = {
  naziv: '',
  sort: 0,
  aktivan: true,
  pozivniBroj: ''
}

export const initialRegion = {
  naziv: '',
  drzavaId: null,
  sort: 0,
  aktivan: true
}

export const initialTownship = {
  naziv: '',
  postanskiBroj: '',
  regionId: null,
  drzavaId: null,
  sort: 0,
  aktivan: true
}

export const initialCity = {
  naziv: '',
  postanskiBroj: '',
  regionId: null,
  drzavaId: null,
  opstinaId: null,
  sort: 0,
  aktivan: true
}

export const initialPositionInCompany = {
  naziv: '',
  sort: 0,
  aktivan: true
}

export const initialUser = {
  korisnickoIme: '',
  lozinka: '',
  ime: '',
  prezime: '',
  email: '',
  mobilni: '',
  poslovniBroj: '',
  ulogaId: null,
  godinaRodjenja: null,
  maticniBroj: '',
  datumPocetkaRadnogOdnosa: null,
  pozicijaFirmaId: ''
}

export const initialService = {
  naziv: '',
  kod: '',
  kategorijaUslugaId: null,
  aktivan: true
}

export const initialServiceCategory = {
  naziv: '',
  sort: 0,
  parentKategorijaId: null,
  aktivan: true
}

export const initialStatus = {
  naziv: '',
  aktivan: true,
  trajanje: null,
  vazeUsluge: true,
  sledeciStatusId: null,
  sistemski: false
}

export const initialTypeOfContact = {
  naziv: '',
  aktivan: true
}

export const initialMeasureUnit = {
  naziv: '',
  oznaka: '',
  aktivan: true
}

export const initialCreateReport = {
  description: '',
  naziv: '',
  datumUnosa: '',
  kod: ''
}

export const initialMeasureUnitsRatio = {
  izJedinicaMereId: null,
  uJedinicaMereId: null,
  odnos: ''
}

export const initialLogin = {
  email: 'admin@gmail.com',
  lozinka: 'Test1234',
  type: 'korisnik'
}

export const initialEntityStatus = {
  naziv: '',
  sort: 0,
  aktivan: true
}

export const initialPackage = {
  naziv: '',
  tipTrajanja: null,
  trajanje: '',
  opis: '',
  kratakOpis: '',
  status: null,
  usluge: [],
  kategorije: [],
  aktivan: true
}

export const initialAnnouncement = {
  tip: 1,
  napomena: '',
  cena: null,
  kolicina: null,
  rod: null,
  status: null,
  proizvodId: null,
  opisProizvodaId: null,
  unetaJedinicaMereId: null,
  unetaCenaJedinicaMereId: null,
  podrazumevanaJedinicaMereId: null,
  podrazumevanaCenaJedinicaMereId: null,
  podrazumevanValutaId: null,
  klijentId: null,
  valutaId: '',
  kontakti: [],
  datumVazenja: null,
  kretanjeCena: false,
  robniIzvestaj: false,
  cenaVidljiva: false,
  oglasVidljiv: false,
  pdv: true,
  fcaDogovor: false,
  fcaKupac: false,
  connectingTables: {
    fca: []
  },
  tipPlacanja: 1
}
export const initialRealizations = {
  objavaKupcaId: null,
  objavaProdavcaId: null,
  kupacId: null,
  prodavacId: null,
  jedinicaMereId: null,
  valutaId: null,
  proizvodId: null,
  opisProizvodaId: null,
  regionId: null,
  gradId: null,
  datum: null,
  kolicina: null,
  cena: null,
  fcaKupac: false,
  fcaDogovor: false,
  napomenaProizvoda: '',
  komentar: '',
  tipPlacanja: null
}

export const initialBlog = {
  naslov: '',
  podnaslov: '',
  tekst: '',
  blogStatus: null,
  aktivan: true
}

export const initialContactPerson = {
  ime: '',
  prezime: '',
  email: '',
  mobilni: '',
  klijentId: null,
  funkcija: '',
  aktivan: true
}

export const initialWorkField = {
  naziv: '',
  aktivan: true
}

export const initialSeaPort = {
  naziv: '',
  drzavaId: null,
  gradId: null,
  aktivan: true
}

export const initialStockExchange = {
  naziv: '',
  drzavaId: null,
  valutaId: null,
  aktivan: true
}

export const initialHistoryReport = {
  link: '',
  type: '',
  singleEmail: ''
}

export const initialCurrency = {
  naziv: '',
  kod: '',
  aktivan: true
}

export const initialWorldSeaPort = {
  proizvodId: null,
  datumUnosa: null,
  lukaUsloviUtovaraId: null,
  isporuka: '',
  cena: '',
  valutaPlacanja: '',
  valutaId: null,
  jedinicaMereId: null,
  aktivan: true,
  podrazumevanaJedinicaMereId: null,
  podrazumevanValutaId: null,
  podrazumevanaCenaJedinicaMereId: null
}

export const initialWorldStockExchange = {
  berzaId: null,
  proizvodId: null,
  jedinicaMereId: null,
  valutaId: null,
  datumUnosa: null,
  cena: null,
  isporuka: '',
  aktivan: true,
  podrazumevanaJedinicaMereId: null,
  podrazumevanValutaId: null,
  podrazumevanaCenaJedinicaMereId: null
}

export const initialWorldStockExchangeMilano = {
  berzaId: null,
  proizvodId: null,
  jedinicaMereId: null,
  valutaId: null,
  datumUnosa: null,
  minCena: '',
  maxCena: '',
  aktivan: true,
  podrazumevanaJedinicaMereId: null,
  podrazumevanValutaId: null,
  podrazumevanaCenaJedinicaMereId: null
}

export const initialPriceMovement = {
  kategorijaId: null,
  proizvodId: null,
  datum: null,
  minCena: '',
  maxCena: '',
  valutaId: null,
  jedinicaMereId: null,
  aktivan: true,
  periodStart: null,
  periodEnd: null,
  podrazumevanaJedinicaMereId: null,
  podrazumevanValutaId: null,
  podrazumevanaCenaJedinicaMereId: null
}
export const initialRole = { naziv: '', pravaPristupa: {} }

export const initialOrganizationField = {
  naziv: '',
  adresa: '',
  grad: '',
  mobilni: '',
  telefon: '',
  fax: '',
  website: '',
  email: '',
  pib: '',
  maticniBr: '',
  sifraDelatnosti: '',
  ziroRacuni: [{ banka: '', ziroRacun: '' }]
}

export const multiFieldsOrgInitial = [
  { dbName: 'banka', label: label.orgBankName, type: 'input' },
  { dbName: 'ziroRacun', label: label.orgBankNum, type: 'input' }
]

export const initialCustomGoodsImportAndExport = {
  datumUnosa: null,
  datum: null,
  importovaneRobe: [],
  header: [],
  dbName: '',
  aktivan: true
}

export const multiFieldsDelatnostInitial = [
  {
    dbName: 'delatnostId',
    collection: 'delatnost',
    label: label.businessActivity,
    type: 'select',
    selectLabel: 'naziv',
    rtkQuery: queryGetAllBusinessActivity(),
    conditional: conditionalBusinesActivity,
    required: true
  },
  {
    dbName: 'podDelatnostId',
    collection: 'podDelatnost',
    label: label.subBusinessActiviti,
    type: 'select',
    selectLabel: 'naziv',
    rtkQuery: queryGetAllSubBusinessActivity(),
    conditional: conditionalBusinesActivity,
    required: true
  }
]

export const multiFieldsCelebrationsInitial = [
  { dbName: 'ime', label: label.inputFullName, type: 'input', inputType: 'text', required: true },
  { dbName: 'datumProslave', label: label.date, type: 'noyeardatapicker', required: true },
  { dbName: 'tipProslave', label: label.inputTypeSelabration, type: 'select', options: CELEBRATION_TYPE, required: true }
]

export const multiFieldsMeasureUnitIntial = [
  {
    label: label.measureUnit,
    dbName: 'jedinicaMereId',
    nestedObj: 'naziv',
    type: 'select',
    rtkQuery: queryGetAllMeasureUnits(),
    selectLabel: 'naziv',
    required: true
  },
  { dbName: 'odnos', label: label.relationshipMeasureUnit, type: 'input', inputType: 'number', required: true },
  { dbName: 'podrazumevana', label: label.defaultMeasureUnit, type: 'checkbox' },
  { dbName: 'podrazumevanaValutaJedinica', label: label.measureUnitsValute, type: 'checkbox' }
]
// export const multiFieldsMeasureForProductsIntial = [
//   {
//     label: label.measureUnit,
//     dbName: 'jedinicaMereId',
//     nestedObj: 'naziv',
//     type: 'select',
//     rtkQuery: queryGetAllMeasureUnits(),
//     selectLabel: 'naziv',
//     required: true
//   },
//   { dbName: 'odnos', label: label.relationshipMeasureUnit, type: 'input', inputType: 'number', required: true },
//   { dbName: 'podrazumevana', label: 'Podrazumevana', type: 'checkbox' }
// ]
export const multiFieldsDescriptionProductInitial = [
  { dbName: 'naziv', label: label.productsDescInput, type: 'input', inputType: 'text', required: true }
]

export const multiFieldsCurrencyProductInitial = [
  {
    label: label.currency,
    dbName: 'valutaId',
    nestedObj: 'naziv',
    type: 'select',
    rtkQuery: queryGetAllCurrency(),
    selectLabel: 'kod',
    required: true
  },
  { dbName: 'podrazumevana', label: 'Podrazumevana', type: 'checkbox' }
]

export const multiFieldsContactsInitial = [
  { dbName: 'ime', label: label.contactPersonInput, type: 'input', inputType: 'text', required: true },
  { dbName: 'email', label: label.emailInput, type: 'input', inputType: 'text', required: true },
  { dbName: 'mobilni', label: label.mobileInput, type: 'phone', defaultValue: '', required: true },
  { dbName: 'funkcija', label: label.functionInput, type: 'input', inputType: 'text' },
  { dbName: 'kontaktOglas', label: label.isAddContactInput, type: 'checkbox' }
]

export const multiFieldsContactTimeInitial = [
  { dbName: 'datumKontakta', label: label.dateContactInput, type: 'datapicker', required: true },
  {
    dbName: 'korisnikId',
    label: label.salesSpecialist,
    type: 'select',
    rtkQuery: queryGetAllUsers(),
    selectLabel: 'korisnickoIme',
    required: true
  },
  {
    dbName: 'vrstaKontaktaId',
    label: label.typeOfContactInput,
    type: 'select',
    rtkQuery: queryGetAllTypeOfContact(),
    selectLabel: 'naziv'
  },
  { dbName: 'poruka', label: label.messageInput, type: 'input', inputType: 'text' }
]

export const multiFieldsWorldSeaPortInitial = [
  {
    dbName: 'proizvodId',
    label: label.productInput,
    type: 'select',
    rtkQuery: queryGetAllProductsPort(),
    selectLabel: 'naziv',
    required: true,
    dependentFields: [
      {
        dependentField: 'proizvodId',
        dbField: 'id',
        updateOptionsDbField: 'valutaId',
        rtkQuery: queryGetAllValutesByProductIdSearchPort
      },
      {
        dependentField: 'proizvodId',
        dbField: 'id',
        updateOptionsDbField: 'jedinicaMereId',
        rtkQuery: queryGetAllMeasureUnitProductsSearchPort
      }
    ]
  },
  { dbName: 'datumUnosa', label: label.dateOfImportInput, type: 'datapicker', required: true, style: { maxWidth: '200px' } },
  {
    dbName: 'lukaUsloviUtovaraId',
    label: label.productSeaPortInput,
    type: 'select',
    rtkQuery: queryGetAllPortDelivery(),
    selectLabel: 'naziv',
    required: true
  },
  {
    dbName: 'isporuka',
    label: label.delivery,
    type: 'multidatepicker',
    required: true
  },
  {
    dbName: 'cena',
    label: label.priceInput,
    type: 'input',
    inputType: 'number',
    placeholder: '00.00',
    required: true,
    style: { maxWidth: '150px' }
  },
  // { dbName: 'valutaPlacanja', label: label.seaPortCurrency, type: 'input', inputType: 'text', required: true, style: { maxWidth: '150px' } },
  {
    dbName: 'valutaId',
    label: label.currency,
    type: 'select',
    selectLabel: 'valutaKod',
    selectValue: 'valutaId',
    required: true,
    style: { maxWidth: '150px' }
  },
  {
    dbName: 'jedinicaMereId',
    label: label.measureUnit,
    type: 'select',
    selectLabel: 'jmNaziv',
    selectValue: 'jedinicaMereId',
    required: true,
    style: { maxWidth: '150px' }
  }
  // {
  //   dbName: 'periodVazenjaCena',
  //   label: label.priceDatePeriodInput,
  //   type: 'datapickerrange',
  //   start: 'periodVazenjaCenaStart',
  //   end: 'periodVazenjaCenaEnd'
  // }
]

export const multiFieldsWorldStockExchangeInitial = [
  {
    dbName: 'berzaId',
    label: label.stockExchange,
    type: 'select',
    rtkQuery: queryGetAllStockExchangeNotMilano(),
    selectLabel: 'naziv',
    required: true,
    dependentFields: [
      {
        dependentField: 'berzaId',
        dbField: 'id',
        updateOptionsDbField: 'proizvodId',
        rtkQuery: queryGetAllProductsByBerzaIdSearch
      }
    ]
  },

  {
    dbName: 'proizvodId',
    label: label.productInput,
    type: 'select',
    // rtkQuery: queryGetAllProductsByBerzaIdSearch(),
    selectLabel: 'proizvodNaziv',
    selectValue: 'proizvodId',
    required: true,
    uniqueDbName: 'proizvodId',
    dependentFields: [
      {
        dependentField: 'proizvodId',
        dbField: 'proizvodId',
        dependentField2: 'berzaId',
        dbField2: 'berzaId',
        updateOptionsDbField: 'valutaId',
        rtkQuery: queryGetAllValutesByProductIdSearchFeatures
      },
      {
        dependentField: 'proizvodId',
        dbField: 'proizvodId',
        dependentField2: 'berzaId',
        dbField2: 'berzaId',
        updateOptionsDbField: 'jedinicaMereId',
        rtkQuery: queryGetAllMeasureUnitProductsSearchFeatures
      }
    ]
  },
  { dbName: 'datumUnosa', label: label.dateOfImportInput, type: 'datapicker' },
  {
    dbName: 'isporuka',
    label: label.delivery,
    type: 'multidatepicker'
  },
  { dbName: 'cena', label: label.priceInput, type: 'input', inputType: 'number', placeholder: '00.00', required: true },
  {
    dbName: 'valutaId',
    label: label.currency,
    type: 'select',
    selectLabel: 'valutaKod',
    selectValue: 'valutaId',
    required: true,
    style: { maxWidth: '150px' }
  },
  {
    dbName: 'jedinicaMereId',
    label: label.measureUnit,
    type: 'select',
    selectLabel: 'jmNaziv',
    selectValue: 'jedinicaMereId',
    required: true,
    style: { maxWidth: '150px' }
  }
]

export const multiFieldsWorldStockExchangeMilanoInitial = [
  {
    dbName: 'berzaId',
    label: label.stockExchange,
    type: 'select',
    rtkQuery: queryGetAllStockExchange(),
    selectLabel: 'naziv',
    required: true,
    disabledOne: true,
    style: { maxWidth: '200px' }
  },
  {
    dbName: 'proizvodId',
    label: label.productInput,
    type: 'select',
    rtkQuery: queryGetAllProductsMilano(),
    selectLabel: 'naziv',
    required: true,
    dependentFields: [
      {
        dependentField: 'proizvodId',
        dbField: 'id',
        updateOptionsDbField: 'valutaId',
        rtkQuery: queryGetAllValutesByProductIdSearchMilano
      },
      {
        dependentField: 'proizvodId',
        dbField: 'id',
        updateOptionsDbField: 'jedinicaMereId',
        rtkQuery: queryGetAllMeasureUnitProductsSearchMilano
      }
    ]
  },
  { dbName: 'datumUnosa', label: label.dateOfImportInput, type: 'datapicker', required: true },
  {
    dbName: 'cenaMin',
    label: label.priceMinInput,
    type: 'input',
    inputType: 'number',
    placeholder: '00.00',
    required: true,
    style: { maxWidth: '200px' }
  },
  {
    dbName: 'cenaMax',
    label: label.priceMaxInput,
    type: 'input',
    inputType: 'number',
    placeholder: '00.00',
    required: true,
    style: { maxWidth: '200px' }
  },
  {
    dbName: 'valutaId',
    label: label.currency,
    type: 'select',
    selectLabel: 'valutaKod',
    selectValue: 'valutaId',
    required: true,
    style: { maxWidth: '150px' }
  },
  {
    dbName: 'jedinicaMereId',
    label: label.measureUnit,
    type: 'select',
    selectLabel: 'jmNaziv',
    selectValue: 'jedinicaMereId',
    required: true,
    style: { maxWidth: '150px' }
  }
]

export const multiFieldsPriceMovementBihInitial = [
  {
    dbName: 'kategorijaId',
    label: label.subGroupBosnia,
    type: 'select',
    rtkQuery: queryGetAllCategoriesBosnia(),
    selectLabel: 'naziv',
    required: true,
    dependentFields: [
      {
        dependentField: 'kategorijaId',
        dbField: 'id',
        updateOptionsDbField: 'proizvodId',
        rtkQuery: queryGetAllProductsBosnia
      }
    ]
  },
  {
    dbName: 'proizvodId',
    label: label.productInput,
    type: 'select',
    rtkQuery: queryGetAllProductsBosnia(),
    selectLabel: 'naziv',
    required: true,
    dependentFields: [
      {
        dependentField: 'proizvodId',
        dbField: 'id',
        updateOptionsDbField: 'valutaId',
        rtkQuery: queryGetAllValutesByProductIdSearchBosna
      },
      {
        dependentField: 'proizvodId',
        dbField: 'id',
        updateOptionsDbField: 'jedinicaMereId',
        rtkQuery: queryGetAllMeasureUnitProductsSearchBosna
      }
    ]
  },
  { dbName: 'datum', label: label.dateOfImportInput, type: 'datapicker', style: { maxWidth: '200px' } },
  {
    dbName: 'cenaMin',
    label: label.priceMinInput,
    type: 'input',
    inputType: 'number',
    placeholder: '00.00',
    required: true,
    style: { maxWidth: '150px' }
  },
  {
    dbName: 'cenaMax',
    label: label.priceMaxInput,
    type: 'input',
    inputType: 'number',
    placeholder: '00.00',
    required: true,
    style: { maxWidth: '150px' }
  },
  // {
  //   dbName: 'valutaPlacanja',
  //   label: label.stockExchangeBosniaCurrency,
  //   type: 'input',
  //   inputType: 'text',
  //   required: true,
  //   style: { maxWidth: '150px' }
  // }
  {
    dbName: 'valutaId',
    label: label.currency,
    type: 'select',
    selectLabel: 'valutaKod',
    selectValue: 'valutaId',
    required: true,
    style: { maxWidth: '150px' }
  },
  {
    dbName: 'jedinicaMereId',
    label: label.measureUnit,
    type: 'select',
    selectLabel: 'jmNaziv',
    selectValue: 'jedinicaMereId',
    required: true,
    style: { maxWidth: '150px' }
  }
]

export const initialConnectTbActivity = { delatnostId: null, podDelatnostId: null }
export const initialConnectTbContacts = { ime: '', email: '', telefon: '', funkcija: '' }
export const initialConnectTbTimeOfContacts = { datumKontakta: null, poruka: '', korisnikId: null, vrstaKontaktaId: null }
export const initialConnectCelebration = { ime: '', datumProslave: null, tipProslave: null }
export const initialMeasureUnitProduct = { jedinicaMereId: null, odnos: '', podrazumevana: false }
export const initialDescriptionProduct = { naziv: '' }
export const initialMeasureForProducts = { jedinicaMereId: null, odnos: '', podrazumevana: false }
export const initialCurrencyForProducts = { valutaId: null, podrazumevana: false }
