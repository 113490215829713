/** Queries for 'Kretanje cena BiH' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialPriceMovement } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedPriceMovementBih = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'kretanjeCenaBih',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.priceMovementBih
    }
  }
}

export const queryGetAllPriceMovementBih = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'kretanjeCenaBih',
      data: { options }
    },
    providesTags: {
      type: tag.priceMovementBih
    }
  }
}
/**
 * @param {initialPriceMovement} data
 */
export const queryAddPriceMovementBih = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'kretanjeCenaBih',
      data: { values: data }
    },
    invalidatesTags: [tag.priceMovementBih],
    successMsg: label.successMsgCreate + ' ' + label.msgPriceMovementBih
  }
}

/**
* @param {initialPriceMovement} data
@param {string} id
*/
export const queryUpdatePriceMovementBih = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'kretanjeCenaBih',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.priceMovementBih],
    successMsg: label.successMsgEdit + ' ' + label.msgPriceMovementBih
  }
}
/**
@param {string} id
*/
export const queryDeletePriceMovementBih = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'kretanjeCenaBih',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.priceMovementBih],
    successMsg: label.successMsgDelete + ' ' + label.msgPriceMovementBih
  }
}

export const queryAddPriceMovementBihMultiple = data => {
  return {
    query: {
      name: 'data',
      mode: 'newMulti',
      type: 'kretanjeCenaBih',
      data: { values: data }
    },
    invalidatesTags: [tag.priceMovementBih],
    successMsg: label.successMsgCreate + ' ' + label.priceMovementBih
  }
}

export const queryGetLastInsertPriceMovementBih = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'kretanjeCenaBih',
      data: { options, subMode: 'lastInsert' }
    },
    providesTags: {
      type: tag.priceMovementBih
    }
  }
}
