import React, { useState } from 'react'
import styles from './auth.module.scss'
import { label } from 'constants/labels'
import { BoxContainer } from 'components/containers/containers'
import Button from '../../components/button/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormMailResetPassword from '../../components/forms/formMailResetPassword'
import FormPhoneResetPassword from '../../components/forms/formPhoneResetPassword'
import { useForgotPasswordMutation, useForgotPasswordSmsMutation } from 'api/authApiSlice'
import { NavLink, useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
  const [showFormPhone, setShowFormPhone] = useState(false)
  const [showFormMail, setShowFormMail] = useState(false)
  const [forgotPasswordSendMail] = useForgotPasswordMutation()
  const [forgotPasswordSendSms] = useForgotPasswordSmsMutation()
  const navigate = useNavigate()

  const handleResetType = type => () => {
    if (type === 'mail') {
      setShowFormMail(true)
      setShowFormPhone(false)
    } else {
      setShowFormMail(false)
      setShowFormPhone(true)
    }
  }

  const onSubmit = async data => {
    await forgotPasswordSendMail(data)
    navigate('/')
  }

  const onSubmitSms = async data => {
    await forgotPasswordSendSms(data)
    navigate('/enter-sms-code')
  }

  return (
    <div className={styles.wrapper}>
      <BoxContainer globalClass="p-3">
        <div>
          <h2>Zaboravili ste lozinku?</h2>
          <Button
            onClick={handleResetType('mail')}
            label={label.resetMail}
            iconLeft={<FontAwesomeIcon icon="fa-envelope" />}
            globalClass="mt-2 w-100"
          />
          <Button
            onClick={handleResetType('phone')}
            label={label.resetPhone}
            iconLeft={<FontAwesomeIcon icon="fa-mobile-screen" />}
            globalClass="mt-2 w-100"
          />
        </div>
        <div className="ta-c mt-2">
          Nazad na <NavLink to="/">Login stranicu!</NavLink>
        </div>
      </BoxContainer>
      <BoxContainer globalClass="p-3">
        {showFormMail && <FormMailResetPassword onSubmit={onSubmit} />}
        {showFormPhone && <FormPhoneResetPassword onSubmit={onSubmitSms} />}
      </BoxContainer>
    </div>
  )
}

export default ForgotPassword
