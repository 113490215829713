import SlateEditor from 'components/slateEditor'
import { getTemplateBill } from 'components/slateEditor/templates/templatesBill'
import { BILL_TYPES } from 'constants/other'
import React from 'react'
import { isObjectEmpty } from 'utils'
const testReportData2 = {
  owner: {
    name: 'Info Tim Logistika DOO Novi Sad',
    address: 'Gagarinova1/19,Novi Sad',
    mobile: '065/3833191',
    tel: '021/6618341',
    fax: '021/6618341',
    web: 'www.infoteam.rs',
    email: 'info@infoteam.com',
    pib: '107488692',
    firmNum: '20813415',
    code: '2333',
    bankNum1: '20813415 Banca Intesa',
    bankNum2: '20813415 Unicredit bank'
  },
  client: {
    name: 'Uvalla D.O.O Omoljica',
    address: 'Patrijarh Arsenija Cernojevica 22',
    town: 'Omoljica',
    pib: '324242424',
    firmNum: '3242424242',
    tax: '30000,00',
    pdv: '660.00',
    total: '50000.00'
  },
  report: {
    billNum: 'r471-00/23',
    preBillNum: 'r471-00/23',
    avansNum: 'r471-00/23',
    paymentDate: '21.11.2022',
    issueDate: '21.11.2022',
    paymentType: 'bezgotovinski',
    taxRemark: 'Nema',
    paymentDeadline: 'Odmah'
  },
  products: [
    {
      name: 'Web i mobilna aplikacija od 10.03 do 10.04',
      unit: '',
      quantity: 1,
      pricePerUnit: '232323,00',
      pdv: '500.00',
      pdvRate: '20%',
      tax: '3000.00',
      total: '55000.00'
    },
    {
      name: 'Mobilna aplikacija od 10.03 do 10.04',
      unit: '',
      quantity: 13,
      pricePerUnit: '232323.00',
      pdv: '500.00',
      pdvRate: '20%',
      tax: '3000',
      total: '55000.'
    }
  ],
  productTotalPrice: '110000.00'
}

const SlateBillReport = () => {
  return <div>{!isObjectEmpty(testReportData2) && <SlateEditor template={getTemplateBill(testReportData2, BILL_TYPES.preBill)} />}</div>
}

export default SlateBillReport
