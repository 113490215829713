/** Page  'Poddelatnost' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import Button from 'components/button'
import { FormWrapper, TwoColumns } from 'components/containers/containers'
import FormSubBisinessActivity from 'components/forms/formSubBisinessActivity'
import HistoryTable from 'components/historyTable'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { subBussinesActivityFields } from 'constants/searchFields'
import { headingSubBusinessActivity } from 'constants/tbHeading'
import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import {
  queryAddSubBusinessActivity,
  queryDeleteSubBusinessActivity,
  queryGetPagedSubBusinessActivity,
  queryUpdateSubBusinessActivity
} from 'query/querySubBusinessActivity'
import { queryGetAllUsers } from 'query/queryUser'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData } from 'redux/reducers/staticSlice'
import { isObjectEmpty } from 'utils'

const SubBisinessActivity = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const { data: subBusinessActivity = [] } = useGetAllQuery(queryGetPagedSubBusinessActivity(pageNumber, pageSize, searchOptions))
  const [createData] = useUpdateCreateOneMutation()
  const [updateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (subBusinessActivity.rows && subBusinessActivity.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, subBusinessActivity?.rows])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, id) => {
    dispatch(setIsLoadingData(true))
    if (id) {
      const res = await updateData(queryUpdateSubBusinessActivity(data, id))
      if (!res.error) dispatch(setIsDisabled(true))
    } else {
      const res = await createData(queryAddSubBusinessActivity(data))
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
  }

  const onRowClick = data => {
    dispatch(setIsDisabled(true))
    navigate(`/poddelatnost?id=${data.id}`)
    setData(data)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/poddelatnost`)
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteSubBusinessActivity(data.id))
      if (!res.error) dispatch(closeModal(1))
      navigate(`/poddelatnost`)
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteSubBussinesActivity} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
  }
  const showModalForForm = () => {
    navigate(`/poddelatnost`)
    dispatch(
      openModal({
        arrItem: {
          content: (
            <FormWrapper>
              <h2>{label.addSubBusinessActivity}</h2>
              <FormSubBisinessActivity existingData={data} onSubmit={onSubmit} id={null} />
            </FormWrapper>
          ),
          order: 1,
          size: 'md'
        },
        active: 1
      })
    )
  }

  const onSubmitSearch = async data => {
    setSearchOptions(data)
    setPageNumber(1)
  }

  const openHistory = () => {
    dispatch(
      openModal({
        arrItem: {
          content: (
            <div>
              <HistoryTable
                collection="podDelatnost"
                link="/poddelatnost?id="
                connectionTb={[
                  { rtkQuery: queryGetAllUsers(), dbName: 'korisnikId' },
                  { rtkQuery: queryGetAllBusinessActivity(), dbName: 'delatnostId' }
                ]}
              />
            </div>
          ),
          order: 1,
          size: 'xlg'
        },
        active: 1
      })
    )
  }
  return (
    <div>
      <Title title={label.subBusinessActivity} total={subBusinessActivity?.count}>
        <Button
          onClick={showModalForForm}
          label={label.add}
          iconLeft={<FontAwesomeIcon icon="fa-plus" />}
          accessAction="ADD"
          accessCode={code}
        />
      </Title>
      <Search fields={subBussinesActivityFields} onSearch={onSubmitSearch} />
      {!!subBusinessActivity?.rows?.length && (
        <h3 className="history-link" onClick={openHistory}>
          {label.historyChanges}
        </h3>
      )}
      <TwoColumns>
        <Table
          heading={headingSubBusinessActivity}
          data={subBusinessActivity?.rows}
          onRowClick={onRowClick}
          onAction={onActionClick}
          havePagination={true}
          onPaginationChange={onPaginationChange}
          pageNumber={pageNumber}
          pageSize={pageSize}
          total={subBusinessActivity?.count}
        />
        {!isObjectEmpty(data) && (
          <FormWrapper>
            <FormSubBisinessActivity existingData={data} header={true} id={id} onSubmit={onSubmit} onDelete={openDeleteModal} code={code} />
          </FormWrapper>
        )}
      </TwoColumns>
    </div>
  )
}
export default SubBisinessActivity
