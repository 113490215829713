/** Page  'Uloge' */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Title from 'components/title'
import Button from 'components/button'
import { label } from 'constants/labels'
import { useGetAllQuery } from 'api/dataApiSlice'
import { queryGetAllRoles } from 'query/queryRole'
import Table from 'components/table'
import { headingRoles } from 'constants/tbHeading'
import { TwoColumns } from 'components/containers/containers'

const Roles = ({ code }) => {
  const navigate = useNavigate()
  const { data: roles = [] } = useGetAllQuery(queryGetAllRoles())

  const onRowClick = e => navigate(`${e.id}`)

  return (
    <div>
      <Title title={label.roles} total={roles.length} globalClass="mb-2">
        <Button
          label={label.add}
          iconLeft={<FontAwesomeIcon icon="fa-plus" />}
          onClick={() => navigate('new')}
          accessAction="ADD"
          accessCode={code}
        />
      </Title>
      <TwoColumns>
        <Table heading={headingRoles} data={roles} onRowClick={onRowClick} />
        <div></div>
      </TwoColumns>
    </div>
  )
}

export default Roles
